import React, { useEffect, useState } from 'react';
import { MenuTop as ContactMenuTop } from '../MenuTop';
import { notification } from 'antd';
import { ContactProps } from '../types';
import { Spin } from 'antd/es';
import { ContactCard } from '../cards';
import ContactStore from '../stores/ContactStore';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import ErrorRender from '../../../library/outlets/ErrorRender';
import { PageHeader } from '../../../library';
import { ContactCreateForm, ContactDetailForm } from '../forms';
import { toastSuccess } from '../../../utils/toast';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const ContactPage = observer(() => {
    const [isLoading, setIsLoading] = useState(false);
    const [contact, setContact] = useState<ContactProps | null>(null);
    const [stage, setStage] = useState<'create' | 'update' | 'list'>('list');

    const searchParams = useQuery();

    async function handleListCategories() {
        setIsLoading(true);
        await ContactStore.list_categories();
        setIsLoading(false);
    }

    async function handleList(params = {}) {
        setIsLoading(true);
        try {
            await ContactStore.list(params);
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    useEffect(() => {
        handleList(searchParams);
        handleListCategories();
    }, [location.search]);

    async function handleCreate(payload: ContactProps) {
        setIsLoading(true);
        try {
            await ContactStore.create(payload);
            await handleList();
            toastSuccess('Contacto registrado!');
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    async function handleRemove(id: number) {
        try {
            setIsLoading(true);
            await ContactStore.remove(id);
            toastSuccess('Contacto eliminado!');
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    return (
        <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
            <ContactMenuTop active={'contacts'} />
            <Spin spinning={isLoading}>
                <PageHeader
                    setStage={(stage: 'create' | 'update' | 'list') => {
                        setContact(null);
                        setStage(stage);
                    }}
                    stage={stage}
                    title={
                        stage === 'list' ? 'Contactos' : stage === 'create' ? 'Nuevo contacto' : 'Actualizar contacto'
                    }
                    subtitle={'Información de personas que colaboran con la parroquia'}
                    titleButton={
                        stage === 'list' ? 'Nuevo contacto' : stage === 'create' ? 'Ver contactos' : 'Ver contactos'
                    }
                />
                {stage === 'list' ? (
                    <div className="w-full grid grid-cols lg:grid-cols-2 xl:grid-cols-3 gap-4 mt-2">
                        {!isLoading && (
                            <ContactCard
                                contacts={ContactStore.contacts}
                                setContact={setContact}
                                setIsDetailContactModalVisible={() => setStage('update')}
                                handleRemove={handleRemove}
                            />
                        )}
                    </div>
                ) : stage === 'create' ? (
                    <div className={'py-6'}>
                        <ContactCreateForm
                            categories={ContactStore.categories}
                            handleCreate={async (payload: ContactProps, list: boolean) => {
                                await handleCreate(payload);
                                if (list) {
                                    await handleList();
                                    setStage('list');
                                }
                            }}
                        />
                    </div>
                ) : (
                    stage === 'update' &&
                    contact !== null && (
                        <div className={'py-6'}>
                            <ContactDetailForm
                                contact={contact}
                                categories={ContactStore.categories}
                                handleListContacts={value => {
                                    value && handleList();
                                }}
                            />
                        </div>
                    )
                )}
            </Spin>
        </div>
    );
});
