import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import { message } from 'antd';
import { API_URL } from './constants';

const NETWORK_DEFAULT_TIMEOUT = 10000;
const client = axios.create({
    baseURL: API_URL,
    timeout: NETWORK_DEFAULT_TIMEOUT,
});

client.interceptors.response.use(
    (response: any = {}) => {
        return response;
    },
    error => {
        return Promise.reject(error);
    }
);

const getHeaders = async (hasFiles = false) => {
    const headers = hasFiles ? { 'Content-Type': 'multipart/forms-data' } : { 'Content-Type': 'application/json' };

    const token = localStorage.getItem('token');

    if (token) {
        return {
            ...headers,
            Authorization: `Token ${token}`,
        };
    }

    return headers;
};

function getHeader(has_files = false) {
    const token = window.localStorage.getItem('token');

    if (token) {
        return {
            'Content-Type': has_files ? 'multipart/forms-data' : 'application/json',
            Authorization: `Token ${token}`,
        };
    } else {
        return {
            'Content-Type': has_files ? 'multipart/forms-data' : 'application/json',
        };
    }
}

const responseBody = (resp: AxiosResponse) => {
    return resp.data;
};

const handleErrors = (error: AxiosError) => {
    if (!error?.response) {
        message.error(error?.message ? error?.message : 'Error');
    } else if (error?.response?.status === 500) {
        message.error(error?.message ? error?.message : 'Error');
    }
    throw error;
};

export const get = async (url: string, params = {}, CancelTokenSource?: CancelTokenSource): Promise<any> => {
    return client.get(url, {
        params: params,
        headers: await getHeaders(),
        cancelToken: CancelTokenSource?.token,
    });
};

export const post = async (url: string, payload = {}): Promise<any> => {
    return client.post(url, payload, {
        headers: await getHeaders(),
        withCredentials: false,
    });
};

export const put = async (url: string, payload = {}): Promise<any> => {
    return client.put(url, payload, {
        headers: await getHeaders(),
    });
};

export const patch = async (url: string, payload = {}): Promise<any> => {
    return client.patch(url, payload, {
        headers: await getHeaders(),
    });
};

export const remove = async (url: string): Promise<any> => {
    return client.delete(url, {
        headers: await getHeaders(),
    });
};

// For response blob
const requests = {
    get2: (url: string, options?: AxiosRequestConfig) =>
        axios
            .get(`${API_URL}${url}`, {
                // @ts-ignore
                headers: getHeader(),
                ...options,
            })
            .then(responseBody)
            .catch(handleErrors),
    post2: (url: string, data = {}, options?: AxiosRequestConfig, has_files?: boolean) =>
        axios
            .post(`${API_URL}${url}`, data, {
                // @ts-ignore
                headers: getHeader(has_files),
                ...options,
            })
            .then(responseBody)
            .catch(handleErrors),
};

const { get2 } = requests;

export { requests, get2 };
