import React from 'react';
import { Typography } from 'antd';
import { SiAddthis } from 'react-icons/si';
import { RiListOrdered } from 'react-icons/ri';
import { SwitchLogos } from '../inputs/SwitchLogos';
import { SwitchSignature } from '../inputs/SwitchSignature';

interface Props {
    stage: 'list' | 'create' | 'update';
    title: string;
    titleButton: string;
    setStage: (stage: 'create' | 'update' | 'list') => void;
}

export const PageHeaderBooks = (props: Props) => {
    return (
        <>
            <div className="lg:flex lg:items-center lg:justify-between">
                <div className="min-w-0 flex-1">
                    <span className="font-semibold text-lg text-slate-700">{props.title}</span>
                    <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                            <svg
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M6 3.75A2.75 2.75 0 018.75 1h2.5A2.75 2.75 0 0114 3.75v.443c.572.055 1.14.122 1.706.2C17.053 4.582 18 5.75 18 7.07v3.469c0 1.126-.694 2.191-1.83 2.54-1.952.599-4.024.921-6.17.921s-4.219-.322-6.17-.921C2.694 12.73 2 11.665 2 10.539V7.07c0-1.321.947-2.489 2.294-2.676A41.047 41.047 0 016 4.193V3.75zm6.5 0v.325a41.622 41.622 0 00-5 0V3.75c0-.69.56-1.25 1.25-1.25h2.5c.69 0 1.25.56 1.25 1.25zM10 10a1 1 0 00-1 1v.01a1 1 0 001 1h.01a1 1 0 001-1V11a1 1 0 00-1-1H10z"
                                    clipRule="evenodd"
                                />
                                <path d="M3 15.055v-.684c.126.053.255.1.39.142 2.092.642 4.313.987 6.61.987 2.297 0 4.518-.345 6.61-.987.135-.041.264-.089.39-.142v.684c0 1.347-.985 2.53-2.363 2.686a41.454 41.454 0 01-9.274 0C3.985 17.585 3 16.402 3 15.055z" />
                            </svg>
                            Total
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                            <svg
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            Tomos
                        </div>
                    </div>
                </div>
                <div className="mt-5 flex lg:mt-0 lg:ml-4">
                    {props.stage === 'list' ? (
                        <>
                            <span className="sm:ml-3 pr-2" style={{ borderRight: '3px', borderStyle: 'ridge' }}>
                                <SwitchLogos />
                            </span>
                            <span className="sm:ml-3 pr-2" style={{ borderRight: '3px', borderStyle: 'ridge' }}>
                                <SwitchSignature />
                            </span>
                            <span className="sm:ml-3">
                                <Typography.Link onClick={() => props.setStage('create')}>
                                    <div className={'flex flex-row'}>
                                        <SiAddthis className={'mt-1 mr-1'} />
                                        <span>{props.titleButton}</span>
                                    </div>
                                </Typography.Link>
                            </span>
                        </>
                    ) : props.stage === 'create' ? (
                        <span className="sm:ml-3">
                            <Typography.Link onClick={() => props.setStage('list')}>
                                <div className={'flex flex-row'}>
                                    <RiListOrdered className={'mt-1 mr-1'} />
                                    <span>{props.titleButton}</span>
                                </div>
                            </Typography.Link>
                        </span>
                    ) : (
                        props.stage === 'update' && (
                            <span className="sm:ml-3">
                                <Typography.Link onClick={() => props.setStage('list')}>
                                    <div className={'flex flex-row'}>
                                        <RiListOrdered className={'mt-1 mr-1'} />
                                        <span>{props.titleButton}</span>
                                    </div>
                                </Typography.Link>
                            </span>
                        )
                    )}
                </div>
            </div>
        </>
    );
};
