import React from 'react';
import Humanize from 'humanize-plus';

interface Props {
    value: number | string;
    style?: React.CSSProperties;
}

export const Price = ({ value, style }: Props) => {
    const val = typeof value === 'string' ? parseFloat(value) : value;

    if (value >= 0) {
        return <span style={style}>{Humanize.formatNumber(val, 2)}</span>;
    } else {
        return <span style={style}>0.00</span>;
    }
};
