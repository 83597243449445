import React from 'react';
import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { InputNumber } from 'antd/es';
import { useQueryParameters } from '../../../library';

export const ContributorFilter = () => {
    const query = useQueryParameters();
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        const params: any = {};

        if (values.name) params['name'] = values.name;
        if (values.email) params['email'] = values.email;
        if (values.cellphone) params['cellphone'] = values.cellphone;

        const queryString = Object.keys(params)
            .map(key => key + '=' + params[key])
            .join('&');

        navigate(`/catechism/contributor/?${queryString}`, { replace: true });
    };

    return (
        <div className={'flex justify-start w-full py-6 px-8'}>
            <div className="w-full">
                <Form onFinish={onFinish} layout="horizontal" form={form}>
                    <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <Form.Item label="Nombre" name="name" initialValue={query.get('name')}>
                            <Input placeholder="Apellidos o nombres" allowClear size={'small'} />
                        </Form.Item>

                        <Form.Item label="Email" name="email" initialValue={query.get('email')}>
                            <Input allowClear size={'small'} />
                        </Form.Item>
                    </div>
                    <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <Form.Item label="Celular" initialValue={query.get('cellphone')} name={'cellphone'}>
                            <InputNumber style={{ width: '100%' }} size={'small'} />
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <div className="flex justify-center space-x-4">
                            <Button className={'second'} type="primary" htmlType={'submit'}>
                                Filtrar Registros
                            </Button>
                            <Button
                                onClick={async () => {
                                    form.resetFields();
                                    navigate(`/catechism/contributor/`, { replace: true });
                                }}
                            >
                                Limpiar Filtros
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};
