import React, { createContext, useState, useContext } from 'react';
import { LayoutProps } from '../utils/types';
const AuthContext = createContext(null);

export const AuthProvider = ({ children }: LayoutProps) => {
    const [user, setUser] = useState<string | null>(null);

    const loginContext = (user: string | null) => {
        setUser(user);
    };

    const logoutContext = () => {
        setUser(null);
    };

    // @ts-ignore
    return <AuthContext.Provider value={{ user, loginContext, logoutContext }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
