import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { Strings } from '../../../utils/strings';
import { AreaProps } from '../types';
import { VscSaveAs } from 'react-icons/vsc';

interface Props {
    type: string;
    area?: AreaProps;
    handleUpdate?: (payload: AreaProps, list: boolean, id: number) => void;
    handleCreate?: (payload: AreaProps, list: boolean) => void;
}

export const AreaForm: React.FC<Props> = ({ type, area, handleUpdate, handleCreate }: Props) => {
    const [form] = Form.useForm();
    const [list, setList] = useState(false);

    useEffect(() => {
        form.resetFields();
    }, [area]);

    const onFinish = (values: AreaProps) => {
        const payload: AreaProps = {
            name: values.name,
        };
        if (type === 'create' && handleCreate) {
            handleCreate(payload, list);
            form.resetFields();
        } else if (type === 'update' && handleUpdate && area && area.id) {
            handleUpdate(payload, list, area.id);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Form
                form={form}
                initialValues={{
                    name: area?.name,
                }}
                layout="inline"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    name="name"
                    label={'Nombre del área'}
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button
                        className="second"
                        type="primary"
                        htmlType="submit"
                        icon={<VscSaveAs className="inline-flex mr-1" size={'18px'} />}
                        onClick={() => setList(true)}
                    >
                        Guardar
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};
