import React from 'react';
import { Button, Form, Input } from 'antd';
import { toastError } from '../../../utils/toast';
import { Strings } from '../../../utils/strings';
import { BsFileEarmarkRichtext } from 'react-icons/bs';
import { MdCleaningServices } from 'react-icons/md';

interface Props {
    getFileUrl: (payload: any) => void;
    type: string;
}

export const BaptismLicenseForm = (props: Props) => {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        const members = values.members.split(',');
        const results = members.map((member: string) => member.trim());

        const payload = {
            members: results,
            parents: values.parents,
            second_parish: values.parish,
            type: props.type,
        };
        if (props.type === 'A4') {
            toastError('Este certificado no esta disponible en tamaño A4');
        } else {
            props.getFileUrl(payload);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log(errorInfo);
    };

    return (
        <Form className="p-6" form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <div className="grid grid-cols-1 gap-4">
                <Form.Item
                    label={'Niño/a(s)'}
                    help={'Si son 2 o más niños, ingrese los nombres separados por una coma (,)'}
                    name="members"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-3">
                <Form.Item
                    label={'Papás'}
                    name="parents"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={'Parroquia'}
                    name="parish"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>
            </div>

            <Form.Item>
                <div className={'flex flex-row justify-center space-x-4 pt-4'}>
                    <Button
                        className={'mx-2 second'}
                        type="primary"
                        htmlType="submit"
                        icon={<BsFileEarmarkRichtext className="inline-flex mr-1" size={'18px'} />}
                    >
                        {Strings.actions.generate_certificate}
                    </Button>
                    <Button
                        className={'mx-2'}
                        onClick={() => {
                            form.resetFields();
                        }}
                        icon={<MdCleaningServices className="inline-flex mr-1" size={'18px'} />}
                    >
                        {Strings.actions.clean_form}
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
};
