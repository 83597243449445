import React, { useEffect, useState } from 'react';
import { MenuTop as ContactMenuTop } from '../MenuTop';
import { notification, Spin } from 'antd';
import { ContactCategoryProps } from '../types';
import { CategoryCreateForm } from '../forms';
import ContactStore from '../stores/ContactStore';
import { toastSuccess } from '../../../utils/toast';
import ErrorRender from '../../../library/outlets/ErrorRender';
import { CategoryCard } from '../cards';
import { observer } from 'mobx-react';
import { PageHeader } from '../../../library';

export const CategoryPage = observer(() => {
    const [isLoading, setIsLoading] = useState(false);
    const [category, setCategory] = useState<ContactCategoryProps | null>(null);
    const [stage, setStage] = useState<'create' | 'update' | 'list'>('list');

    async function handleList() {
        setIsLoading(true);
        await ContactStore.list_categories();
        setIsLoading(false);
    }

    useEffect(() => {
        handleList();
    }, []);

    async function handleCreate(payload: ContactCategoryProps) {
        setIsLoading(true);
        try {
            await ContactStore.create_category(payload);
            toastSuccess('Categoría registrada!');
            await handleList();
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    async function handleRemove(id: number) {
        try {
            setIsLoading(true);
            await ContactStore.remove_category(id);
            toastSuccess('Categoría eliminada!');
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    return (
        <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
            <ContactMenuTop active={'categories'} />
            <PageHeader
                setStage={(stage: 'create' | 'update' | 'list') => {
                    setCategory(null);
                    setStage(stage);
                }}
                stage={stage}
                title={
                    stage === 'list' ? 'Categorías' : stage === 'create' ? 'Nueva categoría' : 'Actualizar categoría'
                }
                subtitle={'Categorías para organizar los contactos'}
                titleButton={
                    stage === 'list' ? 'Nueva categoria' : stage === 'create' ? 'Ver categorías' : 'Ver categorías'
                }
            />

            {stage === 'list' ? (
                <div className={'pt-4'}>
                    <Spin spinning={isLoading}>
                        <div className="w-full grid grid-cols lg:grid-cols-4 xl:grid-cols-3 gap-4">
                            <CategoryCard categories={ContactStore.categories} handleRemove={handleRemove} />
                        </div>
                    </Spin>
                </div>
            ) : stage === 'create' ? (
                <div className={'py-6'}>
                    <CategoryCreateForm
                        setStage={setStage}
                        handleCreate={async (payload: ContactCategoryProps, list: boolean) => {
                            await handleCreate(payload);
                            if (list) {
                                await handleList();
                                setStage('list');
                            }
                        }}
                    />
                </div>
            ) : (
                stage === 'update' && category !== null && <div>{/*TODO add forms*/}</div>
            )}
        </div>
    );
});
