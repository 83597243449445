import React from 'react';
import { Button, Form, Input, Select } from 'antd';
import { ContactCategoryProps, ContactProps } from '../types';
import { Strings } from '../../../utils/strings';
import { VscSaveAll } from 'react-icons/vsc';
import { FcCancel } from 'react-icons/fc';

interface Props {
    categories: ContactCategoryProps[];
    handleCreate: (payload: ContactProps, list: boolean) => void;
}

export const ContactCreateForm = ({ categories, handleCreate }: Props) => {
    const [form] = Form.useForm();
    const { Option } = Select;

    const onFinish = (values: ContactProps) => {
        const payload = {
            categories: values.categories,
            name: values.name,
            email: values.email,
            mobile: values.mobile,
            mobile_operator: values.mobile_operator,
            phone: values.phone,
            note: values.note,
        };
        handleCreate(payload, true);
        form.resetFields();
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Form
                form={form}
                name="newcontact-form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
            >
                <div className="grid grid-cols-2 gap-4">
                    <Form.Item
                        label="Nombre"
                        name="name"
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Categoría"
                        name="categories"
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <Select mode="multiple" allowClear>
                            {categories.map(category => (
                                <Option key={category.id} value={category.name}>
                                    {category.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>

                <div className="grid grid-cols-2 gap-4">
                    <Form.Item label="Correo Electrónico" name="email">
                        <Input />
                    </Form.Item>

                    <Form.Item label="Teléfono Fijo" name="phone">
                        <Input />
                    </Form.Item>
                </div>

                <div className="grid grid-cols-2 gap-4">
                    <Form.Item label="Telefono Celular" name="mobile">
                        <Input />
                    </Form.Item>

                    <Form.Item label="Operador" name="mobile_operator">
                        <Input />
                    </Form.Item>
                </div>

                <div className="grid grid-cols-1 gap-4">
                    <Form.Item label="Nota" name="note">
                        <Input.TextArea style={{ width: '100%' }} autoSize={true} />
                    </Form.Item>
                </div>

                <Form.Item>
                    <div className={'mt-4 flex flex-row-reverse'}>
                        <Button
                            className={'mx-2 second'}
                            type="primary"
                            htmlType="submit"
                            icon={<VscSaveAll className="inline-flex mr-1" size={'18px'} />}
                        >
                            {Strings.actions.save}
                        </Button>
                        <Button
                            className={'mx-2 remove'}
                            onClick={() => {
                                form.resetFields();
                            }}
                            icon={<FcCancel className="inline-flex mr-1" size={'18px'} />}
                        >
                            {Strings.actions.cancel}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </>
    );
};
