import React from 'react';
import { Button, DatePicker, Form, Input } from 'antd';
import { toastError } from '../../../utils/toast';
import moment from 'moment';
import { BsFileEarmarkRichtext } from 'react-icons/bs';
import { Strings } from '../../../utils/strings';
import { MdCleaningServices } from 'react-icons/md';
import locale from 'antd/es/date-picker/locale/es_ES';

interface Props {
    getFileUrl: (payload: any) => void;
    type: string;
}

export const PreBaptismForm = (props: Props) => {
    const [form] = Form.useForm();
    const onFinish = (values: any) => {
        const members = values.members.split(',');
        const results = members.map((member: string) => member.trim());

        const payload = {
            group: values.group,
            parents: values.parents,
            godparents: values.godparents,
            members: results,
            date_course: moment(values.date_one).format('YYYY-MM-DD'),
            date_baptism: moment(values.date_two).format('YYYY-MM-DD'),
            time_baptism: moment(values.date_two).format('  H:mm'),
            type: props.type,
        };
        if (props.type == 'A4') {
            toastError('Este certificado no esta disponible en tamaño A4');
        } else {
            props.getFileUrl(payload);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log(errorInfo);
    };

    return (
        <Form className="p-6" form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <div className="grid grid-cols-3 gap-4">
                <Form.Item
                    label={'Grupo / Responsable'}
                    name="group"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input placeholder={'Pastoral Familiar'} />
                </Form.Item>

                <Form.Item
                    label={'Papás'}
                    name="parents"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={'Padrinos'}
                    name="godparents"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <Form.Item
                    label={'Fecha del Cursillo'}
                    name="date_one"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <DatePicker locale={locale} className="form-control" style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                    label={'Fecha del Bautizo'}
                    name="date_two"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <DatePicker
                        format={'YYYY/MM/DD HH:mm'}
                        showTime={{ format: 'HH:mm' }}
                        locale={locale}
                        className="form-control"
                        style={{ width: '100%' }}
                    />
                </Form.Item>
            </div>

            <div className="grid grid-cols-1 gap-4">
                <Form.Item
                    label={'Niño/a(s)'}
                    help={'Si son 2 o más niños, ingrese los nombres separados por una coma (,)'}
                    name="members"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input placeholder="Victor Quillay Bravo, Lauro Lucero Lucero" />
                </Form.Item>
            </div>

            <Form.Item>
                <div className={'flex flex-row justify-center space-x-4 pt-4'}>
                    <Button
                        className={'mx-2 second'}
                        type="primary"
                        htmlType="submit"
                        icon={<BsFileEarmarkRichtext className="inline-flex mr-1" size={'18px'} />}
                    >
                        {Strings.actions.generate_certificate}
                    </Button>
                    <Button
                        className={'mx-2'}
                        onClick={() => {
                            form.resetFields();
                        }}
                        icon={<MdCleaningServices className="inline-flex mr-1" size={'18px'} />}
                    >
                        {Strings.actions.clean_form}
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
};
