import React from 'react';
import { Select, Input, Form, Button } from 'antd';
import { Strings } from '../../../utils/strings';
import { CommunityProps, ZoneProps } from '../types';
import { VscSaveAll } from 'react-icons/vsc';
import { FcCancel } from 'react-icons/fc';

interface Props {
    record?: CommunityProps | null;
    zones: Array<ZoneProps>;
    handleCreate?: (payload: CommunityProps) => void;
    handleUpdate?: (payload: CommunityProps, id: number) => void;
    setIsModalVisible: (value: boolean) => void;
}

export const CommunityForm: React.FC<Props> = (props: Props) => {
    const { Option } = Select;
    const [form] = Form.useForm();

    const onFinish = (values: { zone: string; name: string; description: string }) => {
        const zone = props.zones.filter((zone: ZoneProps) => zone.name === values.zone);

        const payload = {
            zone: Number(zone[0].id),
            name: values.name,
            description: values.description,
        };

        if (props.handleUpdate && props.record && props.record.id) {
            props.handleUpdate(payload, props.record.id);
        } else if (props.handleCreate) {
            props.handleCreate(payload);
        }
        form.resetFields();
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Form
                form={form}
                initialValues={{
                    zone: props.record?.zone,
                    name: props.record?.name,
                    description: props.record?.description,
                }}
                name="period-form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
            >
                <div className="grid gap-4">
                    <Form.Item
                        label="Seleccione Zona"
                        name="zone"
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <Select allowClear showSearch defaultValue={props.record ? props.record.id : ''}>
                            {props.zones.map(zone => {
                                return (
                                    <Option key={zone.id} value={zone.name}>
                                        {zone.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Comunidad"
                        name="name"
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item label="Descripción" name="description">
                        <Input />
                    </Form.Item>
                </div>

                <Form.Item>
                    <div className={'flex flex-row-reverse'}>
                        <Button
                            className={'mx-2 second'}
                            type="primary"
                            htmlType="submit"
                            icon={<VscSaveAll className="inline-flex mr-1" size={'18px'} />}
                        >
                            {Strings.actions.save}
                        </Button>
                        <Button
                            className={'mx-2 remove'}
                            onClick={() => props.setIsModalVisible(false)}
                            icon={<FcCancel className="inline-flex mr-1" size={'18px'} />}
                        >
                            {Strings.actions.cancel}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </>
    );
};
