import * as React from 'react';
import { useState } from 'react';
import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/es/select';
import { observer } from 'mobx-react';
import { CommunityProps } from '../types';
import CommunityStore from '../stores/CommunityStore';

let busy: any = null;

interface Props extends SelectProps {
    onChange?: (value: any, contact: any) => void;
}

const CommunitiesSearchSelect = observer((props: Props) => {
    const [loading, setLoading] = useState(false);
    const [communities, setCommunities] = useState<CommunityProps[]>([]);

    async function searchRecords(params: { search: string }) {
        await CommunityStore.list(params);
        setCommunities(CommunityStore.communities);
        setLoading(false);
    }

    return (
        <Select
            mode={'multiple'}
            showSearch
            allowClear
            labelInValue
            filterOption={false}
            style={{ width: '100%' }}
            dropdownMatchSelectWidth={false}
            placeholder="Buscar comunidad"
            notFoundContent={loading ? <Spin size="small" /> : null}
            onSearch={name => {
                if (busy) {
                    clearTimeout(busy);
                }
                const params = {
                    search: name,
                };
                setLoading(true);

                busy = setTimeout(() => searchRecords(params), 400);
            }}
            {...props}
        >
            {communities.map(record => {
                return (
                    <>
                        {record.id && (
                            <Select.Option key={record.id} value={record.id}>
                                {record.name}
                            </Select.Option>
                        )}
                    </>
                );
            })}
        </Select>
    );
});

export default CommunitiesSearchSelect;
