import React, { useEffect } from 'react';
import { Input, Form, Button } from 'antd';
import { Strings } from '../../../utils/strings';
import LevelStore from '../stores/LevelStore';
import LevelSelect from '../selects/LevelSelect';
import { observer } from 'mobx-react';
import CommunitiesSearchSelect from '../selects/CommunitiesSearchSelect';
import { VscSaveAll } from 'react-icons/vsc';
import { FcCancel } from 'react-icons/fc';

interface Props {
    period: { id: number; year: string };
    handleCreate: (payload: any) => void;
    setIsModalVisible: (value: boolean) => void;
}

export const CourseForm: React.FC<Props> = observer(({ handleCreate, period, setIsModalVisible }: Props) => {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        const payload = {
            name: values.name,
            level: values.level.value,
            period: period.id,
            quota: Number(values.quota),
            community: values.communities.map((community: { key: number }) => community.key),
        };
        handleCreate(payload);
        form.resetFields();
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    async function initial_data() {
        await LevelStore.list();
    }

    useEffect(() => {
        initial_data();
    }, []);

    return (
        <>
            <Form
                form={form}
                name="period-form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                initialValues={{ period: period.year }}
            >
                <div className="grid grid-cols-2 gap-4">
                    <Form.Item
                        label="Seleccione Nivel"
                        name="level"
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <LevelSelect levels={LevelStore.levels} />
                    </Form.Item>

                    <Form.Item label="Periodo Activo" name="period">
                        <Input readOnly />
                    </Form.Item>

                    <Form.Item
                        label="Identificador del Grupo"
                        name="name"
                        help={'Paralelo o nombre del curso'}
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Cupo"
                        name="quota"
                        help={'Nro. Max. de estudiantes'}
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <Input />
                    </Form.Item>
                </div>

                <div className="grid gap-4">
                    <Form.Item
                        label="Comunidades"
                        name="communities"
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                        help={'Puede seleccionar una o mas comunidades'}
                    >
                        <CommunitiesSearchSelect />
                    </Form.Item>
                </div>

                <Form.Item>
                    <div className={'flex flex-row-reverse'}>
                        <Button
                            className={'mx-2 second'}
                            type="primary"
                            htmlType="submit"
                            icon={<VscSaveAll className="inline-flex mr-1" size={'18px'} />}
                        >
                            {Strings.actions.save}
                        </Button>
                        <Button
                            className={'mx-2 remove'}
                            onClick={() => {
                                form.resetFields();
                                setIsModalVisible(false);
                            }}
                            icon={<FcCancel className="inline-flex mr-1" size={'18px'} />}
                        >
                            {Strings.actions.cancel}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </>
    );
});
