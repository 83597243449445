import { makeObservable, observable, runInAction } from 'mobx';
import { get, patch, post, remove } from '../../../utils/api';
import { PeriodProps } from '../types';

class PeriodStore {
    periods: PeriodProps[] = [];
    period: PeriodProps | null = null;
    period_active: { id: number; year: string } | null = null;

    constructor() {
        makeObservable(this, {
            periods: observable,
            period: observable,
        });
    }

    async view(id: number) {
        const response = await get(`/url_api_periods/${id}`);
        runInAction(() => {
            this.period = response.data;
        });
    }

    async list(params = {}) {
        const response = await get(`/staff/catechism/period/`, params);
        runInAction(() => {
            this.periods = response.data.results;
        });
        return response.data.results;
    }

    async create(payload: PeriodProps) {
        const response = await post(`/staff/catechism/period/`, payload);
        runInAction(() => {
            this.periods.push(response.data);
        });
        return response.data;
    }

    async patch(payload = {}, id: number) {
        const response = await patch(`/staff/catechism/period/${id}/`, payload);
        runInAction(() => {
            this.period = response.data;
        });
        return response.data;
    }

    async get_active() {
        const response = await get(`/staff/catechism/period/active/`);
        runInAction(() => {
            this.period_active = {
                id: response.data.id,
                year: response.data.period,
            };
        });
    }

    async enable_period(id: number) {
        const response = await post(`/staff/catechism/period/${id}/enable-period/`);
        runInAction(() => {
            this.period = response.data;
        });
        await this.list();
    }

    async remove(id: number) {
        return await remove(`/staff/catechism/period/${id}`).then(() => this.list());
    }
}

export default new PeriodStore();
