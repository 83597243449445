import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { notification, Spin } from 'antd';
import { AreaProps } from '../types';
import ErrorRender from '../../../library/outlets/ErrorRender';
import { toastSuccess } from '../../../utils/toast';
import { PageHeader } from '../../../library';
import AreaStore from '../stores/AreaStore';
import { AreaForm } from '../forms';
import { AreaCard } from '../cards';
import { requests } from '../../../utils/api';
import { DownloadFile } from '../../../utils/download_file';
import CourseStore from '../../catechism/stores/CourseStore';

export const AreaPage = observer(() => {
    const [isLoading, setIsLoading] = useState(false);
    const [stage, setStage] = useState<'create' | 'update' | 'list'>('list');
    const [area, setArea] = useState<AreaProps | null>(null);

    async function handleList() {
        setIsLoading(true);
        try {
            await AreaStore.list();
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    useEffect(() => {
        handleList();
    }, []);

    async function handleCreate(payload: AreaProps) {
        setIsLoading(true);
        try {
            await AreaStore.create(payload);
            toastSuccess('Area registrada!');
            await handleList();
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    async function handleUpdate(payload: AreaProps, id: number) {
        setIsLoading(true);
        try {
            await AreaStore.patch(payload, id);
            toastSuccess('Area actualizada!');
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    async function handleRemove(id: number) {
        setIsLoading(true);
        try {
            await AreaStore.remove(id);
            toastSuccess('Area Eliminada');
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    const download_articles_by_area = async (area_id: number, area_name: string) => {
        setIsLoading(true);
        const url = '/staff/inventory/area/' + area_id + '/export-articles/';
        await requests
            .get2(url, { responseType: 'blob' })
            .then((response: any) => {
                DownloadFile(response, 'Inventory - ' + area_name + '.pdf');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
                <Spin spinning={isLoading}>
                    <PageHeader
                        setStage={(stage: 'create' | 'update' | 'list') => {
                            setArea(null);
                            setStage(stage);
                        }}
                        stage={stage}
                        title={stage === 'list' ? 'Áreas' : stage === 'create' ? 'Nueva área' : 'Actualizar área'}
                        subtitle={'Áreas de la parroquia'}
                        titleButton={stage === 'list' ? 'Nueva área' : stage === 'create' ? 'Ver áreas' : 'Ver áreas'}
                    />
                    {stage === 'list' ? (
                        <div className={'pt-4'}>
                            <Spin spinning={isLoading}>
                                <div className="w-full grid grid-cols lg:grid-cols-4 xl:grid-cols-3 gap-4">
                                    <AreaCard
                                        areas={AreaStore.areas}
                                        handleRemove={handleRemove}
                                        setArea={area => {
                                            setStage('update');
                                            setArea(area);
                                        }}
                                        handleDownload={download_articles_by_area}
                                    />
                                </div>
                            </Spin>
                        </div>
                    ) : stage === 'create' ? (
                        <div className={'py-6'}>
                            <AreaForm
                                type={'create'}
                                handleCreate={async (payload: AreaProps, list: boolean) => {
                                    await handleCreate(payload);
                                    if (list) {
                                        await handleList();
                                        setStage('list');
                                    }
                                }}
                            />
                        </div>
                    ) : (
                        stage === 'update' &&
                        area !== null && (
                            <div className={'py-6'}>
                                <AreaForm
                                    type={'update'}
                                    area={area}
                                    handleUpdate={async (payload: AreaProps, list: boolean, id: number) => {
                                        await handleUpdate(payload, id);
                                        if (list) {
                                            await handleList();
                                            setStage('list');
                                        }
                                    }}
                                />
                            </div>
                        )
                    )}
                </Spin>
            </div>
        </>
    );
});
