import React, { useEffect, useState } from 'react';
import { notification, Spin } from 'antd';
import { CertificatePreview, PageHeader, ViewerImages } from '../../../library';
import { ArticleForm } from '../forms';
import { ArticleProps } from '../types';
import ErrorRender from '../../../library/outlets/ErrorRender';
import { toastSuccess } from '../../../utils/toast';
import ArticleStore from '../stores/ArticleStore';
import { ArticlesTable } from '../tables';
import { requests } from '../../../utils/api';
import { isMobile } from 'react-device-detect';
import { ArticlesFilter } from '../filters';
import { useLocation } from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const ArticlePage = () => {
    const searchParams = useQuery();
    const [isLoading, setIsLoading] = useState(false);
    const [stage, setStage] = useState<'create' | 'update' | 'list'>('list');
    const [article, setArticle] = useState<ArticleProps | null>(null);
    const [viewImages, setViewImages] = useState<boolean>(false);
    const [drawerPdf, setDrawerPdf] = useState<boolean>(false);
    const [fileURL, setFileURL] = useState('');

    async function handleList(params = {}) {
        setIsLoading(true);
        try {
            await ArticleStore.list(params);
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    useEffect(() => {
        handleList(searchParams);
    }, [location.search]);

    async function handleCreate(payload: ArticleProps) {
        setIsLoading(true);
        try {
            await ArticleStore.create(payload);
            toastSuccess('Artículo registrado!');
            await handleList();
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    async function handleUpdate(payload: ArticleProps, id: number) {
        setIsLoading(true);
        try {
            await ArticleStore.patch(payload, id);
            toastSuccess('Artículo actualizado!');
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    async function handleRemove(id: number) {
        setIsLoading(true);
        try {
            await ArticleStore.remove(id);
            toastSuccess('Artículo Eliminado!');
            setIsLoading(false);
            setStage('list');
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    async function getFileURL(article_id: number) {
        setIsLoading(true);
        setDrawerPdf(true);
        try {
            requests
                .get2(`/staff/inventory/article/${article_id}/certificate/`, { responseType: 'blob' })
                .then((response: any) => {
                    if (isMobile) {
                        return setFileURL('');
                    } else {
                        const file = new Blob([response], { type: 'application/pdf' });
                        const fileURL2 = URL.createObjectURL(file);
                        setFileURL(fileURL2);
                    }
                    setIsLoading(false);
                });
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    return (
        <>
            <div>
                <Spin spinning={isLoading}>
                    <div className="relative bg-white p-4 sm:p-6 rounded-xl overflow-hidden mb-8">
                        <PageHeader
                            setStage={(stage: 'create' | 'update' | 'list') => {
                                setArticle(null);
                                setStage(stage);
                            }}
                            stage={stage}
                            title={
                                stage === 'list'
                                    ? 'Artículos'
                                    : stage === 'create'
                                    ? 'Nuevo artículo'
                                    : 'Actualizar artículo'
                            }
                            subtitle={'Inventario'}
                            titleButton={
                                stage === 'list'
                                    ? 'Nuevo artículo'
                                    : stage === 'create'
                                    ? 'Ver artículos'
                                    : 'Ver artículos'
                            }
                        />
                        {stage === 'list' && <ArticlesFilter path={'/inventory/articles'} />}
                    </div>
                    {stage === 'list' ? (
                        <div className="relative bg-white rounded-xl overflow-hidden mb-8">
                            <ArticlesTable
                                loading={isLoading}
                                handleRemove={handleRemove}
                                setRecord={(article: ArticleProps) => {
                                    setArticle(article);
                                    setStage('update');
                                }}
                                handleViewImages={(value: boolean, article: ArticleProps) => {
                                    setArticle(article);
                                    setViewImages(true);
                                }}
                                handlePreviewCertificate={getFileURL}
                            />
                        </div>
                    ) : stage === 'create' ? (
                        <div className="relative p-6 bg-white rounded-xl overflow-hidden mb-8">
                            <ArticleForm
                                type={'create'}
                                handleCreate={async (payload: ArticleProps, list: boolean) => {
                                    await handleCreate(payload);
                                    if (list) {
                                        await handleList();
                                        setStage('list');
                                    }
                                }}
                            />
                        </div>
                    ) : (
                        stage === 'update' &&
                        article !== null && (
                            <div className="relative p-6 bg-white rounded-xl overflow-hidden mb-8">
                                <ArticleForm
                                    handlePreviewCertificate={getFileURL}
                                    type={'update'}
                                    article={article}
                                    handleUpdate={async (payload: ArticleProps, list: boolean, id: number) => {
                                        await handleUpdate(payload, id);
                                        if (list) {
                                            await handleList();
                                            setStage('list');
                                        }
                                    }}
                                    handleRemove={async (id: number) => handleRemove(id)}
                                />
                            </div>
                        )
                    )}
                </Spin>
            </div>

            {drawerPdf && (
                <CertificatePreview
                    loading={isLoading}
                    handleClose={() => {
                        setFileURL('');
                        setDrawerPdf(false);
                    }}
                    fileUrl={fileURL}
                />
            )}

            {article !== null && (
                <ViewerImages article={article} modalImages={viewImages} setModalImages={setViewImages} />
            )}
        </>
    );
};
