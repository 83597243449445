import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useQueryParameters } from '../../../library';
import { AreaSelect, SubareaSelect } from '../selects';
import { SubareaProps } from '../types';
import { Strings } from '../../../utils/strings';

const FormItem = Form.Item;

interface Props {
    path: string;
}

export const ArticlesFilter = (props: Props) => {
    const navigate = useNavigate();
    const [subareas, setSubareas] = useState<Array<SubareaProps>>([]);

    const query = useQueryParameters();

    const [form] = Form.useForm();

    const handleSubmit = (values: any) => {
        const params: any = {};

        if (values.name) params['name'] = values.name;
        if (values.code) params['code'] = values.code;
        if (values.area) params['area'] = values.area;
        if (values.subarea) params['subarea'] = values.subarea;

        const queryString = Object.keys(params)
            .map(key => key + '=' + params[key])
            .join('&');

        navigate(`${props.path}/?${queryString}`, { replace: true });
    };

    useEffect(() => {
        form.resetFields();
    }, []);

    return (
        <div className={'flex justify-start w-full py-6 px-8'}>
            <div className="w-full">
                <Form onFinish={handleSubmit} layout="horizontal" form={form}>
                    <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <FormItem label="Artículo" initialValue={query.get('name')} name={'name'}>
                            <Input placeholder={'Nomnbre del árticulo'} />
                        </FormItem>
                        <Form.Item label={Strings.inventory.code} initialValue={query.get('code')} name="code">
                            <Input />
                        </Form.Item>
                    </div>

                    <div className=" grid grid-cols-2 lg:grid-cols-2 gap-4">
                        <FormItem label="Area" initialValue={query.get('area')} name={'area'}>
                            <AreaSelect style={{ width: '100%' }} setSubareas={setSubareas} />
                        </FormItem>
                        <Form.Item
                            label="Subárea"
                            initialValue={query.get('subarea')}
                            name="subarea"
                            help={'Primero seleccione una área'}
                        >
                            <SubareaSelect style={{ wdith: '100%' }} subareas={subareas} />
                        </Form.Item>
                    </div>

                    <div className="flex justify-center py-4 space-x-4">
                        <Button className={'second'} type="primary" htmlType={'submit'}>
                            Filtrar Registros
                        </Button>
                        <Button
                            onClick={() => {
                                form.resetFields();
                                navigate(`${props.path}/`, { replace: true });
                            }}
                        >
                            Limpiar Filtros
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
};
