import React, { useEffect, useState } from 'react';
import { MenuTop as CatechismMenuTop } from '../MenuTop';
import { Button, Card, Modal, notification } from 'antd';
import { SiAddthis } from 'react-icons/si';
import { observer } from 'mobx-react';
import { ZoneProps } from '../types';
import ZoneStore from '../stores/ZoneStore';
import ErrorRender from '../../../library/outlets/ErrorRender';
import PeriodStore from '../stores/PeriodStore';
import { ZonesTable } from '../tables';
import { ZoneForm } from '../forms';
import { CreateForms } from '../CreateForms';
import { toastSuccess } from '../../../utils/toast';

export const CatechismZonePage = observer(() => {
    const [isLoading, setIsLoading] = useState(false);
    const [isModalCreate, setIsModalCreate] = useState(false);
    const [isModalUpdate, setIsModalUpdate] = useState(false);
    const [record, setRecord] = useState<ZoneProps | null>(null);

    async function handleList() {
        setIsLoading(true);
        try {
            await ZoneStore.list();
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    async function handleCreateZone(values: ZoneProps) {
        const payload: ZoneProps = {
            name: values.name,
            description: values.description,
        };
        try {
            setIsLoading(true);
            await ZoneStore.create(payload);
            await handleList();
            toastSuccess('Zona registrada!');
            setIsModalCreate(false);
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    useEffect(() => {
        handleList();
        getPeriodActive();
    }, []);

    async function handleUpdate(payload: ZoneProps, id: number) {
        setIsLoading(true);
        try {
            await ZoneStore.patch(payload, id);
            await handleList();
            toastSuccess('Zona actualizada!');
            setIsLoading(false);
            setIsModalUpdate(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    async function handleRemove(id: number) {
        setIsLoading(true);
        try {
            await ZoneStore.remove(id);
            toastSuccess('Zona eliminada!');
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    async function getPeriodActive() {
        try {
            await PeriodStore.get_active();
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
    }

    return (
        <>
            <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
                <CatechismMenuTop active={'zone'} />
                <div className={'my-8'}>
                    <Card
                        bordered={false}
                        size="small"
                        title={
                            <>
                                <span className="font-bold">Zonas</span> o circuitos para organizar las comunidades
                            </>
                        }
                        extra={
                            <div className={'flex flex-row'}>
                                <div className={'mr-5 mt-1.5'}>
                                    {PeriodStore.period_active !== null && (
                                        <span>
                                            <b>Período activo: </b>
                                            {PeriodStore.period_active.year}
                                        </span>
                                    )}
                                </div>
                                <Button type={'link'} onClick={() => setIsModalCreate(true)}>
                                    <div className={'flex flex-row'}>
                                        <SiAddthis className={'mt-1 mr-1'} />
                                        <span>Nueva zona</span>
                                    </div>
                                </Button>
                            </div>
                        }
                    >
                        <ZonesTable
                            loading={isLoading}
                            handleRemove={handleRemove}
                            records={ZoneStore.zones}
                            setRecord={setRecord}
                            setIsModalVisible={setIsModalUpdate}
                        />
                    </Card>

                    <Modal
                        title="Actualizar Catequista"
                        visible={isModalUpdate}
                        footer={false}
                        onCancel={() => setIsModalUpdate(false)}
                    >
                        <ZoneForm
                            setIsModalVisible={setIsModalUpdate}
                            record={record}
                            handleUpdate={(payload: ZoneProps, id: number) => handleUpdate(payload, id)}
                        />
                    </Modal>
                </div>

                {isModalCreate && (
                    <Modal
                        title={<b>{'Nueva Zona'}</b>}
                        visible={isModalCreate}
                        footer={false}
                        onCancel={() => setIsModalCreate(false)}
                    >
                        <CreateForms
                            isModalVisible={isModalCreate}
                            setIsModalVisible={setIsModalCreate}
                            modal={{ title: 'Nueva Zona', type: 'FormZone' }}
                            handleCreate={handleCreateZone}
                        />
                    </Modal>
                )}
            </div>
        </>
    );
});
