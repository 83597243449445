import React from 'react';
import { Alert, Button, Form, Input } from 'antd';
import UploadFile from '../inputs/UploadFile';
import { SignatureFile } from '../../utils/types';
import { FormInstance } from 'antd/es';

interface IInfoSignatureFileProps {
    signature_file?: SignatureFile;
    form: FormInstance;
    handleUploadSignature: (payload: { password: string; signature_file: number }) => void;
}

export const InfoSignatureFile = (props: IInfoSignatureFileProps) => {
    const onFinish = async (values: any) => {
        const payload = {
            password: values.password,
            signature_file: values.signature[0].response.id,
        };
        props.handleUploadSignature(payload);
    };

    return (
        <div className="container mx-auto">
            <div className="rounded-lg p-6 relative z-10" style={{ cursor: 'auto' }}>
                <div className="flex flex-wrap items-center py-5">
                    <div className="flex w-full h-48 md:h-64 lg:h-72 relative">
                        <div className="w-8/12 pr-4 relative">
                            <div className="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                                <span className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                                    Firma digital
                                </span>
                                <div className="font-normal text-gray-700 dark:text-gray-400">
                                    Certificado permitidos .p12 o .pfx
                                </div>
                                <div className={'text-sm text-gray-400'}>
                                    La firma digital le permite generar certificados autofirmados digitalmente.
                                </div>
                                <div className={'pt-4'}>
                                    {props.signature_file ? (
                                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                            <tbody>
                                                <tr className="bg-white border-b">
                                                    <th
                                                        scope="row"
                                                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                    >
                                                        Serial:
                                                    </th>
                                                    <td className="py-4 px-6">{props.signature_file.serial}</td>
                                                </tr>
                                                <tr className="bg-white border-b">
                                                    <th
                                                        scope="row"
                                                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                    >
                                                        Propietario:
                                                    </th>
                                                    <td className="py-4 px-6">{props.signature_file.owner_cer}</td>
                                                </tr>
                                                <tr className="bg-white border-b">
                                                    <th
                                                        scope="row"
                                                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                    >
                                                        Fecha de emisión:
                                                    </th>
                                                    <td className="py-4 px-6">{props.signature_file.issue_date}</td>
                                                </tr>
                                                <tr className="bg-white border-b">
                                                    <th
                                                        scope="row"
                                                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                    >
                                                        Fecha de caducidad::
                                                    </th>
                                                    <td className="py-4 px-6">
                                                        {props.signature_file.expiration_date}
                                                    </td>
                                                </tr>
                                                <tr className="bg-white border-b">
                                                    <th
                                                        scope="row"
                                                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                    >
                                                        Firma:
                                                    </th>
                                                    <td className="py-4 px-6">{props.signature_file.filename}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    ) : (
                                        <Alert
                                            message={
                                                <span>
                                                    La parroquia no tiene registrada la firma digital del párroco o
                                                    titular encargado de firmar los documentos.
                                                </span>
                                            }
                                            showIcon={true}
                                            type="error"
                                            closable={false}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="w-4/12 h-full">
                            <div className="flex flex-col w-full h-full">
                                <div className="flex-1 pb-2">
                                    <div className="w-full h-full relative">
                                        <div className="p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                                            <span className="mb-2 text-xl font-semibold tracking-tight text-gray-900">
                                                Registrar firma digital
                                            </span>
                                            <div className={'mt-4'}>
                                                <Form
                                                    layout={'vertical'}
                                                    name="upload-signature"
                                                    onFinish={onFinish}
                                                    autoComplete="off"
                                                    form={props.form}
                                                >
                                                    <Form.Item
                                                        label="Clave"
                                                        name="password"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input your username!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>

                                                    <Form.Item
                                                        label="Certificado de firma"
                                                        name="signature"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input your password!',
                                                            },
                                                        ]}
                                                    >
                                                        <UploadFile fileType={'signature'} />
                                                    </Form.Item>

                                                    <Form.Item className={'flex justify-center'}>
                                                        <Button type="primary" htmlType="submit">
                                                            Guardar firma digital
                                                        </Button>
                                                    </Form.Item>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
