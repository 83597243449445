import * as React from 'react';
import { useState } from 'react';
import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/es/select';
import { observer } from 'mobx-react';
import { capitalizeFirstLetter } from '../../../library';
import MemberStore from '../stores/MemberStore';

let busy: any = null;

interface Props extends SelectProps {
    onChange?: (value: any, contact: any) => void;
}

const MemberSearchSelect = observer((props: Props) => {
    const [loading, setLoading] = useState(false);
    const [members, setMembers] = useState<any[]>([]);

    async function searchRecords(params: { search: string }) {
        await MemberStore.list(params);
        setMembers(MemberStore.members);
        setLoading(false);
    }

    return (
        <Select
            showSearch
            allowClear
            labelInValue
            filterOption={false}
            style={{ width: '100%' }}
            dropdownMatchSelectWidth={false}
            placeholder="Apellidos, nombres o cédula"
            notFoundContent={loading ? <Spin size="small" /> : null}
            onSearch={name => {
                if (busy) {
                    clearTimeout(busy);
                }
                const params = {
                    search: name,
                };
                setLoading(true);

                busy = setTimeout(() => searchRecords(params), 400);
            }}
            {...props}
        >
            {members.map(record => {
                return (
                    <>
                        {record.id && (
                            <Select.Option key={record.id} value={record.id} data={record}>
                                {record.last_name && capitalizeFirstLetter(record.last_name) + ' '}
                                {record.name && capitalizeFirstLetter(record.name)}
                                {record.card_id && ' - ' + record.card_id}
                            </Select.Option>
                        )}
                    </>
                );
            })}
        </Select>
    );
});

export default MemberSearchSelect;
