import { makeObservable, observable, runInAction } from 'mobx';
import { get, patch, post, remove } from '../../../utils/api';
import { MemberProps } from '../types';

class MemberStore {
    members: any[] = [];
    member: MemberProps | null = null;
    registrations: any[] | null = null;
    pagination: { defaultPageSize: number; total: number } = { defaultPageSize: 20, total: 0 };

    constructor() {
        makeObservable(this, {
            members: observable,
            member: observable,
            pagination: observable,
        });
    }

    async view(id: number) {
        const response = await get(`/staff/catechism/members/${id}`);
        runInAction(() => {
            this.member = response.data;
        });
        return response.data;
    }

    async list(params = {}) {
        const response = await get(`/staff/catechism/members/`, params);
        runInAction(() => {
            this.members = response.data.results;
            this.pagination = {
                defaultPageSize: 20,
                total: response.data.count,
            };
        });
    }

    async create(payload: MemberProps) {
        const response = await post(`/staff/catechism/members/`, payload);
        runInAction(() => {
            this.members.push(response.data);
        });
        return response.data;
    }

    async patch(payload = {}, id: number) {
        const response = await patch(`/staff/catechism/members/${id}/`, payload);
        runInAction(() => {
            this.member = response.data;
        });
        return response.data;
    }

    async remove(id: number) {
        return await remove(`/staff/catechism/members/${id}`).then(() => this.list());
    }

    async reset_member_registrations() {
        runInAction(() => {
            this.member = null;
            this.registrations = null;
        });
    }

    async getRegistrations(member_id: number) {
        const response = await get(`/staff/catechism/members/${member_id}/registrations/`);
        runInAction(() => {
            this.registrations = response.data;
        });
        return response.data;
    }

    async syncMember(member: MemberProps | null) {
        runInAction(() => {
            if (member !== null) {
                this.member = member;
            } else {
                this.member = null;
            }
        });
    }
}

export default new MemberStore();
