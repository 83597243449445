import { makeObservable, observable, runInAction } from 'mobx';
import { get, patch, post, remove } from '../../../utils/api';
import { LevelProps } from '../types';

class LevelStore {
    levels: LevelProps[] = [];
    level: LevelProps | null = null;
    pagination: { defaultPageSize: number; total: number } = { defaultPageSize: 20, total: 0 };

    constructor() {
        makeObservable(this, {
            levels: observable,
            level: observable,
        });
    }

    async view(id: number) {
        const response = await get(`/staff/catechism/level/${id}`);
        runInAction(() => {
            this.level = response.data;
        });
    }

    async list(params = {}) {
        const response = await get(`/staff/catechism/level/`, params);
        runInAction(() => {
            this.levels = response.data.results;
        });
        return response.data.results;
    }

    async create(payload: LevelProps) {
        const response = await post(`/staff/catechism/level/`, payload);
        runInAction(() => {
            this.levels.push(response.data);
        });
        return response.data;
    }

    async patch(payload = {}, id: number) {
        const response = await patch(`/staff/catechism/level/${id}/`, payload);
        runInAction(() => {
            this.level = response.data;
        });
        return response.data;
    }

    async remove(id: number) {
        return await remove(`/staff/catechism/level/${id}`).then(() => this.list());
    }
}

export default new LevelStore();
