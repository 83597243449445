import React from 'react';
import { Button, Col, Drawer, Form, Input, Row, Space } from 'antd';
import { CompanyProps } from '../../utils/types';
import { Strings } from '../../utils/strings';

interface Props {
    company: CompanyProps;
    open: boolean;
    setClose: (value: boolean) => void;
    onFinish: (payload: CompanyProps) => void;
}
export const UpdateCompanyDrawer = (props: Props) => {
    const [form] = Form.useForm();

    const onFinish = () => {
        const payload: CompanyProps = {
            legal_name: form.getFieldValue('legal_name'),
            display_name: form.getFieldValue('display_name'),
            parish_priest: form.getFieldValue('parish_priest'),
            phone: form.getFieldValue('phone'),
            parish: form.getFieldValue('parish'),
            street: form.getFieldValue('street'),
            city: form.getFieldValue('city'),
            state: form.getFieldValue('state'),
            country: form.getFieldValue('country'),
            website: form.getFieldValue('website'),
            zipCode: form.getFieldValue('zipCode'),
            is_logo: props.company.is_logo,
            is_signature: props.company.is_signature,
        };
        props.onFinish(payload);
    };

    const onClose = () => {
        props.setClose(false);
    };

    return (
        <>
            <Drawer
                title="Actualizar información de la parroquia"
                width={720}
                onClose={onClose}
                closable
                visible={props.open}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancelar</Button>
                        <Button className={'second'} onClick={onFinish} type="primary">
                            Guardar Cambios
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        legal_name: props.company.legal_name,
                        display_name: props.company.display_name,
                        parish_priest: props.company.parish_priest,
                        phone: props.company.phone,
                        parish: props.company.parish,
                        street: props.company.street,
                        city: props.company.city,
                        state: props.company.state,
                        country: props.company.country,
                        website: props.company.website,
                        zipCode: props.company.zipCode,
                    }}
                    onFinish={onFinish}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="legal_name"
                                label="Nombre Legal"
                                rules={[{ required: true, message: Strings.inputs.errors.required }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="display_name"
                                label="Nombre corto"
                                rules={[{ required: true, message: Strings.inputs.errors.required }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="website"
                                label="Sitio Web"
                                rules={[{ required: true, message: Strings.inputs.errors.required }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="zipCode"
                                label="Código Postal"
                                rules={[{ required: true, message: Strings.inputs.errors.required }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="parish_priest"
                                label="Párroco actual"
                                rules={[{ required: true, message: Strings.inputs.errors.required }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="phone"
                                label="Teléfonos"
                                rules={[{ required: true, message: Strings.inputs.errors.required }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="street"
                                label="Dirección"
                                rules={[{ required: true, message: Strings.inputs.errors.required }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item
                                name="parish"
                                label="Parroquia"
                                rules={[{ required: true, message: Strings.inputs.errors.required }]}
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="city"
                                label="Ciudad"
                                rules={[{ required: true, message: Strings.inputs.errors.required }]}
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="state"
                                label="Provincia"
                                rules={[{ required: true, message: Strings.inputs.errors.required }]}
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="country"
                                label="País"
                                rules={[{ required: true, message: Strings.inputs.errors.required }]}
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );
};
