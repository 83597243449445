import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Divider, Form, Input, Popconfirm } from 'antd';
import { VscFilePdf, VscSaveAll, VscSaveAs, VscTrash } from 'react-icons/vsc';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import { Strings } from '../../../utils/strings';
import { ProclamationProps } from '../types';

interface Props {
    type: 'create' | 'update';
    proclamation?: ProclamationProps;
    handleUpdate?: (payload: ProclamationProps, list: boolean) => void;
    handleCreate?: (payload: ProclamationProps, list: boolean) => void;
    handleRemove?: (id: number) => void;
    handlePreviewCertificate?: (id: number, template: string) => void;
}

export const ProclamationForm = ({
    type,
    proclamation,
    handleCreate,
    handleRemove,
    handleUpdate,
    handlePreviewCertificate,
}: Props) => {
    const [list, setList] = useState(false);
    const [form] = Form.useForm();
    const proclamationDate = proclamation?.date && proclamation?.time ? proclamation.date + proclamation.time : null;

    useEffect(() => {
        form.resetFields();
    }, [proclamation]);

    const onFinish = (values: ProclamationProps) => {
        const payload: ProclamationProps = {
            member_one: values.member_one,
            member_two: values.member_two,
            church: values.church,
            date: moment(values.date).format('YYYY-MM-DD'),
            time: moment(values.date).format('HH:mm'),
            boyfriend_place: values.boyfriend_place,
            boyfriend_address: values.boyfriend_address,
            girlfriend_place: values.girlfriend_place,
            girlfriend_address: values.girlfriend_address,
            boyfriend_father: values.boyfriend_father,
            boyfriend_mother: values.boyfriend_mother,
            girlfriend_father: values.girlfriend_father,
            girlfriend_mother: values.girlfriend_mother,
        };

        if (type === 'create' && handleCreate) {
            handleCreate(payload, list);
            form.resetFields();
        } else if (type === 'update' && handleUpdate) {
            const refactor_payload = {
                id: proclamation?.id,
                ...payload,
            };
            handleUpdate(refactor_payload, list);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            form={form}
            initialValues={{
                member_one: proclamation?.member_one,
                member_two: proclamation?.member_two,
                church: proclamation?.church,
                date: proclamationDate ? moment(proclamationDate, 'YYYY-MM-DD HH:mm') : null,
                boyfriend_place: proclamation?.boyfriend_place,
                boyfriend_address: proclamation?.boyfriend_address,
                girlfriend_place: proclamation?.girlfriend_place,
                girlfriend_address: proclamation?.girlfriend_address,
                boyfriend_father: proclamation?.boyfriend_father,
                boyfriend_mother: proclamation?.boyfriend_mother,
                girlfriend_father: proclamation?.girlfriend_father,
                girlfriend_mother: proclamation?.girlfriend_mother,
            }}
            name="add-edit-baptism"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
        >
            <div className="grid grid-cols-2 gap-4">
                <Form.Item
                    label="Novio"
                    name="member_one"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Novia"
                    name="member_two"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <Form.Item
                    label="Fecha de matrimonio"
                    name="date"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <DatePicker
                        format={'YYYY/MM/DD HH:mm'}
                        showTime={{ format: 'HH:mm' }}
                        locale={locale}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
                <Form.Item
                    label="Iglesia"
                    name="church"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>
            </div>

            <Divider>Datos del Novio</Divider>

            <div className="grid grid-cols-2 gap-4">
                <Form.Item label="Papá del novio" name="boyfriend_father">
                    <Input />
                </Form.Item>

                <Form.Item label="Mamá del novio" name="boyfriend_mother">
                    <Input />
                </Form.Item>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <Form.Item
                    label="Lugar de nacimiento del novio"
                    name="boyfriend_place"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Domicilio del novio"
                    name="boyfriend_address"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>
            </div>

            <Divider>Datos de la Novia</Divider>

            <div className="grid grid-cols-2 gap-4">
                <Form.Item label="Papá de la novia" name="girlfriend_father">
                    <Input />
                </Form.Item>

                <Form.Item label="Mamá de la novia" name="girlfriend_mother">
                    <Input />
                </Form.Item>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <Form.Item
                    label="Lugar de nacimiento de la novia"
                    name="girlfriend_place"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Domicilio de la novia"
                    name="girlfriend_address"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>
            </div>

            <Form.Item>
                <div className={'flex flex-row space-x-4'}>
                    <Button
                        className={'second'}
                        type="primary"
                        htmlType="submit"
                        icon={<VscSaveAs className="inline-flex mr-1" size={'18px'} />}
                        onClick={() => setList(false)}
                    >
                        Guardar
                    </Button>
                    <Button
                        className={'second'}
                        type="primary"
                        htmlType="submit"
                        icon={<VscSaveAll className="inline-flex mr-1" size={'18px'} />}
                        onClick={() => setList(true)}
                    >
                        Guardar y ver registros
                    </Button>
                    {type === 'update' && (
                        <>
                            <Button
                                type="default"
                                icon={<VscFilePdf className="inline-flex mr-1" size={'18px'} />}
                                onClick={() =>
                                    handlePreviewCertificate &&
                                    proclamation?.id &&
                                    handlePreviewCertificate(proclamation.id, 'A4')
                                }
                            >
                                Certificado en A4
                            </Button>

                            <Button
                                type="default"
                                icon={<VscFilePdf className="inline-flex mr-1" size={'18px'} />}
                                onClick={() =>
                                    handlePreviewCertificate &&
                                    proclamation?.id &&
                                    handlePreviewCertificate(proclamation.id, 'A5')
                                }
                            >
                                Certificado en A5
                            </Button>

                            <Popconfirm
                                title={'¿Eliminar Proclama?'}
                                onConfirm={() => handleRemove && proclamation?.id && handleRemove(proclamation.id)}
                                okText={'Eliminar'}
                                cancelText={'Cancelar'}
                            >
                                <Button
                                    className={'remove'}
                                    type="default"
                                    icon={<VscTrash className="inline-flex mr-1" size={'18px'} />}
                                >
                                    Eliminar
                                </Button>
                            </Popconfirm>
                        </>
                    )}
                </div>
            </Form.Item>
        </Form>
    );
};
