import { makeObservable, observable, runInAction } from 'mobx';
import { get, patch, post, remove } from '../../../utils/api';
import { CourseProps } from '../types';

class CourseStore {
    registrations: any[] = [];
    courses: CourseProps[] = [];
    course: CourseProps | null = null;
    pagination: { defaultPageSize: number; total: number } = { defaultPageSize: 20, total: 0 };
    is_loading = false;

    constructor() {
        makeObservable(this, {
            courses: observable,
            course: observable,
            pagination: observable,
            is_loading: observable,
        });
    }

    async view(id: number) {
        const response = await get(`/staff/catechism/courses/${id}`);
        runInAction(() => {
            this.course = response.data;
        });
    }

    async list(params = {}) {
        this.is_loading = true;
        const response = await get(`/staff/catechism/courses/`, params);
        runInAction(() => {
            this.courses = response.data.results;
            this.pagination = {
                defaultPageSize: 20,
                total: response.data.count,
            };
            this.is_loading = false;
        });
        return response.data.results;
    }

    async create(payload: CourseProps) {
        const response = await post(`/staff/catechism/courses/`, payload);
        runInAction(() => {
            this.courses.push(response.data);
        });
        this.list();
        return response.data;
    }

    async patch(payload = {}, id: number) {
        const response = await patch(`/staff/catechism/courses/${id}/`, payload);
        runInAction(() => {
            this.course = response.data;
        });
        return response.data;
    }

    async courses_by_level(payload = {}, id: number) {
        const response = await post(`/staff/catechism/community/${id}/courses-by-level/`, payload);
        runInAction(() => {
            this.courses = response.data;
        });
        return response.data;
    }

    async registrations_by_course(course_id: number) {
        const response = await get(`/staff/catechism/courses/${course_id}/registrations/`);
        runInAction(() => {
            this.registrations = response.data;
        });
        return response.data;
    }

    async remove(id: number) {
        return await remove(`/staff/catechism/courses/${id}`).then(() => this.list());
    }

    async change_course(registration_id: number, payload: { course: number }) {
        return await post(`/staff/catechism/registration/${registration_id}/change-course/`, payload);
    }

    clean_data = async () => {
        this.registrations = [];
    };
}

export default new CourseStore();
