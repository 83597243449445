import { notification } from 'antd';

export const NotificationComponent = (
    type: 'success' | 'warning' | 'error',
    message: string,
    description?: string
): void => {
    notification[type]({
        message: message,
        description: description,
    });
};
