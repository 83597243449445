import React from 'react';
import { Button, DatePicker, Form } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import { Strings } from '../../../utils/strings';
import { VscSaveAll } from 'react-icons/vsc';
import { FcCancel } from 'react-icons/fc';

interface Props {
    handleCreate: (payload: any) => void;
    setIsModalVisible: (value: boolean) => void;
}

export const PeriodForm: React.FC<Props> = ({ handleCreate, setIsModalVisible }: Props) => {
    const [form] = Form.useForm();

    const onFinish = (values: { start_date: Date; end_date: Date }) => {
        const payload = {
            start_date: moment(values.start_date).format('YYYY-MM-DD'),
            end_date: moment(values.end_date).format('YYYY-MM-DD'),
        };
        handleCreate(payload);
        form.resetFields();
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Form form={form} name="period-form" onFinish={onFinish} onFinishFailed={onFinishFailed} layout="vertical">
                <div className="grid gap-4">
                    <Form.Item
                        label="Fecha Inicio"
                        name="start_date"
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <DatePicker locale={locale} className="form-control" style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item
                        label="Fecha Fin"
                        name="end_date"
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <DatePicker locale={locale} className="form-control" style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item>
                        <div className={'flex flex-row-reverse'}>
                            <Button
                                className={'mx-2 second'}
                                type="primary"
                                htmlType="submit"
                                icon={<VscSaveAll className="inline-flex mr-1" size={'18px'} />}
                            >
                                {Strings.actions.save}
                            </Button>
                            <Button
                                className={'mx-2 remove'}
                                onClick={() => setIsModalVisible(false)}
                                icon={<FcCancel className="inline-flex mr-1" size={'18px'} />}
                            >
                                {Strings.actions.cancel}
                            </Button>
                        </div>
                    </Form.Item>
                </div>
            </Form>
        </>
    );
};
