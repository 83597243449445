import React, { useEffect, useState } from 'react';
import { MenuTop as CatechismMenuTop } from '../MenuTop';
import { MemberProps } from '../types';
import MemberStore from '../stores/MemberStore';
import { notification, Space, Spin } from 'antd/es';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Card, Drawer, Modal, Tag, Typography } from 'antd';
import { MembersFilter } from '../filters';
import { MemberForm, RegisterForm } from '../forms';
import PeriodStore from '../stores/PeriodStore';
import LevelStore from '../stores/LevelStore';
import RegistrationStore from '../stores/RegistrationStore';
import ErrorRender from '../../../library/outlets/ErrorRender';
import { MemberTable } from '../tables';
import { capitalizeFirstLetter } from '../../../library';
import { CreateForms } from '../CreateForms';
import { toastSuccess, toastWarnig } from '../../../utils/toast';
import { SiAddthis } from 'react-icons/si';
import { RiCloseCircleLine, RiErrorWarningLine, RiListOrdered, RiPlayCircleLine } from 'react-icons/ri';
import { TbCircleCheck } from 'react-icons/tb';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const CatechismMemberPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [isModalCreate, setIsModalCreate] = useState(false);
    const [isModalUpdate, setIsModalUpdate] = useState(false);
    const [isModalRegistrationCreate, setIsModalRegistrationCreate] = useState(false);
    const [registrationCreated, setRegistrationCreated] = useState(false);
    const [registrationCurrent, setRegistrationCurrent] = useState(false);
    const [drawerHistory, setDrawerHistory] = useState(false);
    const [record, setRecord] = useState<MemberProps | null>(null);
    const searchParams = useQuery();

    async function handleListMembers(params = {}) {
        setLoading(true);
        try {
            await MemberStore.list(params);
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function handleCreateMember(payload: any) {
        setLoading(true);
        try {
            await MemberStore.create(payload);
            await handleListMembers();
            toastSuccess('Integrante registrado!');
            setIsModalCreate(false);
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    const handleCreateRegistration = async (payload: any) => {
        if (record) {
            setLoading(true);
            payload.member = record.id;
            try {
                await RegistrationStore.create(payload).then(() => setRegistrationCreated(true));
                toastSuccess('Matricula registrada!');
                setLoading(false);
            } catch (err: any) {
                notification.error({
                    message: <ErrorRender error={err} />,
                    placement: 'topRight',
                });
                setLoading(false);
            }
        }
    };

    async function getLevels() {
        try {
            await LevelStore.list();
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
    }

    async function handleGetRegistrations(member_id: number) {
        setLoading(true);
        setRegistrationCurrent(false);
        try {
            await MemberStore.getRegistrations(member_id).then(() => {
                const registration_current = MemberStore.registrations?.filter((r: any) => {
                    return r.course.period.period === PeriodStore.period_active?.year;
                });
                if (registration_current !== undefined && registration_current.length > 0) {
                    setRegistrationCurrent(true);
                } else {
                    setRegistrationCurrent(false);
                }
            });
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
        setLoading(false);
    }

    async function handlePatchRegistration(payload: any, registration_id: number) {
        setLoading(true);
        try {
            const response = await RegistrationStore.patch(payload, registration_id);
            if (response && record && record.id) await handleGetRegistrations(record.id);
            toastWarnig('Se cambio el estado de la matrícula');
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function getPeriodActive() {
        try {
            await PeriodStore.get_active();
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
    }

    useEffect(() => {
        getPeriodActive();
        getLevels();
        handleListMembers(searchParams);
    }, [location.search]);

    async function handleUpdate(payload: MemberProps, id: number) {
        setLoading(true);
        try {
            await MemberStore.patch(payload, id);
            await handleListMembers();
            toastSuccess('Integrante actualizado!');
            setLoading(false);
            setIsModalUpdate(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function handleRemove(id: number) {
        setLoading(true);
        try {
            await MemberStore.remove(id);
            toastSuccess('Integrante eliminado!');
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    return (
        <>
            <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
                <CatechismMenuTop active={'member'} />
                <div className={'my-8'}>
                    <Card
                        bordered={false}
                        size="small"
                        title={
                            <div className={'flex flex-row'}>
                                <div className={'mr-2'}>
                                    <span className="font-bold">Integrantes</span> registrados
                                </div>
                            </div>
                        }
                        extra={
                            <div className={'flex flex-row'}>
                                <div className={'mr-5 mt-1.5'}>
                                    {PeriodStore.period_active !== null && (
                                        <span>
                                            <b>Período activo: </b>
                                            {PeriodStore.period_active.year}
                                        </span>
                                    )}
                                </div>
                                <Button type={'link'} onClick={() => setIsModalCreate(true)}>
                                    <div className={'flex flex-row'}>
                                        <SiAddthis className={'mt-1 mr-1'} />
                                        <span>Nuevo integrante</span>
                                    </div>
                                </Button>
                            </div>
                        }
                    >
                        <div className={'flex flex-row w-full'}>
                            <div className={'w-full'}>
                                <MembersFilter />
                            </div>
                        </div>
                        <MemberTable
                            handleRemove={handleRemove}
                            loading={loading}
                            setRecord={async (record: MemberProps) => {
                                if (record.id) {
                                    await setRecord(record);
                                    await MemberStore.syncMember(null).then(() => {
                                        MemberStore.syncMember(record);
                                    });
                                    await handleGetRegistrations(record.id);
                                }
                            }}
                            setIsModalVisible={setIsModalUpdate}
                            setIsModalRegistrationCreate={setIsModalRegistrationCreate}
                        />
                    </Card>

                    {record && isModalUpdate && (
                        <Modal
                            title="Actualizar Integrante"
                            visible={isModalUpdate}
                            footer={false}
                            onCancel={() => setIsModalUpdate(false)}
                            width={'60%'}
                        >
                            <MemberForm
                                setIsModalVisible={setIsModalUpdate}
                                member={record}
                                handleUpdate={(payload: any, id: number) => handleUpdate(payload, id)}
                                type={'edit'}
                            />
                        </Modal>
                    )}

                    {isModalCreate && (
                        <Modal
                            title={<b>{'Nuevo Integrante'}</b>}
                            visible={isModalCreate}
                            footer={false}
                            onCancel={() => setIsModalCreate(false)}
                            width={'60%'}
                        >
                            <CreateForms
                                isModalVisible={isModalCreate}
                                setIsModalVisible={setIsModalCreate}
                                modal={{ title: 'Nuevo Integrante', type: 'FormMember' }}
                                handleCreate={handleCreateMember}
                            />
                        </Modal>
                    )}

                    {isModalRegistrationCreate && record && (
                        <Modal
                            title={
                                <div>
                                    <div className={'font-bold'}>Nueva Matrícula: </div>
                                </div>
                            }
                            visible={isModalRegistrationCreate}
                            footer={false}
                            onCancel={() => setIsModalRegistrationCreate(false)}
                            width={'80%'}
                        >
                            {PeriodStore.period_active && (
                                <>
                                    {registrationCreated ? (
                                        <>
                                            <div
                                                className="flex p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800"
                                                role="alert"
                                            >
                                                <svg
                                                    aria-hidden="true"
                                                    className="flex-shrink-0 inline w-5 h-5 mr-3"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                                        clipRule="evenodd"
                                                    ></path>
                                                </svg>
                                                <span className="sr-only">Matrícula Creada</span>
                                                <div>
                                                    <span>
                                                        Se registro la matrícula para:{' '}
                                                        {record.last_name !== null && record.last_name} {record.name}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className={'flex justify-center space-x-2 mt-10'}>
                                                <Button
                                                    type="primary"
                                                    onClick={() => {
                                                        setRegistrationCreated(false);
                                                        setIsModalRegistrationCreate(false);
                                                    }}
                                                >
                                                    Continuar en Ingerantes
                                                </Button>
                                                <Button
                                                    type="primary"
                                                    onClick={() => {
                                                        setIsModalRegistrationCreate(false);
                                                        navigate('/catechism/registration/', { replace: true });
                                                    }}
                                                >
                                                    Ir a Matrículas
                                                </Button>
                                            </div>
                                        </>
                                    ) : (
                                        <Spin spinning={loading}>
                                            <div
                                                className={
                                                    registrationCurrent
                                                        ? 'flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800'
                                                        : 'flex p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800"'
                                                }
                                                role="alert"
                                            >
                                                <svg
                                                    aria-hidden="true"
                                                    className="flex-shrink-0 inline w-5 h-5 mr-3"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                                        clipRule="evenodd"
                                                    ></path>
                                                </svg>
                                                <span className="sr-only">Info</span>
                                                <div>
                                                    <span className="font-medium font-bold text-lg">
                                                        {record.last_name !== null && record.last_name} {record.name}
                                                    </span>
                                                    <div className={'flex flex-row'}>
                                                        <div className={'ml-2'}>
                                                            <label className={'font-bold'}>Hijo/a de:</label>{' '}
                                                            {record.father && capitalizeFirstLetter(record.father)} -{' '}
                                                            {record.mother && capitalizeFirstLetter(record.mother)}
                                                        </div>
                                                        <div className={'ml-2'}>
                                                            <label className={'font-bold'}>F. de Nacimiento:</label>{' '}
                                                            {record.birth_date}
                                                        </div>
                                                        <div className={'ml-2'}>
                                                            <label className={'font-bold'}>Edad:</label> {record.age}
                                                        </div>
                                                    </div>
                                                    <div className={'flex flex-col'}>
                                                        <div className={'ml-2'}>
                                                            <label className={'font-bold'}>Dirección:</label>{' '}
                                                            {record.address}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex justify-end'}>
                                                {registrationCurrent && (
                                                    <span className={'text-sm text-red-700'}>
                                                        Ya existe la matrícula para el período{' '}
                                                        {PeriodStore.period_active.year}
                                                    </span>
                                                )}
                                                <Typography.Link
                                                    onClick={async () => {
                                                        if (record && record.id) {
                                                            await handleGetRegistrations(record.id);
                                                            setDrawerHistory(true);
                                                        }
                                                    }}
                                                >
                                                    <div className={'flex flex-row ml-4'}>
                                                        <RiListOrdered className={'mt-1 mr-1'} />
                                                        <span>Ver historal</span>
                                                    </div>
                                                </Typography.Link>
                                            </div>
                                            <div>
                                                <RegisterForm
                                                    levels={LevelStore.levels}
                                                    handleCreate={handleCreateRegistration}
                                                    period={PeriodStore.period_active}
                                                />
                                            </div>
                                            <div className={'flex justify-center space-x-2 mt-10'}>
                                                <Button
                                                    type="default"
                                                    onClick={() => setIsModalRegistrationCreate(false)}
                                                >
                                                    Cerrar
                                                </Button>
                                                <Button
                                                    type="default"
                                                    onClick={() => {
                                                        setIsModalRegistrationCreate(false);
                                                        navigate('/catechism/registration/', { replace: true });
                                                    }}
                                                >
                                                    Ir a Matrículas
                                                </Button>
                                            </div>
                                        </Spin>
                                    )}
                                </>
                            )}
                        </Modal>
                    )}
                </div>

                {drawerHistory && (
                    <Drawer
                        width="80%"
                        title={
                            <>
                                <b>Integrante:</b>{' '}
                                {record && (
                                    <span>
                                        {record.last_name !== null && record.last_name} {record.name}
                                    </span>
                                )}
                            </>
                        }
                        placement="right"
                        onClose={() => setDrawerHistory(false)}
                        visible={drawerHistory}
                    >
                        Historial de catequesis por período:
                        <hr />
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
                            <Spin spinning={loading}>
                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="text-center py-3 px-6">
                                                Período
                                            </th>
                                            <th scope="col" className="text-center py-3">
                                                Comunidad / Nivel / Grupo
                                            </th>
                                            <th scope="col" className="text-center py-3 px-6">
                                                Estado
                                            </th>
                                            <th scope="col" className="text-center py-3 px-6">
                                                Representante
                                            </th>
                                            <th scope="col" className="text-center py-3 px-6">
                                                Fecha de Sacramento
                                            </th>
                                            <th>
                                                <span className="sr-only">Actions</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {MemberStore.registrations !== null &&
                                            MemberStore.registrations.map((record: any) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td className={'text-center py-2'}>
                                                                {record.course.period.period}
                                                            </td>
                                                            <td className={'text-center py-2'}>
                                                                <div>
                                                                    {capitalizeFirstLetter(record.course.level.alias)}
                                                                </div>
                                                                <div className={'text-xs'}>
                                                                    {capitalizeFirstLetter(
                                                                        record.course.community.name
                                                                    )}{' '}
                                                                    | {capitalizeFirstLetter(record.course.name)}
                                                                </div>
                                                            </td>
                                                            <td className={'text-center py-2'}>
                                                                {record.status.toLowerCase() === 'en curso' ? (
                                                                    <Tag color={'purple'}>{record.status}</Tag>
                                                                ) : record.status.toLowerCase() === 'aprobado' ? (
                                                                    <Tag color={'#1fb529'}>{record.status}</Tag>
                                                                ) : record.status.toLowerCase() === 'reprobado' ? (
                                                                    <Tag color={'#de3415'}>{record.status}</Tag>
                                                                ) : (
                                                                    record.status.toLowerCase() === 'retirado' && (
                                                                        <Tag color={'#fd832b'}>{record.status}</Tag>
                                                                    )
                                                                )}
                                                            </td>
                                                            <td className={'text-center py-2'}>
                                                                {capitalizeFirstLetter(record.representative)}
                                                            </td>
                                                            <td className={'text-center py-2'}>
                                                                {record.date_sacrament}
                                                            </td>
                                                            <td>
                                                                <Space size="middle">
                                                                    <TbCircleCheck
                                                                        className="cursor-pointer transform hover:scale-150 duration-75"
                                                                        title="Aprobar"
                                                                        size={'18px'}
                                                                        color={'green'}
                                                                        onClick={async () => {
                                                                            await handlePatchRegistration(
                                                                                { status: 'Aprobado' },
                                                                                record.id
                                                                            );
                                                                        }}
                                                                    />

                                                                    <RiCloseCircleLine
                                                                        className="cursor-pointer transform hover:scale-150 duration-75"
                                                                        title="Reprobar"
                                                                        size={'18px'}
                                                                        color={'red'}
                                                                        onClick={async () => {
                                                                            await handlePatchRegistration(
                                                                                { status: 'Reprobado' },
                                                                                record.id
                                                                            );
                                                                        }}
                                                                    />

                                                                    <RiErrorWarningLine
                                                                        className="cursor-pointer transform hover:scale-150 duration-75"
                                                                        title="Retirado"
                                                                        size={'18px'}
                                                                        color={'purple'}
                                                                        onClick={async () => {
                                                                            await handlePatchRegistration(
                                                                                { status: 'Retirado' },
                                                                                record.id
                                                                            );
                                                                        }}
                                                                    />

                                                                    <RiPlayCircleLine
                                                                        className="cursor-pointer transform hover:scale-150 duration-75"
                                                                        title="En Curso"
                                                                        size={'18px'}
                                                                        color={'#26BBD3'}
                                                                        onClick={async () => {
                                                                            await handlePatchRegistration(
                                                                                { status: 'En Curso' },
                                                                                record.id
                                                                            );
                                                                        }}
                                                                    />
                                                                </Space>
                                                            </td>
                                                        </tr>
                                                    </>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </Spin>
                        </div>
                    </Drawer>
                )}
            </div>
        </>
    );
};
