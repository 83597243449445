import { makeObservable, observable, runInAction } from 'mobx';
import { get, patch, post, remove } from '../../../utils/api';
import { MarriageProps } from '../types';

class MarriageStore {
    marriages: MarriageProps[] = [];
    marriage: MarriageProps | null = null;
    pagination: { defaultPageSize: number; total: number } = { defaultPageSize: 20, total: 0 };

    constructor() {
        makeObservable(this, {
            marriages: observable,
            marriage: observable,
            pagination: observable,
        });
    }

    async get(id: number) {
        const response = await get(`/staff/books/marriage/${id}`);
        runInAction(() => {
            this.marriage = response.data;
        });
    }

    async list(params = {}) {
        const response = await get(`/staff/books/marriage/`, params);
        runInAction(() => {
            this.marriages = response.data.results;
            this.pagination = {
                defaultPageSize: 20,
                total: response.data.count,
            };
        });
        return response.data.results;
    }

    async create(payload: MarriageProps) {
        const response = await post(`/staff/books/marriage/`, payload);
        runInAction(() => {
            this.marriages.push(response.data);
        });
        return response.data;
    }

    async patch(payload = {}, id?: number) {
        const response = await patch(`/staff/books/marriage/${id}/`, payload);
        runInAction(() => {
            this.marriage = response.data;
            this.list();
        });
        return response.data;
    }

    async remove(id: number) {
        return await remove(`/staff/books/marriage/${id}`).then(() => {
            this.list();
        });
    }

    async view(id: number) {
        const response = await get(`/staff/books/marriage/${id}`);
        runInAction(() => {
            this.marriage = response.data;
        });
        return response.data;
    }
}

export default new MarriageStore();
