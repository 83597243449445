import React from 'react';
import { Alert } from 'antd';
import { AxiosError } from 'axios';

// @ts-ignore
const parseMessage = (message: any) => {
    if (!message) {
        return;
    }

    if (typeof message == 'string') {
        return message;
    }

    if (Array.isArray(message)) {
        return parseArray(message);
    }

    if (typeof message === 'object') {
        return parseObject(message);
    }

    return message;
};

// @ts-ignore
const parseArray = (messages: any[]) => {
    return messages.map(message => {
        return parseMessage(message);
    });
};

const parseObject = (messages: []) => {
    return Object.keys(messages).map(key => {
        // @ts-ignore
        return <ErrorItem key={key} field={key} label={messages[key]} />;
    });
};

const ErrorItem = ({ field, label }: { field: string; label: string }) => {
    if (!field || field === 'non_field_errors' || field === 'detail') {
        return <span> {label}</span>;
    }

    field = field.replace(/_/g, ' ');

    return (
        <span>
            <span style={{ fontSize: '300' }}>{field}</span> : {label} &nbsp;
        </span>
    );
};

interface Props {
    error?: AxiosError | null;
}

const ErrorRender = ({ error }: Props) => {
    if (!error) {
        return null;
    }
    if (!error.response) {
        return <span>{JSON.stringify(error.message)}</span>;
    }

    if (error.response.status === 400) {
        // @ts-ignore
        const items = Object.keys(error.response.data).map(key => {
            // @ts-ignore
            const message = parseMessage(error.response?.data[key]);

            return (
                <li key={key}>
                    <ErrorItem field={key} label={message} />
                </li>
            );
        });

        return <Alert message={<ul>{items}</ul>} type="error" showIcon />;
    }

    if (error.response.status === 401) {
        return <span>No autorizado</span>;
    }

    if (error.response.status === 403) {
        return <span>Acceso denegado.</span>;
    }

    if (error.response.status === 404) {
        return <span>No existe el registro o archivo</span>;
    }

    // @ts-ignore
    if (error.response.data.detail) {
        // @ts-ignore
        return <span>{JSON.stringify(error.response.data.detail)}</span>;
    }

    return null;
};

export default ErrorRender;
