import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import UserStore from '../../stores/UserStore';
import { Strings } from '../../utils/strings';
import logo from '../../images/logo.png';
import { NotificationComponent } from '../../library';

export const RecoverPasswordPage = () => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const onFinish = async (values: { email: string }) => {
        setLoading(true);
        try {
            await UserStore.resetPassword(values);
            NotificationComponent('success', Strings.notifications.success_recover);
            setLoading(false);
            form.resetFields();
        } catch (e) {
            NotificationComponent('error', Strings.notifications.error_email_recover);
            setLoading(false);
        }
    };

    const redirectLogin = async () => {
        await UserStore.loadUser();
        if (UserStore.user) {
            navigate('/dashboard', { replace: true });
        }
    };

    useEffect(() => {
        form.resetFields;
        redirectLogin();
    }, []);

    return (
        <section className="flex flex-col w-full md:max-w-4xl h-screen mx-auto overflow-hidden bg-white md:rounded-lg md:shadow-lg md:flex-row md:h-full">
            <div className="flex items-center justify-center md:w-1/2 bg-slate-800">
                <div className="px-6 pt-6 pb-4 md:px-8 md:py-1">
                    <div className="flex justify-center pb-5">
                        <img className="w-1/4 md:w-2/3 md:flex" src={logo} alt="SGP-Alpes" />
                    </div>
                    <h2 className="font-['Oswald'] text-center text-2xl md:text-3xl text-gray-300">
                        {Strings.titles.app}
                    </h2>
                </div>
            </div>

            <div className="flex items-center h-full w-full max-w-md px-6 mx-auto md:w-1/2">
                <div className="flex-1">
                    <div className="text-center">
                        <h2 className="text-3xl md:text-4xl font-semibold md:font-bold text-center text-slate-700">
                            {Strings.titles.recover_password}
                        </h2>
                        <p className="mt-6 md:mt-3 md:text-lg text-slate-600">{Strings.texts.recover_password}</p>
                    </div>

                    <div className="mt-6">
                        <Form
                            form={form}
                            initialValues={{ remember: true }}
                            layout="vertical"
                            name="recover_password-form"
                            onFinish={onFinish}
                        >
                            <Form.Item
                                name="email"
                                rules={[
                                    { required: true, message: Strings.inputs.errors.required_email },
                                    { type: 'email', message: Strings.inputs.errors.invalid_email },
                                ]}
                            >
                                <Input
                                    autoComplete="email"
                                    className="border-gray-200 focus:border-black"
                                    placeholder={Strings.inputs.placeholders.email}
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    className="w-full mt-2"
                                    htmlType="submit"
                                    size="large"
                                    type="primary"
                                    loading={loading}
                                >
                                    {Strings.actions.send}
                                </Button>
                            </Form.Item>
                        </Form>

                        <p className="mt-6 text-sm text-center text-slate-600">
                            {Strings.labels.login} &nbsp;
                            <Link className="font-semibold text-slate-700 hover:text-alpes_green-400" to="/login">
                                {Strings.actions.log_in}
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};
