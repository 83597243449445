import React, { useEffect, useState } from 'react';
import { MenuTop as CatechismMenuTop } from '../MenuTop';
import { useLocation } from 'react-router-dom';
import { Button, Card, Drawer, notification, Spin, Tag, Typography } from 'antd';
import { isMobile } from 'react-device-detect';
import { Form, Space } from 'antd/es';
import ErrorRender from '../../../library/outlets/ErrorRender';
import RegistrationStore from '../stores/RegistrationStore';
import MemberStore from '../stores/MemberStore';
import PeriodStore from '../stores/PeriodStore';
import LevelStore from '../stores/LevelStore';
import { requests } from '../../../utils/api';
import { DownloadFile } from '../../../utils/download_file';
import MemberSearchSelect from '../selects/MemberSearchSelect';
import { capitalizeFirstLetter, SwitchLogos } from '../../../library';
import { RegisterForm } from '../forms';
import { RegistrationsFilter } from '../filters';
import { RegistrationsTable } from '../tables';
import { SiAddthis } from 'react-icons/si';
import { RiDownloadCloudFill } from 'react-icons/ri';
import { toastInfo, toastSuccess } from '../../../utils/toast';
import { RiListOrdered, RiCloseCircleLine, RiErrorWarningLine, RiPlayCircleLine } from 'react-icons/ri';
import { TbCircleCheck } from 'react-icons/tb';
import { IoReloadOutline } from 'react-icons/io5';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const CatechismRegistrationPage = () => {
    const [create, setCreate] = useState(false);
    const [drawerInvoice, setDrawerInvoice] = useState(false);
    const [drawerHistory, setDrawerHistory] = useState(false);
    const [registrationCurrent, setRegistrationCurrent] = useState(false);
    const [selectMember, setSelectMember] = useState<{ id: number; name: string }>();
    const [registrations, setRegistrations] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [spinLoading, setSpinLoading] = useState<boolean>(false);
    const [fileURL, setFileURL] = useState<string | null>(null);

    const searchParams = useQuery();

    const handleCreate = async (payload: any) => {
        if (selectMember) {
            setLoading(true);
            payload.member = selectMember.id;
            try {
                await RegistrationStore.create(payload);
                await handleListMembersRegistration();
                toastSuccess('Matrícula Registrada!');
                setCreate(false);
                setLoading(false);
            } catch (err: any) {
                notification.error({
                    message: <ErrorRender error={err} />,
                    placement: 'topRight',
                });
                setLoading(false);
            }
        }
    };

    async function handleListMembersRegistration(params = {}) {
        setLoading(true);
        try {
            await RegistrationStore.list(params).then(() => {
                setRegistrations(RegistrationStore.registrations);
            });
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
        setLoading(false);
    }

    async function handleGetMember(member_id: number) {
        setLoading(true);
        try {
            await MemberStore.view(member_id);
            await handleGetRegistrations(member_id);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
        setLoading(false);
    }

    async function handleRemove(registration_id: number) {
        setLoading(true);
        try {
            await RegistrationStore.remove(registration_id);
            await handleListMembersRegistration(searchParams);
            toastSuccess('Matrícula eliminada');
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function handleGetTotals(params = {}) {
        setLoading(true);
        try {
            await RegistrationStore.get_totals(params).then(() => {
                setRegistrations(RegistrationStore.registrations);
            });
            toastInfo('Se actualizo los totales');
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
        setLoading(false);
    }

    async function getPeriodActive() {
        try {
            await PeriodStore.get_active();
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
    }

    async function getLevels() {
        try {
            await LevelStore.list();
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
    }

    useEffect(() => {
        getPeriodActive();
        getLevels();
        handleListMembersRegistration(searchParams);
    }, [location.search]);

    const get_file_registration = (registration_id: number) => {
        setLoading(true);
        requests
            .get2(`/staff/catechism/registration/${registration_id}/certificate/`, {
                responseType: 'blob',
            })
            .then((response: any) => {
                if (isMobile) {
                    setDrawerInvoice(false);
                } else {
                    const file = new Blob([response], { type: 'application/pdf' });
                    const fileURL2 = URL.createObjectURL(file);
                    setLoading(false);
                    setFileURL(fileURL2);
                }
            })
            .catch((err: any) => {
                notification.error({
                    message: <ErrorRender error={err} />,
                    placement: 'topRight',
                });
                setDrawerInvoice(false);
            });
    };

    const download_form_registration = async () => {
        setSpinLoading(true);
        const url = '/staff/catechism/registration/download-registration-forms/';
        await requests
            .get2(url, { responseType: 'blob' })
            .then((response: any) => {
                DownloadFile(response, 'Ficha de Matrícula.pdf');
            })
            .finally(() => setSpinLoading(false));
    };

    async function handleGetRegistrations(member_id: number) {
        setLoading(true);
        try {
            await MemberStore.getRegistrations(member_id).then(() => {
                const registration_current = MemberStore.registrations?.filter((r: any) => {
                    return r.course.period.period === PeriodStore.period_active?.year;
                });
                if (registration_current !== undefined && registration_current.length > 0) {
                    setRegistrationCurrent(true);
                } else {
                    setRegistrationCurrent(false);
                }
            });
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
        setLoading(false);
    }

    async function handlePatchRegistration(payload: any, registration_id: number) {
        setLoading(true);
        try {
            const response = await RegistrationStore.patch(payload, registration_id);
            if (response && selectMember) await handleGetRegistrations(selectMember.id);
            toastSuccess('Matrícula actualizada!');
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    return (
        <>
            <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
                <CatechismMenuTop active={'registration'} />
                <div className={'my-8'}>
                    <Card
                        bordered={false}
                        size="small"
                        title={
                            <>
                                {!create ? (
                                    <span className="font-bold">Matrículas Registradas</span>
                                ) : (
                                    <span className="font-bold">Nueva Matrícula</span>
                                )}
                            </>
                        }
                        extra={
                            <div className={'flex flex-row'}>
                                <div className={'mr-5 mt-1.5'}>
                                    {PeriodStore.period_active !== null && (
                                        <span>
                                            <b>Período activo: </b>
                                            {PeriodStore.period_active.year}
                                        </span>
                                    )}
                                </div>

                                <div className={'mr-5 mt-1.5'}>
                                    <SwitchLogos />
                                </div>

                                <div className={'mr-5 mt-1.5'}>
                                    <Spin spinning={spinLoading}>
                                        <Typography.Link onClick={() => download_form_registration()}>
                                            <div className={'flex flex-row'} title={'Descargar'}>
                                                <RiDownloadCloudFill className={'mt-1 mr-1'} />
                                                <span>Ficha de matrícula</span>
                                            </div>
                                        </Typography.Link>
                                    </Spin>
                                </div>

                                <div className={'mr-5 mt-1.5'}>
                                    <Typography.Link
                                        onClick={async () => {
                                            await MemberStore.reset_member_registrations();
                                            setCreate(!create);
                                        }}
                                    >
                                        <div className={'flex flex-row'}>
                                            <SiAddthis className={'mt-1 mr-1'} />
                                            <span>{!create ? 'Nueva Matrícula' : 'Matrículas Registradas'}</span>
                                        </div>
                                    </Typography.Link>
                                </div>
                            </div>
                        }
                    >
                        {create && PeriodStore.period_active ? (
                            <>
                                <div className={'flex justify-start w-full'}>
                                    <Form.Item label={'Buscar Integrante'} className={'w-full'}>
                                        <MemberSearchSelect
                                            size={'small'}
                                            style={{ width: '100%' }}
                                            className={'py-4'}
                                            onSelect={async (member: { key: number; label: string }) => {
                                                await handleGetMember(member.key);
                                                setSelectMember({ id: member.key, name: member.label });
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                {selectMember && MemberStore.member !== null && (
                                    <>
                                        <div
                                            className={
                                                registrationCurrent
                                                    ? 'flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800'
                                                    : 'flex p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800"'
                                            }
                                            role="alert"
                                        >
                                            <svg
                                                aria-hidden="true"
                                                className="flex-shrink-0 inline w-5 h-5 mr-3"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                                    clipRule="evenodd"
                                                ></path>
                                            </svg>
                                            <span className="sr-only">Info</span>
                                            <div>
                                                <span className="font-medium font-bold text-lg">
                                                    {selectMember.name}
                                                </span>
                                                <div className={'flex flex-row'}>
                                                    <div className={'ml-2'}>
                                                        <label className={'font-bold'}>Hijo/a de:</label>{' '}
                                                        {MemberStore.member.father &&
                                                            capitalizeFirstLetter(MemberStore.member.father)}{' '}
                                                        -{' '}
                                                        {MemberStore.member.mother &&
                                                            capitalizeFirstLetter(MemberStore.member.mother)}
                                                    </div>
                                                    <div className={'ml-2'}>
                                                        <label className={'font-bold'}>Fecha de Nacimiento:</label>{' '}
                                                        {MemberStore.member.birth_date}
                                                    </div>
                                                    <div className={'ml-2'}>
                                                        <label className={'font-bold'}>Edad:</label>{' '}
                                                        {MemberStore.member.age}
                                                    </div>
                                                </div>
                                                <div className={'flex flex-col'}>
                                                    <div className={'ml-2'}>
                                                        <label className={'font-bold'}>Dirección:</label>{' '}
                                                        {MemberStore.member.address}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {selectMember && true && (
                                            <div className={'flex justify-end'}>
                                                {registrationCurrent && (
                                                    <span className={'text-sm text-red-700'}>
                                                        Ya existe la matrícula para el período{' '}
                                                        {PeriodStore.period_active.year}
                                                    </span>
                                                )}
                                                <Typography.Link
                                                    onClick={async () => {
                                                        if (selectMember) {
                                                            await handleGetRegistrations(selectMember.id);
                                                            setDrawerHistory(true);
                                                        }
                                                    }}
                                                >
                                                    <div className={'flex flex-row ml-4'}>
                                                        <RiListOrdered className={'mt-1 mr-1'} />
                                                        <span>Ver historal</span>
                                                    </div>
                                                </Typography.Link>
                                            </div>
                                        )}

                                        {PeriodStore.period_active && (
                                            <div className={'mt-4'}>
                                                <RegisterForm
                                                    levels={LevelStore.levels}
                                                    handleCreate={handleCreate}
                                                    period={PeriodStore.period_active}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <div className={'flex flex-row w-full'}>
                                    <div className={'w-2/3'}>
                                        <RegistrationsFilter levels={LevelStore.levels} />
                                    </div>

                                    <div className={'flex flex-col w-1/3'}>
                                        <div className={'flex justify-center p-6'}>
                                            <div className={'flex space-x-4 mx-20'}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <div className="px-4 py-2 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                                                        <h5 className="text-2xl tracking-tight text-gray-900 dark:text-white">
                                                            {RegistrationStore.totals.total}
                                                        </h5>
                                                        <p className="font-normal text-gray-700 dark:text-gray-400">
                                                            <Tag color={'green'}>100%</Tag>
                                                        </p>
                                                        <p className={'font-bold text-green-600'}>Total</p>
                                                    </div>
                                                </div>
                                                <div style={{ textAlign: 'center' }}>
                                                    <div className="px-4 py-2 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                                                        <h5 className="text-2xl tracking-tight text-gray-900 dark:text-white">
                                                            {RegistrationStore.totals.woman_total}
                                                        </h5>
                                                        <p className="font-normal text-gray-700 dark:text-gray-400">
                                                            <Tag color={'pink'}>
                                                                {RegistrationStore.totals.percent_woman} %
                                                            </Tag>
                                                        </p>
                                                        <p className={'font-bold text-pink-700'}>Níñas</p>
                                                    </div>
                                                </div>
                                                <div style={{ textAlign: 'center' }}>
                                                    <div className="px-4 py-2 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                                                        <h5 className="text-2xl tracking-tight text-gray-900 dark:text-white">
                                                            {RegistrationStore.totals.man_total}
                                                        </h5>
                                                        <p className="font-normal text-gray-700 dark:text-gray-400">
                                                            <Tag color={'blue'}>
                                                                {RegistrationStore.totals.percent_man} %
                                                            </Tag>
                                                        </p>
                                                        <p className={'font-bold text-blue-400'}>Níños</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'flex justify-center'}>
                                            <Button
                                                onClick={() => handleGetTotals(searchParams)}
                                                icon={<IoReloadOutline className="inline-flex mr-1" size={'18px'} />}
                                            >
                                                Totales
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                                <RegistrationsTable
                                    loading={loading}
                                    get_file_registration={(registration_id: number) => {
                                        setDrawerInvoice(true);
                                        get_file_registration(registration_id);
                                    }}
                                    registrations={registrations}
                                    handleRemove={handleRemove}
                                />
                            </>
                        )}
                    </Card>

                    <Drawer
                        width="80%"
                        title={
                            <>
                                <b>Integrante:</b> {selectMember && <span>{selectMember.name}</span>}
                            </>
                        }
                        placement="right"
                        onClose={() => setDrawerHistory(false)}
                        visible={drawerHistory}
                    >
                        Historial de catequesis por período:
                        <hr />
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
                            <Spin spinning={loading}>
                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="text-center py-3 px-6">
                                                Período
                                            </th>
                                            <th scope="col" className="text-center py-3">
                                                Comunidad / Nivel / Grupo
                                            </th>
                                            <th scope="col" className="text-center py-3 px-6">
                                                Estado
                                            </th>
                                            <th scope="col" className="text-center py-3 px-6">
                                                Representante
                                            </th>
                                            <th scope="col" className="text-center py-3 px-6">
                                                Fecha de Sacramento
                                            </th>
                                            <th>
                                                <span className="sr-only">Actions</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {MemberStore.registrations !== null &&
                                            MemberStore.registrations.map((record: any) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td className={'text-center py-2'}>
                                                                {record.course.period.period}
                                                            </td>
                                                            <td className={'text-center py-2'}>
                                                                <div>
                                                                    {capitalizeFirstLetter(record.course.level.alias)}
                                                                </div>
                                                                <div className={'text-xs'}>
                                                                    {capitalizeFirstLetter(
                                                                        record.course.community.name
                                                                    )}{' '}
                                                                    | {capitalizeFirstLetter(record.course.name)}
                                                                </div>
                                                            </td>
                                                            <td className={'text-center py-2'}>
                                                                {record.status.toLowerCase() === 'en curso' ? (
                                                                    <Tag color={'purple'}>{record.status}</Tag>
                                                                ) : record.status.toLowerCase() === 'aprobado' ? (
                                                                    <Tag color={'#1fb529'}>{record.status}</Tag>
                                                                ) : record.status.toLowerCase() === 'reprobado' ? (
                                                                    <Tag color={'#de3415'}>{record.status}</Tag>
                                                                ) : (
                                                                    record.status.toLowerCase() === 'retirado' && (
                                                                        <Tag color={'#fd832b'}>{record.status}</Tag>
                                                                    )
                                                                )}
                                                            </td>
                                                            <td className={'text-center py-2'}>
                                                                {capitalizeFirstLetter(record.representative)}
                                                            </td>
                                                            <td className={'text-center py-2'}>
                                                                {record.date_sacrament}
                                                            </td>
                                                            <td>
                                                                <Space size="middle">
                                                                    <TbCircleCheck
                                                                        className="cursor-pointer transform hover:scale-150 duration-75"
                                                                        title="Aprobar"
                                                                        size={'18px'}
                                                                        color={'green'}
                                                                        onClick={async () => {
                                                                            await handlePatchRegistration(
                                                                                { status: 'Aprobado' },
                                                                                record.id
                                                                            );
                                                                        }}
                                                                    />

                                                                    <RiCloseCircleLine
                                                                        className="cursor-pointer transform hover:scale-150 duration-75"
                                                                        title="Reprobar"
                                                                        size={'18px'}
                                                                        color={'red'}
                                                                        onClick={async () => {
                                                                            await handlePatchRegistration(
                                                                                { status: 'Reprobado' },
                                                                                record.id
                                                                            );
                                                                        }}
                                                                    />

                                                                    <RiErrorWarningLine
                                                                        className="cursor-pointer transform hover:scale-150 duration-75"
                                                                        title="Retirado"
                                                                        size={'18px'}
                                                                        color={'purple'}
                                                                        onClick={async () => {
                                                                            await handlePatchRegistration(
                                                                                { status: 'Retirado' },
                                                                                record.id
                                                                            );
                                                                        }}
                                                                    />

                                                                    <RiPlayCircleLine
                                                                        className="cursor-pointer transform hover:scale-150 duration-75"
                                                                        title="En Curso"
                                                                        size={'18px'}
                                                                        color={'#26BBD3'}
                                                                        onClick={async () => {
                                                                            await handlePatchRegistration(
                                                                                { status: 'En Curso' },
                                                                                record.id
                                                                            );
                                                                        }}
                                                                    />
                                                                </Space>
                                                            </td>
                                                        </tr>
                                                    </>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </Spin>
                        </div>
                    </Drawer>

                    <Drawer
                        title="Comprobante de Matrícula"
                        width={'80%'}
                        closable
                        visible={drawerInvoice}
                        placement={'left'}
                        onClose={() => {
                            setFileURL(null);
                            setDrawerInvoice(false);
                        }}
                    >
                        <div className={'py-10'}>
                            <Spin spinning={loading} tip={'Loading...'}>
                                {fileURL && (
                                    <iframe
                                        style={{ marginTop: '-50px' }}
                                        title="Cerificado de Bautizo"
                                        src={fileURL}
                                        frameBorder="0"
                                        height={window.innerHeight}
                                        width="100%"
                                    />
                                )}
                            </Spin>
                        </div>
                    </Drawer>
                </div>
            </div>
        </>
    );
};
