import { makeObservable, observable, runInAction } from 'mobx';
import { get, patch, post, remove } from '../../../utils/api';
import { SubareaProps } from '../types';

class SubareaStore {
    subareas: SubareaProps[] = [];
    subarea: SubareaProps | null = null;
    pagination: { defaultPageSize: number; total: number } = { defaultPageSize: 20, total: 0 };

    constructor() {
        makeObservable(this, {
            subareas: observable,
            subarea: observable,
            pagination: observable,
        });
    }

    async list(params = {}) {
        const response = await get(`/staff/inventory/subarea/`, params);
        runInAction(() => {
            this.subareas = response.data.results;
            this.pagination = {
                defaultPageSize: 20,
                total: response.data.count,
            };
        });
        return response.data.results;
    }

    async create(payload: SubareaProps) {
        const response = await post(`/staff/inventory/subarea/`, payload);
        runInAction(() => {
            this.subareas.push(response.data);
        });
        return response.data;
    }

    async patch(payload = {}, id: number) {
        const response = await patch(`/staff/inventory/subarea/${id}/`, payload);
        runInAction(() => {
            this.subarea = response.data;
        });
        return response.data;
    }

    async remove(id: number) {
        return await remove(`/staff/inventory/subarea/${id}`).then(() => this.list());
    }
}

export default new SubareaStore();
