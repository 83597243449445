import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { Strings } from '../../../utils/strings';
import { SubareaProps } from '../types';
import { VscSaveAs } from 'react-icons/vsc';
import { AreaSelect } from '../selects';

interface Props {
    type: string;
    subarea?: SubareaProps;
    handleUpdate?: (payload: SubareaProps, list: boolean, id: number) => void;
    handleCreate?: (payload: SubareaProps, list: boolean) => void;
}

export const SubareaForm = ({ type, subarea, handleUpdate, handleCreate }: Props) => {
    const [form] = Form.useForm();
    const [list, setList] = useState(false);

    useEffect(() => {
        form.resetFields();
    }, [subarea]);

    const onFinish = (values: SubareaProps) => {
        const payload: SubareaProps = {
            name: values.name,
        };
        if (type === 'create' && handleCreate) {
            const refactor_payload = {
                area: values.area,
                ...payload,
            };
            handleCreate(refactor_payload, list);
            form.resetFields();
        } else if (type === 'update' && handleUpdate && subarea && subarea.id && typeof subarea.area === 'object') {
            const refactor_payload = {
                area: subarea.area.id,
                ...payload,
            };
            handleUpdate(refactor_payload, list, subarea.id);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Form
                form={form}
                initialValues={{
                    name: subarea?.name,
                }}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                name="add-edit-subarea"
            >
                <div className="grid grid-cols-2 gap-4">
                    {type === 'create' && (
                        <Form.Item
                            label="Seleccione Area"
                            name="area"
                            rules={[{ required: true, message: Strings.inputs.errors.required }]}
                        >
                            <AreaSelect style={{ wdith: '100%' }} setSubareas={v => console.log(v)} />
                        </Form.Item>
                    )}

                    <div className={'w-1/2'}>
                        <Form.Item
                            name="name"
                            label={'Nombre de la subárea'}
                            rules={[{ required: true, message: Strings.inputs.errors.required }]}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                </div>

                <div className={'flex flex-row space-x-4'}>
                    <Form.Item>
                        <Button
                            className="second"
                            type="primary"
                            htmlType="submit"
                            icon={<VscSaveAs className="inline-flex mr-1" size={'18px'} />}
                            onClick={() => setList(true)}
                        >
                            Guardar
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </>
    );
};
