import React from 'react';
import { Pagination } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { PaginationProps } from 'antd/lib/pagination/Pagination';

export const PaginationTable = (props: PaginationProps) => {
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(useLocation().search);
    const page = searchParams?.get('page');
    const current = page ? parseInt(page) : 1;

    return (
        <div style={{ margin: 'auto', textAlign: 'center' }}>
            <Pagination
                current={current}
                onChange={page => {
                    searchParams.set('page', page.toString());
                    navigate(`?${searchParams.toString()}`, { replace: true });
                }}
                showSizeChanger={false}
                {...props}
            />
        </div>
    );
};
