import React, { useEffect } from 'react';
import { useAuth } from '../context/Auth';
import { Navigate, useLocation } from 'react-router-dom';
import { LayoutProps } from '../utils/types';
import UserStore from '../stores/UserStore';
import CompanyStore from '../stores/CompanyStore';
import ReportStore from '../stores/ReportStore';

// @ts-ignore
// eslint-disable-next-line react/prop-types
export const RequireAuth = ({ children }) => {
    const location = useLocation();
    useAuth();

    useEffect(() => {
        UserStore.loadUser();
    }, []);

    // @ts-ignore
    if (localStorage.getItem('token') === null) {
        return <Navigate to="/login" state={{ path: location.pathname }} />;
    }
    return children;
};
