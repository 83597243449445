import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Drawer, PageHeader, notification, Breadcrumb, Modal, Spin, Tag, Typography } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react';
import RegistrationStore from '../stores/RegistrationStore';
import Search from 'antd/es/input/Search';
import { isMobile } from 'react-device-detect';
import CourseStore from '../stores/CourseStore';
import { CollaboratorProps, RegistrationProps } from '../types';
import { capitalizeFirstLetter, SwitchLogos } from '../../../library';
import { requests } from '../../../utils/api';
import { DownloadFile } from '../../../utils/download_file';
import ErrorRender from '../../../library/outlets/ErrorRender';
import ContributorSearchSelect from '../selects/ContributorSearchSelect';
import { CoursesTable, CoursesTableChange, MembersCourseTable } from '../tables';
import { toastInfo, toastSuccess, toastWarnig } from '../../../utils/toast';
import { RiMore2Fill, RiMailSendFill } from 'react-icons/ri';
import { BsFillFilePdfFill } from 'react-icons/bs';
import { MdPictureAsPdf } from 'react-icons/md';
import { HiOutlineUsers } from 'react-icons/hi';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { ExportExcel } from '../../../utils/common';
import { CoursesFilterByLevelCommunity } from '../filters';

interface Props {
    period: { id: number; year: string };
}

const AdminCourseSubpage = observer((props: Props) => {
    const { course_id } = useParams();
    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement>(null);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [drawerCertificate, setDrawerCertificate] = useState<boolean>(false);
    const [drawerListByCourse, setDrawerListByCourse] = useState<boolean>(false);
    const [spinLoading, setSpinLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [viewModalTeachers, setViewModalTeachers] = useState(false);
    const [fileRegistrationCertificateURL, setFileRegistrationCertificateURL] = useState<string | null>(null);
    const [fileListByCourseURL, setFileListByCourseURL] = useState<string | null>(null);
    const [registrations, setRegistrations] = useState<any[] | []>([]);
    const [teachers, setTeachers] = useState<CollaboratorProps[] | []>([]);
    const [teachers_ids, setTeachersIds] = useState<number[]>([]);
    const [registration_id, setRegistrationId] = useState<number | null>(null);
    const [modalChangeCourse, setModalChangeCourse] = useState<boolean>(false);

    async function handleRetrieveCourse(course_id: number) {
        setLoading(true);
        try {
            await CourseStore.view(course_id).then(() => {
                if (CourseStore.course !== null && CourseStore.course.teacher) {
                    setTeachers(CourseStore.course.teacher);
                }
            });
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function handleListRegistrations(course_id: number) {
        setLoading(true);
        try {
            const response = await CourseStore.registrations_by_course(course_id);
            if (response) {
                const members = CourseStore.registrations.map(r => {
                    return {
                        member_name:
                            r.member.last_name !== null ? r.member.last_name + ' ' + r.member.name : r.member.name,
                        ...r,
                    };
                });
                members.sort((a, b) => a.member_name.localeCompare(b.member_name));
                setRegistrations(members);
            }
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    useEffect(() => {
        if (course_id) {
            handleListRegistrations(Number(course_id));
            handleRetrieveCourse(Number(course_id));
        }
    }, [course_id]);

    async function handlePatchCourse(payload: any, course_id: number) {
        setLoading(true);
        try {
            await CourseStore.patch(payload, course_id).then(async () => {
                toastSuccess('Catequistas del grupo actualizados');
                setTeachers([]);
                setTeachersIds([]);
                setViewModalTeachers(false);
                await handleRetrieveCourse(course_id);
            });
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function handlePatchRegistration(payload: any, registration_id: number) {
        setLoading(true);
        try {
            const response = await RegistrationStore.patch(payload, registration_id);
            if (response && course_id) await handleListRegistrations(Number(course_id));
            toastWarnig('Se cambio el estado de la matrícula');
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    const onSearch = async (value: string) => {
        await RegistrationStore.list({ full_name: value, course: course_id }).then(() => {
            setRegistrations(RegistrationStore.registrations);
        });
    };

    const handleSearch = _.debounce(async (value: string) => {
        setLoading(false);
        if (value) {
            await onSearch(value);
            setLoading(false);
        } else {
            if (course_id) {
                await handleListRegistrations(Number(course_id));
                setLoading(false);
            }
        }
    }, 500);

    const get_file_registration = (registration_id: number) => {
        setLoading(true);
        requests
            .get2(`/staff/catechism/registration/${registration_id}/certificate-status/`, {
                responseType: 'blob',
            })
            .then((response: any) => {
                if (isMobile) {
                    setDrawerCertificate(false);
                } else {
                    const file = new Blob([response], { type: 'application/pdf' });
                    const fileURL2 = URL.createObjectURL(file);
                    setLoading(false);
                    setFileRegistrationCertificateURL(fileURL2);
                }
            })
            .catch((err: any) => {
                notification.error({
                    message: <ErrorRender error={err} />,
                    placement: 'topRight',
                });
                setDrawerCertificate(false);
                setLoading(false);
            });
    };

    const get_file_list_by_course = (course_id: number) => {
        setLoading(true);
        requests
            .get2(`/staff/catechism/courses/${course_id}/list-by-course/`, {
                responseType: 'blob',
            })
            .then((response: any) => {
                if (isMobile) {
                    setDrawerListByCourse(false);
                } else {
                    const file = new Blob([response], { type: 'application/pdf' });
                    const fileURL2 = URL.createObjectURL(file);
                    setLoading(false);
                    setFileListByCourseURL(fileURL2);
                }
            })
            .catch((err: any) => {
                notification.error({
                    message: <ErrorRender error={err} />,
                    placement: 'topRight',
                });
                setDrawerListByCourse(false);
                setLoading(false);
            });
    };

    const download_list_by_course = async (course_id: number) => {
        setSpinLoading(true);
        const url = '/staff/catechism/courses/' + course_id + '/list-by-course/';
        await requests
            .get2(url, { responseType: 'blob' })
            .then((response: any) => {
                DownloadFile(response, CourseStore.course?.name + '.pdf');
            })
            .finally(() => setSpinLoading(false));
    };

    const send_list_by_course_to_teacher = async (course_id: number) => {
        setSpinLoading(true);
        let teacher_without_email = 0;
        if (teachers && teachers.length > 0) {
            const url = '/staff/catechism/courses/' + course_id + '/send-list-by-course-to-teacher/';

            teachers.map((teacher: CollaboratorProps) => {
                if (teacher.email !== null || teacher.email !== '') {
                    teacher_without_email = teacher_without_email + 1;
                }
            });

            if (teacher_without_email > 0) {
                toastInfo('Uno o más catequistas no tienen registrado el email');
            }

            try {
                await requests
                    .get2(url)
                    .then(() => {
                        toastSuccess('Se envio el email');
                    })
                    .finally(() => setSpinLoading(false));
            } catch (err: any) {
                notification.error({
                    message: <ErrorRender error={err} />,
                    placement: 'topRight',
                });
            }
        } else {
            toastInfo('El grupo no tiene asignado catequistas');
        }
        setSpinLoading(false);
    };

    const handleClickOutside = (event: Event) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setIsMenuVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    const handleExportExcel = async () => {
        const group = CourseStore.course?.name;
        const community = typeof CourseStore.course?.community === 'object' && CourseStore.course?.community?.name;
        const level = typeof CourseStore.course?.level === 'object' && CourseStore.course?.level?.alias;
        const fileName = community + ' - ' + level + ' - ' + group + ' - ' + 'Lista de alumnos';

        const excel_data = registrations.map((registration: RegistrationProps, index: number) => {
            return {
                '#': index + 1,
                Estado: registration.status,
                Cedula: typeof registration.member === 'object' && registration.member.card_id,
                Nombres: registration.member_name !== undefined && capitalizeFirstLetter(registration.member_name),
                'Fecha de nacimiento': typeof registration.member === 'object' && registration.member.birth_date,
                Edad: typeof registration.member === 'object' && registration.member.age,
                Direccion: typeof registration.member === 'object' && registration.member.address,
                Celular:
                    typeof registration.member === 'object' && registration.member.cellphone !== null
                        ? registration.member.cellphone
                        : '',
                Telefono:
                    typeof registration.member === 'object' && registration.member.phone !== null
                        ? registration.member.phone
                        : '',
                Papa:
                    typeof registration.member === 'object' &&
                    registration.member.father &&
                    capitalizeFirstLetter(registration.member.father),
                Mama:
                    typeof registration.member === 'object' &&
                    registration.member.mother &&
                    capitalizeFirstLetter(registration.member.mother),
            };
        });
        ExportExcel(excel_data, fileName);
    };

    return (
        <>
            <div className="flex flex-col justify-center">
                <div className="p-4">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate('/dashboards/catechism/course', { replace: false })}
                        title={
                            <>
                                <span className={'font-bold text-md'}>{CourseStore.course?.name}:</span>
                            </>
                        }
                        subTitle={
                            <div className={'flex flex-row'}>
                                <span className="font-bold text-lg">Catequista/s: </span>
                                {teachers.length > 0 && (
                                    <div className={'flex flex-row'}>
                                        {teachers.map((teacher: CollaboratorProps) => {
                                            if (teacher !== undefined) {
                                                return (
                                                    <div key={teacher.id} className={'ml-4'}>
                                                        <div className={'flex flex-col'}>
                                                            <div>{capitalizeFirstLetter(teacher.name)}</div>
                                                            <div style={{ fontSize: '12px' }}>
                                                                {teacher.email !== null &&
                                                                    teacher.email !== '' &&
                                                                    teacher.email.toLowerCase()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>
                                )}
                            </div>
                        }
                        breadcrumb={
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    {typeof CourseStore.course?.community === 'object' &&
                                        typeof CourseStore.course?.community.zone === 'object' &&
                                        CourseStore.course?.community?.zone?.name}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {typeof CourseStore.course?.community === 'object' &&
                                        CourseStore.course?.community?.name}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {typeof CourseStore.course?.level === 'object' && CourseStore.course?.level?.alias}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>{CourseStore.course?.name}</Breadcrumb.Item>
                            </Breadcrumb>
                        }
                        extra={
                            <div className="flex items-center">
                                <Tag style={{ marginLeft: '5px', fontSize: '14px' }} color={'green'}>
                                    Total: {CourseStore.course?.total_registrations}
                                </Tag>
                                <Tag style={{ marginLeft: '5px', fontSize: '14px' }} color={'blue'}>
                                    Níños: {CourseStore.course?.man_registrations}
                                </Tag>
                                <Tag style={{ marginLeft: '5px', fontSize: '14px' }} color={'pink'}>
                                    Níñas: {CourseStore.course?.woman_registrations}
                                </Tag>

                                <div className={'ml-3'}>
                                    <SwitchLogos />
                                </div>

                                <div ref={ref}>
                                    <div
                                        onClick={() => setIsMenuVisible(prev => !prev)}
                                        className="dropdownButton px-1 py-2 bg-white text-sm leading-none flex items-center justify-between cursor-pointer"
                                    >
                                        <span className={'px-1'}>
                                            <div className={'flex flex-row ml-4'}>
                                                <span>Opciones</span>
                                                <RiMore2Fill />
                                            </div>
                                        </span>
                                    </div>

                                    {isMenuVisible && (
                                        <div className="absolute right-2 z-50 mt-2 pt-2 pb-2 mb-2 w-60 bg-white shadow rounded">
                                            <Spin spinning={spinLoading}>
                                                <div className={'mb-4'}>
                                                    <Typography.Link
                                                        onClick={() => {
                                                            setTeachers([]);
                                                            setViewModalTeachers(true);
                                                        }}
                                                    >
                                                        <div className={'flex flex-row ml-4'}>
                                                            <HiOutlineUsers className={'mt-1 mr-1'} />
                                                            <span>
                                                                {teachers.length > 0 ? 'Actualizar' : 'Agregar'}{' '}
                                                                Catequista/s
                                                            </span>
                                                        </div>
                                                    </Typography.Link>
                                                </div>

                                                <div className={'mb-4'}>
                                                    <Typography.Link
                                                        onClick={async () => {
                                                            if (course_id) {
                                                                get_file_list_by_course(Number(course_id));
                                                                setDrawerListByCourse(true);
                                                            }
                                                        }}
                                                    >
                                                        <div className={'flex flex-row ml-4'}>
                                                            <BsFillFilePdfFill className={'mt-1 mr-1'} />
                                                            <span>Ver Lista</span>
                                                        </div>
                                                    </Typography.Link>
                                                </div>

                                                <div className={'mb-4'}>
                                                    <Typography.Link
                                                        onClick={async () => {
                                                            if (course_id) {
                                                                await send_list_by_course_to_teacher(Number(course_id));
                                                            }
                                                        }}
                                                    >
                                                        <div className={'flex flex-row ml-4'}>
                                                            <RiMailSendFill className={'mt-1 mr-1'} />
                                                            <span>Enviar Lista por email</span>
                                                        </div>
                                                    </Typography.Link>
                                                </div>

                                                <div className={'mb-4'}>
                                                    <Typography.Link
                                                        onClick={async () => {
                                                            if (course_id) {
                                                                await download_list_by_course(Number(course_id));
                                                            }
                                                        }}
                                                    >
                                                        <div className={'flex flex-row ml-4'}>
                                                            <MdPictureAsPdf className={'mt-1 mr-1'} />
                                                            <span>Descargar en PDF</span>
                                                        </div>
                                                    </Typography.Link>
                                                </div>

                                                <div>
                                                    <Typography.Link
                                                        onClick={async () => {
                                                            if (course_id) {
                                                                await handleExportExcel();
                                                            }
                                                        }}
                                                    >
                                                        <div className={'flex flex-row ml-4'}>
                                                            <RiFileExcel2Fill className={'mt-1 mr-1'} />
                                                            <span>Export a Excel</span>
                                                        </div>
                                                    </Typography.Link>
                                                </div>
                                            </Spin>
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                    />

                    <Card
                        bordered={false}
                        size="small"
                        title={
                            <div className={'flex row-flex'}>
                                <div>
                                    <span className={'font-bold'}>Integrantes&nbsp;</span> del grupo.
                                </div>
                                <Search
                                    className={'px-4'}
                                    style={{ width: '40%' }}
                                    key={'input-search-registrations'}
                                    allowClear={true}
                                    placeholder={'Nombres o apellidos'}
                                    onSearch={onSearch}
                                    onChange={(data: any) => handleSearch(data.target.value)}
                                />
                            </div>
                        }
                        extra={
                            <div className={'flex flex-row space-x-2'}>
                                <span style={{ marginTop: '5px' }}>
                                    <b>Período activo: </b>
                                    {props.period.year}
                                </span>
                            </div>
                        }
                    >
                        <MembersCourseTable
                            handlePatch={handlePatchRegistration}
                            registrations={registrations}
                            loading={loading}
                            setDrawerCertificate={(value: boolean, registration_id: number) => {
                                get_file_registration(registration_id);
                                setDrawerCertificate(value);
                            }}
                            setModalChangeCourse={(value: boolean, registration_id: number) => {
                                setModalChangeCourse(value);
                                setRegistrationId(registration_id);
                            }}
                        />
                    </Card>
                </div>
            </div>

            {drawerCertificate && (
                <Drawer
                    width="80%"
                    title="Certificado de Catequesis"
                    placement="left"
                    onClose={() => setDrawerCertificate(false)}
                    visible={drawerCertificate}
                >
                    <div className={'py-10'}>
                        <Spin spinning={loading} tip={'loading...'}>
                            {fileRegistrationCertificateURL && (
                                <iframe
                                    style={{ marginTop: '-50px' }}
                                    title="Cerificado de Catequesi"
                                    src={fileRegistrationCertificateURL}
                                    frameBorder="0"
                                    height={window.innerHeight}
                                    width="100%"
                                />
                            )}
                        </Spin>
                    </div>
                </Drawer>
            )}

            {drawerListByCourse && (
                <Drawer
                    width="80%"
                    title="Lista de grupo"
                    placement="left"
                    onClose={() => setDrawerListByCourse(false)}
                    visible={drawerListByCourse}
                >
                    <div className={'py-10'}>
                        <Spin spinning={loading} tip={'Loading...'}>
                            {fileListByCourseURL && (
                                <iframe
                                    style={{ marginTop: '-50px' }}
                                    title="Lista de grupo"
                                    src={fileListByCourseURL}
                                    frameBorder="0"
                                    height={window.innerHeight}
                                    width="100%"
                                />
                            )}
                        </Spin>
                    </div>
                </Drawer>
            )}

            {viewModalTeachers && (
                <Modal
                    title={<span className={'font-bold'}>Agregar catequistas al grupo</span>}
                    closable={true}
                    visible={viewModalTeachers}
                    onCancel={() => setViewModalTeachers(false)}
                    cancelText={'Cancelar'}
                    okText={'Guardar'}
                    width={850}
                    onOk={async () => {
                        if (course_id) {
                            setTeachers([]);
                            await handlePatchCourse({ teacher: teachers_ids }, Number(course_id));
                        }
                    }}
                    bodyStyle={{ minHeight: '250px' }}
                >
                    <span className={'py-4'}>Puede agregar uno o varios catequistas al grupo:</span>
                    <ContributorSearchSelect
                        mode={'multiple'}
                        onSelect={async (teacher: { value: number }) => {
                            setTeachersIds([...teachers_ids, teacher.value]);
                        }}
                        onDeselect={async (teacher: { value: number }) => {
                            await setTeachersIds(teachers_ids.filter(t => t !== teacher.value));
                        }}
                    />
                </Modal>
            )}

            {modalChangeCourse && (
                <Modal
                    title={<span className={'font-bold'}>Cambiar de grupo</span>}
                    closable={true}
                    visible={modalChangeCourse}
                    onCancel={() => setModalChangeCourse(false)}
                    cancelText={'Cancelar'}
                    width={850}
                    bodyStyle={{ minHeight: '250px' }}
                    footer={false}
                >
                    <span className={'py-4'}>Seleccione el nivel y la comumidad para ver los grupos disponibles:</span>
                    <CoursesFilterByLevelCommunity />
                    <CoursesTableChange
                        loading={CourseStore.is_loading}
                        setNewCourse={async (new_course_id: number) => {
                            if (new_course_id && registration_id) {
                                await CourseStore.change_course(registration_id, { course: new_course_id })
                                    .then(() => {
                                        setModalChangeCourse(false);
                                        setRegistrationId(null);
                                        toastSuccess('Se ha cambiado de grupo correctamente');
                                    })
                                    .catch(err => {
                                        notification.error({
                                            message: <ErrorRender error={err} />,
                                            placement: 'topRight',
                                        });
                                    })
                                    .finally(async () => {
                                        await handleListRegistrations(Number(course_id));
                                        await handleRetrieveCourse(Number(course_id));
                                    });
                            }
                        }}
                    />
                </Modal>
            )}
        </>
    );
});

export default AdminCourseSubpage;
