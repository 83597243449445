import React from 'react';
import { ArticleProps } from '../../modules/inventory/types';
import Viewer from 'react-viewer';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';

interface Props {
    article: ArticleProps;
    modalImages: boolean;
    setModalImages: (value: boolean) => void;
}

export const ViewerImages: React.FC<Props> = ({ article, modalImages, setModalImages }: Props) => {
    const images: ImageDecorator[] | { src: string; alt: string }[] | undefined = [];

    if (article.images) {
        article.images.map(image => {
            if (typeof image === 'object') {
                images.push({ src: image.file, alt: image.filename });
            }
        });
    }

    return (
        <>
            <Viewer
                visible={modalImages}
                onClose={() => {
                    setModalImages(false);
                }}
                images={images}
            />
        </>
    );
};
