import React from 'react';
import { buildTourItemSelector } from './util';
import TutoStep from '../../library/outlets/TutoStep';

const DASHBOARD_ITEMS_IDS = Object.freeze({
    booksMenu: 'd_booksMenu',
    catechismMenu: 'd_catechismMenu',
    booksSubMenu: 'd_booksSubMenu',
    settingsSignatureFile: 'd_SettingsSignatureFile',
});

const DASHBOARD_TOUR_CONFIG = [
    {
        selector: buildTourItemSelector(DASHBOARD_ITEMS_IDS.booksMenu),
        content: () => (
            <TutoStep
                title="Las opciones del menú de libros parroquiales son:"
                items={[
                    {
                        title: 'Bautizos:',
                        description: 'Añadir registros del libro de bautizos',
                    },
                    {
                        title: 'Proclamas:',
                        description: 'Añadir proclamas o avisos de matrimonios',
                    },
                    {
                        title: 'Matrimonios:',
                        description: 'Añadir registros del libro de matrimonios',
                    },
                    {
                        title: 'Defunciones:',
                        description: 'Añadir registros del libro de defunciones',
                    },
                ]}
            />
        ),
        stepInteraction: false,
    },
    {
        selector: buildTourItemSelector(DASHBOARD_ITEMS_IDS.catechismMenu),
        content: () => (
            <TutoStep
                title="Las opciones del menú de catequesis son:"
                items={[
                    {
                        title: 'Períodos:',
                        description: 'Años lectivos',
                    },
                    {
                        title: 'Catequistas:',
                        description: 'Se asignan a los grupos',
                    },
                    {
                        title: 'Niveles:',
                        description: 'Iniciación Reconciliación, etc.',
                    },
                    {
                        title: 'Zonas:',
                        description: 'Agrugan las comunidades',
                    },
                    {
                        title: 'Comunidades:',
                        description: 'Lugares donde se dicta catequesis',
                    },
                    {
                        title: 'Grupos:',
                        description: 'Cursos o paralelos por cada nivel',
                    },
                    {
                        title: 'Integrantes:',
                        description: 'Niños, niñas o personas que cursan la catequesis',
                    },
                    {
                        title: 'Matrículas:',
                        description: 'Integrantes matrículados por periodo',
                    },
                ]}
            />
        ),
    },
    {
        selector: buildTourItemSelector(DASHBOARD_ITEMS_IDS.booksMenu),
        content: () => (
            <TutoStep
                title="Registrar un bautizo"
                description="Click en libros parroquiales y seleccione Bautizos para ver los siguente pasos."
                action="Para continur primero cierre esta ventana"
            />
        ),
        stepInteraction: false,
    },
];

// const SIGNATURE_TOUR_CONFIG = [
//     {
//         selector: buildTourItemSelector(DASHBOARD_ITEMS_IDS.settingsUser),
//         content: () => (
//             <TutoStep
//                 title="Abrir el menú Sistema"
//                 items={[
//                     {
//                         description: 'Click en Firma Digital',
//                     },
//                 ]}
//             />
//         ),
//         stepInteraction: true,
//     },
// ];

export { DASHBOARD_TOUR_CONFIG, DASHBOARD_ITEMS_IDS };
