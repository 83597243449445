import React from 'react';
import { Dropdown, Menu } from 'antd';
import type { MenuProps } from 'antd';
import { RiArrowDownSLine, RiLogoutCircleRLine, RiSettings3Line, RiUserFill, RiRefreshLine } from 'react-icons/ri';
import { UserProps } from '../../utils/types';

interface Props {
    companyName: UserProps | null;
    showSettings: (value: boolean) => void;
    logout: () => void;
}

export const UserMenu = ({ companyName, showSettings, logout }: Props) => {
    const onClick: MenuProps['onClick'] = ({ key }) => {
        if (key === '3') logout();
        if (key === '2') showSettings(true);
        if (key === '4') location.reload();
    };

    const menu = (
        <Menu
            onClick={onClick}
            items={[
                {
                    key: '1',
                    label: (
                        <div className="flex flex-col my-1">
                            <span className="text-base font-semibold mb-1">{companyName?.fullname}</span>
                            <span className="font-thin">{companyName?.email}</span>
                        </div>
                    ),
                },
                {
                    type: 'divider',
                },
                {
                    key: '2',
                    label: 'Ajustes',
                    icon: <RiSettings3Line size={'18px'} />,
                },
                {
                    type: 'divider',
                },
                {
                    key: '4',
                    label: 'Refrescar Sistema',
                    icon: <RiRefreshLine size={'18px'} />,
                },
                {
                    type: 'divider',
                },
                {
                    key: '3',
                    label: 'Cerrar sesión',
                    icon: <RiLogoutCircleRLine size={'18px'} />,
                },
            ]}
        />
    );
    return (
        <Dropdown overlay={menu}>
            <a onClick={e => e.preventDefault()}>
                <div className="flex flex-row items-center">
                    <RiUserFill className="text-slate-700 mr-1" size="20px" />
                    <span className="font-normal text-base">{companyName?.fullname}</span>
                    <RiArrowDownSLine className="ml-1" size={'20px'} />
                </div>
            </a>
        </Dropdown>
    );
};
