import { action, makeObservable, observable } from 'mobx';

const breakpoints = {
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
    xxxl: 3200,
};

class UIStore {
    selectedBreakPoint = 992;
    selectedKey = 'lg';
    windowDimensions: {
        width: number;
        height: number;
    } = {
        width: 0,
        height: 0,
    };
    drawerVisible = false;
    responsive = false;

    constructor() {
        makeObservable(this, {
            responsive: observable,
            selectedBreakPoint: observable,
            selectedKey: observable,
            windowDimensions: observable,
            drawerVisible: observable,
            showDrawer: action.bound,
            hideDrawer: action.bound,
        });
    }

    selectBreakPoint(key: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl') {
        this.selectedBreakPoint = breakpoints[key];
    }

    setSelectedKey(key: string) {
        this.selectedKey = key;
    }

    updateWindowDimensions(width: number, height: number) {
        this.windowDimensions.width = width;
        this.windowDimensions.height = height;
        this.responsive = this.windowDimensions?.width <= this.selectedBreakPoint;
    }

    getContainerHeight() {
        const header = 76;
        const footer = this.responsive ? 38 : 32;
        const menu = this.responsive ? 64 : 0;
        return this.windowDimensions.height - header - footer - menu;
    }

    showDrawer() {
        this.drawerVisible = true;
    }

    hideDrawer() {
        this.drawerVisible = false;
    }
}

export default new UIStore();
