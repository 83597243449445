import React, { useEffect, useState } from 'react';
import { MenuTop as CatechismMenuTop } from '../MenuTop';
import { Button, Card, Modal, notification } from 'antd';
import { SiAddthis } from 'react-icons/si';
import { observer } from 'mobx-react';
import { LevelProps } from '../types';
import LevelStore from '../stores/LevelStore';
import ErrorRender from '../../../library/outlets/ErrorRender';
import PeriodStore from '../stores/PeriodStore';
import { LevelsTable } from '../tables';
import { LevelForm } from '../forms';
import { CreateForms } from '../CreateForms';
import { toastSuccess } from '../../../utils/toast';

export const CatechismLevelPage = observer(() => {
    const [isLoading, setIsLoading] = useState(false);
    const [isModalCreate, setIsModalCreate] = useState(false);
    const [isModalUpdate, setIsModalUpdate] = useState(false);
    const [record, setRecord] = useState<LevelProps | null>(null);

    async function handleList() {
        setIsLoading(true);
        try {
            await LevelStore.list();
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    async function handleCreateLevel(values: LevelProps) {
        const payload: LevelProps = {
            position: values.position,
            name: values.name,
            alias: values.alias,
            status: values.status,
        };
        setIsLoading(true);
        try {
            await LevelStore.create(payload);
            toastSuccess('Nivel registrado');
            await handleList();
            setIsModalCreate(false);
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    useEffect(() => {
        handleList();
        getPeriodActive();
    }, []);

    async function handleUpdate(payload: LevelProps, id: number) {
        setIsLoading(true);
        try {
            await LevelStore.patch(payload, id);
            await handleList();
            toastSuccess('Nivel actualizado');
            setIsModalUpdate(false);
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    async function handleRemove(id: number) {
        setIsLoading(true);
        try {
            await LevelStore.remove(id);
            toastSuccess('Nivel Eliminado');
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    async function getPeriodActive() {
        try {
            await PeriodStore.get_active();
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
    }

    return (
        <>
            <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
                <CatechismMenuTop active={'level'} />
                <div className={'my-8'}>
                    <Card
                        bordered={false}
                        size="small"
                        title={
                            <>
                                <span className="font-bold">Niveles</span> de catequesis
                            </>
                        }
                        extra={
                            <div className={'flex flex-row'}>
                                <div className={'mr-5 mt-1.5'}>
                                    {PeriodStore.period_active !== null && (
                                        <span>
                                            <b>Período activo: </b>
                                            {PeriodStore.period_active.year}
                                        </span>
                                    )}
                                </div>
                                <Button type={'link'} onClick={() => setIsModalCreate(true)}>
                                    <div className={'flex flex-row'}>
                                        <SiAddthis className={'mt-1 mr-1'} />
                                        <span>Nuevo nivel</span>
                                    </div>
                                </Button>
                            </div>
                        }
                    >
                        <LevelsTable
                            loading={isLoading}
                            handleRemove={handleRemove}
                            records={LevelStore.levels}
                            setRecord={setRecord}
                            setIsModalVisible={setIsModalUpdate}
                        />
                    </Card>

                    <Modal
                        title="Actualizar Catequista"
                        visible={isModalUpdate}
                        footer={false}
                        onCancel={() => setIsModalUpdate(false)}
                    >
                        <LevelForm
                            setIsModalVisible={setIsModalUpdate}
                            record={record}
                            handleUpdate={(payload: LevelProps, id: number) => handleUpdate(payload, id)}
                        />
                    </Modal>
                </div>

                {isModalCreate && (
                    <Modal
                        title={<b>{'Nuevo Nivel'}</b>}
                        visible={isModalCreate}
                        footer={false}
                        onCancel={() => setIsModalCreate(false)}
                    >
                        <CreateForms
                            isModalVisible={isModalCreate}
                            setIsModalVisible={setIsModalCreate}
                            modal={{ title: 'Nuevo Nivel', type: 'FormLevel' }}
                            handleCreate={handleCreateLevel}
                        />
                    </Modal>
                )}
            </div>
        </>
    );
});
