import React, { useEffect, useState } from 'react';
import { capitalizeFirstLetter, PageHeaderReports, Price } from '../../../library';
import { notification, Spin } from 'antd';
import { ReportBrothersFilter, ReportRegistrationFilter } from './filters';
import { useLocation } from 'react-router-dom';
import ErrorRender from '../../../library/outlets/ErrorRender';
import ReportStore from '../../../stores/ReportStore';
import { observer } from 'mobx-react';
import moment from 'moment/moment';
import { RegistrationProps } from '../../catechism/types';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const CatechismBrothersReportPage = observer(() => {
    const searchParams = useQuery();
    const [loading, setLoading] = useState<boolean>(false);

    const getReports = async (params = {}) => {
        setLoading(true);
        await ReportStore.getBrothers(params)
            .catch((err: any) => {
                notification.error({
                    message: <ErrorRender error={err} />,
                    placement: 'topRight',
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (location.search !== '') {
            getReports(searchParams);
        }
    }, [location.search]);

    return (
        <>
            <div className="relative bg-white p-4 sm:p-6 rounded-xl overflow-hidden mb-8">
                <Spin spinning={loading}>
                    <PageHeaderReports title={'Catequeis: Hermanos'} subtitle={'Informe de catequesis por hermanos'} />
                    <ReportBrothersFilter path={'/reports-catechism/brothers'} />
                </Spin>
            </div>
            <div className="relative bg-white rounded-xl overflow-hidden mb-8">
                <Spin spinning={loading}>
                    <section className="text-gray-600 body-font flex justify-center items-center">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="py-3 px-6">
                                        Cédula
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        Nombre Completo
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        Fecha de Nacimiento
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        Catequesis
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        Matrícula
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {ReportStore.brothers.length > 0 && (
                                    <>
                                        {ReportStore.brothers.map((record: RegistrationProps, index: number) => {
                                            return (
                                                <tr
                                                    key={index}
                                                    className={
                                                        index % 2 == 0
                                                            ? 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'
                                                            : 'bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700'
                                                    }
                                                >
                                                    <td className={'py-4 px-6'}></td>
                                                    <td className={'py-4 px-6'}>
                                                        <div className={'font-bold'}>
                                                            {typeof record.member === 'object' &&
                                                                record.member.last_name && (
                                                                    <span>
                                                                        {capitalizeFirstLetter(record.member.last_name)}
                                                                        &nbsp;
                                                                    </span>
                                                                )}
                                                            {typeof record.member === 'object' && record.member.name && (
                                                                <span>
                                                                    {capitalizeFirstLetter(record.member.name)}
                                                                    &nbsp;
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div className={'text-xs'}>
                                                            Padres:{' '}
                                                            {typeof record.member === 'object' && record.member.father && (
                                                                <span>
                                                                    {capitalizeFirstLetter(record.member.father)}
                                                                    &nbsp;
                                                                </span>
                                                            )}
                                                            {' / '}
                                                            {typeof record.member === 'object' && record.member.mother && (
                                                                <span>
                                                                    {capitalizeFirstLetter(record.member.mother)}
                                                                    &nbsp;
                                                                </span>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className={'py-4 px-6'}>
                                                        {typeof record.member === 'object' &&
                                                            record.member.birth_date &&
                                                            record.member.age !== null && (
                                                                <div>
                                                                    <div className={'text-xs'}>
                                                                        {moment(record.member.birth_date).format(
                                                                            'YYYY-MM-DD'
                                                                        )}
                                                                    </div>
                                                                    <div className={'text-xs'}>{record.member.age}</div>
                                                                </div>
                                                            )}
                                                    </td>
                                                    <td className={'py-4 px-6'}>
                                                        <div className={'font-bold'}>
                                                            {typeof record.course === 'object' && (
                                                                <span>
                                                                    {capitalizeFirstLetter(record.course.name)}
                                                                    &nbsp;
                                                                </span>
                                                            )}
                                                            {' / '}
                                                            {typeof record.course === 'object' &&
                                                                typeof record.course.level === 'object' && (
                                                                    <span>
                                                                        {capitalizeFirstLetter(
                                                                            record.course.level.alias
                                                                        )}
                                                                        &nbsp;
                                                                    </span>
                                                                )}
                                                        </div>
                                                        <div className={'text-xs'}>
                                                            {typeof record.course === 'object' &&
                                                                typeof record.course.period === 'object' && (
                                                                    <span>
                                                                        {capitalizeFirstLetter(
                                                                            record.course.period.period
                                                                        )}
                                                                        &nbsp;
                                                                    </span>
                                                                )}
                                                            <br />
                                                            {typeof record.course === 'object' &&
                                                                typeof record.course.community === 'object' && (
                                                                    <span>
                                                                        {capitalizeFirstLetter(
                                                                            record.course.community.name
                                                                        )}
                                                                        &nbsp;
                                                                    </span>
                                                                )}
                                                        </div>
                                                    </td>
                                                    <td className={'py-4 px-6'}>
                                                        <div className={'font-bold'}>
                                                            $ <Price value={record.price} />
                                                        </div>
                                                        <div className={'text-xs'}>
                                                            <div>
                                                                <span>{capitalizeFirstLetter(record.status)}</span>
                                                            </div>
                                                            <div>
                                                                <span>{record.discount_reason}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </section>
                </Spin>
            </div>
        </>
    );
});
