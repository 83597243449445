import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { HiChevronDoubleLeft, HiChevronDoubleRight, HiChevronDown } from 'react-icons/hi';
import SidebarStore from '../../stores/SidebarStore';
import SidebarLinkGroup from './SidebarLinkGroup';
import large_logo from '../../images/sgp-alpes.png';
import logo from '../../images/logo.png';
import { DASHBOARD_ITEMS_IDS } from '../../utils/tutorials';
import { Tag } from 'antd';

export const Sidebar = observer(() => {
    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
    const [sidebarExpanded, setSidebarExpanded] = useState(
        storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
    );

    const location = useLocation();
    const { pathname } = location;

    const sidebar = useRef<HTMLDivElement>(null);
    const trigger = useRef<HTMLButtonElement>(null);

    // close sidebar on click outside
    useEffect(() => {
        const clickHandler = ({ target }: any) => {
            if (!sidebar.current || !trigger.current) return;
            if (
                SidebarStore.button.current.contains(target) ||
                sidebar.current.contains(target) ||
                trigger.current.contains(target)
            )
                return;
            SidebarStore.handleVisibleSidebar(false);
        };
        document.addEventListener('click', clickHandler, true);
        return () => document.removeEventListener('click', clickHandler, true);
    });

    // close sidebar if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }: any) => {
            if (!SidebarStore.isVisible || keyCode !== 27) return;
            SidebarStore.handleVisibleSidebar(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    useEffect(() => {
        localStorage.setItem('sidebar-expanded', JSON.stringify(sidebarExpanded));
        if (sidebarExpanded) {
            document.querySelector('body')?.classList.add('sidebar-expanded');
        } else {
            document.querySelector('body')?.classList.remove('sidebar-expanded');
        }
    }, [sidebarExpanded]);

    return (
        <div>
            {/* Sidebar backdrop (mobile only) */}
            <div
                className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
                    SidebarStore.isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'
                }`}
                aria-hidden="true"
            ></div>

            {/* Sidebar */}
            <div
                id="sidebar"
                ref={sidebar}
                className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out ${
                    SidebarStore.isVisible ? 'translate-x-0' : '-translate-x-64'
                }`}
            >
                {/* Sidebar header */}
                <div className="flex justify-between mb-10 pr-3 sm:px-2">
                    <button
                        ref={trigger}
                        className="lg:hidden text-slate-500 hover:text-slate-400"
                        onClick={() => SidebarStore.handleVisibleSidebar(!SidebarStore.isVisible)}
                        aria-controls="sidebar"
                        aria-expanded={SidebarStore.isVisible}
                    >
                        <HiChevronDoubleLeft size={'22px'} />
                    </button>

                    <div className="block w-full">
                        <div className="flex justify-center items-center font-semibold text-base text-slate-500">
                            <div className="flex items-center hover:text-slate-400">
                                <img
                                    className="lg:hidden lg:sidebar-expanded:block 2xl:block"
                                    style={{ height: 40 }}
                                    src={large_logo}
                                />
                                <img
                                    className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
                                    aria-hidden="true"
                                    style={{ height: 40, width: 40 }}
                                    src={logo}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Links */}
                <div className="space-y-8">
                    <div>
                        <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
                            <span
                                className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
                                aria-hidden="true"
                            >
                                •••
                            </span>
                            <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">Páginas</span>
                        </h3>
                        <ul className="mt-3">
                            {/* Dashboard */}
                            <li
                                className={`px-3 py-2 rounded-sm mb-0.5 lg:mb-1 last:mb-0 ${
                                    pathname === '/dashboard' && 'bg-slate-900'
                                }`}
                            >
                                <NavLink
                                    end
                                    to="/dashboard"
                                    className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                        pathname === '/dashboard' && 'hover:text-slate-200'
                                    }`}
                                >
                                    <div className="flex items-center">
                                        <svg className="shrink-0 h-6 w-6" viewBox="0 0 64 64">
                                            <circle
                                                className={`fill-current text-slate-600 ${
                                                    pathname === '/dashboard' && 'text-lime-600'
                                                }`}
                                                cx="32"
                                                cy="32"
                                                r="24"
                                            />
                                            <path
                                                className={`fill-current text-slate-500 ${
                                                    pathname === '/dashboard' && 'text-lime-500'
                                                }`}
                                                d="M32,32V8A24,24,0,0,1,49,48.941Z"
                                            />
                                            <path
                                                className={`fill-current text-slate-400 ${
                                                    pathname === '/dashboard' && 'text-lime-200'
                                                }`}
                                                d="M32,32V3A29,29,0,0,1,61,32Z"
                                            />
                                        </svg>
                                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Dashboard
                                        </span>
                                    </div>
                                </NavLink>
                            </li>

                            {/* Reports */}
                            <SidebarLinkGroup activecondition={pathname.includes('/reports')}>
                                {(handleClick: any, open: any) => {
                                    return (
                                        <>
                                            <a
                                                href="src/library/sidebar/Sidebar#0"
                                                className={`mb-1 block text-slate-200 hover:text-white truncate transition duration-150 ${
                                                    pathname.includes('/reports') && 'hover:text-slate-200'
                                                }`}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                                                }}
                                            >
                                                <div className="flex items-center justify-between">
                                                    <div className="flex items-center">
                                                        <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                                            <path
                                                                className={`fill-current text-slate-400 ${
                                                                    pathname.includes('/reports') && 'text-lime-200'
                                                                }`}
                                                                d="M0 20h24v2H0z"
                                                            ></path>
                                                            <path
                                                                className={`fill-current text-slate-600 ${
                                                                    pathname.includes('/reports') && 'text-lime-600'
                                                                }`}
                                                                d="M4 18h2a1 1 0 001-1V8a1 1 0 00-1-1H4a1 1 0 00-1 1v9a1 1 0 001 1zM11 18h2a1 1 0 001-1V3a1 1 0 00-1-1h-2a1 1 0 00-1 1v14a1 1 0 001 1zM17 12v5a1 1 0 001 1h2a1 1 0 001-1v-5a1 1 0 00-1-1h-2a1 1 0 00-1 1z"
                                                            ></path>
                                                        </svg>
                                                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                            Reportes
                                                        </span>
                                                    </div>
                                                    {/* Icon */}
                                                    <div className="flex shrink-0 ml-2">
                                                        <HiChevronDown
                                                            className={`text-slate-400 ${
                                                                open && 'transform rotate-180'
                                                            }`}
                                                            size={'22px'}
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                            <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                                                <ul className={`pl-9 my-2 ${!open && 'hidden'}`}>
                                                    <div className="mb-2">Catequesis</div>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/reports-catechism/registrations"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname === '/reports-catechism/registrations' &&
                                                                'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname === '/reports-catechism/registrations' &&
                                                                    'text-white'
                                                                }`}
                                                            >
                                                                Matrículas
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/reports-catechism/brothers"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname === '/reports-catechism/brothers' &&
                                                                'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname === '/reports-catechism/brothers' &&
                                                                    'text-white'
                                                                }`}
                                                            >
                                                                Hermanos
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                                                <ul className={`pl-9 my-2 ${!open && 'hidden'}`}>
                                                    <div className="mb-2">Libros Parroquiales</div>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/reports-books/baptisms"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname === '/reports-books/baptisms' && 'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname === '/reports-books/baptisms' &&
                                                                    'text-white'
                                                                }`}
                                                            >
                                                                Bautizos
                                                            </span>
                                                            {/*<Tag style={{ marginLeft: '5px' }} color="#87d068">*/}
                                                            {/*    Nuevo*/}
                                                            {/*</Tag>*/}
                                                        </NavLink>
                                                    </li>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/reports-books/marriages"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname === '/reports-books/marriages' && 'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname === '/reports-books/marriages' &&
                                                                    'text-white'
                                                                }`}
                                                            >
                                                                Matrimonios
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </>
                                    );
                                }}
                            </SidebarLinkGroup>

                            {/* Books */}
                            <SidebarLinkGroup activecondition={pathname.includes('/books')}>
                                {(handleClick: any, open: any) => {
                                    return (
                                        <div data-tut={DASHBOARD_ITEMS_IDS.booksMenu}>
                                            <a
                                                href="src/library/sidebar/Sidebar#0"
                                                className={`mb-1 block text-slate-200 hover:text-white truncate transition duration-150 ${
                                                    pathname.includes('/books') && 'hover:text-slate-200'
                                                }`}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                                                }}
                                            >
                                                <div className="flex items-center justify-between">
                                                    <div className="flex items-center">
                                                        <svg className="shrink-0 h-6 w-6">
                                                            <path
                                                                className={`fill-current text-slate-600 ${
                                                                    pathname.includes('/books') && 'text-lime-600'
                                                                }`}
                                                                d="M6 4h4c.553 0 1 .449 1 1.002v19.998c0 .553-.447 1-1 1h-4c-.553 0-1-.447-1-1v-19.999c0-.552.447-1.001 1-1.001z"
                                                            />
                                                            <path
                                                                className={`fill-current text-slate-400 ${
                                                                    pathname.includes('/books') && 'text-lime-300'
                                                                }`}
                                                                d="M5 19h6v4.002h-6v-4.002z"
                                                            />
                                                            <path
                                                                className={`fill-current text-slate-500 ${
                                                                    pathname.includes('/books') && 'text-lime-500'
                                                                }`}
                                                                d="M12 0h6c.553 0 1 .449 1 1.002v23.998c0 .553-.447 1-1 1h-6c-.553 0-1-.447-1-1v-23.999c0-.552.447-1.001 1-1.001z"
                                                            />
                                                            <path
                                                                className={`fill-current text-slate-400 ${
                                                                    pathname.includes('/books') && 'text-lime-200'
                                                                }`}
                                                                d="M14 22.001h2v-14.001h-2v14.001zm0-18.001v2h2v-2h-2z"
                                                            />
                                                            <path
                                                                className={`fill-current text-slate-600 ${
                                                                    pathname.includes('/books') && 'text-lime-600'
                                                                }`}
                                                                d="M20 6h6c.553 0 1 .449 1 1v18c0 .553-.447 1-1 1h-6c-.553 0-1-.447-1-1v-18c0-.551.447-1 1-1z"
                                                            />
                                                            <path
                                                                className={`fill-current text-slate-400 ${
                                                                    pathname.includes('/books') && 'text-lime-300'
                                                                }`}
                                                                d="M19 16h8v-4h-8v4zm0-6v1.002h8v-1.002h-8z"
                                                            />
                                                        </svg>
                                                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                            Libros
                                                        </span>
                                                        <Tag style={{ marginLeft: '5px' }} color="#D6950A">
                                                            Actualizado
                                                        </Tag>
                                                    </div>
                                                    {/* Icon */}
                                                    <div className="flex shrink-0 ml-2">
                                                        <HiChevronDown
                                                            className={`text-slate-400 ${
                                                                open && 'transform rotate-180'
                                                            }`}
                                                            size={'22px'}
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                            <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                                                <ul className={`pl-9 my-2 ${!open && 'hidden'}`}>
                                                    <li
                                                        className="pl-2 mb-2 py-1 last:mb-0"
                                                        data-tut={DASHBOARD_ITEMS_IDS.booksSubMenu}
                                                    >
                                                        <NavLink
                                                            end
                                                            to="/books/baptisms"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname.includes('/books/baptisms') && 'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname.includes('/books/baptisms') && 'text-white'
                                                                }`}
                                                            >
                                                                Bautizos
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/books/proclamations"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname.includes('/books/proclamations') &&
                                                                'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname.includes('/books/proclamations') &&
                                                                    'text-white'
                                                                }`}
                                                            >
                                                                Proclamas
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/books/marriages"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname.includes('/books/marriages') && 'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname.includes('/books/marriages') &&
                                                                    'text-white'
                                                                }`}
                                                            >
                                                                Matrimonios
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/books/deaths"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname.includes('/books/deaths') && 'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname.includes('/books/deaths') && 'text-white'
                                                                }`}
                                                            >
                                                                Defunciones
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    );
                                }}
                            </SidebarLinkGroup>

                            {/* Catechism */}
                            <SidebarLinkGroup activecondition={pathname.includes('/catechism')}>
                                {(handleClick: any, open: any) => {
                                    return (
                                        <div data-tut={DASHBOARD_ITEMS_IDS.catechismMenu}>
                                            <a
                                                href="src/library/sidebar/Sidebar#0"
                                                className={`mb-1 block text-slate-200 hover:text-white truncate transition duration-150 ${
                                                    pathname.includes('/catechism') && 'hover:text-slate-200'
                                                }`}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                                                }}
                                            >
                                                <div className="flex items-center justify-between">
                                                    <div className="flex items-center">
                                                        <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                                            <path
                                                                className={`fill-current text-slate-400 ${
                                                                    pathname.includes('/catechism') && 'text-lime-200'
                                                                }`}
                                                                d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z"
                                                            />
                                                            <path
                                                                className={`fill-current text-slate-600 ${
                                                                    pathname.includes('/catechism') && 'text-lime-600'
                                                                }`}
                                                                d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z"
                                                            />
                                                        </svg>
                                                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                            Catequesis
                                                        </span>
                                                    </div>
                                                    {/* Icon */}
                                                    <div className="flex shrink-0 ml-2">
                                                        <HiChevronDown
                                                            className={`text-slate-400 ${
                                                                open && 'transform rotate-180'
                                                            }`}
                                                            size={'22px'}
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                            <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                                                <ul className={`pl-9 my-2 ${!open && 'hidden'}`}>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/catechism/period"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname === '/catechism/period' && 'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname === '/catechism/period' && 'text-white'
                                                                }`}
                                                            >
                                                                Períodos
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/catechism/contributor"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname === '/catechism/contributor' && 'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname === '/catechism/contributor' &&
                                                                    'text-white'
                                                                }`}
                                                            >
                                                                Catequistas
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/catechism/level"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname === '/catechism/level' && 'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname === '/catechism/level' && 'text-white'
                                                                }`}
                                                            >
                                                                Niveles
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/catechism/zone"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname === '/catechism/zone' && 'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname === '/catechism/zone' && 'text-white'
                                                                }`}
                                                            >
                                                                Zonas
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/catechism/community"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname === '/catechism/community' && 'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname === '/catechism/community' && 'text-white'
                                                                }`}
                                                            >
                                                                Comunidades
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/catechism/course"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname === '/catechism/course' && 'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname === '/catechism/course' && 'text-white'
                                                                }`}
                                                            >
                                                                Grupos
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/catechism/member"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname === '/catechism/member' && 'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname === '/catechism/member' && 'text-white'
                                                                }`}
                                                            >
                                                                Integrantes
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/catechism/registration"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname === '/catechism/registration' && 'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname === '/catechism/registration' &&
                                                                    'text-white'
                                                                }`}
                                                            >
                                                                Matrículas
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    );
                                }}
                            </SidebarLinkGroup>

                            {/* Certificates */}
                            <SidebarLinkGroup activecondition={pathname.includes('/certificates')}>
                                {(handleClick: any, open: any) => {
                                    return (
                                        <>
                                            <a
                                                href="src/library/sidebar/Sidebar#0"
                                                className={`mb-1 block text-slate-200 hover:text-white truncate transition duration-150 ${
                                                    pathname.includes('/certificates') && 'hover:text-slate-200'
                                                }`}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                                                }}
                                            >
                                                <div className="flex items-center justify-between">
                                                    <div className="flex items-center">
                                                        <svg className="shrink-0 h-6 w-6" viewBox="0 0 58 58">
                                                            <polygon
                                                                className={`fill-current text-slate-600 ${
                                                                    pathname.includes('/certificates') &&
                                                                    'text-lime-600'
                                                                }`}
                                                                points="45.5,14 31.5,0 0.5,0 0.5,58 45.5,58"
                                                            />
                                                            <path
                                                                className={`fill-current text-slate-400 ${
                                                                    pathname.includes('/certificates') &&
                                                                    'text-lime-200'
                                                                }`}
                                                                d="M10.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S9.948,23,10.5,23z"
                                                            />
                                                            <path
                                                                className={`fill-current text-slate-400 ${
                                                                    pathname.includes('/certificates') &&
                                                                    'text-lime-200'
                                                                }`}
                                                                d="M10.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S9.948,15,10.5,15z"
                                                            />
                                                            <path
                                                                className={`fill-current text-slate-400 ${
                                                                    pathname.includes('/certificates') &&
                                                                    'text-lime-200'
                                                                }`}
                                                                d="M35.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S36.052,29,35.5,29z"
                                                            />
                                                            <path
                                                                className={`fill-current text-slate-400 ${
                                                                    pathname.includes('/certificates') &&
                                                                    'text-lime-200'
                                                                }`}
                                                                d="M35.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S36.052,37,35.5,37z"
                                                            />
                                                            <polygon
                                                                className={`fill-current text-slate-200 ${
                                                                    pathname.includes('/certificates') &&
                                                                    'text-lime-200'
                                                                }`}
                                                                points="31.5,0 31.5,14 45.5,14"
                                                            />
                                                            <polygon
                                                                className={`fill-current text-slate-200 ${
                                                                    pathname.includes('/certificates') &&
                                                                    'text-lime-200'
                                                                }`}
                                                                points="45.5,36 49.208,43.242 57.5,44.403 51.5,50.04 52.916,58 45.5,54.242 38.084,58 39.5,50.04 33.5,44.403 41.792,43.242"
                                                            />
                                                        </svg>
                                                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                            Certificados
                                                        </span>
                                                    </div>
                                                    {/* Icon */}
                                                    <div className="flex shrink-0 ml-2">
                                                        <HiChevronDown
                                                            className={`text-slate-400 ${
                                                                open && 'transform rotate-180'
                                                            }`}
                                                            size={'22px'}
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                            <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                                                <ul className={`pl-9 my-2 ${!open && 'hidden'}`}>
                                                    <div className="mb-2">Cursillos</div>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/certificates/pre-baptism"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname === '/certificates/pre-baptism' && 'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname === '/certificates/pre-baptism' &&
                                                                    'text-white'
                                                                }`}
                                                            >
                                                                Pre - Bautismal
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/certificates/pre-marriage"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname === '/certificates/pre-marriage' &&
                                                                'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname === '/certificates/pre-marriage' &&
                                                                    'text-white'
                                                                }`}
                                                            >
                                                                Pre - Matrimonial
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <div className="mb-2">Comunicados</div>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/certificates/marriage-notification"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname === '/certificates/marriage-notification' &&
                                                                'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname ===
                                                                        '/certificates/marriage-notification' &&
                                                                    'text-white'
                                                                }`}
                                                            >
                                                                Aviso de Matrimonio
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/certificates/marriage-license"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname === '/certificates/marriage-license' &&
                                                                'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname === '/certificates/marriage-license' &&
                                                                    'text-white'
                                                                }`}
                                                            >
                                                                Licencia de Matrimonio
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/certificates/baptism-license"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname === '/certificates/baptism-license' &&
                                                                'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname === '/certificates/baptism-license' &&
                                                                    'text-white'
                                                                }`}
                                                            >
                                                                Permiso de Bautizo
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/certificates/freedom-singleness"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname === '/certificates/freedom-singleness' &&
                                                                'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname === '/certificates/freedom-singleness' &&
                                                                    'text-white'
                                                                }`}
                                                            >
                                                                Libertad y Soltería
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/certificates/honorability"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname === '/certificates/honorability' &&
                                                                'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname === '/certificates/honorability' &&
                                                                    'text-white'
                                                                }`}
                                                            >
                                                                Honorabilidad
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </>
                                    );
                                }}
                            </SidebarLinkGroup>

                            {/* Inventory */}
                            <SidebarLinkGroup activecondition={pathname.includes('/inventory')}>
                                {(handleClick: any, open: any) => {
                                    return (
                                        <>
                                            <a
                                                href="src/library/sidebar/Sidebar#0"
                                                className={`mb-1 block text-slate-200 hover:text-white truncate transition duration-150 ${
                                                    pathname.includes('/inventory') && 'hover:text-slate-200'
                                                }`}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                                                }}
                                            >
                                                <div className="flex items-center justify-between">
                                                    <div className="flex items-center">
                                                        <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                                            <path
                                                                className={`fill-current text-slate-600 ${
                                                                    pathname.includes('/inventory') && 'text-lime-600'
                                                                }`}
                                                                d="M16 13v4H8v-4H0l3-9h18l3 9h-8Z"
                                                            ></path>
                                                            <path
                                                                className={`fill-current text-slate-400 ${
                                                                    pathname.includes('/inventory') && 'text-lime-400'
                                                                }`}
                                                                d="m23.72 12 .229.686A.984.984 0 0 1 24 13v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-8c0-.107.017-.213.051-.314L.28 12H8v4h8v-4H23.72ZM13 0v7h3l-4 5-4-5h3V0h2Z"
                                                            ></path>
                                                        </svg>
                                                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                            Inventario
                                                        </span>
                                                    </div>
                                                    {/* Icon */}
                                                    <div className="flex shrink-0 ml-2">
                                                        <HiChevronDown
                                                            className={`text-slate-400 ${
                                                                open && 'transform rotate-180'
                                                            }`}
                                                            size={'22px'}
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                            <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                                                <ul className={`pl-9 my-2 ${!open && 'hidden'}`}>
                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/inventory/areas"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname === '/inventory/areas' && 'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname === '/inventory/areas' && 'text-white'
                                                                }`}
                                                            >
                                                                Áreas
                                                            </span>
                                                        </NavLink>
                                                    </li>

                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/inventory/subareas"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname === '/inventory/subareas' && 'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname === '/inventory/subareas' && 'text-white'
                                                                }`}
                                                            >
                                                                Subáreas
                                                            </span>
                                                        </NavLink>
                                                    </li>

                                                    <li className="pl-2 mb-2 py-1 last:mb-0">
                                                        <NavLink
                                                            end
                                                            to="/inventory/articles"
                                                            className={`block text-slate-400 hover:text-white transition duration-150 truncate ${
                                                                pathname === '/inventory/articles' && 'text-white'
                                                            }`}
                                                        >
                                                            <span
                                                                className={`text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                                                                    pathname === '/inventory/articles' && 'text-white'
                                                                }`}
                                                            >
                                                                Artículos
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </>
                                    );
                                }}
                            </SidebarLinkGroup>

                            {/* Contacts */}
                            <li
                                className={`px-3 py-2 rounded-sm mb-0.5 lg:mb-1 last:mb-0 ${
                                    pathname.includes('/contacts') && 'bg-slate-900'
                                }`}
                            >
                                <NavLink
                                    end
                                    to="/contacts"
                                    className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                        pathname.includes('/contacts') && 'hover:text-slate-200'
                                    }`}
                                >
                                    <div className="flex items-center">
                                        <svg className="shrink-0 h-6 w-6" viewBox="0 0 512 512">
                                            <path
                                                className={`fill-current text-slate-400 ${
                                                    pathname.includes('/contacts') && 'text-lime-200'
                                                }`}
                                                d="M38.788,170.667h23.273h23.273c12.853,0,23.273-10.42,23.273-23.273c0-12.853-10.42-23.273-23.273-23.273H62.061H38.788c-12.853,0-23.273,10.42-23.273,23.273C15.515,160.247,25.935,170.667,38.788,170.667z"
                                            />
                                            <path
                                                className={`fill-current text-slate-400 ${
                                                    pathname.includes('/contacts') && 'text-lime-200'
                                                }`}
                                                d="M85.333,232.727H62.061H38.788c-12.853,0-23.273,10.42-23.273,23.273c0,12.853,10.42,23.273,23.273,23.273h23.273h23.273c12.853,0,23.273-10.42,23.273-23.273C108.606,243.147,98.186,232.727,85.333,232.727z"
                                            />
                                            <path
                                                className={`fill-current text-slate-400 ${
                                                    pathname.includes('/contacts') && 'text-lime-200'
                                                }`}
                                                d="M85.333,341.333H62.061H38.788c-12.853,0-23.273,10.42-23.273,23.273s10.42,23.273,23.273,23.273h23.273h23.273c12.853,0,23.273-10.42,23.273-23.273S98.186,341.333,85.333,341.333z"
                                            />
                                            <circle
                                                className={`fill-current text-slate-200 ${
                                                    pathname.includes('/contacts') && 'text-lime-200'
                                                }`}
                                                cx="279.273"
                                                cy="201.697"
                                                r="69.818"
                                            />
                                            <path
                                                className={`fill-current text-slate-200 ${
                                                    pathname.includes('/contacts') && 'text-lime-200'
                                                }`}
                                                d="M279.273,271.514c-59.982,0-108.606,48.621-108.606,108.606h217.212C387.879,320.135,339.254,271.514,279.273,271.514z"
                                            />
                                            <path
                                                className={`fill-current text-slate-600 ${
                                                    pathname.includes('/contacts') && 'text-lime-600'
                                                }`}
                                                d="M473.212,0H279.273H162.909C107.301,0,62.061,45.241,62.061,100.848v23.273h23.273c12.853,0,23.273,10.42,23.273,23.273c0,12.853-10.42,23.273-23.273,23.273H62.061v62.061h23.273c12.853,0,23.273,10.42,23.273,23.273c0,12.853-10.42,23.273-23.273,23.273H62.061v62.061h23.273c12.853,0,23.273,10.42,23.273,23.273s-10.42,23.273-23.273,23.273H62.061v23.273C62.061,466.759,107.301,512,162.909,512h116.364h193.939c12.853,0,23.273-10.42,23.273-23.273V23.273C496.485,10.42,486.065,0,473.212,0z M279.273,380.121H170.667c0-59.985,48.624-108.606,108.606-108.606c-38.558,0-69.818-31.26-69.818-69.818c0-38.561,31.26-69.818,69.818-69.818c38.558,0,69.818,31.257,69.818,69.818c0,38.558-31.26,69.818-69.818,69.818c59.982,0,108.606,48.621,108.606,108.606H279.273z"
                                            />
                                            <path
                                                className={`fill-current text-slate-600 ${
                                                    pathname.includes('/contacts') && 'text-lime-600'
                                                }`}
                                                d="M170.667,380.12c0-59.985,48.624-108.606,108.606-108.606c-38.558,0-69.818-31.26-69.818-69.818c0-38.56,31.26-69.818,69.818-69.818V0H162.909C107.301,0,62.061,45.241,62.061,100.848v23.273h23.273c12.853,0,23.273,10.42,23.273,23.273c0,12.853-10.42,23.273-23.273,23.273H62.061v62.061h23.273c12.853,0,23.273,10.42,23.273,23.273c0,12.853-10.42,23.273-23.273,23.273H62.061v62.061h23.273c12.853,0,23.273,10.42,23.273,23.273s-10.42,23.273-23.273,23.273H62.061v23.273C62.061,466.759,107.301,512,162.909,512h116.364V380.12H170.667z"
                                            />
                                        </svg>
                                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Contactos
                                        </span>
                                    </div>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Expand / collapse button */}
                <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
                    <div className="px-3 py-2">
                        <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
                            <HiChevronDoubleRight
                                size={'2em'}
                                className="text-slate-500 hover:text-slate-400 sidebar-expanded:rotate-180"
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});
