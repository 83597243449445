import { makeObservable, observable, runInAction } from 'mobx';
import { get, patch, post, remove } from '../../../utils/api';
import { CollaboratorProps } from '../types';

class CollaboratorStore {
    collaborators: CollaboratorProps[] = [];
    collaborator: CollaboratorProps | null = null;
    pagination: { defaultPageSize: number; total: number } = { defaultPageSize: 20, total: 0 };

    constructor() {
        makeObservable(this, {
            collaborators: observable,
            collaborator: observable,
            pagination: observable,
        });
    }

    async view(id: number) {
        const response = await get(`/staff/catechism/contributor/${id}`);
        runInAction(() => {
            this.collaborator = response.data;
        });
    }

    async list(params = {}) {
        const response = await get(`/staff/catechism/contributor/`, params);
        runInAction(() => {
            this.collaborators = response.data.results;
            this.pagination = {
                defaultPageSize: 20,
                total: response.data.count,
            };
        });
        return response.data.results;
    }

    async create(payload: CollaboratorProps) {
        const response = await post(`/staff/catechism/contributor/`, payload);
        runInAction(() => {
            this.collaborators.push(response.data);
        });
        return response.data;
    }

    async patch(payload = {}, id: number) {
        const response = await patch(`/staff/catechism/contributor/${id}/`, payload);
        runInAction(() => {
            this.collaborator = response.data;
        });
        return response.data;
    }

    async remove(id: number) {
        return await remove(`/staff/catechism/contributor/${id}`).then(() => this.list());
    }
}

export default new CollaboratorStore();
