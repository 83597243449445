import React from 'react';
import { Navbar, Sidebar } from '../library';
import { Outlet } from 'react-router-dom';

export const PrivateLayout = () => {
    return (
        <div className="flex h-screen overflow-hidden">
            <Sidebar />

            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Navbar />
                <main>
                    <div className="px-0 sm:px-6 lg:px-8 py-4 w-full max-w-9xl mx-auto">
                        <Outlet />
                    </div>
                </main>
            </div>
        </div>
    );
};
