import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import CompanyStore from '../../stores/CompanyStore';
import { notification, Switch } from 'antd';
import ErrorRender from '../outlets/ErrorRender';
import { toastError, toastInfo, toastWarnig } from '../../utils/toast';
import UserStore from '../../stores/UserStore';
import { hasSignature } from '../../hooks';

export const SwitchSignature = observer(() => {
    const [signature, setSignature] = useState<boolean>(
        CompanyStore.company !== null && CompanyStore.company.is_signature
    );
    const [companyId, setCompanyId] = useState<number | undefined>(undefined);

    async function onChangeSignature(checked: any) {
        try {
            const signature: boolean = hasSignature();
            if (signature) {
                if (companyId !== undefined) {
                    await CompanyStore.patch(
                        {
                            is_signature: checked,
                        },
                        companyId
                    );
                }

                if (CompanyStore.company?.is_signature) {
                    toastInfo('Generar documentos con firma digital');
                } else {
                    toastWarnig('Generar documentos sin firma digital');
                }
                setSignature(CompanyStore.signature);
            } else {
                toastError(
                    'Para utilizar esta opción primero debe subir un certificado de firma digital en Ajustes del sistema',
                    false
                );
            }
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
    }

    async function getCompany() {
        try {
            await UserStore.loadUser();
            if (UserStore.user) {
                await CompanyStore.get(UserStore.user.company.id);
                setCompanyId(UserStore.user.company.id);
                setSignature(CompanyStore.signature);
            }
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
    }

    useEffect(() => {
        getCompany();
    }, []);

    return (
        <div title={'On: Firma electrónica. \n Off: Sin firma electrónica'} className={'font-semibold text-sm'}>
            Firma Digital: Off <Switch size={'small'} onChange={onChangeSignature} checked={signature} /> On
        </div>
    );
});
