import React from 'react';
import { Button, Form, Input } from 'antd';
import { toastError } from '../../../utils/toast';
import { Strings } from '../../../utils/strings';
import { BsFileEarmarkRichtext } from 'react-icons/bs';
import { MdCleaningServices } from 'react-icons/md';

interface Props {
    getFileUrl: (payload: any) => void;
    type: string;
}

export const FreedomSinglenessForm = (props: Props) => {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        const payload = {
            member: values.member,
            boyfriend: values.boyfriend,
            memberAddress: values.memberAddress,
            second_parish: values.parish,
            lengthResidence: values.lengthResidence,
            kinship: values.kinship,
            witness1: values.witness1,
            witness1Address: values.witness1Address,
            witness2: values.witness2,
            witness2Address: values.witness2Address,
            type: props.type,
        };
        if (props.type == 'A4') {
            props.getFileUrl(payload);
        } else {
            toastError('Este certificado no esta disponible en tamaño A5');
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log(errorInfo);
    };

    return (
        <Form className="p-6" form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <div className="grid grid-cols-2 gap-4">
                <Form.Item
                    label={'Solicitante'}
                    name="member"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input placeholder={'Nombre del portador del certificado'} />
                </Form.Item>
                <Form.Item
                    label={'Novio/a'}
                    name="boyfriend"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <Form.Item
                    label={'Dirección'}
                    name="memberAddress"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={'Parroquia Eclesiástica'}
                    name="parish"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <Form.Item
                    label={'Tiempo de Residencia'}
                    name="lengthResidence"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input placeholder={'Un mes, dos meses, un año, dos años'} />
                </Form.Item>
                <Form.Item
                    label={'Parentesco'}
                    name="kinship"
                    initialValue={'Ninguno'}
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <Form.Item
                    label={'Nombre del Primer Testigo'}
                    name="witness1"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={'Dirección'}
                    name="witness1Address"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <Form.Item
                    label={'Nombre del Segundo Testigo'}
                    name="witness2"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={'Dirección'}
                    name="witness2Address"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>
            </div>

            <Form.Item>
                <div className={'flex flex-row justify-center space-x-4 pt-4'}>
                    <Button
                        className={'mx-2 second'}
                        type="primary"
                        htmlType="submit"
                        icon={<BsFileEarmarkRichtext className="inline-flex mr-1" size={'18px'} />}
                    >
                        {Strings.actions.generate_certificate}
                    </Button>
                    <Button
                        className={'mx-2'}
                        onClick={() => {
                            form.resetFields();
                        }}
                        icon={<MdCleaningServices className="inline-flex mr-1" size={'18px'} />}
                    >
                        {Strings.actions.clean_form}
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
};
