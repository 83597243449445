import React, { useState } from 'react';
import { Select } from 'antd';
import _ from 'lodash';
import BaptismStore from '../../../modules/books/stores/BaptismStore';
import { BaptismProps } from '../../books/types';
import { SelectProps } from 'antd/es/select';
import { capitalizeFirstLetter } from '../../../library';

const { Option } = Select;

interface Props extends SelectProps {
    onChange?: (value: number) => void;
}

export const BaptizedSelect = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<Array<BaptismProps>>([]);

    const handleSearch = _.debounce(async (value: string) => {
        setLoading(prev => !prev);
        if (value) {
            BaptismStore.list({ search: value }).then(() => {
                setData(BaptismStore.baptisms);
            });
            setLoading(prev => !prev);
        } else {
            setData([]);
        }
    }, 500);

    return (
        <Select
            key={'select-baptism'}
            showSearch
            allowClear
            className="form-control show-tick"
            loading={loading}
            placeholder="Buscar en bautizos"
            style={{ width: '100%' }}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            notFoundContent={null}
            {...props}
        >
            {data.map((baptism: BaptismProps) => {
                return (
                    <Option key={baptism.id} value={baptism.id ? baptism.id : 0}>
                        {capitalizeFirstLetter(baptism.member)}
                    </Option>
                );
            })}
        </Select>
    );
};
