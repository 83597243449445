import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Spin, notification } from 'antd';
import { isMobile } from 'react-device-detect';
import { ProclamationForm } from '../forms';
import { ProclamationTable } from '../tables';
import { BooksFilter } from '../filters';
import ProclamationStore from '../stores/ProclamationStore';
import { CertificatePreview, PageHeaderBooks } from '../../../library';
import ErrorRender from '../../../library/outlets/ErrorRender';
import { requests } from '../../../utils/api';
import { ProclamationProps } from '../types';
import { toastSuccess } from '../../../utils/toast';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const ProclamationsPage = () => {
    const searchParams = useQuery();
    const [loading, setLoading] = useState<boolean>(false);
    const [stage, setStage] = useState<'create' | 'update' | 'list'>('list');
    const [proclamation, setProclamation] = useState<ProclamationProps | null>(null);
    const [drawerCertificate, setDrawerCertificate] = useState<boolean>(false);
    const [fileURL, setFileURL] = useState('');

    async function handleListProclamations(params = {}) {
        setLoading(true);
        try {
            await ProclamationStore.list(params);
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    useEffect(() => {
        handleListProclamations(searchParams).then(() => console.log('Load proclamations with filters'));
    }, [location.search]);

    async function handleCreate(payload: ProclamationProps) {
        setLoading(true);
        try {
            await ProclamationStore.create(payload);
            setLoading(false);
            toastSuccess('Proclama registrada!');
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function handleUpdate(payload: ProclamationProps) {
        setLoading(true);
        try {
            await ProclamationStore.patch(payload, payload.id);
            setLoading(false);
            toastSuccess('Proclama actualizada!');
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function handleRemove(id: number) {
        setLoading(true);
        try {
            await ProclamationStore.remove(id);
            setStage('list');
            toastSuccess('Proclama eliminada!');
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function getFileURL(id: number, template: string) {
        setLoading(true);
        setDrawerCertificate(true);
        try {
            requests
                .get2(`/staff/books/proclamation/${id}/certificate/${template}`, { responseType: 'blob' })
                .then((response: any) => {
                    if (isMobile) {
                        return setFileURL('');
                    } else {
                        const file = new Blob([response], { type: 'application/pdf' });
                        const fileURL2 = URL.createObjectURL(file);
                        setFileURL(fileURL2);
                    }
                    setLoading(false);
                });
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    return (
        <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
            <Spin spinning={loading}>
                <PageHeaderBooks
                    setStage={(stage: 'create' | 'update' | 'list') => {
                        setProclamation(null);
                        setStage(stage);
                    }}
                    stage={stage}
                    title={
                        stage === 'list' ? 'Proclamas' : stage === 'create' ? 'Nueva Proclama' : 'Actualizar Proclama'
                    }
                    titleButton={
                        stage === 'list' ? 'Nueva Proclama' : stage === 'create' ? 'Ver Proclamas' : 'Ver Proclamas'
                    }
                />

                {stage === 'list' ? (
                    <>
                        <BooksFilter path={'/books/proclamations'} />
                        <ProclamationTable
                            loading={loading}
                            handleRemove={handleRemove}
                            setProclamation={(proclamation: ProclamationProps) => {
                                setProclamation(proclamation);
                                setStage('update');
                            }}
                            handlePreviewCertificate={getFileURL}
                        />
                    </>
                ) : stage === 'create' ? (
                    <div className={'py-6'}>
                        <ProclamationForm
                            type={'create'}
                            handleCreate={async (payload: ProclamationProps, list: boolean) => {
                                await handleCreate(payload);
                                if (list) {
                                    await handleListProclamations();
                                    setStage('list');
                                }
                            }}
                        />
                    </div>
                ) : (
                    stage === 'update' &&
                    proclamation !== null && (
                        <div className={'py-6'}>
                            <ProclamationForm
                                type={'update'}
                                proclamation={proclamation}
                                handleUpdate={async (payload: ProclamationProps, list: boolean) => {
                                    await handleUpdate(payload);
                                    if (list) {
                                        await handleListProclamations();
                                        setStage('list');
                                    }
                                }}
                                handleRemove={handleRemove}
                                handlePreviewCertificate={getFileURL}
                            />
                        </div>
                    )
                )}

                {drawerCertificate && (
                    <CertificatePreview
                        loading={loading}
                        handleClose={() => {
                            setFileURL('');
                            setDrawerCertificate(false);
                        }}
                        fileUrl={fileURL}
                    />
                )}
            </Spin>
        </div>
    );
};
