import React from 'react';
import { Popconfirm, Space, Typography } from 'antd';
import { observer } from 'mobx-react';
import { Spin } from 'antd/es';
import { RiDeleteBin2Fill, RiEditBoxLine } from 'react-icons/ri';
import { PaginationTable } from '../../../library';
import { SubareaProps } from '../types';
import { capitalize } from 'lodash';
import SubareaStore from '../stores/SubareaStore';

interface Props {
    loading: boolean;
    handleRemove: (id: number) => void;
    setRecord: (record: SubareaProps) => void;
}

export const SubAreasTable = observer((props: Props) => {
    return (
        <>
            <div className="relative overflow-x-auto">
                <Spin spinning={props.loading}>
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="py-3 px-6">
                                    Área
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Subárea
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    <span className="sr-only">Actions</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {SubareaStore.subareas.length > 0 ? (
                                SubareaStore.subareas.map((record: SubareaProps, index: number) => {
                                    return (
                                        <>
                                            <tr
                                                key={index}
                                                className={
                                                    index % 2 == 0
                                                        ? 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'
                                                        : 'bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700'
                                                }
                                            >
                                                <td
                                                    scope="row"
                                                    className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    <div className={'font-bold'}>
                                                        {typeof record.area === 'object' &&
                                                            capitalize(record.area.name)}
                                                    </div>
                                                </td>
                                                <td className={'py-4 px-6'}>{capitalize(record.name)}</td>
                                                <td className="py-4 px-6">
                                                    <Space size="middle" className="px-2">
                                                        <Typography.Link
                                                            title="Actualizar"
                                                            onClick={() => props.setRecord(record)}
                                                        >
                                                            <RiEditBoxLine className="ml-1" color={'orange'} />
                                                        </Typography.Link>
                                                    </Space>

                                                    <Space size="middle" className="px-2">
                                                        <Popconfirm
                                                            title={'Eliminar subárea?'}
                                                            onConfirm={() =>
                                                                record.id &&
                                                                props.handleRemove &&
                                                                props.handleRemove(record.id)
                                                            }
                                                            okText={'Eliminar'}
                                                            cancelText={'Cancelar'}
                                                        >
                                                            <Typography.Link>
                                                                <RiDeleteBin2Fill
                                                                    className="cursor-pointer transform hover:scale-150 duration-75 ml-1"
                                                                    color="#DE3E1B"
                                                                    title={'Eliminar'}
                                                                />
                                                            </Typography.Link>
                                                        </Popconfirm>
                                                    </Space>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })
                            ) : (
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td className="py-4 px-6" colSpan={4}>
                                        <div className={'flex justify-center'}>No existe registros</div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className={'pt-2 pb-2'}>
                        <PaginationTable {...SubareaStore.pagination} />
                    </div>
                </Spin>
            </div>
        </>
    );
});
