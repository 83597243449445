import React from 'react';
import { GrSystem } from 'react-icons/gr';
import moment from 'moment/moment';
import { CompanyProps } from '../../utils/types';

interface IInfoCompanyProps {
    company: CompanyProps;
    update?: boolean;
    setUpdateCompanyDrawer?: (value: boolean) => void;
}

export const InfoCompany = ({ company, setUpdateCompanyDrawer, update }: IInfoCompanyProps) => {
    return (
        <div className="container mx-auto">
            <div className="rounded-lg p-6 relative z-10" style={{ cursor: 'auto' }}>
                <div className="flex flex-wrap items-center py-5">
                    <div className="flex w-full h-48 md:h-64 lg:h-72 relative">
                        <div className="w-8/12 pr-4 relative">
                            <div className="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                                <span className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                                    Información Principal
                                </span>
                                <div className="font-normal text-gray-700 dark:text-gray-400">
                                    Código de servicio: {company.code}
                                </div>
                                <div className={'text-sm text-gray-400'}>
                                    Proporcione este código para solicitar soporte técnico.
                                </div>

                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <tbody>
                                        <tr className="bg-white border-b">
                                            <th
                                                scope="row"
                                                className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                            >
                                                Nombre Legal:
                                            </th>
                                            <td className="py-4 px-6">{company.legal_name}</td>
                                        </tr>
                                        <tr className="bg-white border-b">
                                            <th
                                                scope="row"
                                                className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                            >
                                                Ubicación:
                                            </th>
                                            <td className="py-4 px-6">
                                                {company.parish +
                                                    ' / ' +
                                                    company.city +
                                                    ' / ' +
                                                    company.state +
                                                    ' / ' +
                                                    company.country}
                                            </td>
                                        </tr>
                                        <tr className="bg-white border-b">
                                            <th
                                                scope="row"
                                                className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                            >
                                                Parroco:
                                            </th>
                                            <td className="py-4 px-6">{company.parish_priest}</td>
                                        </tr>
                                        <tr className="bg-white border-b">
                                            <th
                                                scope="row"
                                                className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                            >
                                                Teléfonos:
                                            </th>
                                            <td className="py-4 px-6">{company.phone}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                {update === true && setUpdateCompanyDrawer && (
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setUpdateCompanyDrawer(true)}
                                        className={
                                            'flex items-center justify-center text-center relative text-white font-bold text-sm bg-gray-200 text-gray-800 px-8 py-3 rounded-lg shadow-lg hover:shadow-none hover:opacity-75'
                                        }
                                    >
                                        Cambiar Información
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="w-4/12 h-full">
                            <div className="flex flex-col w-full h-full">
                                <div className="flex-1 pb-2">
                                    <div className="w-full h-full relative">
                                        <div className="p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                                            <span className="mb-2 w-10 h-10 text-gray-500 dark:text-gray-400">
                                                <GrSystem size={40} />
                                            </span>
                                            <span className="mb-2 text-xl font-semibold tracking-tight text-gray-900">
                                                Información de Sistema
                                            </span>
                                            <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">
                                                Licencia: Software Propietario
                                            </p>
                                            <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">
                                                SAAS: Software como Servicio.
                                            </p>
                                            <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">
                                                Versión 3.2.2
                                            </p>
                                            <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">
                                                Registro: {moment(company?.created_at).format('YYYY/MM/DD HH:mm')}
                                            </p>
                                            <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">
                                                Renovación: No Definido
                                            </p>
                                            <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">
                                                Pago: Anual
                                            </p>
                                            <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">
                                                Estado:{' '}
                                                <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
                                                    Activo
                                                </span>
                                            </p>
                                            <p>
                                                Desarrollado por:{' '}
                                                <a
                                                    href="#"
                                                    className="inline-flex items-center text-blue-600 hover:underline"
                                                >
                                                    Grupo Alpes
                                                    <svg
                                                        className="ml-2 w-5 h-5"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path>
                                                        <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path>
                                                    </svg>
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
