import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PeriodStore from '../stores/PeriodStore';
import { MenuTop as CatechismMenuTop } from '../MenuTop';
import AdminCourseSubpage from '../subpages/AdminCourseSubpage';

export const CatechismCourseAdminPage = observer(() => {
    const [period, setPeriod] = useState<{ id: number; year: string } | null>(null);

    async function initial_data() {
        await PeriodStore.get_active().then(() => setPeriod(PeriodStore.period_active));
    }

    useEffect(() => {
        initial_data();
    }, []);

    return (
        <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
            <CatechismMenuTop active={'course'} />
            {period !== null && <AdminCourseSubpage period={period} />}
        </div>
    );
});
