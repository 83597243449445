import { buildTourItemSelector } from './util';

const QUOTE_RATES_ITEMS_IDS = Object.freeze({
    table: 'qr_table',
    button: 'qr_button',
});

const QUOTE_RATES_TOUR_CONFIG = [
    {
        selector: buildTourItemSelector(QUOTE_RATES_ITEMS_IDS.table),
        content: `To edit information you can click on any existing rate or pulse edit button.`,
    },
    {
        selector: buildTourItemSelector(QUOTE_RATES_ITEMS_IDS.button),
        content: `Create a new rate for then use it in Part Quote!`,
    },
];

export { QUOTE_RATES_TOUR_CONFIG, QUOTE_RATES_ITEMS_IDS };
