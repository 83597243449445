import { makeObservable, observable, runInAction } from 'mobx';
import { get, patch, post, remove } from '../../../utils/api';
import { ContactCategoryProps, ContactProps } from '../types';

class ContactStore {
    contacts: ContactProps[] = [];
    contact: ContactProps | null = null;
    categories: ContactCategoryProps[] = [];
    category: ContactCategoryProps | null = null;

    constructor() {
        makeObservable(this, {
            contacts: observable,
            contact: observable,
            categories: observable,
            category: observable,
        });
    }

    async view(id: number) {
        const response = await get(`/staff/contacts/contact/${id}`);
        runInAction(() => {
            this.contact = response.data;
        });
    }

    async list(params = {}) {
        const response = await get(`/staff/contacts/contact/`, params);
        runInAction(() => {
            this.contacts = response.data.results;
        });
        return response.data.results;
    }

    async create(payload: ContactProps) {
        const response = await post(`/staff/contacts/contact/`, payload);
        runInAction(() => {
            this.contacts.push(response.data);
        });
        return response.data;
    }

    async patch(payload = {}, id: number) {
        const response = await patch(`/staff/contacts/contact/${id}/`, payload);
        runInAction(() => {
            this.contact = response.data;
        });
        return response.data;
    }

    async remove(id: number) {
        return await remove(`/staff/contacts/contact/${id}`).then(() => this.list());
    }

    // Categories
    async list_categories(params = {}) {
        const response = await get(`/staff/contacts/category/`, params);
        runInAction(() => {
            this.categories = response.data.results;
        });
        return response.data.results;
    }

    async create_category(payload: ContactCategoryProps) {
        const response = await post(`/staff/contacts/category/`, payload);
        runInAction(() => {
            this.categories.push(response.data);
        });
        return response.data;
    }

    async remove_category(id: number) {
        return await remove(`/staff/contacts/category/${id}`).then(() => this.list_categories());
    }
}

export default new ContactStore();
