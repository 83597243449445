import { makeObservable, observable, runInAction } from 'mobx';
import { get } from '../utils/api';
import { ReportRegistrationTotals } from '../modules/reports';
import { RegistrationProps } from '../modules/catechism/types';
import { IReportBaptismByAgesProps } from '../modules/books/types';

class ReportStore {
    registrations_totals: ReportRegistrationTotals | null = null;
    brothers: RegistrationProps[] | [] = [];
    marriages: { total: number; total_percent: number } = {
        total: 0,
        total_percent: 0,
    };
    baptisms: IReportBaptismByAgesProps = {
        total: 0,
        total_percent: 0,
        total_average: 0,
        gender_none_total: 0,
        gender_none_total_percent: 0,
        baptism_without_ages: 0,
        baptism_without_ages_percent: 0,
        total_man: 0,
        total_percent_man: 0,
        total_average_man: 0,
        total_woman: 0,
        total_percent_woman: 0,
        total_average_woman: 0,
    };

    constructor() {
        makeObservable(this, {
            registrations_totals: observable,
        });
    }

    async getCatechismRegistrations(params = {}) {
        this.registrations_totals = null;
        const response = await get(`/staff/report/catechism-registrations/`, params);
        runInAction(() => {
            this.registrations_totals = response.data;
        });
    }

    async getBrothers(params = {}) {
        this.brothers = [];
        const response = await get(`/staff/report/catechism-brothers/`, params);
        runInAction(() => {
            this.brothers = response.data.results;
        });
    }

    async getBaptisms(params = {}) {
        this.baptisms = {
            total: 0,
            total_percent: 0,
            total_average: 0,
            gender_none_total: 0,
            gender_none_total_percent: 0,
            baptism_without_ages: 0,
            baptism_without_ages_percent: 0,
            total_man: 0,
            total_percent_man: 0,
            total_average_man: 0,
            total_woman: 0,
            total_percent_woman: 0,
            total_average_woman: 0,
        };
        const response = await get(`/staff/report/books-baptisms/`, params);
        runInAction(() => {
            this.baptisms = response.data;
        });
    }

    async getMarriages(params = {}) {
        this.marriages = {
            total: 0,
            total_percent: 0,
        };
        const response = await get(`/staff/report/books-marriages/`, params);
        runInAction(() => {
            this.marriages = response.data;
        });
    }

    async getTotalRecords() {
        await this.clean_data();
        return await get(`/staff/report/records/`);
    }

    async clean_data() {
        this.registrations_totals = null;
    }
}

export default new ReportStore();
