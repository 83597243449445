import React from 'react';
import { Space, Popconfirm, Typography } from 'antd';
import { LevelProps } from '../types';
import { Spin } from 'antd/es';
import { RiDeleteBin2Fill, RiEditBoxLine } from 'react-icons/ri';

interface Props {
    loading: boolean;
    records: Array<LevelProps>;
    handleRemove: (id: number) => void;
    setIsModalVisible: (value: boolean) => void;
    setRecord: (record: LevelProps) => void;
}

export const LevelsTable = ({ loading, records, handleRemove, setIsModalVisible, setRecord }: Props) => {
    return (
        <Spin spinning={loading}>
            <div className="overflow-x-auto relative">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="py-3 px-6">
                                Posición
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Nombre Principal
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Nombre Secundario
                            </th>
                            <th scope="col" className="py-3 px-6">
                                <span className="sr-only">Edit</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {records.length > 0 ? (
                            records.map((record: LevelProps, index: number) => {
                                return (
                                    <tr
                                        key={index}
                                        className={
                                            index % 2 == 0
                                                ? 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'
                                                : 'bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700'
                                        }
                                    >
                                        <td className="py-4 px-6">{record.position}</td>
                                        <td className="py-4 px-6">{record.name}</td>
                                        <td className="py-4 px-6">{record.alias}</td>
                                        <td className="py-4 px-6">
                                            <Space size="middle" className="px-2">
                                                <Typography.Link
                                                    onClick={() => {
                                                        setIsModalVisible(true);
                                                        setRecord(record);
                                                    }}
                                                >
                                                    <RiEditBoxLine
                                                        title={'Editar'}
                                                        className="cursor-pointer transform hover:scale-150 duration-75 ml-1"
                                                        color={'orange'}
                                                    />
                                                </Typography.Link>
                                            </Space>

                                            <Space size="middle" className="px-2">
                                                <Popconfirm
                                                    title={'Eliminar nivel?'}
                                                    onConfirm={() =>
                                                        record.id && handleRemove && handleRemove(record.id)
                                                    }
                                                    okText={'Eliminar'}
                                                    cancelText={'Cancelar'}
                                                >
                                                    <Typography.Link>
                                                        <RiDeleteBin2Fill
                                                            className="cursor-pointer transform hover:scale-150 duration-75 ml-1"
                                                            color="#DE3E1B"
                                                            title={'Eliminar'}
                                                        />
                                                    </Typography.Link>
                                                </Popconfirm>
                                            </Space>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td className="py-4 px-6" colSpan={4}>
                                    <div className={'flex justify-center'}>No existe registros</div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </Spin>
    );
};
