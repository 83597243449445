import { toast } from 'react-toastify';

export const toastSuccess = (message: string) => {
    return toast.success(message, {
        position: 'top-center',
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
    });
};

export const toastInfo = (message: string) => {
    return toast.info(message, {
        position: 'top-center',
        autoClose: 4500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
    });
};

export const toastError = (message: string, hideProgressBar = true) => {
    return toast.error(message, {
        position: 'top-center',
        autoClose: 4500,
        hideProgressBar: hideProgressBar,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
    });
};

export const toastWarnig = (message: string) => {
    return toast.warning(message, {
        position: 'top-center',
        autoClose: 4500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
    });
};
