import React, { useState } from 'react';
import { Input, Form, Button, Radio, InputNumber } from 'antd';
import { Strings } from '../../../utils/strings';
import LevelSelect from '../selects/LevelSelect';
import { LevelProps } from '../types';
import CommunitySearchSelect from '../selects/CommunitySearchSelect';
import { observer } from 'mobx-react';
import RegistrationStore from '../stores/RegistrationStore';
import CourseStore from '..//stores/CourseStore';
import { RiListOrdered } from 'react-icons/ri';
import { CgCheckR } from 'react-icons/cg';

interface Props {
    levels: LevelProps[];
    period: { id: number; year: string };
    handleCreate: (payload: any) => void;
}

export const RegisterForm = observer(({ levels, period, handleCreate }: Props) => {
    const [form] = Form.useForm();

    const [visibleReason, setVisibleReason] = useState<boolean>(false);
    const [courses, setCourses] = useState<Array<any>>();

    const onHandleVisibleReason = (e: any) => setVisibleReason(e.target.value);

    const onFinish = async (values: any) => {
        const payload = {
            discount: values.discount,
            discount_reason: values.discount_reason,
            representative: values.representative,
            price: values.price,
            period: period.id,
            community: values.community.key,
            level: values.level.key,
        };

        RegistrationStore.sync_payload(payload);
        await CourseStore.courses_by_level({ level: payload.level }, payload.community).then(data => setCourses(data));
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Form
                form={form}
                initialValues={{
                    period: period.year,
                    price: 10,
                    discount: false,
                    discount_reason: 'Relación de Hermanos',
                }}
                name="register-form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
            >
                <div className="grid grid-cols-4 gap-4">
                    <div className="col-span-1">
                        <Form.Item
                            label="Descuento"
                            name="discount"
                            rules={[{ required: true, message: Strings.inputs.errors.required }]}
                        >
                            <Radio.Group onChange={onHandleVisibleReason} value={visibleReason}>
                                <Radio value={false}>No</Radio>
                                <Radio value={true}>Si</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>

                    <div className="col-span-2">
                        {visibleReason && (
                            <Form.Item
                                label="Motivo Descuento"
                                name="discount_reason"
                                rules={[{ required: true, message: Strings.inputs.errors.required }]}
                            >
                                <Input />
                            </Form.Item>
                        )}
                    </div>
                </div>

                <div className="grid grid-cols-3 gap-4">
                    <Form.Item
                        label="Representante"
                        name="representative"
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Costo Matricula"
                        name="price"
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item label="Periodo Activo" name="period">
                        <Input readOnly />
                    </Form.Item>
                </div>

                <div className="grid grid-cols-3 gap-4">
                    <Form.Item
                        label="Comunidad"
                        name="community"
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <CommunitySearchSelect />
                    </Form.Item>

                    <Form.Item
                        label="Nivel"
                        name="level"
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <LevelSelect levels={levels} />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className={'second mt-8'}
                            icon={<RiListOrdered className="inline-flex mr-1" size={'18px'} />}
                        >
                            Ver Grupos Disponibles
                        </Button>
                    </Form.Item>
                </div>
            </Form>

            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Grupo
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Nivel / Comunidad
                            </th>
                            <th scope="col" className="px-6 py-3 text-center">
                                Integrantes
                            </th>
                            <th scope="col" className="px-6 py-3 text-center">
                                Hombres
                            </th>
                            <th scope="col" className="px-6 py-3 text-center">
                                Mujeres
                            </th>
                            <th scope="col" className="px-6 py-3 text-center">
                                Seleccionar
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {courses && courses.length > 0 && (
                            <>
                                {courses.map(record => {
                                    return (
                                        <tr
                                            key={record.id}
                                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                        >
                                            <th
                                                scope="row"
                                                className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                                            >
                                                {record.name}
                                            </th>
                                            <td className="px-6 py-4">
                                                {record.level.alias} / {record.community.name}
                                            </td>
                                            <td className="px-6 py-4 text-center">
                                                {record.total_registrations}/{record.quota}
                                            </td>
                                            <td className="px-6 py-4 text-center">{record.man_registrations}</td>
                                            <td className="px-6 py-4 text-center">{record.woman_registrations}</td>
                                            <td className="px-6 py-4 text-center">
                                                <Button
                                                    title={'Matrícular en este grupo'}
                                                    className={'second'}
                                                    type="link"
                                                    shape="circle"
                                                    onClick={() => {
                                                        const payload = RegistrationStore.payload;
                                                        payload.course = record.id;
                                                        handleCreate(payload);
                                                    }}
                                                >
                                                    <CgCheckR className="inline-flex" size={'18px'} />
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
});
