import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

export default function E404Page() {
    const navigate = useNavigate();
    return (
        <>
            <div className="h-screen w-screen bg-gray-100 flex items-center">
                <div className="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
                    <div className="max-w-md">
                        <div className="text-5xl font-dark font-bold">
                            Whoops...
                            <strong> 404 </strong>
                        </div>
                        <br />
                        <br />
                        <p className="text-2xl md:text-3xl font-light leading-normal">
                            <strong>La página que buscas no exsite.</strong>
                        </p>
                        <br />
                        <br />

                        <p className="mb-8">
                            Compruebe si la URL de la página es correcta. Si cree que se trata de un error, póngase en
                            contacto con el soporte técnico. <strong>¡Gracias!</strong>
                            <br />
                        </p>

                        <Button onClick={() => navigate('/', { replace: true })} type={'primary'}>
                            Regresar al sistema
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}
