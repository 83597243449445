import React, { useEffect, useState } from 'react';
import { MenuTop as CatechismMenuTop } from '../MenuTop';
import { CreateForms } from '../CreateForms';
import { ContributorFilter } from '../filters';
import { Button, Card, Modal, notification } from 'antd';
import { CollaboratorProps } from '../types';
import CollaboratorStore from '../stores/CollaboratorStore';
import PeriodStore from '../stores/PeriodStore';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import ErrorRender from '../../../library/outlets/ErrorRender';
import { CollaboratorForm } from '../forms';
import { ContributorsTable } from '../tables';
import { SiAddthis } from 'react-icons/si';
import { toastSuccess } from '../../../utils/toast';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

interface NewCollProps {
    address?: string;
    cellphone?: string;
    email?: string;
    id?: number;
    name?: string;
    new_roles?: string;
    roles?: string[];
    uid?: string;
}

export const CatechismContributorPage = observer(() => {
    const [isLoading, setIsLoading] = useState(false);
    const [isModalCreate, setIsModalCreate] = useState(false);
    const [isModalUpdate, setIsModalUpdate] = useState(false);
    const [record, setRecord] = useState<CollaboratorProps | null>(null);
    const searchParams = useQuery();

    CollaboratorStore.collaborators.map((object: NewCollProps) => {
        object['new_roles'] = object.roles?.join(' / ');
    });

    async function handleList(params = {}) {
        setIsLoading(true);
        try {
            await CollaboratorStore.list(params);
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    async function handleCreateContributor(values: CollaboratorProps) {
        const payload: CollaboratorProps = {
            name: values.name,
            roles: values.roles,
            address: values.address,
            cellphone: values.cellphone,
            email: values.email,
        };
        setIsLoading(true);
        try {
            await CollaboratorStore.create(payload);
            await handleList();
            toastSuccess('Catequista registrado!');
            setIsModalCreate(false);
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    useEffect(() => {
        handleList(searchParams);
        getPeriodActive();
    }, [location.search]);

    async function handleUpdate(payload: CollaboratorProps, id: number) {
        setIsLoading(true);
        try {
            await CollaboratorStore.patch(payload, id);
            await handleList();
            toastSuccess('Catequista actualizado!');
            setIsModalUpdate(false);
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    async function handleRemove(id: number) {
        setIsLoading(true);
        try {
            await CollaboratorStore.remove(id);
            toastSuccess('Catequista eliminado!');
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    async function getPeriodActive() {
        try {
            await PeriodStore.get_active();
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
    }

    return (
        <>
            <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
                <CatechismMenuTop active={'contributor'} />
                <div className={'my-8'}>
                    <Card
                        bordered={false}
                        size="small"
                        title={
                            <>
                                <span className="font-bold">Catequistas</span> Registrados.
                            </>
                        }
                        extra={
                            <div className={'flex flex-row'}>
                                <div className={'mr-5 mt-1.5'}>
                                    {PeriodStore.period_active !== null && (
                                        <span>
                                            <b>Período activo: </b>
                                            {PeriodStore.period_active.year}
                                        </span>
                                    )}
                                </div>
                                <Button type={'link'} onClick={() => setIsModalCreate(true)}>
                                    <div className={'flex flex-row'}>
                                        <SiAddthis className={'mt-1 mr-1'} />
                                        <span>Nuevo catequista</span>
                                    </div>
                                </Button>
                            </div>
                        }
                    >
                        <div className={'flex flex-row w-full'}>
                            <div className={'w-full'}>
                                <ContributorFilter />
                            </div>
                        </div>
                        <ContributorsTable
                            loading={isLoading}
                            handleRemove={handleRemove}
                            setRecord={setRecord}
                            setIsModalVisible={setIsModalUpdate}
                        />
                    </Card>

                    {isModalUpdate && (
                        <Modal
                            title="Editar Catequista"
                            visible={isModalUpdate}
                            footer={false}
                            onCancel={() => setIsModalUpdate(false)}
                        >
                            <CollaboratorForm
                                setIsModalVisible={setIsModalUpdate}
                                record={record}
                                handleUpdate={(payload: CollaboratorProps, id: number) => handleUpdate(payload, id)}
                            />
                        </Modal>
                    )}
                </div>

                {isModalCreate && (
                    <Modal
                        title={<b>{'Nuevo Catequistas'}</b>}
                        visible={isModalCreate}
                        footer={false}
                        onCancel={() => setIsModalCreate(false)}
                    >
                        <CreateForms
                            isModalVisible={isModalCreate}
                            setIsModalVisible={setIsModalCreate}
                            modal={{ title: 'Nuevo Catequista', type: 'FormContributor' }}
                            handleCreate={handleCreateContributor}
                        />
                    </Modal>
                )}
            </div>
        </>
    );
});
