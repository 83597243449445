import React, { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { Strings } from '../../../utils/strings';
import { CollaboratorProps } from '../types';
import { capitalizeFirstLetter } from '../../../library';
import { VscSaveAll } from 'react-icons/vsc';
import { FcCancel } from 'react-icons/fc';

interface Props {
    record?: CollaboratorProps | null;
    handleCreate?: (payload: any) => void;
    handleUpdate?: (payload: CollaboratorProps, id: number) => void;
    setIsModalVisible: (value: boolean) => void;
}

export const CollaboratorForm = ({ setIsModalVisible, handleCreate, record, handleUpdate }: Props) => {
    const [form] = Form.useForm();

    const onFinish = (values: {
        name: string;
        address: string;
        roles: Array<string>;
        cellphone: string;
        email: string;
    }) => {
        const payload = {
            name: values.name,
            address: values.address,
            roles: ['Catequista'],
            cellphone: values.cellphone,
            email: values.email,
        };
        if (handleUpdate && record && record.id) {
            handleUpdate(payload, record.id);
        } else if (handleCreate) {
            handleCreate(payload);
        }
        form.resetFields();
    };

    useEffect(() => {
        form.resetFields();
    }, [record]);

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Form
                form={form}
                initialValues={{
                    name: record && record?.name !== null && capitalizeFirstLetter(record.name),
                    address: record?.address,
                    cellphone: record?.cellphone,
                    email: record?.email,
                    roles: record?.roles,
                }}
                name="collaborator-form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
            >
                <div className="grid grid-cols-1 gap-4">
                    <Form.Item
                        label="Nombre"
                        name="name"
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <Input />
                    </Form.Item>
                </div>

                <div className="grid grid-cols-1 gap-4">
                    <Form.Item
                        label="Direccion"
                        name="address"
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <Input />
                    </Form.Item>
                </div>

                <div className="grid grid-cols-1 gap-4">
                    <Form.Item
                        label="Rol"
                        name="roles"
                        initialValue={'Catequista'}
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <Input disabled={true} />
                    </Form.Item>
                </div>

                <div className="grid grid-cols-2 gap-4">
                    <Form.Item label="Celular" name="cellphone">
                        <Input />
                    </Form.Item>

                    <Form.Item label="Correo Electronico" name="email">
                        <Input />
                    </Form.Item>
                </div>

                <Form.Item>
                    <div className={'mt-4 flex flex-row-reverse'}>
                        <Button
                            className={'mx-2 second'}
                            type="primary"
                            htmlType="submit"
                            icon={<VscSaveAll className="inline-flex mr-1" size={'18px'} />}
                        >
                            {Strings.actions.save}
                        </Button>
                        <Button
                            className={'mx-2 remove'}
                            onClick={() => {
                                form.resetFields();
                                setIsModalVisible(false);
                            }}
                            icon={<FcCancel className="inline-flex mr-1" size={'18px'} />}
                        >
                            {Strings.actions.cancel}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </>
    );
};
