import React from 'react';
import { Popconfirm, Space, Typography } from 'antd';
import { Spin } from 'antd/es';
import { observer } from 'mobx-react';
import { RiDeleteBin2Fill, RiEditBoxLine } from 'react-icons/ri';
import { CollaboratorProps } from '../types';
import CollaboratorStore from '../stores/CollaboratorStore';
import { capitalizeFirstLetter, PaginationTable } from '../../../library';

interface Props {
    loading: boolean;
    handleRemove: (id: number) => void;
    setIsModalVisible: (value: boolean) => void;
    setRecord: (record: CollaboratorProps) => void;
}

export const ContributorsTable = observer(({ loading, handleRemove, setIsModalVisible, setRecord }: Props) => {
    return (
        <Spin spinning={loading}>
            <div className="overflow-x-auto relative">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="py-3 px-6">
                                #
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Nombres
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Dirección
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Celular
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Email
                            </th>
                            <th scope="col" className="py-3 px-6">
                                <span className="sr-only">Edit</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {CollaboratorStore.collaborators.map((record: CollaboratorProps, index: number) => {
                            return (
                                <tr
                                    key={index}
                                    className={
                                        index % 2 == 0
                                            ? 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'
                                            : 'bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700'
                                    }
                                >
                                    <th
                                        scope="row"
                                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        {++index}
                                    </th>
                                    <td className="py-4 px-6">{capitalizeFirstLetter(record.name)}</td>
                                    <td className="py-4 px-6">{record.address}</td>
                                    <td className="py-4 px-6">{record.cellphone}</td>
                                    <td className="py-4 px-6">{record.email}</td>
                                    <td className="py-4 px-6">
                                        <Space size="middle" className="px-2">
                                            <Typography.Link
                                                onClick={() => {
                                                    setIsModalVisible(true);
                                                    setRecord(record);
                                                }}
                                            >
                                                <RiEditBoxLine
                                                    title={'Editar'}
                                                    className="cursor-pointer transform hover:scale-150 duration-75 ml-1"
                                                    color={'orange'}
                                                />
                                            </Typography.Link>
                                        </Space>

                                        <Space size="middle" className="px-2">
                                            <Popconfirm
                                                title={'Eliminar catequista?'}
                                                onConfirm={() => record.id && handleRemove && handleRemove(record.id)}
                                                okText={'Eliminar'}
                                                cancelText={'Cancelar'}
                                            >
                                                <Typography.Link>
                                                    <RiDeleteBin2Fill
                                                        className="cursor-pointer transform hover:scale-150 duration-75 ml-1"
                                                        color="#DE3E1B"
                                                        title={'Eliminar'}
                                                    />
                                                </Typography.Link>
                                            </Popconfirm>
                                        </Space>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className={'pt-2 pb-2'}>
                    <PaginationTable {...CollaboratorStore.pagination} />
                </div>
            </div>
        </Spin>
    );
});
