import React, { useEffect, useState } from 'react';
import { notification, Select, Typography } from 'antd';
import { ContactCategoryProps, ContactProps } from '../types';
import ContactStore from '../stores/ContactStore';
import ErrorRender from '../../../library/outlets/ErrorRender';
import { toastSuccess } from '../../../utils/toast';

interface Props {
    contact: ContactProps;
    categories: Array<ContactCategoryProps>;
    handleListContacts: (value: boolean) => void;
}

export const ContactDetailForm = ({ contact, categories, handleListContacts }: Props) => {
    const [editableStr, setEditableStr] = useState<ContactProps>({
        name: contact.name,
        email: contact.email ? contact.email : '',
        mobile: contact.mobile ? contact.mobile : '',
        mobile_operator: contact.mobile_operator ? contact.mobile_operator : '',
        phone: contact.phone ? contact.phone : '',
        note: contact.note ? contact.note : '',
        categories: contact.categories,
    });

    const { Option } = Select;
    const { Paragraph } = Typography;

    useEffect(() => {
        setEditableStr({
            name: contact.name,
            email: contact.email ? contact.email : '',
            mobile: contact.mobile ? contact.mobile : '',
            mobile_operator: contact.mobile_operator ? contact.mobile_operator : '',
            phone: contact.phone ? contact.phone : '',
            note: contact.note ? contact.note : '',
            categories: contact.categories,
        });
    }, [contact]);

    async function handleUpdate(id: number, payload: any) {
        try {
            await ContactStore.patch(payload, id);
            toastSuccess('Contacto actualizado!');
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
        handleListContacts(true);
    }

    return (
        <>
            <div className="grid grid-cols-4 gap-2 m-4">
                <span className="font-semibold">Categoría:</span>
                <Select
                    mode="multiple"
                    allowClear
                    className="col-span-3 w-full"
                    value={editableStr.categories}
                    onChange={(select: any) => {
                        const payload = {
                            categories: select,
                        };
                        if (contact.id && select) {
                            setEditableStr({
                                name: editableStr.name,
                                email: editableStr.email,
                                mobile: editableStr.mobile,
                                mobile_operator: editableStr.mobile_operator,
                                phone: editableStr.phone,
                                note: editableStr.note,
                                categories: select,
                            });
                            handleUpdate(contact.id, payload);
                        }
                    }}
                >
                    {categories.map((category: ContactCategoryProps, index: number) => {
                        return (
                            <>
                                {category.id && (
                                    <Option key={index} value={category.name}>
                                        {category.name}
                                    </Option>
                                )}
                            </>
                        );
                    })}
                </Select>
            </div>

            <div className="grid grid-cols-4 gap-2 m-4">
                <span className="font-semibold">Nombre:</span>
                <Paragraph
                    className="col-span-3 w-full"
                    editable={{
                        tooltip: 'Editar',
                        onChange: (value: string) => {
                            const payload = {
                                name: value,
                            };
                            if (contact.id) {
                                setEditableStr({
                                    name: value,
                                    email: editableStr.email,
                                    mobile: editableStr.mobile,
                                    mobile_operator: editableStr.mobile_operator,
                                    phone: editableStr.phone,
                                    note: editableStr.note,
                                    categories: editableStr.categories,
                                });
                                handleUpdate(contact.id, payload);
                            }
                        },
                    }}
                >
                    {editableStr.name}
                </Paragraph>
            </div>

            <div className="grid grid-cols-4 gap-2 m-4">
                <span className="font-semibold">Email:</span>
                <Paragraph
                    className="col-span-3 w-full"
                    editable={{
                        tooltip: 'Editar',
                        onChange: (value: string) => {
                            const payload = {
                                email: value,
                            };
                            if (contact.id) {
                                setEditableStr({
                                    name: editableStr.name,
                                    email: value,
                                    mobile: editableStr.mobile,
                                    mobile_operator: editableStr.mobile_operator,
                                    phone: editableStr.phone,
                                    note: editableStr.note,
                                    categories: editableStr.categories,
                                });
                                handleUpdate(contact.id, payload);
                            }
                        },
                    }}
                >
                    {editableStr.email}
                </Paragraph>
            </div>

            <div className="grid grid-cols-4 gap-2 m-4">
                <span className="font-semibold">Celular:</span>
                <Paragraph
                    className="col-span-3 w-full"
                    editable={{
                        tooltip: 'Editar',
                        onChange: (value: string) => {
                            const payload = {
                                mobile: value,
                            };
                            if (contact.id) {
                                setEditableStr({
                                    name: editableStr.name,
                                    email: editableStr.email,
                                    mobile: value,
                                    mobile_operator: editableStr.mobile_operator,
                                    phone: editableStr.phone,
                                    note: editableStr.note,
                                    categories: editableStr.categories,
                                });
                                handleUpdate(contact.id, payload);
                            }
                        },
                    }}
                >
                    {editableStr.mobile}
                </Paragraph>
            </div>

            <div className="grid grid-cols-4 gap-2 m-4">
                <span className="font-semibold">Operador:</span>
                <Paragraph
                    className="col-span-3 w-full"
                    editable={{
                        tooltip: 'Editar',
                        onChange: (value: string) => {
                            const payload = {
                                mobile_operator: value,
                            };
                            if (contact.id) {
                                setEditableStr({
                                    name: editableStr.name,
                                    email: editableStr.email,
                                    mobile: editableStr.mobile,
                                    mobile_operator: value,
                                    phone: editableStr.phone,
                                    note: editableStr.note,
                                    categories: editableStr.categories,
                                });
                                handleUpdate(contact.id, payload);
                            }
                        },
                    }}
                >
                    {editableStr.mobile_operator}
                </Paragraph>
            </div>

            <div className="grid grid-cols-4 gap-2 m-4">
                <span className="font-semibold">Teléfono:</span>
                <Paragraph
                    className="col-span-3 w-full"
                    editable={{
                        tooltip: 'Editar',
                        onChange: (value: string) => {
                            const payload = {
                                phone: value,
                            };
                            if (contact.id) {
                                setEditableStr({
                                    name: editableStr.name,
                                    email: editableStr.email,
                                    mobile: editableStr.mobile,
                                    mobile_operator: editableStr.mobile_operator,
                                    phone: value,
                                    note: editableStr.note,
                                    categories: editableStr.categories,
                                });
                                handleUpdate(contact.id, payload);
                            }
                        },
                    }}
                >
                    {editableStr.phone}
                </Paragraph>
            </div>

            <div className="grid grid-cols-4 gap-2 m-4">
                <span className="font-semibold">Nota:</span>
                <Paragraph
                    className="col-span-3 w-full"
                    editable={{
                        tooltip: 'Editar',
                        onChange: (value: string) => {
                            const payload = {
                                note: value,
                            };
                            if (contact.id) {
                                setEditableStr({
                                    name: editableStr.name,
                                    email: editableStr.email,
                                    mobile: editableStr.mobile,
                                    mobile_operator: editableStr.mobile_operator,
                                    phone: editableStr.phone,
                                    note: value,
                                    categories: editableStr.categories,
                                });
                                handleUpdate(contact.id, payload);
                            }
                        },
                    }}
                >
                    {editableStr.note}
                </Paragraph>
            </div>
        </>
    );
};
