import { buildTourItemSelector } from './util';

const CUSTOMERS_ITEMS_IDS = Object.freeze({
    lists: 'c_lists',
    accounts: 'c_accounts',
    addAccount: 'c_add_account_button',
    contacts: 'c_contacts',
    contactsFilter: 'c_contacts_filter',
    addContact: 'c_add_contact_button',
});

const CUSTOMERS_MAIN_TOUR_CONFIG = [
    {
        selector: buildTourItemSelector(CUSTOMERS_ITEMS_IDS.lists),
        content: `Your accounts and contacts can be found in this section.`,
    },
    {
        selector: buildTourItemSelector(CUSTOMERS_ITEMS_IDS.accounts),
        content: `You can see an account's information by clicking 'Show details'. Also, know the contacts belonging to this account by clicking 'See contacts'.`,
    },
    {
        selector: buildTourItemSelector(CUSTOMERS_ITEMS_IDS.addAccount),
        content: `Create a new account bu clicking 'Add account.'`,
    },
];

const CUSTOMERS_CONTACTS_TOUR_CONFIG = [
    {
        selector: buildTourItemSelector(CUSTOMERS_ITEMS_IDS.contacts),
        content: `You can see a contact's information by clicking 'Show details'.`,
    },
    {
        selector: buildTourItemSelector(CUSTOMERS_ITEMS_IDS.contactsFilter),
        content: `Show contacts list filtering by account.`,
    },
    {
        selector: buildTourItemSelector(CUSTOMERS_ITEMS_IDS.addContact),
        content: `Create a new contact bu clicking 'Add contact.'`,
    },
];

export { CUSTOMERS_MAIN_TOUR_CONFIG, CUSTOMERS_CONTACTS_TOUR_CONFIG, CUSTOMERS_ITEMS_IDS };
