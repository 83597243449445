import React, { useEffect } from 'react';
import { Button, Form, Input, InputNumber } from 'antd';
import { Strings } from '../../../utils/strings';
import { LevelProps } from '../types';
import { VscSaveAll } from 'react-icons/vsc';
import { FcCancel } from 'react-icons/fc';

interface Props {
    record?: LevelProps | null;
    handleCreate?: (payload: LevelProps) => void;
    handleUpdate?: (payload: LevelProps, id: number) => void;
    setIsModalVisible: (value: boolean) => void;
}

export const LevelForm = ({ setIsModalVisible, record, handleUpdate, handleCreate }: Props) => {
    const [form] = Form.useForm();

    const onFinish = (values: { position: number; name: string; alias: string; status: boolean }) => {
        const payload = {
            position: values.position,
            name: values.name,
            alias: values.alias,
            status: true,
        };

        if (handleUpdate && record && record.id) {
            handleUpdate(payload, record.id);
        } else if (handleCreate) {
            handleCreate(payload);
        }
        form.resetFields();
    };

    useEffect(() => {
        form.resetFields();
    }, [record]);

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Form
                form={form}
                initialValues={{
                    position: record?.position,
                    name: record?.name,
                    alias: record?.alias,
                    status: record?.status,
                }}
                name="level-form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
            >
                <div className="grid grid-cols-1 gap-4">
                    <Form.Item
                        label="Número del nivel"
                        name="position"
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <InputNumber style={{ width: '100%' }} placeholder="Ejm: 1, 2, 3, etc" />
                    </Form.Item>
                </div>

                <div className="grid grid-cols-1 gap-4">
                    <Form.Item
                        label="Nombre completo"
                        name="name"
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <Input placeholder="Ejm: Segundo Nivel de Confirmación" />
                    </Form.Item>
                </div>

                <div className="grid grid-cols-1 gap-4">
                    <Form.Item
                        label="Nombre secundario"
                        name="alias"
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <Input placeholder="Ejm: Confirmación" />
                    </Form.Item>
                </div>

                <Form.Item>
                    <div className={'mt-4 flex flex-row-reverse'}>
                        <Button
                            className={'mx-2 second'}
                            type="primary"
                            htmlType="submit"
                            icon={<VscSaveAll className="inline-flex mr-1" size={'18px'} />}
                        >
                            {Strings.actions.save}
                        </Button>
                        <Button
                            className={'mx-2 remove'}
                            onClick={() => {
                                form.resetFields();
                                setIsModalVisible(false);
                            }}
                            icon={<FcCancel className="inline-flex mr-1" size={'18px'} />}
                        >
                            {Strings.actions.cancel}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </>
    );
};
