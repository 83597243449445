import React from 'react';
import { Button, DatePicker, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import locale from 'antd/es/date-picker/locale/es_ES';
import { InputNumber } from 'antd/es';
import { useQueryParameters } from '../../../library';

export const MembersFilter = () => {
    const query = useQueryParameters();
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        const params: any = {};

        if (values.full_name) params['full_name'] = values.full_name;
        if (values.parents) params['parents'] = values.parents;
        if (values.card_id) params['card_id'] = values.card_id;
        if (values.birth_date) params['birth_date'] = moment(values.birth_date).format('YYYY-MM-DD');

        const queryString = Object.keys(params)
            .map(key => key + '=' + params[key])
            .join('&');

        navigate(`/catechism/member/?${queryString}`, { replace: true });
    };

    return (
        <div className={'flex justify-start w-full py-6 px-8'}>
            <div className="w-full">
                <Form onFinish={onFinish} layout="horizontal" form={form}>
                    <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <Form.Item label="Integrante" name="full_name" initialValue={query.get('full_name')}>
                            <Input placeholder="Apellidos o nombres" allowClear size={'small'} />
                        </Form.Item>

                        <Form.Item label="Padres" name="parents" initialValue={query.get('parents')}>
                            <Input allowClear size={'small'} />
                        </Form.Item>
                    </div>
                    <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <Form.Item
                            label="F. de Nacimiento"
                            name={'birth_date'}
                            initialValue={
                                query.get('birth_date') ? moment(query.get('birth_date'), 'YYYY-MM-DD') : null
                            }
                        >
                            <DatePicker locale={locale} size="small" style={{ width: '100%' }} />
                        </Form.Item>

                        <Form.Item label="Cédula" initialValue={query.get('card_id')} name={'card_id'}>
                            <InputNumber style={{ width: '100%' }} size={'small'} />
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <div className="flex justify-center space-x-4">
                            <Button className={'second'} type="primary" htmlType={'submit'}>
                                Filtrar Registros
                            </Button>
                            <Button
                                onClick={async () => {
                                    form.resetFields();
                                    navigate(`/catechism/member/`, { replace: true });
                                }}
                            >
                                Limpiar Filtros
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};
