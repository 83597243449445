import React, { useEffect, useState } from 'react';
import { TbUser, TbUsers, TbCross } from 'react-icons/tb';
import CompanyStore from '../../stores/CompanyStore';
import ReportStore from '../../stores/ReportStore';
import { CompanyProps, TotalRecordsProps, Tours } from '../../utils/types';
import { useNavigate } from 'react-router-dom';
import { InfoCompany } from '../../library';
import { notification, Spin } from 'antd';
import TutoModal from '../../library/outlets/TutoModal';
import { Strings } from '../../utils/strings';
import UserStore from '../../stores/UserStore';
// import Tour from 'reactour';
import { useWindowSize } from '../../hooks/useWindowZise';
import { isSmartPhoneBrowser } from '../../utils/common';
import { SCREEN_MOBILE_WIDTH_BREAKPOINT } from '../../utils/constants';
import UIStore from '../../stores/UIStore';
import { observer } from 'mobx-react';
import ErrorRender from '../../library/outlets/ErrorRender';

export const DashboardMainPage = observer(() => {
    const [loading, setLoading] = useState<boolean>(false);
    const [totals, setTotals] = useState<TotalRecordsProps | null>();
    const [company, setCompany] = useState<CompanyProps | null>(null);
    const navigate = useNavigate();
    const [tour, setTour] = useState<Tours | null>(null);
    // const [showTour, setShowTour] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const { width } = useWindowSize();

    async function loadUser() {
        setLoading(true);
        try {
            const user = await UserStore.loadUser();
            if (user) {
                await CompanyStore.getOnlyCompany(user.company.id).then(response => setCompany(response.data));
                await ReportStore.getTotalRecords().then(response => {
                    setTotals(response.data);
                });
                const tour = UserStore.hasTutorial();
                const showThisTour = tour && !isSmartPhoneBrowser();
                if (showThisTour && tour) {
                    setTour(tour[0]);
                    if (width && width < SCREEN_MOBILE_WIDTH_BREAKPOINT) {
                        UIStore.showDrawer();
                    }
                    setShowModal(true);
                }
            }
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    useEffect(() => {
        loadUser();
    }, []);

    const handleCloseModal = () => setShowModal(false);

    const handleStartTour = () => {
        handleCloseModal();
        // setShowTour(true);
    };

    const doNotShowAgain = (tour_id: number) => {
        handleCloseModal();
        UserStore.completedTutorial(tour_id);
    };

    return (
        <Spin spinning={loading}>
            <div className="relative p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
                {totals && (
                    <div className="flex sm:flex-row flex-col sm:space-x-4 flex-row w-full items-center justify-center">
                        <div className="flex flex-wrap flex-row sm:flex-col justify-center items-center w-full sm:w-1/4 p-5 bg-white rounded-md shadow-xl border-l-4 border-purple-300">
                            <div className="flex justify-between w-full">
                                <div>
                                    <div className="p-2">
                                        <TbUser size={40} />
                                    </div>
                                </div>
                                <div>
                                    <div
                                        style={{ paddingTop: '0.1em', paddingBottom: '0.1rem' }}
                                        className="flex items-center text-xs px-3 bg-purple-200 text-purple-800 rounded-full"
                                    >
                                        {(
                                            (totals.total_baptisms * 100) /
                                            (totals.total_baptisms +
                                                totals.total_marriages +
                                                totals.total_proclamations +
                                                totals.total_deaths)
                                        ).toFixed(2)}{' '}
                                        %
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="font-bold text-5xl text-center">{totals.total_baptisms}</div>
                                <div className="font-bold text-sm">Bautizos</div>
                            </div>
                        </div>
                        <div className="flex flex-wrap flex-row sm:flex-col justify-center items-center w-full sm:w-1/4 p-5 bg-white rounded-md shadow-xl border-l-4 border-blue-300">
                            <div className="flex justify-between w-full">
                                <div>
                                    <div className="p-2">
                                        <TbUsers size={40} />
                                    </div>
                                </div>
                                <div>
                                    <div
                                        style={{ paddingTop: '0.1em', paddingBottom: '0.1rem' }}
                                        className="flex items-center text-xs px-3 bg-blue-200 text-blue-800 rounded-full"
                                    >
                                        {(
                                            (totals.total_marriages * 100) /
                                            (totals.total_baptisms +
                                                totals.total_marriages +
                                                totals.total_proclamations +
                                                totals.total_deaths)
                                        ).toFixed(2)}{' '}
                                        %
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="font-bold text-5xl">{totals.total_marriages}</div>
                                <div className="font-bold text-sm">Matrimonios</div>
                            </div>
                        </div>
                        <div className="flex flex-wrap flex-row sm:flex-col justify-center items-center w-full sm:w-1/4 p-5 bg-white rounded-md shadow-xl border-l-4 border-red-300">
                            <div className="flex justify-between w-full">
                                <div>
                                    <div className="p-2">
                                        <TbUsers size={40} />
                                    </div>
                                </div>
                                <div>
                                    <div
                                        style={{ paddingTop: '0.1em', paddingBottom: '0.1rem' }}
                                        className="flex items-center text-xs px-3 bg-red-200 text-red-800 rounded-full"
                                    >
                                        {(
                                            (totals.total_proclamations * 100) /
                                            (totals.total_baptisms +
                                                totals.total_marriages +
                                                totals.total_proclamations +
                                                totals.total_deaths)
                                        ).toFixed(2)}{' '}
                                        %
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="font-bold text-5xl text-center">{totals.total_proclamations}</div>
                                <div className="font-bold text-sm">Proclamas</div>
                            </div>
                        </div>
                        <div className="flex flex-wrap flex-row sm:flex-col justify-center items-center w-full sm:w-1/4 p-5 bg-white rounded-md shadow-xl border-l-4 border-green-300">
                            <div className="flex justify-between w-full">
                                <div>
                                    <div className="p-2">
                                        <TbCross size={40} />
                                    </div>
                                </div>
                                <div>
                                    <div
                                        style={{ paddingTop: '0.1em', paddingBottom: '0.1rem' }}
                                        className="flex items-center text-xs px-3 bg-green-200 text-green-800 rounded-full"
                                    >
                                        {(
                                            (totals.total_deaths * 100) /
                                            (totals.total_baptisms +
                                                totals.total_marriages +
                                                totals.total_proclamations +
                                                totals.total_deaths)
                                        ).toFixed(2)}{' '}
                                        %
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="font-bold text-5xl text-center">{totals.total_deaths}</div>
                                <div className="font-bold text-sm">Defunciones</div>
                            </div>
                        </div>
                    </div>
                )}

                {company !== null && <InfoCompany company={company} />}

                {tour && (
                    <TutoModal
                        visible={showModal}
                        onCloseModal={handleCloseModal}
                        onClickButton={handleStartTour}
                        doNotShowAgain={() => tour.id && doNotShowAgain(tour.id)}
                        title={tour.title}
                        description={tour.description}
                        buttonText={Strings.actions.startTutorial}
                    />
                )}
                {/*<Tour*/}
                {/*    onRequestClose={handleCloseTour}*/}
                {/*    onBeforeClose={handleSaveTourAsShowed}*/}
                {/*    steps={SIGNATURE_TOUR_CONFIG}*/}
                {/*    isOpen={showTour}*/}
                {/*/>*/}
            </div>
        </Spin>
    );
});
