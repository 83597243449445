import React from 'react';
import { Routes, Route } from 'react-router';
import { AuthProvider } from './context/Auth';
import { RequireAuth } from './pages/RequireAuth';
import PublicLayout from './layouts/PublicLayout';
import publicRoutes from './routes/public.routes';
import { LoginPage } from './pages/authentication';
import { RouteProps } from './utils/types';
import 'antd/dist/antd.min.css';
import { PrivateLayout } from './layouts';
import { Navigate } from 'react-router-dom';
import privateRoutes from './routes/private.routes';
import { DashboardMainPage } from './pages/dashboards';
import E404Page from './pages/E404Page';

function App() {
    return (
        <AuthProvider>
            <Routes>
                <Route path="/" element={<PrivateLayout />}>
                    <Route index element={<Navigate to="dashboard" />} />
                    <Route
                        path="dashboard"
                        element={
                            <RequireAuth>
                                <DashboardMainPage />
                            </RequireAuth>
                        }
                    />

                    {privateRoutes.map(({ path, Component, Layout }: RouteProps, index) => (
                        <Route
                            key={index}
                            path={path}
                            element={
                                <RequireAuth>
                                    <Component />
                                </RequireAuth>
                            }
                        />
                    ))}
                </Route>

                <Route path="/" element={<PublicLayout />}>
                    <Route index element={<LoginPage />} />

                    {publicRoutes.map(({ path, Component, Layout }: RouteProps, index) => (
                        <Route key={index} path={path} element={<Component />} />
                    ))}
                </Route>

                <Route path="*" element={<E404Page />} />
            </Routes>
        </AuthProvider>
    );
}
export default App;
