import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Spin, notification } from 'antd';
import { isMobile } from 'react-device-detect';
import { BaptismForm } from '../forms';
import { BaptismTable } from '../tables';
import { BooksFilter } from '../filters';
import BaptismStore from '../stores/BaptismStore';
import { CertificatePreview, PageHeaderBooks } from '../../../library';
import ErrorRender from '../../../library/outlets/ErrorRender';
import { requests } from '../../../utils/api';
import { BaptismProps } from '../types';
import { toastError, toastSuccess } from '../../../utils/toast';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const BaptismsPage = () => {
    const searchParams = useQuery();
    const [loading, setLoading] = useState<boolean>(false);
    const [stage, setStage] = useState<'create' | 'update' | 'list'>('list');
    const [baptism, setBaptism] = useState<BaptismProps | null>(null);
    const [drawerCertificate, setDrawerCertificate] = useState<boolean>(false);
    const [fileURL, setFileURL] = useState('');

    async function handleListBaptisms(params = {}) {
        setLoading(true);
        try {
            await BaptismStore.list(params);
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    useEffect(() => {
        handleListBaptisms(searchParams).then(() => console.log('Load baptisms with filters'));
    }, [location.search]);

    async function handleCreate(payload: BaptismProps) {
        setLoading(true);
        try {
            await BaptismStore.create(payload);
            setLoading(false);
            toastSuccess('Bautizo registrado!');
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function handleUpdate(payload: BaptismProps) {
        setLoading(true);
        try {
            await BaptismStore.patch(payload, payload.id);
            toastSuccess('Bautizo actualizado!');
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function handleRemove(id: number) {
        setLoading(true);
        try {
            await BaptismStore.remove(id);
            toastSuccess('Bautizo eliminado!');
            setStage('list');
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function getFileURL(id: number, template: string) {
        setLoading(true);
        setDrawerCertificate(true);
        try {
            requests
                .get2(`/staff/books/baptism/${id}/certificate/${template}`, { responseType: 'blob' })
                .then((response: any) => {
                    if (isMobile) {
                        return setFileURL('');
                    } else {
                        const file = new Blob([response], { type: 'application/pdf' });
                        const fileURL2 = URL.createObjectURL(file);
                        setFileURL(fileURL2);
                    }
                })
                .catch(err => {
                    toastError('No se pudo firmar el certificado, certificado o contraseña inválidos');
                    setDrawerCertificate(false);
                })
                .finally(() => setLoading(false));
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    return (
        <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
            <Spin spinning={loading} tip={'Loading...'}>
                <PageHeaderBooks
                    setStage={(stage: 'create' | 'update' | 'list') => {
                        setBaptism(null);
                        setStage(stage);
                    }}
                    stage={stage}
                    title={stage === 'list' ? 'Bautizos' : stage === 'create' ? 'Nuevo Bautizo' : 'Actualizar Bautizo'}
                    titleButton={
                        stage === 'list' ? 'Nuevo Bautizo' : stage === 'create' ? 'Ver Bautizos' : 'Ver Bautizos'
                    }
                />

                {stage === 'list' ? (
                    <>
                        <BooksFilter path={'/books/baptisms'} />
                        <BaptismTable
                            loading={loading}
                            handleRemove={handleRemove}
                            setBaptism={(baptism: BaptismProps) => {
                                setBaptism(baptism);
                                setStage('update');
                            }}
                            handlePreviewCertificate={getFileURL}
                        />
                    </>
                ) : stage === 'create' ? (
                    <div className={'py-6'}>
                        <BaptismForm
                            type={'create'}
                            handleCreate={async (payload: BaptismProps, list: boolean) => {
                                await handleCreate(payload);
                                if (list) {
                                    await handleListBaptisms();
                                    setStage('list');
                                }
                            }}
                        />
                    </div>
                ) : (
                    stage === 'update' &&
                    baptism !== null && (
                        <div className={'py-6'}>
                            <BaptismForm
                                type={'update'}
                                baptism={baptism}
                                handleUpdate={async (payload: BaptismProps, list: boolean) => {
                                    await handleUpdate(payload);
                                    if (list) {
                                        await handleListBaptisms();
                                        setStage('list');
                                    }
                                }}
                                handleRemove={handleRemove}
                                handlePreviewCertificate={getFileURL}
                            />
                        </div>
                    )
                )}

                {drawerCertificate && (
                    <CertificatePreview
                        loading={loading}
                        handleClose={() => {
                            setFileURL('');
                            setDrawerCertificate(false);
                        }}
                        fileUrl={fileURL}
                    />
                )}
            </Spin>
        </div>
    );
};
