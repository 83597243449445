import { makeObservable, observable, runInAction } from 'mobx';
import { get, patch, post, remove } from '../../../utils/api';
import { ProclamationProps } from '../types';

class ProclamationStore {
    proclamations: ProclamationProps[] = [];
    proclamation: ProclamationProps | null = null;
    pagination: { defaultPageSize: number; total: number } = { defaultPageSize: 20, total: 0 };

    constructor() {
        makeObservable(this, {
            proclamations: observable,
            proclamation: observable,
            pagination: observable,
        });
    }

    async get(id: number) {
        const response = await get(`/staff/books/proclamation/${id}`);
        runInAction(() => {
            this.proclamation = response.data;
        });
    }

    async list(params = {}) {
        const response = await get(`/staff/books/proclamation/`, params);
        runInAction(() => {
            this.proclamations = response.data.results;
            this.pagination = {
                defaultPageSize: 20,
                total: response.data.count,
            };
        });
        return response.data.results;
    }

    async create(payload: ProclamationProps) {
        const response = await post(`/staff/books/proclamation/`, payload);
        runInAction(() => {
            this.proclamations.push(response.data);
        });
        return response.data;
    }

    async patch(payload = {}, id?: number) {
        const response = await patch(`/staff/books/proclamation/${id}/`, payload);
        runInAction(() => {
            this.proclamation = response.data;
            this.list();
        });
        return response.data;
    }

    async remove(id: number) {
        return await remove(`/staff/books/proclamation/${id}`).then(() => {
            this.list();
        });
    }
}

export default new ProclamationStore();
