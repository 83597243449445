import React from 'react';

type StepItem = {
    title?: string;
    description: string;
};

interface TutoStepProps {
    title: string;
    description?: string;
    items?: Array<StepItem>;
    children?: React.ReactNode;
    action?: string;
}

const TutoStep = ({ title, description, items, children, action }: TutoStepProps) => (
    <>
        <div className={'text-sm font-semibold'}>{title}</div>
        {items && items.length > 0 && (
            <>
                {items.map(({ title, description }, index) => (
                    <div key={`ik-${index + 1}`} className={'text-xs mt-2'}>
                        {title && <strong>{title}&nbsp;</strong>}
                        {description}
                    </div>
                ))}
            </>
        )}
        {description && <div className={'text-xs mb-1'}>{description}</div>}
        {action && <div className={'text-xs text-gray-400'}>{action}</div>}
        {children}
    </>
);

export default TutoStep;
