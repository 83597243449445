import React from 'react';
import { Popconfirm, Typography } from 'antd';
import { ContactCategoryProps } from '../types';
import { RiDeleteBin2Fill, RiUser3Line } from 'react-icons/ri';

interface Props {
    categories: Array<ContactCategoryProps>;
    handleRemove: (id: number) => void;
}

export const CategoryCard = (props: Props) => {
    return (
        <>
            {props.categories.map(category => (
                <article key={category.id} className="bg-white border-2 border-gray-100 rounded-xl">
                    <div className="flex items-start p-4">
                        <div>
                            <RiUser3Line style={{ fontSize: '40px' }} />
                        </div>

                        <div className="ml-4">
                            <div className="flex items-center gap-4">
                                <strong className="font-semibold sm:text-lg xl:text-sm ml-4">{category.name}</strong>
                                <Popconfirm
                                    title={'Eliminar categoría?'}
                                    onConfirm={() => category.id && props.handleRemove(category.id)}
                                    okText={'Eliminar'}
                                    cancelText={'Cancelar'}
                                >
                                    <Typography.Link>
                                        <RiDeleteBin2Fill
                                            className="cursor-pointer transform hover:scale-150 duration-75 ml-1"
                                            color="#DE3E1B"
                                            title={'Eliminar'}
                                        />
                                    </Typography.Link>
                                </Popconfirm>
                            </div>
                        </div>
                    </div>
                </article>
            ))}
        </>
    );
};
