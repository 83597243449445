import React, { useState } from 'react';
import { Button, Input, Form } from 'antd';
import { ContactCategoryProps } from '../types';
import { Strings } from '../../../utils/strings';
import { VscSaveAll, VscSaveAs } from 'react-icons/vsc';
import { FcCancel } from 'react-icons/fc';

interface Props {
    setStage: (value: 'list') => void;
    handleCreate: (payload: ContactCategoryProps, list: boolean) => void;
}

export const CategoryCreateForm = ({ setStage, handleCreate }: Props) => {
    const [form] = Form.useForm();
    const [list, setList] = useState(false);

    const onFinish = (values: ContactCategoryProps) => {
        const payload = {
            name: values.name,
        };
        handleCreate(payload, list);
        form.resetFields();
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            form={form}
            name="contact-category-form"
            layout={'vertical'}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <div className="grid grid-cols-2 gap-4">
                <Form.Item
                    label="Nombre de categoría"
                    name="name"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input placeholder={'Catequistas'} />
                </Form.Item>
            </div>

            <Form.Item>
                <div className={'flex flex-row space-x-4'}>
                    <Button
                        className="second"
                        type="primary"
                        htmlType="submit"
                        icon={<VscSaveAs className="inline-flex mr-1" size={'18px'} />}
                        onClick={() => setList(false)}
                    >
                        Guardar
                    </Button>
                    <Button
                        className={'second'}
                        type="primary"
                        htmlType="submit"
                        icon={<VscSaveAll className="inline-flex mr-1" size={'18px'} />}
                        onClick={() => setList(true)}
                    >
                        Guardar y ver registros
                    </Button>
                    <Button
                        className={'mx-2 remove'}
                        onClick={() => {
                            form.resetFields();
                            setStage('list');
                        }}
                        icon={<FcCancel className="inline-flex mr-1" size={'18px'} />}
                    >
                        {Strings.actions.cancel}
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
};
