import React from 'react';
import { Button, Modal } from 'antd';

interface TutoModalProps {
    visible: boolean;
    onCloseModal: () => void;
    onClickButton: () => void;
    title: string;
    description: string;
    buttonText: string;
    doNotShowAgain: () => void;
}

const TutoModal = (props: TutoModalProps) => {
    return (
        <Modal closable footer={null} visible={props.visible} onCancel={props.onCloseModal}>
            <div className="flex flex-col items-center">
                <div className={'text-lg text-gray-500 font-semibold'}>{props.title}</div>
                <div className={'text-gray-500 mt-4'}>{props.description}</div>
                <div className={'text-gray-500 mt-2 mb-4 justify-start'}>
                    Antes de continuar por favor refresque el sistema:{' '}
                    <Button className={'second'} type="link" onClick={() => location.reload()}>
                        Aquí
                    </Button>
                </div>
                <div className={'flex flex-row space-x-4'}>
                    <Button className={'second'} type="primary" onClick={props.onClickButton}>
                        Aceptar
                    </Button>
                    <Button className={'second'} type="default" onClick={props.doNotShowAgain}>
                        No volver a mostrar este mensaje
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default TutoModal;
