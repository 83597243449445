import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import axios from 'axios';
import { Tours, UserProps } from '../utils/types';
import { get, post } from '../utils/api';
import { API_URL } from '../utils/constants';

class UserStore {
    errors?: any;
    isLoading = true;
    token: string | null;
    user: UserProps | null = null;
    tutorials: Tours[] = [];

    constructor() {
        makeObservable(this, {
            isLoading: observable,
            token: observable,
            user: observable,
            login: action,
            logout: action,
            isAuthenticated: computed,
            is_verified_email: computed,
            tutorials: observable,
            hasTutorial: action,
            completedTutorial: action,
        });

        this.token = localStorage.getItem('token');
    }

    getToken() {
        runInAction(() => (this.token = localStorage.getItem('token')));
    }

    async loadUser() {
        await get(`rest-auth/user/`)
            .then((response: any) => {
                runInAction(() => {
                    this.user = response.data;
                    this.tutorials = response.data.tours;
                });
            })
            .catch((err: any) => {
                runInAction(() => {
                    this.errors = err;
                });
                this.user = null;
                localStorage.removeItem('token');
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false;
                });
            });
        return this.user;
    }

    get isAuthenticated() {
        return this.user?.id;
    }

    get is_verified_email() {
        return this.user?.is_verified_email;
    }

    async login(email: string, password: string) {
        const response = await axios.post(
            API_URL + '/rest-auth/login/',
            {
                email: email,
                password: password,
            },
            {
                withCredentials: false,
            }
        );

        if (response) {
            localStorage.setItem('token', response.data.token);
            await this.loadUser();
            return response;
        }
        return false;
    }

    async resetPassword(payload: { email: string }) {
        const response = await post(`/rest-auth/password/reset/`, payload);
        if (response) {
            return response;
        }
    }

    async logout() {
        await post('rest-auth/logout/', {}).then(async () => {
            await runInAction(() => {
                this.user = null;
                localStorage.removeItem('token');
            });
        });
    }

    hasTutorial() {
        return this.tutorials.filter(tour => !tour.finished);
    }

    async completedTutorial(tour_id: number) {
        await post(`/auth/status-tour`, {
            tour: tour_id,
        });
    }
}

export default new UserStore();
