import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
    active: string;
}

export const MenuTop = (props: Props) => {
    const navigate = useNavigate();
    const style_active_tab =
        'inline-block p-4 text-alpes_green-600 rounded-t-lg border-b-2 border-alpes_green-600 active';
    const style_tab =
        'inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300';

    return (
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 mb-4">
            <ul className="flex flex-wrap -mb-px">
                <li className="mr-2">
                    <div
                        className={props.active === 'contacts' ? style_active_tab : style_tab}
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('/contacts', { replace: true })}
                    >
                        Contactos
                    </div>
                </li>
                <li className="mr-2">
                    <div
                        className={props.active === 'categories' ? style_active_tab : style_tab}
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('/contacts/category', { replace: false })}
                    >
                        Categorias
                    </div>
                </li>
            </ul>
        </div>
    );
};
