import React from 'react';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { buildTourItemSelector } from './util';
import TutoStep from '../../library/outlets/TutoStep';

const QUOTES_ITEMS_IDS = Object.freeze({
    list: 'q_list',
    filters: 'q_filters',
    button: 'q_button',
    qd_none: 'qd_none',
    qd_firstItem: 'qd_first_item',
    qid_preview: 'qid_preview',
    qid_switch: 'qid_switch',
    qid_matrix: 'qid_matrix',
    qid_itemSpec: 'qdi_specifications',
    qid_internalProcess: 'qdi_int_process',
    qdi_none: 'qdi_none',
});

/** HIDED FOR PRESENTATIONAL PURPOSE
 {
    selector: buildTourItemSelector(QUOTES_ITEMS_IDS.filters),
    content: `Your quotes can be searched by name, dates, status or accounts.`
  },
 */
const QUOTES_TOUR_CONFIG = [
    {
        selector: buildTourItemSelector(QUOTES_ITEMS_IDS.list),
        content: () => (
            <TutoStep
                title="Pending and Completed Quotes List"
                description="On the Quotes page you can view pending and completed quotes, or use the 'Create Quote' button to start a new one."
            />
        ),
    },
    {
        selector: buildTourItemSelector(QUOTES_ITEMS_IDS.button),
        content: () => (
            <TutoStep
                title="Open Pending Quote"
                description="To see how the quoting process works, we have created a sample quote for you. Please click the 'Show Details' button to go on to next steps."
            />
        ),
    },
];

const QUOTES_DETAIL_TOUR_CONFIG = [
    {
        selector: buildTourItemSelector(QUOTES_ITEMS_IDS.qd_none),
        content: () => (
            <TutoStep
                title="Quote Details Page"
                description="The quote details page will display the Account and Customer info along with the parts on the quote."
            />
        ),
    },
    {
        selector: buildTourItemSelector(QUOTES_ITEMS_IDS.qd_firstItem),
        content: () => (
            <TutoStep
                title="Part Details"
                description="We have a sample part loaded on this quote that has already been finished for you. Click the 'Quote Part' button on the part to go into the Quote Matrix to see how the pricing is calculated for this part."
            />
        ),
    },
];

const QUOTES_ITEM_TOUR_CONFIG = [
    {
        selector: buildTourItemSelector(QUOTES_ITEMS_IDS.qid_preview),
        content: () => (
            <TutoStep
                title="Part Files"
                description="The left side of the quote tool will display the 2D and 3D files uploaded for the part."
            />
        ),
    },
    {
        selector: buildTourItemSelector(QUOTES_ITEMS_IDS.qid_switch),
        content: () => (
            <TutoStep title="Switch Previews" description="You can toggle between 2D and 3D using the buttons." />
        ),
    },
    {
        selector: buildTourItemSelector(QUOTES_ITEMS_IDS.qid_matrix),
        content: () => (
            <TutoStep
                title="Quote Matrix"
                description="The right portion of the quote tool contains the Quote Matrix where you will modify the process details to calculate price."
            />
        ),
    },
    {
        selector: buildTourItemSelector(QUOTES_ITEMS_IDS.qid_itemSpec),
        content: () => (
            <TutoStep
                title="Material, Coating and Finish"
                description="This section is for selecting the requested material, coating and finish options. The pricing for these will be entered into the External Processes table in the matrix."
            />
        ),
    },
    {
        selector: buildTourItemSelector(QUOTES_ITEMS_IDS.qid_internalProcess),
        content: () => (
            <TutoStep
                title="Internal Process Matrix"
                description="This is where the magic happens. To calculate pricing for the part, you will enter in the amount of time associated with each process. The formula will calculate cost based off of the per hour cost associated with each process from the Quoting Rates table. You can also add new processes here by selecting the Add New option at the bottom of the table."
            >
                <p>
                    To add a new quantity, click the <PlusOutlined /> icon.
                </p>
                <p>
                    To edit a quoting rate, click the <EditOutlined /> icon that appears when you are clicked into any
                    of the processes.
                </p>
            </TutoStep>
        ),
    },
    {
        selector: buildTourItemSelector(QUOTES_ITEMS_IDS.qdi_none),
        content: () => (
            <TutoStep title="Time to start quoting!">
                <p>
                    We encourage you to play around with the sample quote to get used to the Quote Matrix. When you are
                    ready, click the <Button type="primary">Save and Exit</Button> button in the bottom right corner
                </p>
                <p>
                    To start a new quote from scratch, you can use the <Button>Create quote</Button> button located in
                    the top right corner of the header bar.
                </p>
            </TutoStep>
        ),
    },
];

export { QUOTES_TOUR_CONFIG, QUOTES_DETAIL_TOUR_CONFIG, QUOTES_ITEM_TOUR_CONFIG, QUOTES_ITEMS_IDS };
