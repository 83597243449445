import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import CompanyStore from '../../stores/CompanyStore';
import { notification, Switch } from 'antd';
import ErrorRender from '../outlets/ErrorRender';
import { toastInfo, toastWarnig } from '../../utils/toast';
import UserStore from '../../stores/UserStore';

export const SwitchLogos = observer(() => {
    const [logo, setLogo] = useState<boolean>(CompanyStore.company !== null && CompanyStore.company.is_logo);
    const [companyId, setCompanyId] = useState<number | undefined>(undefined);

    async function onChangeLogo(checked: any) {
        try {
            if (companyId !== undefined) {
                await CompanyStore.patch(
                    {
                        is_logo: checked,
                    },
                    companyId
                );
            }

            if (CompanyStore.company?.is_logo) {
                toastInfo('Generar documentos con logotipos');
            } else {
                toastWarnig('Generar documentos sin logotipos');
            }
            setLogo(CompanyStore.logos);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
    }

    async function getCompany() {
        try {
            await UserStore.loadUser();
            if (UserStore.user) {
                await CompanyStore.get(UserStore.user.company.id);
                setCompanyId(UserStore.user.company.id);
                setLogo(CompanyStore.logos);
            }
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
    }

    useEffect(() => {
        getCompany();
    }, []);

    return (
        <div title={'On: Con logotipos. \n Off: Sin logotipos'} className={'font-semibold text-sm'}>
            Logotipos: Off <Switch size={'small'} onChange={onChangeLogo} checked={logo} /> On
        </div>
    );
});
