import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import ReportStore from '../../../stores/ReportStore';
import { notification, Spin } from 'antd';
import ErrorRender from '../../../library/outlets/ErrorRender';
import { PageHeaderReports } from '../../../library';
import { ReportBaptismFilter } from './filters';
import { toastSuccess } from '../../../utils/toast';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const BooksBaptismsReportPage = observer(() => {
    const searchParams = useQuery();
    const [loading, setLoading] = useState<boolean>(false);

    const getReports = async (params = {}) => {
        setLoading(true);
        await ReportStore.getBaptisms(params)
            .then(() => toastSuccess('Reporte generado correctamente'))
            .catch((err: any) => {
                notification.error({
                    message: <ErrorRender error={err} />,
                    placement: 'topRight',
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (location.search !== '') {
            getReports(searchParams);
        }
    }, [location.search]);

    return (
        <>
            <div className="relative bg-white p-4 sm:p-6 rounded-xl overflow-hidden mb-8">
                <Spin spinning={loading}>
                    <PageHeaderReports
                        title={'Bautizos por edades'}
                        subtitle={'Seleccione una rango de fechas y edades'}
                    />
                    <ReportBaptismFilter
                        path={'/reports-books/baptisms'}
                        reset={async () => {
                            console.log('reset');
                            await getReports();
                        }}
                    />
                </Spin>
            </div>

            <div>
                <Spin spinning={loading}>
                    <section className="text-gray-600 body-font flex justify-center items-center">
                        <div className="container px-5 py-5 mx-auto">
                            <div className="flex flex-wrap -m-4 text-center">
                                <div className="p-4 sm:w-1/2 lg:w-1/3 w-full hover:scale-105 duration-500">
                                    <div className=" flex items-center  justify-between p-4  rounded-lg bg-white shadow-indigo-50 shadow-md">
                                        <div>
                                            <span className="text-lg">Total Bautizos</span>
                                            <h3 className="mt-2 text-xl font-bold text-yellow-500 text-left">
                                                {ReportStore.baptisms.total}
                                            </h3>
                                            <p className="text-sm font-semibold text-gray-400">
                                                Promedio: {ReportStore.baptisms.total_average} años
                                            </p>
                                        </div>
                                        <div>
                                            <div>
                                                <h1 className="text-gray-400 text-2xl">
                                                    {ReportStore.baptisms.total_percent}%
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-4 sm:w-1/2 lg:w-1/3 w-full hover:scale-105 duration-500">
                                    <div className=" flex items-center  justify-between p-4  rounded-lg bg-white shadow-indigo-50 shadow-md">
                                        <div>
                                            <span className="text-lg">Bautizos Hombres</span>
                                            <h3 className="mt-2 text-xl font-bold text-cyan-500 text-left">
                                                {ReportStore.baptisms.total_man}
                                            </h3>
                                            <p className="text-sm font-semibold text-gray-400">
                                                Promedio: {ReportStore.baptisms.total_average_man} años
                                            </p>
                                        </div>
                                        <div>
                                            <div>
                                                <h1 className="text-gray-400 text-2xl">
                                                    {ReportStore.baptisms.total_percent_man} %
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-4 sm:w-1/2 lg:w-1/3 w-full hover:scale-105 duration-500">
                                    <div className=" flex items-center  justify-between p-4  rounded-lg bg-white shadow-indigo-50 shadow-md">
                                        <div>
                                            <span className="text-lg">Bautizos Mujeres</span>
                                            <h3 className="mt-2 text-xl font-bold text-pink-500 text-left">
                                                {ReportStore.baptisms.total_woman}
                                            </h3>
                                            <p className="text-sm font-semibold text-gray-400">
                                                Promedio: {ReportStore.baptisms.total_average_woman} años
                                            </p>
                                        </div>
                                        <div>
                                            <div>
                                                <h1 className="text-gray-400 text-2xl">
                                                    {ReportStore.baptisms.total_percent_woman} %
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-4 sm:w-1/2 lg:w-1/3 w-full hover:scale-105 duration-500">
                                    <div className=" flex items-center  justify-between p-4  rounded-lg bg-white shadow-indigo-50 shadow-md">
                                        <div>
                                            <span className="text-lg">Bautizos no cálculados</span>
                                            <h3 className="mt-2 text-xl font-bold text-red-600 text-left">
                                                {ReportStore.baptisms.baptism_without_ages}
                                            </h3>
                                            <p className="text-sm font-semibold text-gray-400">
                                                Sin fecha de nacimiento
                                            </p>
                                        </div>
                                        <div>
                                            <div>
                                                <h1 className="text-red-600 text-2xl">
                                                    {ReportStore.baptisms.baptism_without_ages_percent} %
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-4 sm:w-1/2 lg:w-1/3 w-full hover:scale-105 duration-500">
                                    <div className=" flex items-center  justify-between p-4  rounded-lg bg-white shadow-indigo-50 shadow-md">
                                        <div>
                                            <span className="text-lg">Bautizos sin género</span>
                                            <h3 className="mt-2 text-xl font-bold text-red-600 text-left">
                                                {ReportStore.baptisms.gender_none_total}
                                            </h3>
                                            <p className="text-sm font-semibold text-gray-400">Sin género asignado</p>
                                        </div>
                                        <div>
                                            <div>
                                                <h1 className="text-red-600 text-2xl">
                                                    {ReportStore.baptisms.gender_none_total_percent} %
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Spin>
            </div>
        </>
    );
});
