import React from 'react';
import { Popconfirm, Space, Typography } from 'antd';
import { CommunityProps } from '../types';
import { Spin } from 'antd/es';
import { RiDeleteBin2Fill } from 'react-icons/ri';

interface Props {
    loading: boolean;
    records: Array<CommunityProps>;
    handleRemove?: (id: number) => void;
    showActions: boolean;
}

export const CommunitiesTable = (props: Props) => {
    return (
        <Spin spinning={props.loading}>
            <div className="overflow-x-auto relative">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="py-3 px-6">
                                #
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Zona
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Comunidad
                            </th>
                            <th scope="col" className="py-3 px-6">
                                <span className="sr-only">Edit</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.records.map((record: CommunityProps, index: number) => {
                            return (
                                <tr
                                    key={index}
                                    className={
                                        index % 2 == 0
                                            ? 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'
                                            : 'bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700'
                                    }
                                >
                                    <th
                                        scope="row"
                                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        {++index}
                                    </th>
                                    <td className="py-4 px-6">
                                        {typeof record.zone === 'object' ? record.zone.name : ''}
                                    </td>
                                    <td className="py-4 px-6">
                                        <div>{record.name}</div>
                                        <div className={'text-xs text-gray-400'}>
                                            <span className={'font-semibold'}>Des: </span>
                                            {record.description}
                                        </div>
                                    </td>
                                    <td className="py-4 px-6">
                                        <Space size="middle" className="px-2">
                                            <Popconfirm
                                                title={'Eliminar comunidad?'}
                                                onConfirm={() =>
                                                    record.id && props.handleRemove && props.handleRemove(record.id)
                                                }
                                                okText={'Eliminar'}
                                                cancelText={'Cancelar'}
                                            >
                                                <Typography.Link>
                                                    <RiDeleteBin2Fill
                                                        className="cursor-pointer transform hover:scale-150 duration-75 ml-1"
                                                        color="#DE3E1B"
                                                        title={'Eliminar'}
                                                    />
                                                </Typography.Link>
                                            </Popconfirm>
                                        </Space>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </Spin>
    );
};
