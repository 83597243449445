import React, { useEffect, useState } from 'react';
import { notification, Spin } from 'antd';
import { PageHeader } from '../../../library';
import { SubAreasTable } from '../tables';
import { SubareaProps } from '../types';
import { useLocation } from 'react-router-dom';
import SubareaStore from '../stores/SubareaStore';
import ErrorRender from '../../../library/outlets/ErrorRender';
import { toastSuccess } from '../../../utils/toast';
import { SubareaForm } from '../forms';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const SubareaPage = () => {
    const searchParams = useQuery();
    const [isLoading, setIsLoading] = useState(false);
    const [stage, setStage] = useState<'create' | 'update' | 'list'>('list');
    const [subarea, setSubarea] = useState<SubareaProps | null>(null);

    async function handleList(params = {}) {
        setIsLoading(true);
        try {
            await SubareaStore.list(params);
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    useEffect(() => {
        handleList(searchParams);
    }, [location.search]);

    async function handleCreate(payload: SubareaProps) {
        setIsLoading(true);
        try {
            await SubareaStore.create(payload);
            toastSuccess('Subárea registrada!');
            await handleList();
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    async function handleUpdate(payload: SubareaProps, id: number) {
        setIsLoading(true);
        try {
            await SubareaStore.patch(payload, id);
            toastSuccess('Subárea actualizada!');
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    async function handleRemove(id: number) {
        setIsLoading(true);
        try {
            await SubareaStore.remove(id);
            toastSuccess('Subárea eliminada!');
            setIsLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
                <Spin spinning={isLoading}>
                    <PageHeader
                        setStage={(stage: 'create' | 'update' | 'list') => {
                            setSubarea(null);
                            setStage(stage);
                        }}
                        stage={stage}
                        title={
                            stage === 'list' ? 'Subáreas' : stage === 'create' ? 'Nueva subárea' : 'Actualizar subárea'
                        }
                        subtitle={'Subáreas de la parroquia'}
                        titleButton={
                            stage === 'list' ? 'Nueva subárea' : stage === 'create' ? 'Ver subáreas' : 'Ver subáreas'
                        }
                    />
                    {stage === 'list' ? (
                        <div className={'pt-4'}>
                            <Spin spinning={isLoading}>
                                <SubAreasTable
                                    loading={isLoading}
                                    handleRemove={handleRemove}
                                    setRecord={(record: SubareaProps) => {
                                        setSubarea(record);
                                        setStage('update');
                                    }}
                                />
                            </Spin>
                        </div>
                    ) : stage === 'create' ? (
                        <div className={'py-6'}>
                            <SubareaForm
                                type={'create'}
                                handleCreate={async (payload: SubareaProps, list: boolean) => {
                                    await handleCreate(payload);
                                    if (list) {
                                        await handleList();
                                        setStage('list');
                                    }
                                }}
                            />
                        </div>
                    ) : (
                        stage === 'update' &&
                        subarea !== null && (
                            <div className={'py-6'}>
                                <SubareaForm
                                    type={'update'}
                                    subarea={subarea}
                                    handleUpdate={async (payload: SubareaProps, list: boolean, id: number) => {
                                        await handleUpdate(payload, id);
                                        if (list) {
                                            await handleList();
                                            setStage('list');
                                        }
                                    }}
                                />
                            </div>
                        )
                    )}
                </Spin>
            </div>
        </>
    );
};
