import React, { useEffect, useState } from 'react';
import { MenuTop as CatechismMenuTop } from '../MenuTop';
import { Button, Card, Modal, notification } from 'antd';
import PeriodStore from '../stores/PeriodStore';
import { PeriodProps } from '../types';
import { observer } from 'mobx-react';
import moment from 'moment/moment';
import ErrorRender from '../../../library/outlets/ErrorRender';
import { PeriodsTable } from '../tables';
import { CreateForms } from '../CreateForms';
import { toastSuccess } from '../../../utils/toast';
import { SiAddthis } from 'react-icons/si';

export const CatechismPeriodPage = observer(() => {
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showModal, setShowModal] = useState<{ title: string; visible: boolean; type: string } | null>(null);

    async function handleList() {
        setLoading(true);
        try {
            await PeriodStore.list();
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function handleCreatePeriod(values: { start_date: string; end_date: string }) {
        const period = moment(values.start_date).format('YYYY') + ' - ' + moment(values.end_date).format('YYYY');
        const payload: PeriodProps = {
            year_start: Number(moment(values.start_date).format('YYYY')),
            year_end: Number(moment(values.end_date).format('YYYY')),
            date_start: values.start_date,
            date_end: values.end_date,
            period: period,
            status: false,
        };
        setLoading(true);
        try {
            await PeriodStore.create(payload);
            await handleList();
            setIsModalVisible(false);
            setLoading(false);
            toastSuccess('Período registrado!');
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    useEffect(() => {
        getPeriodActive();
        handleList();
    }, []);

    async function handleRemove(id: number) {
        setLoading(true);
        try {
            await PeriodStore.remove(id);
            toastSuccess('Período Eliminado!');
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function getPeriodActive() {
        try {
            await PeriodStore.get_active();
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
    }

    return (
        <>
            <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
                <CatechismMenuTop active={'period'} />
                <div className={'my-8'}>
                    <Card
                        bordered={false}
                        size="small"
                        title={
                            <>
                                <span className="font-bold">Periodos</span> Registrados.
                            </>
                        }
                        extra={
                            <div className={'flex flex-row'}>
                                <div className={'mr-5 mt-1.5'}>
                                    {PeriodStore.period_active !== null && (
                                        <span>
                                            <b>Período activo: </b>
                                            {PeriodStore.period_active.year}
                                        </span>
                                    )}
                                </div>
                                <Button
                                    type={'link'}
                                    onClick={() => {
                                        setShowModal({ title: 'Nuevo Período', type: 'FormPeriod', visible: true });
                                        setIsModalVisible(true);
                                    }}
                                >
                                    <div className={'flex flex-row'}>
                                        <SiAddthis className={'mt-1 mr-1'} />
                                        <span>Nuevo período</span>
                                    </div>
                                </Button>
                            </div>
                        }
                    >
                        <PeriodsTable loading={loading} handleRemove={handleRemove} records={PeriodStore.periods} />
                    </Card>
                </div>

                {isModalVisible && showModal !== null && (
                    <Modal
                        title={<b>{showModal.title}</b>}
                        visible={isModalVisible}
                        footer={false}
                        onCancel={() => setIsModalVisible(false)}
                    >
                        <CreateForms
                            isModalVisible={isModalVisible}
                            setIsModalVisible={setIsModalVisible}
                            modal={{ title: showModal.title, type: showModal.type }}
                            handleCreate={handleCreatePeriod}
                        />
                    </Modal>
                )}
            </div>
        </>
    );
});
