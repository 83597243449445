import React, { useEffect } from 'react';
import { notification } from 'antd';
import { observer } from 'mobx-react';
import PeriodStore from './stores/PeriodStore';
import ZoneStore from './stores/ZoneStore';
import ErrorRender from '../../library/outlets/ErrorRender';
import { CollaboratorForm, CommunityForm, CourseForm, LevelForm, MemberForm, PeriodForm, ZoneForm } from './forms';

interface Props {
    isModalVisible: boolean;
    setIsModalVisible: (value: boolean) => void;
    modal: { title: string; type: string };
    handleCreate: (payload: any) => void;
}

export const CreateForms = observer((props: Props) => {
    async function handleListZones() {
        await ZoneStore.list().catch((err: any) => {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        });
    }

    useEffect(() => {
        if ('FormCommunity' == props.modal.type) handleListZones();
    }, []);

    const getForm = () => {
        let Form = (
            <PeriodForm
                handleCreate={props.handleCreate}
                setIsModalVisible={(value: boolean) => {
                    props.setIsModalVisible(value);
                }}
            />
        );

        switch (props.modal.type) {
            case 'FormPeriod':
                Form = (
                    <PeriodForm
                        handleCreate={props.handleCreate}
                        setIsModalVisible={(value: boolean) => {
                            props.setIsModalVisible(value);
                        }}
                    />
                );
                break;
            case 'FormContributor':
                Form = (
                    <CollaboratorForm
                        handleCreate={props.handleCreate}
                        setIsModalVisible={(value: boolean) => {
                            props.setIsModalVisible(value);
                        }}
                    />
                );
                break;
            case 'FormLevel':
                Form = (
                    <LevelForm
                        handleCreate={props.handleCreate}
                        setIsModalVisible={(value: boolean) => {
                            props.setIsModalVisible(value);
                        }}
                    />
                );
                break;
            case 'FormZone':
                Form = (
                    <ZoneForm
                        handleCreate={props.handleCreate}
                        setIsModalVisible={(value: boolean) => {
                            props.setIsModalVisible(value);
                        }}
                    />
                );
                break;
            case 'FormCommunity':
                Form = (
                    <CommunityForm
                        handleCreate={props.handleCreate}
                        setIsModalVisible={(value: boolean) => {
                            props.setIsModalVisible(value);
                        }}
                        zones={ZoneStore.zones}
                    />
                );
                break;
            case 'FormCourse':
                Form = (
                    <>
                        {PeriodStore.period_active && (
                            <CourseForm
                                handleCreate={props.handleCreate}
                                setIsModalVisible={(value: boolean) => {
                                    props.setIsModalVisible(value);
                                }}
                                period={PeriodStore.period_active}
                            />
                        )}
                    </>
                );
                break;
            case 'FormMember':
                Form = (
                    <MemberForm
                        type="create"
                        handleCreate={props.handleCreate}
                        setIsModalVisible={(value: boolean) => {
                            props.setIsModalVisible(value);
                        }}
                    />
                );
                break;
        }
        return Form;
    };

    return <>{getForm()}</>;
});
