import { makeObservable, observable, runInAction } from 'mobx';
import { get, patch, post, remove } from '../../../utils/api';
import { ArticleProps } from '../types';

class ArticleStore {
    articles: ArticleProps[] = [];
    article: ArticleProps | null = null;
    pagination: { defaultPageSize: number; total: number } = { defaultPageSize: 20, total: 0 };

    constructor() {
        makeObservable(this, {
            articles: observable,
            article: observable,
            pagination: observable,
        });
    }

    async get(id: number) {
        const response = await get(`/staff/inventory/article/${id}`);
        runInAction(() => {
            this.article = response.data;
        });
    }

    async list(params = {}) {
        const response = await get(`/staff/inventory/article/`, params);
        runInAction(() => {
            this.articles = response.data.results;
            this.pagination = {
                defaultPageSize: 20,
                total: response.data.count,
            };
        });
        return response.data.results;
    }

    async create(payload: ArticleProps) {
        const response = await post(`/staff/inventory/article/`, payload);
        runInAction(() => {
            this.articles.push(response.data);
        });
        return response.data;
    }

    async patch(payload: any = {}, id: number) {
        if (typeof payload.area === 'object') {
            payload.area = payload.area.id;
        }
        if (typeof payload.subarea === 'object') {
            payload.subarea = payload.subarea.id;
        }
        const response = await patch(`/staff/inventory/article/${id}/`, payload);
        runInAction(() => {
            this.article = response.data;
        });
        return response.data;
    }

    async remove(id: number) {
        return await remove(`/staff/inventory/article/${id}`).then(() => this.list());
    }
}

export default new ArticleStore();
