import { Alert, Button, Drawer, Form, Input, notification, Spin, Tabs, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { InfoCompany } from '../cards/InfoCompany';
import { CompanyProps } from '../../utils/types';
import { getCurrentUser } from '../../hooks';
import CompanyStore from '../../stores/CompanyStore';
import { toastSuccess } from '../../utils/toast';
import ErrorRender from '../outlets/ErrorRender';
import { UpdateCompanyDrawer } from './UpdateCompanyDrawer';
import { FaSignature } from 'react-icons/fa';
import UploadFile from '../inputs/UploadFile';
import { observer } from 'mobx-react';
import { InfoSignatureFile } from '../cards/InfoSignatureFile';

interface ISettingsDrawerProps {
    handleDrawerSettings: (value: boolean) => void;
    openDrawerSettings: boolean;
}

export const SettingsDrawer = observer((props: ISettingsDrawerProps) => {
    const [form] = Form.useForm();
    const [updateCompanyDrawer, setUpdateCompanyDrawer] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [company, setCompany] = useState<CompanyProps | null>(null);

    async function getCompany() {
        const user = getCurrentUser().data;
        if (user && user.company.id) {
            await CompanyStore.getOnlyCompany(user.company.id).then(response => setCompany(response.data));
        }
    }

    useEffect(() => {
        getCompany();
    }, []);

    useEffect(() => {
        setCompany(CompanyStore.company);
    }, [CompanyStore.company]);

    async function onUpdateCompany(payload: CompanyProps) {
        setLoading(true);
        try {
            if (company !== null && company.id) {
                await CompanyStore.patch(payload, company.id);
                await getCompany();
                setUpdateCompanyDrawer(false);
                setLoading(false);
                toastSuccess('Informacion de la Parroquia Actualizada!');
            }
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    const handleUploadSignature = async (payload: { password: string; signature_file: number }) => {
        setLoading(true);
        try {
            if (company !== null && company.id) {
                await CompanyStore.updateSignatureFile(company.id, payload);
                toastSuccess('Firma digital registrada!');
                setLoading(false);
                form.resetFields();
            }
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    };

    return (
        <>
            <Drawer
                title="Ajustes de Sistema"
                placement={'left'}
                closable={true}
                onClose={() => props.handleDrawerSettings(false)}
                visible={props.openDrawerSettings}
                key={'settings-company'}
                width={'100%'}
            >
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Parroquia" key="1">
                        {company !== null && (
                            <InfoCompany
                                company={company}
                                setUpdateCompanyDrawer={setUpdateCompanyDrawer}
                                update={true}
                            />
                        )}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Firma Digital" key="2">
                        {company !== null && (
                            <InfoSignatureFile
                                form={form}
                                handleUploadSignature={handleUploadSignature}
                                signature_file={company.signature_file}
                            />
                        )}
                    </Tabs.TabPane>
                </Tabs>
            </Drawer>

            {updateCompanyDrawer && company !== null && (
                <UpdateCompanyDrawer
                    company={company}
                    open={updateCompanyDrawer}
                    setClose={setUpdateCompanyDrawer}
                    onFinish={onUpdateCompany}
                />
            )}
        </>
    );
});
