import React from 'react';
import { Select } from 'antd';
import { SubareaProps } from '../types';

const { Option } = Select;

interface Props {
    style: any;
    subareas: any;
    onChange?: (id: number) => void;
}

export const SubareaSelect = ({ style, subareas, onChange }: Props) => {
    const change = (id: number) => {
        if (typeof onChange === 'function') {
            onChange(id);
        }
    };

    return (
        <>
            <Select
                showSearch
                style={style}
                placeholder="Buscar Subarea"
                optionFilterProp="children"
                filterOption={(input, option) => option?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
                onChange={(id: string, row: any) => {
                    if (row) {
                        change(row.key);
                    }
                }}
            >
                {subareas.map((subarea: SubareaProps) => {
                    return (
                        <Option key={subarea.id} value={subarea.name}>
                            {subarea.name}
                        </Option>
                    );
                })}
            </Select>
        </>
    );
};
