import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
    active: string;
}

export const MenuTop = (props: Props) => {
    const navigate = useNavigate();
    const style_active_tab =
        'inline-block p-4 text-alpes_green-600 rounded-t-lg border-b-2 border-alpes_green-600 active';
    const style_tab =
        'inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300';

    return (
        <>
            <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200">
                <ul className="flex flex-wrap -mb-px">
                    <li>
                        <div
                            className={props.active === 'period' ? style_active_tab : style_tab}
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate('/catechism/period', { replace: true })}
                        >
                            Periodos
                        </div>
                    </li>
                    <li>
                        <div
                            className={props.active === 'contributor' ? style_active_tab : style_tab}
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate('/catechism/contributor', { replace: false })}
                        >
                            Catequistas
                        </div>
                    </li>
                    <li>
                        <div
                            className={props.active === 'level' ? style_active_tab : style_tab}
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate('/catechism/level', { replace: true })}
                        >
                            Niveles
                        </div>
                    </li>
                    <li>
                        <div
                            className={props.active === 'zone' ? style_active_tab : style_tab}
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate('/catechism/zone', { replace: true })}
                        >
                            Zonas
                        </div>
                    </li>
                    <li>
                        <div
                            className={props.active === 'community' ? style_active_tab : style_tab}
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate('/catechism/community', { replace: true })}
                        >
                            Comunidades
                        </div>
                    </li>
                    <li>
                        <div
                            className={props.active === 'course' ? style_active_tab : style_tab}
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate('/catechism/course', { replace: true })}
                        >
                            Grupos
                        </div>
                    </li>
                    <li>
                        <div
                            className={props.active === 'member' ? style_active_tab : style_tab}
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate('/catechism/member', { replace: true })}
                        >
                            Integrantes
                        </div>
                    </li>
                    <li>
                        <div
                            className={props.active === 'registration' ? style_active_tab : style_tab}
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate('/catechism/registration', { replace: true })}
                        >
                            Matrículas
                        </div>
                    </li>
                </ul>
            </div>
        </>
    );
};
