import React, { useEffect, useState } from 'react';
import { MenuTop as CatechismMenuTop } from '../MenuTop';
import { notification } from 'antd/es';
import { Button, Card, Modal } from 'antd';
import { SiAddthis } from 'react-icons/si';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import LevelStore from '../stores/LevelStore';
import CourseStore from '../stores/CourseStore';
import PeriodStore from '../stores/PeriodStore';
import { CoursesFilter } from '../filters';
import { CoursesTable } from '../tables';
import { CreateForms } from '../CreateForms';
import ErrorRender from '../../../library/outlets/ErrorRender';
import { toastSuccess } from '../../../utils/toast';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const CatechismCoursePage = observer(() => {
    const [loading, setLoading] = useState(false);
    const [isModalCreate, setIsModalCreate] = useState(false);
    const searchParams = useQuery();

    async function handleListLevels() {
        setLoading(true);
        try {
            await LevelStore.list();
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function handleList(params = {}) {
        setLoading(true);
        try {
            await CourseStore.list(params);
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    const handleCreateCourse = async (payload: any) => {
        setLoading(true);
        try {
            await CourseStore.create(payload);
            await handleList();
            toastSuccess('Grupo registrado!');
            setLoading(false);
            setIsModalCreate(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        getPeriodActive();
        handleListLevels();
        handleList(searchParams);
    }, [location.search]);

    const handleRemove = async (id: number) => {
        setLoading(true);
        try {
            await CourseStore.remove(id);
            toastSuccess('Grupo eliminado!');
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    };

    async function getPeriodActive() {
        try {
            await PeriodStore.get_active();
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
    }

    return (
        <>
            <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
                <CatechismMenuTop active={'course'} />
                <div className={'my-8'}>
                    <Card
                        bordered={false}
                        size="small"
                        title={
                            <>
                                <span className="font-bold">Grupos</span> de catequesis.
                            </>
                        }
                        extra={
                            <div className={'flex flex-row'}>
                                <div className={'mr-5 mt-1.5'}>
                                    {PeriodStore.period_active !== null && (
                                        <span>
                                            <b>Período activo: </b>
                                            {PeriodStore.period_active.year}
                                        </span>
                                    )}
                                </div>
                                <Button type={'link'} onClick={() => setIsModalCreate(true)}>
                                    <div className={'flex flex-row'}>
                                        <SiAddthis className={'mt-1 mr-1'} />
                                        <span>Nuevo grupo</span>
                                    </div>
                                </Button>
                            </div>
                        }
                    >
                        <div className={'flex flex-row w-full'}>
                            <div className={'w-full'}>
                                <CoursesFilter />
                            </div>
                        </div>
                        <CoursesTable loading={loading} handleRemove={handleRemove} />
                    </Card>
                </div>

                {isModalCreate && (
                    <Modal
                        title={<b>{'Nuevo Grupo'}</b>}
                        visible={isModalCreate}
                        footer={false}
                        onCancel={() => setIsModalCreate(false)}
                    >
                        <CreateForms
                            isModalVisible={isModalCreate}
                            setIsModalVisible={setIsModalCreate}
                            modal={{ title: 'Nuevo Curso', type: 'FormCourse' }}
                            handleCreate={handleCreateCourse}
                        />
                    </Modal>
                )}
            </div>
        </>
    );
});
