import React from 'react';
import { Popconfirm, Space, Typography } from 'antd';
import { observer } from 'mobx-react';
import { Spin } from 'antd/es';
import moment from 'moment';
import { RiDeleteBin2Fill, RiEditBoxLine, RiNewspaperFill } from 'react-icons/ri';
import { capitalizeFirstLetter, PaginationTable } from '../../../library';
import MemberStore from '../stores/MemberStore';

interface Props {
    loading: boolean;
    handleRemove: (id: number) => void;
    setIsModalVisible: (value: boolean) => void;
    setIsModalRegistrationCreate: (value: boolean) => void;
    setRecord: (record: any) => void;
}

export const MemberTable = observer((props: Props) => {
    return (
        <>
            <div className="relative overflow-x-auto">
                <Spin spinning={props.loading}>
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="py-3 px-6">
                                    Integrantes / Padres
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    F. de Nacimiento
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    <span className="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {MemberStore.members.length > 0 ? (
                                MemberStore.members.map((record: any, index: number) => {
                                    return (
                                        <>
                                            <tr
                                                key={index}
                                                className={
                                                    index % 2 == 0
                                                        ? 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'
                                                        : 'bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700'
                                                }
                                            >
                                                <td className={'py-4 px-6'}>
                                                    <div className={'font-bold'}>
                                                        {record.last_name !== null && (
                                                            <span>
                                                                {capitalizeFirstLetter(record.last_name)}
                                                                &nbsp;
                                                            </span>
                                                        )}
                                                        {capitalizeFirstLetter(record.name)}
                                                    </div>
                                                    <div className={'text-xs'}>
                                                        Padres: {record.father && capitalizeFirstLetter(record.father)},{' '}
                                                        {record.mother && capitalizeFirstLetter(record.mother)}
                                                    </div>
                                                </td>
                                                <td className={'py-4 px-6'}>
                                                    {record.birth_date && record.age !== null && (
                                                        <div>
                                                            <div className={'text-xs'}>
                                                                {moment(record.birth_date).format('YYYY-MM-DD')}
                                                            </div>
                                                            <div className={'text-xs'}>{record.age}</div>
                                                        </div>
                                                    )}
                                                </td>
                                                <td className="py-4 px-6">
                                                    <Space size="middle" className={'px-2'}>
                                                        <Typography.Link
                                                            onClick={() => {
                                                                props.setRecord(record);
                                                                props.setIsModalRegistrationCreate(true);
                                                            }}
                                                        >
                                                            <RiNewspaperFill
                                                                title={'Nueva Matricula'}
                                                                className="ml-1 cursor-pointer transform hover:scale-150 duration-75"
                                                                color={'green'}
                                                            />
                                                        </Typography.Link>
                                                    </Space>

                                                    <Space size="middle" className="px-2">
                                                        <Typography.Link
                                                            onClick={() => {
                                                                props.setIsModalVisible(true);
                                                                props.setRecord(record);
                                                            }}
                                                        >
                                                            <RiEditBoxLine
                                                                title={'Editar'}
                                                                className="cursor-pointer transform hover:scale-150 duration-75 ml-1"
                                                                color={'orange'}
                                                            />
                                                        </Typography.Link>
                                                    </Space>

                                                    <Space size="middle" className="px-2">
                                                        <Popconfirm
                                                            title={'Eliminar integrante?'}
                                                            onConfirm={() =>
                                                                record.id &&
                                                                props.handleRemove &&
                                                                props.handleRemove(record.id)
                                                            }
                                                            okText={'Eliminar'}
                                                            cancelText={'Cancelar'}
                                                        >
                                                            <Typography.Link>
                                                                <RiDeleteBin2Fill
                                                                    className="cursor-pointer transform hover:scale-150 duration-75 ml-1"
                                                                    color="#DE3E1B"
                                                                    title={'Eliminar'}
                                                                />
                                                            </Typography.Link>
                                                        </Popconfirm>
                                                    </Space>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })
                            ) : (
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td className="py-4 px-6" colSpan={4}>
                                        <div className={'flex justify-center'}>No existe registros</div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className={'pt-2 pb-2'}>
                        <PaginationTable {...MemberStore.pagination} />
                    </div>
                </Spin>
            </div>
        </>
    );
});
