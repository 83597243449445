import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Divider, Form, Input, Popconfirm } from 'antd';
import { VscFilePdf, VscSaveAll, VscSaveAs, VscTrash } from 'react-icons/vsc';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import { Strings } from '../../../utils/strings';
import { DeathProps } from '../types';

interface Props {
    type: 'create' | 'update';
    death?: DeathProps;
    handleUpdate?: (payload: DeathProps, list: boolean) => void;
    handleCreate?: (payload: DeathProps, list: boolean) => void;
    handleRemove?: (id: number) => void;
    handlePreviewCertificate?: (id: number, template: string) => void;
}

export const DeathForm = ({
    type,
    death,
    handleCreate,
    handleRemove,
    handleUpdate,
    handlePreviewCertificate,
}: Props) => {
    const [list, setList] = useState(false);
    const [form] = Form.useForm();
    const dateFormat = 'YYYY-MM-DD';

    useEffect(() => {
        form.resetFields();
    }, [death]);

    const onFinish = (values: any) => {
        const payload = {
            member: values.member,
            father: values.father,
            mother: values.mother,
            date: moment(values.date).format('YYYY-MM-DD'),
            age: values.age,
            tome: values.tome,
            folio: values.folio,
            number: values.number,
            priest: values.priest,
            birth_date: values.birth_date ? moment(values.birth_date).format('YYYY-MM-DD') : null,
            birth_place: values.birth_place,
        };

        if (type === 'create' && handleCreate) {
            handleCreate(payload, list);
            form.resetFields();
        } else if (type === 'update' && handleUpdate) {
            const refactor_payload = {
                id: death?.id,
                ...payload,
            };
            handleUpdate(refactor_payload, list);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            form={form}
            initialValues={{
                member: death?.member,
                father: death?.father,
                mother: death?.mother,
                date: death?.date ? moment(death?.date, dateFormat) : '',
                age: death?.age,
                tome: death?.tome,
                folio: death?.folio,
                number: death?.number,
                priest: death?.priest,
                birth_date: death?.birth_date ? moment(death?.birth_date, 'YYYY-MM-DD') : '',
                birth_place: death?.birth_place,
            }}
            name="add-edit-member"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
        >
            <div className="grid grid-cols-4 gap-4">
                <div className="col-span-2">
                    <Form.Item
                        label="Nombres"
                        name="member"
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <Input />
                    </Form.Item>
                </div>

                <Form.Item
                    label="Edad"
                    name="age"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Fecha de defunción"
                    name="date"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <DatePicker locale={locale} style={{ width: '100%' }} />
                </Form.Item>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <Form.Item label="Papá" name="father">
                    <Input />
                </Form.Item>

                <Form.Item label="Mamá" name="mother">
                    <Input />
                </Form.Item>
            </div>

            <div className="grid grid-cols-5 gap-4">
                <div className="col-span-2">
                    <Form.Item
                        label="Ministro"
                        name="priest"
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <Input />
                    </Form.Item>
                </div>
                <Form.Item
                    label="Tomo"
                    name="tome"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Folio"
                    name="folio"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Número"
                    name="number"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>
            </div>

            <Divider>Datos de nacimiento</Divider>

            <div className="grid grid-cols-2 gap-4">
                <Form.Item label="Fecha de nacimiento" name="birth_date">
                    <DatePicker locale={locale} style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item label="Lugar de nacimiento" name="birth_place">
                    <Input />
                </Form.Item>
            </div>

            <Form.Item>
                <div className={'flex flex-row space-x-4'}>
                    <Button
                        className="second"
                        type="primary"
                        htmlType="submit"
                        icon={<VscSaveAs className="inline-flex mr-1" size={'18px'} />}
                        onClick={() => setList(false)}
                    >
                        Guardar
                    </Button>
                    <Button
                        className={'second'}
                        type="primary"
                        htmlType="submit"
                        icon={<VscSaveAll className="inline-flex mr-1" size={'18px'} />}
                        onClick={() => setList(true)}
                    >
                        Guardar y ver registros
                    </Button>
                    {type === 'update' && (
                        <>
                            <Button
                                type="default"
                                icon={<VscFilePdf className="inline-flex mr-1" size={'18px'} />}
                                onClick={() =>
                                    handlePreviewCertificate && death?.id && handlePreviewCertificate(death.id, 'A4')
                                }
                            >
                                Certificado en A4
                            </Button>

                            <Button
                                type="default"
                                icon={<VscFilePdf className="inline-flex mr-1" size={'18px'} />}
                                onClick={() =>
                                    handlePreviewCertificate && death?.id && handlePreviewCertificate(death.id, 'A5')
                                }
                            >
                                Certificado en A5
                            </Button>

                            <Popconfirm
                                title={'¿Eliminar Defunción?'}
                                onConfirm={() => handleRemove && death?.id && handleRemove(death.id)}
                                okText={'Eliminar'}
                                cancelText={'Cancelar'}
                            >
                                <Button
                                    className={'remove'}
                                    type="default"
                                    icon={<VscTrash className="inline-flex mr-1" size={'18px'} />}
                                >
                                    Eliminar
                                </Button>
                            </Popconfirm>
                        </>
                    )}
                </div>
            </Form.Item>
        </Form>
    );
};
