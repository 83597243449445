import React from 'react';
import { Select } from 'antd';
import _ from 'lodash';
import { useState } from 'react';
import MemberStore from '../stores/MemberStore';

const { Option } = Select;

interface Props {
    style: any;
    onChange?: (id: number) => void;
}

export const MemberSelect = ({ onChange, style }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<Array<any>>([]);

    const handleSearch = _.debounce(async (value: string) => {
        setLoading(!loading);
        if (value) {
            MemberStore.list({ search: value }).then(() => {
                setData(MemberStore.members);
            });
            setLoading(false);
        } else {
            setData([]);
        }
    }, 500);

    const change = (id: number) => {
        if (typeof onChange === 'function') {
            onChange(id);
        }
    };

    return (
        <Select
            key={'select-member'}
            showSearch
            allowClear
            className="form-control show-tick"
            loading={loading}
            placeholder={'Nombres o apellidos'}
            style={style}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            notFoundContent={null}
            onChange={(id: string, row: any) => {
                if (row) change(row.key);
            }}
        >
            {data.map((member: any) => {
                return (
                    <Option key={member.id} value={member.name}>
                        {member.name}
                    </Option>
                );
            })}
        </Select>
    );
};
