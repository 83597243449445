import React, { useEffect } from 'react';
import { Button, Form } from 'antd';
import LevelSelect from '../selects/LevelSelect';
import CommunitySearchSelect from '../selects/CommunitySearchSelect';
import { observer } from 'mobx-react';
import { useQueryParameters } from '../../../library';
import LevelStore from '../stores/LevelStore';
import CourseStore from '../stores/CourseStore';

export const CoursesFilterByLevelCommunity = observer(() => {
    const query = useQueryParameters();

    const [form] = Form.useForm();

    const onFinish = async (values: any): Promise<void> => {
        const community = values.community ? values.community.value : null;
        const level = values.level ? values.level.value : null;
        await CourseStore.list({ community, level });
    };

    useEffect(() => {
        LevelStore.list();
    }, []);

    return (
        <div className={'flex justify-start w-full py-6 px-8'}>
            <div className="w-full">
                <Form onFinish={onFinish} layout="horizontal" form={form}>
                    <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <Form.Item label="Comunidad" name="community" initialValue={query.get('community')}>
                            <CommunitySearchSelect size={'small'} />
                        </Form.Item>

                        <Form.Item label="Nivel" name="level" initialValue={query.get('level')}>
                            <LevelSelect size={'small'} levels={LevelStore.levels} />
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <div className="flex justify-center space-x-4">
                            <Button className={'second'} type="primary" htmlType={'submit'}>
                                Ver Grupos
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
});
