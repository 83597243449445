import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Slider, InputNumber } from 'antd';
import { useNavigate } from 'react-router-dom';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import { DateRangePicker, useQueryParameters } from '../../../../library';
import { toastError } from '../../../../utils/toast';

interface Props {
    path: string;
    reset: () => void;
}

export const ReportBaptismFilter = (props: Props) => {
    const navigate = useNavigate();
    const query = useQueryParameters();

    const [form] = Form.useForm();

    const handleSubmit = (values: any) => {
        const params: any = [];

        if (values.created_time) {
            const created_time_min = moment(values.created_time[0]).format('YYYY-MM-DD');
            const created_time_max = moment(values.created_time[1]).format('YYYY-MM-DD');
            params['range_dates'] = [created_time_min, created_time_max];
        }

        if (Number(ageMin) > Number(ageMax)) {
            toastError('La edad mínima no puede ser mayor a la edad máxima');
        } else {
            params['range_ages'] = [ageMin, ageMax];
            const queryString = Object.keys(params)
                .map(key => key + '=' + params[key])
                .join('&');

            navigate(`${props.path}/?${queryString}`, { replace: true });
        }
    };

    useEffect(() => {
        form.resetFields();
    }, []);

    const [ageMin, setAgeMin] = useState(0);
    const [ageMax, setAgeMax] = useState(1);

    return (
        <div className={'flex justify-start w-full py-2 px-8'}>
            <div className={'w-full'}>
                <Form onFinish={handleSubmit} layout="horizontal" form={form}>
                    <div className="grid grid-cols-1 lg:grid-cols-1 gap-4 pb-4">
                        <Form.Item
                            label="Fechas de bautizo:"
                            name="created_time"
                            initialValue={query.get('created_time')}
                            help={
                                <span className={'text-xs'}>
                                    Seleccione el rango de fechas de bautizo, o deje en blanco para buscar en todas las
                                    fechas.
                                </span>
                            }
                        >
                            <DateRangePicker locale={locale} size={'small'} style={{ width: '100%' }} />
                        </Form.Item>
                    </div>
                    <div className="grid grid-cols-2 lg:grid-cols-2 gap-4">
                        <Form.Item label="Edad mínina:" name="age_min" initialValue={query.get('created_time')}>
                            <Row>
                                <Col span={12}>
                                    <Slider min={0} max={99} onChange={setAgeMin} value={ageMin} />
                                </Col>
                                <Col span={4}>
                                    <InputNumber
                                        min={0}
                                        max={99}
                                        style={{ margin: '0 16px' }}
                                        value={ageMin}
                                        onChange={setAgeMin}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item label="Edad máxina:" name="age_max" initialValue={query.get('created_time')}>
                            <Row>
                                <Col span={12}>
                                    <Slider min={1} max={100} onChange={setAgeMax} value={ageMax} />
                                </Col>
                                <Col span={4}>
                                    <InputNumber
                                        min={1}
                                        max={100}
                                        style={{ margin: '0 16px' }}
                                        value={ageMax}
                                        onChange={setAgeMax}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                    </div>

                    <div className="flex justify-center space-x-4">
                        <Button className={'second'} type="primary" htmlType={'submit'}>
                            Filtrar
                        </Button>
                        <Button
                            onClick={() => {
                                form.resetFields();
                                setAgeMax(0);
                                setAgeMin(0);
                                props.reset();
                            }}
                        >
                            Limpiar Filtros
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
};
