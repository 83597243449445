import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Carousel, Form, Input, InputNumber, Modal, PageHeader, Popconfirm } from 'antd';
import { Strings } from '../../../utils/strings';
import { ArticleProps, FileProps, SubareaProps } from '../types';
import { VscFilePdf, VscSaveAll, VscSaveAs, VscTrash } from 'react-icons/vsc';
import UploadFile from '../../../library/inputs/UploadFile';
import { AreaSelect, SubareaSelect } from '../selects';
import { capitalize } from 'lodash';

interface Props {
    type: string;
    article?: ArticleProps;
    handleUpdate?: (payload: ArticleProps, list: boolean, id: number) => void;
    handleCreate?: (payload: ArticleProps, list: boolean) => void;
    handleRemove?: (id: number) => void;
    handleViewImages?: () => void;
    handlePreviewCertificate?: (id: number) => void;
}

const { TextArea } = Input;

export const ArticleForm = ({
    type,
    article,
    handleCreate,
    handleUpdate,
    handleRemove,
    handlePreviewCertificate,
}: Props) => {
    const [form] = Form.useForm();
    const [formUploadImages] = Form.useForm();
    const [subareas, setSubareas] = useState<Array<SubareaProps>>([]);
    const [list, setList] = useState(false);
    const [openUploadImage, setOpenUploadImage] = useState(false);

    useEffect(() => {
        form.resetFields();
    }, [article]);

    const onFinish = (values: any) => {
        let images = [];
        let old_images = [];

        if (values.images && values.images.length > 0) {
            images = values.images.map((image: any) => {
                if (image.response) {
                    return image.response.id;
                }
            });
        }

        if (article && article.id) {
            if (article.images && article.images.length > 0) {
                old_images = article.images.map((image: any) => {
                    return image.id;
                });
            }
        }

        const payload = {
            code: values.code,
            name: values.article,
            quantity: values.quantity,
            status: values.state,
            value: values.value,
            location: values.location,
            description: values.description,
            images: images.concat(old_images),
        };

        if (type === 'create' && handleCreate) {
            const refactor_payload = {
                area: values.area,
                subarea: values.subarea,
                ...payload,
            };
            handleCreate(refactor_payload, list);
        } else if (type === 'update' && handleUpdate && article && article.id) {
            const refactor_payload = {
                area: article.area,
                subarea: article.subarea,
                ...payload,
            };
            handleUpdate(refactor_payload, list, article.id);
        }
        form.resetFields();
    };

    const onFinishUploadImages = (values: any) => {
        let images = [];
        if (values.images && values.images.length > 0) {
            images = values.images.map((image: any) => {
                if (image.response) {
                    return image.response.id;
                }
            });
        }
        if (images.length > 0 && type === 'update' && handleUpdate && article && article.id) {
            let old_images = [];
            if (article.images && article.images.length > 0) {
                old_images = article.images.map((image: any) => {
                    return image.id;
                });
            }
            const payload = {
                code: article.code,
                name: article.name,
                quantity: article.quantity,
                status: article.status,
                value: article.value,
                location: article.location,
                description: article.description,
                images: images.concat(old_images),
            };
            handleUpdate(payload, list, article.id);
            formUploadImages.resetFields();
            setOpenUploadImage(false);
        }
    };

    return (
        <>
            {type === 'update' && (
                <PageHeader
                    className="site-page-header"
                    title={capitalize(article?.name)}
                    subTitle={capitalize(article?.description)}
                    breadcrumb={
                        <Breadcrumb>
                            <Breadcrumb.Item>{typeof article?.area === 'object' && article.area.name}</Breadcrumb.Item>
                            <Breadcrumb.Item>
                                {typeof article?.subarea === 'object' && article.subarea.name}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{capitalize(article?.name)}</Breadcrumb.Item>
                        </Breadcrumb>
                    }
                />
            )}

            {article && article.images && (
                <div className="flex items-center justify-center px-4">
                    <div className="max-w-sm mb-5 overflow-hidden rounded-xl shadow-md duration-200 hover:scale-105 hover:shadow-xl">
                        <Carousel autoplay>
                            {article.images.map((image: FileProps | number, index: number) => (
                                <div key={index}>
                                    <img
                                        src={typeof image === 'object' ? image.file : undefined}
                                        alt="plant"
                                        className="h-56 w-full"
                                    />
                                    <div className={'flex items-center justify-center px-10'}>
                                        <div className="text-gray-400 text-sm pt-2">
                                            {article && article.images && (
                                                <>
                                                    {index + 1} de {article.images.length}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                        <Button
                            style={{ border: 'none' }}
                            className={'w-full'}
                            type={'default'}
                            onClick={() => {
                                formUploadImages.resetFields();
                                setOpenUploadImage(true);
                            }}
                        >
                            Agregar imágenes
                        </Button>
                    </div>
                </div>
            )}

            <div>
                <Form
                    form={form}
                    initialValues={{
                        code: article?.code,
                        article: capitalize(article?.name),
                        quantity: article?.quantity,
                        state: capitalize(article?.status),
                        value: article?.value,
                        location: article?.location,
                        description: capitalize(article?.description),
                    }}
                    name="add-edit-article"
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <div className="grid grid-cols-2 gap-4">
                        {type === 'create' && (
                            <>
                                <Form.Item
                                    label="Seleccione Area"
                                    name="area"
                                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                                >
                                    <AreaSelect style={{ wdith: '100%' }} setSubareas={setSubareas} />
                                </Form.Item>

                                <Form.Item
                                    label="Seleccione Subarea"
                                    name="subarea"
                                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                                >
                                    <SubareaSelect style={{ wdith: '100%' }} subareas={subareas} />
                                </Form.Item>
                            </>
                        )}
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <Form.Item
                            label={Strings.inventory.code}
                            name="code"
                            rules={[{ required: true, message: Strings.inputs.errors.required }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label={Strings.inventory.article}
                            name="article"
                            rules={[{ required: true, message: Strings.inputs.errors.required }]}
                        >
                            <Input />
                        </Form.Item>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <Form.Item
                            label={Strings.inventory.quantity}
                            name="quantity"
                            rules={[{ required: true, message: Strings.inputs.errors.required }]}
                        >
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>

                        <Form.Item label={Strings.inventory.state} name="state">
                            <Input />
                        </Form.Item>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <Form.Item label={Strings.inventory.value} name="value">
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>

                        <Form.Item label={Strings.inventory.location} name="location">
                            <Input />
                        </Form.Item>
                    </div>

                    <div className="grid grid-cols-1 gap-4">
                        <Form.Item label={Strings.inventory.description} name="description">
                            <TextArea autoSize />
                        </Form.Item>
                    </div>

                    {type === 'create' && (
                        <div className="grid grid-cols-1 gap-4">
                            <Form.Item label={Strings.inventory.uploadImages} name="images">
                                <UploadFile multiple={true} fileType="image" />
                            </Form.Item>
                        </div>
                    )}

                    <div className={'flex flex-row space-x-4'}>
                        {type === 'create' && (
                            <Button
                                className="second"
                                type="primary"
                                htmlType="submit"
                                icon={<VscSaveAs className="inline-flex mr-1" size={'18px'} />}
                                onClick={() => setList(false)}
                            >
                                Guardar
                            </Button>
                        )}
                        <Button
                            className={'second'}
                            type="primary"
                            htmlType="submit"
                            icon={<VscSaveAll className="inline-flex mr-1" size={'18px'} />}
                            onClick={() => setList(true)}
                        >
                            Guardar y ver registros
                        </Button>
                        {type === 'update' && (
                            <>
                                <Button
                                    type="default"
                                    icon={<VscFilePdf className="inline-flex mr-1" size={'18px'} />}
                                    onClick={() =>
                                        handlePreviewCertificate && article?.id && handlePreviewCertificate(article.id)
                                    }
                                >
                                    {Strings.actions.view_in_pdf}
                                </Button>

                                <Popconfirm
                                    title={'¿Eliminar artículo?'}
                                    onConfirm={() => handleRemove && article?.id && handleRemove(article.id)}
                                    okText={'Eliminar'}
                                    cancelText={'Cancelar'}
                                >
                                    <Button
                                        className={'remove'}
                                        type="default"
                                        icon={<VscTrash className="inline-flex mr-1" size={'18px'} />}
                                    >
                                        {Strings.actions.delete}
                                    </Button>
                                </Popconfirm>
                            </>
                        )}
                    </div>
                </Form>
            </div>

            <Modal closable title={'Agregar imágenes'} visible={openUploadImage} footer={false}>
                <Form form={formUploadImages} onFinish={onFinishUploadImages} layout={'vertical'}>
                    <Form.Item label={Strings.inventory.uploadImages} name="images">
                        <UploadFile multiple={true} fileType="image" />
                    </Form.Item>
                    <div className={'flex flex-row space-x-4 justify-end'}>
                        <Button onClick={() => setOpenUploadImage(false)}>Cancelar</Button>
                        <Button
                            className="second"
                            type="primary"
                            htmlType="submit"
                            icon={<VscSaveAll className="inline-flex mr-1" size={'18px'} />}
                        >
                            Actualizar imágenes
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};
