import { DashboardMainPage } from '../pages/dashboards';
import { RouteProps } from '../utils/types';
import { PrivateLayout } from '../layouts';
import { BaptismsPage, DeathsPage, MarriagesPage, ProclamationsPage } from '../modules/books';
import {
    CatechismCommunityPage,
    CatechismContributorPage,
    CatechismCourseAdminPage,
    CatechismCoursePage,
    CatechismLevelPage,
    CatechismMemberPage,
    CatechismPeriodPage,
    CatechismRegistrationPage,
    CatechismZonePage,
} from '../modules/catechism';
import { CategoryPage as ContactCategoryPage, ContactPage } from '../modules/contacts';
import {
    BaptismLicensePage,
    FreedomSinglenessPage,
    HonorabilityPage,
    MarriageLicensePage,
    MarriageNotificationPage,
    PreBaptismPage,
    PreMarriagePage,
} from '../modules/certificates';
import { AreaPage, ArticlePage, SubareaPage } from '../modules/inventory';
import {
    BooksBaptismsReportPage,
    BooksMarriagesReportPage,
    CatechismBrothersReportPage,
    CatechismRegistrationsReportPage,
} from '../modules/reports';

const privateRoutes: RouteProps[] = [
    {
        path: 'books/baptisms',
        Component: BaptismsPage,
        Layout: PrivateLayout,
    },
    {
        path: 'books/deaths',
        Component: DeathsPage,
        Layout: PrivateLayout,
    },
    {
        path: 'books/proclamations',
        Component: ProclamationsPage,
        Layout: PrivateLayout,
    },
    {
        path: 'books/marriages',
        Component: MarriagesPage,
        Layout: PrivateLayout,
    },

    {
        path: 'catechism/period',
        Component: CatechismPeriodPage,
        Layout: PrivateLayout,
    },
    {
        path: 'catechism/contributor',
        Component: CatechismContributorPage,
        Layout: PrivateLayout,
    },
    {
        path: 'catechism/level',
        Component: CatechismLevelPage,
        Layout: PrivateLayout,
    },
    {
        path: 'catechism/zone',
        Component: CatechismZonePage,
        Layout: PrivateLayout,
    },
    {
        path: 'catechism/community',
        Component: CatechismCommunityPage,
        Layout: PrivateLayout,
    },
    {
        path: 'catechism/member',
        Component: CatechismMemberPage,
        Layout: PrivateLayout,
    },
    {
        path: 'catechism/registration',
        Component: CatechismRegistrationPage,
        Layout: PrivateLayout,
    },
    {
        path: 'catechism/course',
        Component: CatechismCoursePage,
        Layout: PrivateLayout,
    },
    {
        path: 'catechism/course/:course_id',
        Component: CatechismCourseAdminPage,
        Layout: PrivateLayout,
    },

    {
        path: 'certificates/pre-baptism',
        Component: PreBaptismPage,
        Layout: PrivateLayout,
    },

    {
        path: 'certificates/pre-marriage',
        Component: PreMarriagePage,
        Layout: PrivateLayout,
    },

    {
        path: 'certificates/marriage-notification',
        Component: MarriageNotificationPage,
        Layout: PrivateLayout,
    },

    {
        path: 'certificates/marriage-license',
        Component: MarriageLicensePage,
        Layout: PrivateLayout,
    },

    {
        path: 'certificates/baptism-license',
        Component: BaptismLicensePage,
        Layout: PrivateLayout,
    },

    {
        path: 'certificates/freedom-singleness',
        Component: FreedomSinglenessPage,
        Layout: PrivateLayout,
    },

    {
        path: 'certificates/honorability',
        Component: HonorabilityPage,
        Layout: PrivateLayout,
    },

    {
        path: 'contacts',
        Component: ContactPage,
        Layout: PrivateLayout,
    },

    {
        path: 'contacts/Category',
        Component: ContactCategoryPage,
        Layout: PrivateLayout,
    },

    {
        path: 'dashboard',
        Component: DashboardMainPage,
        Layout: PrivateLayout,
    },
    {
        path: 'inventory/areas',
        Component: AreaPage,
        Layout: PrivateLayout,
    },

    {
        path: 'inventory/subareas',
        Component: SubareaPage,
        Layout: PrivateLayout,
    },

    {
        path: 'inventory/articles',
        Component: ArticlePage,
        Layout: PrivateLayout,
    },

    // URLs Reports
    {
        path: 'reports-catechism/registrations',
        Component: CatechismRegistrationsReportPage,
        Layout: PrivateLayout,
    },
    {
        path: 'reports-catechism/brothers',
        Component: CatechismBrothersReportPage,
        Layout: PrivateLayout,
    },
    {
        path: 'reports-books/baptisms',
        Component: BooksBaptismsReportPage,
        Layout: PrivateLayout,
    },
    {
        path: 'reports-books/marriages',
        Component: BooksMarriagesReportPage,
        Layout: PrivateLayout,
    },
];

export default privateRoutes;
