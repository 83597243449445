import { makeObservable, observable, runInAction } from 'mobx';
import { get, patch, post, remove } from '../../../utils/api';
import { BaptismProps } from '../types';

class BaptismStore {
    baptisms: BaptismProps[] = [];
    baptism: BaptismProps | null = null;
    pagination: { defaultPageSize: number; total: number } = { defaultPageSize: 20, total: 0 };

    constructor() {
        makeObservable(this, {
            baptisms: observable,
            baptism: observable,
            pagination: observable,
        });
    }

    async get(id: number) {
        const response = await get(`/staff/books/baptism/${id}`);
        runInAction(() => {
            this.baptism = response.data;
        });
    }

    async list(params = {}) {
        const response = await get(`/staff/books/baptism/`, params);
        runInAction(() => {
            this.baptisms = response.data.results;
            this.pagination = {
                defaultPageSize: 20,
                total: response.data.count,
            };
        });
        return response.data.results;
    }

    async create(payload: BaptismProps) {
        const response = await post(`/staff/books/baptism/`, payload);
        runInAction(() => {
            this.baptisms.push(response.data);
        });
        return response.data;
    }

    async patch(payload = {}, id?: number) {
        const response = await patch(`/staff/books/baptism/${id}/`, payload);
        runInAction(() => {
            this.baptism = response.data;
            this.list();
        });
        return response.data;
    }

    async remove(id: number) {
        return await remove(`/staff/books/baptism/${id}`).then(() => {
            this.list();
        });
    }
}

export default new BaptismStore();
