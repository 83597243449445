import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Spin, notification } from 'antd';
import { isMobile } from 'react-device-detect';
import { MarriageForm } from '../forms';
import { MarriageTable } from '../tables';
import { CertificatePreview } from '../../../library';
import { BooksFilter } from '../filters';
import MarriageStore from '../stores/MarriageStore';
import { PageHeaderBooks } from '../../../library';
import ErrorRender from '../../../library/outlets/ErrorRender';
import { requests } from '../../../utils/api';
import { MarriageProps } from '../types';
import { toastSuccess } from '../../../utils/toast';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const MarriagesPage = () => {
    const searchParams = useQuery();
    const [loading, setLoading] = useState<boolean>(false);
    const [stage, setStage] = useState<'create' | 'update' | 'list'>('list');
    const [marriage, setMarriage] = useState<MarriageProps | null>(null);
    const [drawerCertificate, setDrawerCertificate] = useState<boolean>(false);
    const [fileURL, setFileURL] = useState('');

    async function handleListMarriages(params = {}) {
        setLoading(true);
        try {
            await MarriageStore.list(params);
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    useEffect(() => {
        handleListMarriages(searchParams).then(() => console.log('Load marriages with filters'));
    }, [location.search]);

    async function handleCreate(payload: MarriageProps) {
        setLoading(true);
        try {
            await MarriageStore.create(payload);
            setLoading(false);
            toastSuccess('Matrimonio registrado!');
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function handleUpdate(payload: MarriageProps) {
        setLoading(true);
        try {
            await MarriageStore.patch(payload, payload.id);
            setLoading(false);
            toastSuccess('Matrimonio actualizado!');
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function handleRemove(id: number) {
        setLoading(true);
        try {
            await MarriageStore.remove(id);
            setStage('list');
            toastSuccess('Matrimonio eliminado!');
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function getFileURL(id: number, template: string) {
        setLoading(true);
        setDrawerCertificate(true);
        try {
            requests
                .get2(`/staff/books/marriage/${id}/certificate/${template}`, { responseType: 'blob' })
                .then((response: any) => {
                    if (isMobile) {
                        return setFileURL('');
                    } else {
                        const file = new Blob([response], { type: 'application/pdf' });
                        const fileURL2 = URL.createObjectURL(file);
                        setFileURL(fileURL2);
                    }
                    setLoading(false);
                });
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    return (
        <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
            <Spin spinning={loading}>
                <PageHeaderBooks
                    setStage={(stage: 'create' | 'update' | 'list') => {
                        setMarriage(null);
                        setStage(stage);
                    }}
                    stage={stage}
                    title={
                        stage === 'list'
                            ? 'Matrimonios'
                            : stage === 'create'
                            ? 'Nuevo Matrimonio'
                            : 'Actualizar Matrimonio'
                    }
                    titleButton={
                        stage === 'list'
                            ? 'Nuevo Matrimonio'
                            : stage === 'create'
                            ? 'Ver Matrimonios'
                            : 'Ver Matrimonios'
                    }
                />

                {stage === 'list' ? (
                    <>
                        <BooksFilter path={'/books/marriages'} />
                        <MarriageTable
                            loading={loading}
                            handleRemove={handleRemove}
                            setMarriage={(marriage: MarriageProps) => {
                                setMarriage(marriage);
                                setStage('update');
                            }}
                            handlePreviewCertificate={getFileURL}
                        />
                    </>
                ) : stage === 'create' ? (
                    <div className={'py-6'}>
                        <MarriageForm
                            type={'create'}
                            handleCreate={async (payload: MarriageProps, list: boolean) => {
                                await handleCreate(payload);
                                if (list) {
                                    await handleListMarriages();
                                    setStage('list');
                                }
                            }}
                        />
                    </div>
                ) : (
                    stage === 'update' &&
                    marriage !== null && (
                        <div className={'py-6'}>
                            <MarriageForm
                                type={'update'}
                                marriage={marriage}
                                handleUpdate={async (payload: MarriageProps, list: boolean) => {
                                    await handleUpdate(payload);
                                    if (list) {
                                        await handleListMarriages();
                                        setStage('list');
                                    }
                                }}
                                handleRemove={handleRemove}
                                handlePreviewCertificate={getFileURL}
                            />
                        </div>
                    )
                )}

                {drawerCertificate && (
                    <CertificatePreview
                        loading={loading}
                        handleClose={() => {
                            setFileURL('');
                            setDrawerCertificate(false);
                        }}
                        fileUrl={fileURL}
                    />
                )}
            </Spin>
        </div>
    );
};
