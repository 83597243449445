import React from 'react';
import { Button, Form, Input } from 'antd';
import LevelSelect from '../selects/LevelSelect';
import ContributorSearchSelect from '../selects/ContributorSearchSelect';
import CommunitySearchSelect from '../selects/CommunitySearchSelect';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useQueryParameters } from '../../../library';
import LevelStore from '../stores/LevelStore';

export const CoursesFilter = observer(() => {
    const query = useQueryParameters();
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        const params: any = {};

        if (values.name) params['name'] = values.name;
        if (values.community) params['community'] = values.community.value;
        if (values.level) params['level'] = values.level.value;
        if (values.teacher) params['teacher'] = values.teacher.value;

        const queryString = Object.keys(params)
            .map(key => key + '=' + params[key])
            .join('&');

        navigate(`/catechism/course/?${queryString}`, { replace: true });
    };

    return (
        <div className={'flex justify-start w-full py-6 px-8'}>
            <div className="w-full">
                <Form onFinish={onFinish} layout="horizontal" form={form}>
                    <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <Form.Item label="Grupo" name="name" initialValue={query.get('name')}>
                            <Input placeholder="Grupo - 1" allowClear size={'small'} />
                        </Form.Item>

                        <Form.Item label="Nivel" name="level" initialValue={query.get('level')}>
                            <LevelSelect size={'small'} levels={LevelStore.levels} />
                        </Form.Item>
                    </div>
                    <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <Form.Item label="Comunidad" name="community" initialValue={query.get('community')}>
                            <CommunitySearchSelect size={'small'} />
                        </Form.Item>

                        <Form.Item label="Catequista" initialValue={query.get('teacher')} name={'teacher'}>
                            <ContributorSearchSelect size={'small'} />
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <div className="flex justify-center space-x-4">
                            <Button className={'second'} type="primary" htmlType={'submit'}>
                                Filtrar Registros
                            </Button>
                            <Button
                                onClick={async () => {
                                    form.resetFields();
                                    navigate(`/catechism/course/`, { replace: true });
                                }}
                            >
                                Limpiar Filtros
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
});
