import { action, makeObservable, observable, runInAction } from 'mobx';

class SidebarStore {
    isVisible = false;
    button: any;

    constructor() {
        makeObservable(this, {
            isVisible: observable,
            button: observable,
            handleVisibleSidebar: action,
            getButtonElement: action,
        });
    }

    handleVisibleSidebar(param: boolean) {
        runInAction(() => (this.isVisible = param));
    }

    getButtonElement(param: any) {
        runInAction(() => (this.button = param));
    }
}

export default new SidebarStore();
