import React, { useState } from 'react';
import { CertificatePreview, PageHeaderCertificates } from '../../../library';
import { requests } from '../../../utils/api';
import { isMobile } from 'react-device-detect';
import { notification } from 'antd';
import ErrorRender from '../../../library/outlets/ErrorRender';
import { HonorabilityForm } from '../forms';

export const HonorabilityPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [fileURL, setFileURL] = useState('');
    const [type, setType] = useState<string>('A4');
    const [drawerCertificate, setDrawerCertificate] = useState<boolean>(false);

    async function getFileURL(payload: any) {
        setLoading(true);
        setDrawerCertificate(true);
        try {
            requests
                .post2(`/staff/certificate/honorability/`, payload, { responseType: 'blob' })
                .then((response: any) => {
                    if (isMobile) {
                        return setFileURL('');
                    } else {
                        const file = new Blob([response], { type: 'application/pdf' });
                        const fileURL2 = URL.createObjectURL(file);
                        setFileURL(fileURL2);
                    }
                    setLoading(false);
                });
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    return (
        <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
            <PageHeaderCertificates
                title={'Certificado de Honorabilidad'}
                subtitle={'Complete los campos requeridos, seleccione el formato de impresón A5 o A4'}
                setType={setType}
                type={type}
            />

            <div className={'pt-4'}>
                <HonorabilityForm getFileUrl={getFileURL} type={type} />
            </div>

            {drawerCertificate && (
                <CertificatePreview
                    loading={loading}
                    handleClose={() => {
                        setFileURL('');
                        setDrawerCertificate(false);
                    }}
                    fileUrl={fileURL}
                />
            )}
        </div>
    );
};
