import * as React from 'react';
import { Button, Upload } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { UploadFile as UploadFileInterface } from 'antd/es/upload/interface';

import { UploadOutlined } from '@ant-design/icons';
import { ALLOWED_FILES } from '../../utils/FileExtensions';
import { API_URL } from '../../utils/constants';

interface Props {
    fileType?: 'image' | 'signature';
    onUpload?: (file: UploadFileInterface) => void;
    onRemove?: (file: UploadFileInterface) => void;
    value?: any;
    multiple?: boolean;
    accept?: string;
    onChange?: (fileList: Array<any>) => void;
    renderAs?: 'dragger' | 'button';
}

const UploadFile = React.forwardRef((props: Props) => {
    const { multiple, onChange, onUpload, onRemove, value, fileType, renderAs } = props;

    const token = localStorage.getItem('token');

    const handleChange = (info: UploadChangeParam) => {
        const { file } = info;

        let fileList = info.fileList;

        if (!multiple) {
            fileList = info.fileList.slice(-1);
        }

        if (onChange) {
            onChange(fileList);
        }

        if (info.file.status === 'done' && !!onUpload) {
            onUpload(info.file);
        }

        if (info.file.status === 'removed' && !!onRemove) {
            onRemove(file);
        }
    };

    let url = `staff/files/`;
    let allowed_files = '';

    if (!props.accept) {
        switch (fileType) {
            case 'image':
                url = `staff/files/`;
                allowed_files = ALLOWED_FILES.allowed_image.map(r => `.${r.toLowerCase()}`).toString();
                break;
            case 'signature':
                url = `staff/upload-signature-file/`;
                allowed_files = ALLOWED_FILES.allowed_signatures.map(r => `.${r.toLowerCase()}`).toString();
                break;
        }
    }

    let otherProps = {};

    if (value) {
        otherProps = {
            fileList: value,
        };
    }

    const headers: any = {};

    if (token) {
        headers['Authorization'] = `Token ${token}`;
    }

    if (renderAs == 'button') {
        return (
            <Upload
                name="file"
                action={API_URL + '/' + url}
                headers={headers}
                accept={allowed_files}
                {...otherProps}
                onChange={handleChange}
            >
                <Button size={'small'}>
                    <UploadOutlined /> Upload
                </Button>
            </Upload>
        );
    }

    return (
        <Upload.Dragger
            multiple={multiple}
            name="file"
            action={API_URL + '/' + url}
            headers={headers}
            accept={allowed_files}
            {...otherProps}
            onChange={handleChange}
        >
            <UploadOutlined />
            <p>Haga clic o arrastre el archivo a esta área para cargarlo.</p>
            <p className="ant-upload-hint">
                {multiple ? 'Puede subir uno o varios archivos a la vez.' : 'Puede subir un solo archivo a la vez.'}
            </p>
        </Upload.Dragger>
    );
});

UploadFile.displayName = 'UploadFile';

export default UploadFile;
