import UserStore from '../stores/UserStore';

export const getCurrentUser = () => {
    const { user } = UserStore;

    return {
        data: user,
    };
};

export const getCurrentCompany = () => {
    const { user } = UserStore;

    if (user) {
        return {
            data: user.company,
        };
    }
};

export const hasSignature = () => {
    const { user } = UserStore;

    return !!(user && user.company.signature_file !== null);
};

export const getAppBooks = () => {
    const { user } = UserStore;
    const app = user?.company.apps.filter(app => app.name === 'Books');
    if (app && app.length > 0) return true;
};

export const getAppCatechism = () => {
    const { user } = UserStore;
    const app = user?.company.apps.filter(app => app.name === 'Catechism');
    if (app && app.length > 0) return true;
};

export const getAppInventory = () => {
    const { user } = UserStore;
    const app = user?.company.apps.filter(app => app.name === 'Inventory');
    if (app && app.length > 0) return true;
};

export const getCertificates = () => {
    const { user } = UserStore;
    const app = user?.company.apps.filter(app => app.name === 'Certificates');
    if (app && app.length > 0) return true;
};
