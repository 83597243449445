import { ConfigProvider, DatePicker as ANTDatePicket } from 'antd';
import React from 'react';
import moment from 'moment';

export const DateRangePicker = (props: any) => {
    return (
        <ConfigProvider>
            <ANTDatePicket.RangePicker
                ranges={{
                    Hoy: [moment(), moment()],
                    'Esta Semana': [moment().startOf('week'), moment().endOf('week')],
                    'Ultima Semana': [
                        moment().startOf('week').subtract(1, 'week'),
                        moment().endOf('week').subtract(1, 'week'),
                    ],
                    'Este mes': [moment().startOf('month'), moment().endOf('month')],
                    'Ultimo mes': [
                        moment().startOf('month').subtract(1, 'month'),
                        moment().endOf('month').subtract(1, 'month'),
                    ],
                    'Este año': [moment().startOf('year'), moment().endOf('year')],
                    'Ultimo año': [
                        moment().startOf('year').subtract(1, 'year'),
                        moment().endOf('year').subtract(1, 'year'),
                    ],
                }}
                {...props}
            />
        </ConfigProvider>
    );
};
