import React from 'react';
import { Popconfirm, Space, Tag, Typography } from 'antd';
import { observer } from 'mobx-react';
import { RiDeleteBin2Fill, RiEditBoxLine } from 'react-icons/ri';
import DeathStore from '../stores/DeathStore';
import { capitalizeFirstLetter, PaginationTable } from '../../../library';
import { DeathProps } from '../types';

interface Props {
    loading: boolean;
    handleRemove: (id: number) => void;
    handlePreviewCertificate: (id: number, template: string) => void;
    setDeath?: (death: DeathProps) => void;
}

export const DeathTable = observer(({ handleRemove, handlePreviewCertificate, setDeath }: Props) => {
    return (
        <>
            <div className="overflow-x-auto relative">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="py-3 px-6">
                                Integrante
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Fecha de defunción
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Tomo / Folio / Número
                            </th>
                            <th scope="col" className="py-3 px-6">
                                <span className="sr-only">Acciones</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {DeathStore.deaths.length > 0 ? (
                            DeathStore.deaths.map((record: DeathProps, index: number) => {
                                return (
                                    <tr
                                        key={index}
                                        className={
                                            index % 2 == 0
                                                ? 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'
                                                : 'bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700'
                                        }
                                    >
                                        <td
                                            scope="row"
                                            className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                            <div className={'font-semibold'}>
                                                {capitalizeFirstLetter(record.member)}
                                            </div>
                                        </td>
                                        <td className={'py-4 px-6'}>{record.date}</td>
                                        <td className={'py-4 px-6'}>
                                            {record.tome} / {record.folio} / {record.number}
                                        </td>
                                        <td className={'py-4 px-6'}>
                                            <Space size="middle">
                                                <Tag
                                                    color="green"
                                                    key={'A4' + record.id}
                                                    style={{ cursor: 'pointer' }}
                                                    title="Generar Certificado en formato A4"
                                                    onClick={() =>
                                                        record.id && handlePreviewCertificate(record.id, 'A4')
                                                    }
                                                >
                                                    Cer. A4
                                                </Tag>
                                                <Tag
                                                    color="green"
                                                    key={'A5' + record.id}
                                                    style={{ cursor: 'pointer' }}
                                                    title="Generar certificado en formato A5"
                                                    onClick={() =>
                                                        record.id && handlePreviewCertificate(record.id, 'A5')
                                                    }
                                                >
                                                    Cer. A5
                                                </Tag>
                                                <Typography.Link
                                                    title="Actualizar"
                                                    onClick={() => setDeath && setDeath(record)}
                                                >
                                                    <RiEditBoxLine className="ml-1" color={'orange'} />
                                                </Typography.Link>
                                                <Popconfirm
                                                    title={'¿Eliminar Defunción?'}
                                                    okText={'Eliminar'}
                                                    cancelText={'Cancelar'}
                                                    onConfirm={() => record.id && handleRemove(record.id)}
                                                >
                                                    <Typography.Link title="Eliminar">
                                                        <RiDeleteBin2Fill className="ml-1" color={'#DE3E1B'} />
                                                    </Typography.Link>
                                                </Popconfirm>
                                            </Space>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td className="py-4 px-6" colSpan={6}>
                                    <div className={'flex justify-center'}>No existe registros</div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className={'pt-2 pb-2'}>
                <PaginationTable {...DeathStore.pagination} />
            </div>
        </>
    );
});
