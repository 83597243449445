import React, { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useQueryParameters } from '../../../../library';

interface Props {
    path: string;
}

export const ReportBrothersFilter = (props: Props) => {
    const navigate = useNavigate();
    const query = useQueryParameters();

    const [form] = Form.useForm();

    const handleSubmit = (values: any) => {
        const params: any = [];

        if (values.parents) {
            params['parents'] = values.parents;
        }

        const queryString = Object.keys(params)
            .map(key => key + '=' + params[key])
            .join('&');

        navigate(`${props.path}/?${queryString}`, { replace: true });
    };

    useEffect(() => {
        form.resetFields();
    }, []);

    return (
        <div className={'flex justify-start w-full py-6 px-8'}>
            <div className={'w-full'}>
                <Form onFinish={handleSubmit} layout="horizontal" form={form}>
                    <div className="grid grid-cols-2 lg:grid-cols-2 gap-4">
                        <Form.Item label="Padres:" name="parents" initialValue={query.get('parents')}>
                            <Input size={'small'} />
                        </Form.Item>
                    </div>

                    <Form.Item>
                        <div className="flex justify-center space-x-4">
                            <Button className={'second'} type="primary" htmlType={'submit'}>
                                Filtrar
                            </Button>
                            <Button
                                title={'Doble clic, para limpiar datos'}
                                onClick={() => {
                                    form.resetFields();
                                    navigate(`${props.path}/`, { replace: true });
                                }}
                            >
                                Limpiar Filtros
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};
