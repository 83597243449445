import * as React from 'react';
import { useState } from 'react';
import { notification, Select, Spin } from 'antd';
import { SelectProps } from 'antd/es/select';
import { observer } from 'mobx-react';
import ErrorRender from '../outlets/ErrorRender';
import CompanyStore from '../../stores/CompanyStore';
import { capitalizeFirstLetter } from '../functions/utils';

let busy: any = null;

interface Props extends SelectProps {
    onChange?: (value: any, contact: any) => void;
}

const PriestSearchSelect = observer((props: Props) => {
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState<{ id: number; name: string }[]>([]);

    async function searchRecords(params: { search: string }) {
        setLoading(true);
        try {
            await CompanyStore.getPriests(params).then(() => {
                setRecords(CompanyStore.priests);
            });
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    return (
        <Select
            showSearch
            allowClear
            labelInValue
            filterOption={false}
            style={{ width: '100%' }}
            dropdownMatchSelectWidth={false}
            placeholder="Nombre del sacerdote"
            notFoundContent={loading ? <Spin size="small" /> : null}
            onSearch={name => {
                if (busy) {
                    clearTimeout(busy);
                }
                const params = {
                    search: name,
                };
                setLoading(true);

                busy = setTimeout(() => searchRecords(params), 400);
            }}
            {...props}
        >
            {records.map((record: { id: number; name: string }) => {
                return (
                    <>
                        <Select.Option key={record.id} value={record.id} data={record}>
                            {record.name && capitalizeFirstLetter(record.name)}
                        </Select.Option>
                    </>
                );
            })}
        </Select>
    );
});

export default PriestSearchSelect;
