import React from 'react';
import { Space, Divider, Tag } from 'antd';
import { observer } from 'mobx-react';
import { Spin } from 'antd/es';
import { capitalizeFirstLetter } from '../../../library';
import { RiCloseCircleLine, RiErrorWarningLine, RiPlayCircleLine, RiPrinterLine } from 'react-icons/ri';
import { TbCircleCheck } from 'react-icons/tb';
import { MdOutlinePublishedWithChanges } from 'react-icons/md';

interface Props {
    registrations: any[] | [];
    loading: boolean;
    setDrawerCertificate: (value: boolean, registration_id: number) => void;
    handlePatch: (object: any, registration_id: number) => void;
    setModalChangeCourse: (value: boolean, registration_id: number) => void;
}

export const MembersCourseTable = observer(
    ({ registrations, loading, setDrawerCertificate, handlePatch, setModalChangeCourse }: Props) => {
        return (
            <div className="relative overflow-x-auto">
                <Spin spinning={loading}>
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="py-3 px-6">
                                    #
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Integrante
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Estado
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    <span className="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {registrations.length > 0 ? (
                                registrations.map((record: any, index: number) => {
                                    return (
                                        <>
                                            <tr
                                                key={index}
                                                className={
                                                    index % 2 == 0
                                                        ? 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'
                                                        : 'bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700'
                                                }
                                            >
                                                <td
                                                    scope="row"
                                                    className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    {++index}
                                                </td>
                                                <td className={'py-4 px-6'}>
                                                    <div className={'font-bold'}>
                                                        {record.member.last_name !== null && (
                                                            <span>
                                                                {capitalizeFirstLetter(record.member.last_name)}
                                                                &nbsp;
                                                            </span>
                                                        )}
                                                        {capitalizeFirstLetter(record.member.name)}
                                                    </div>
                                                </td>
                                                <td className={'py-4 px-6'}>
                                                    {record.status.toLowerCase() === 'en curso' ? (
                                                        <Tag color={'purple'}>{record.status}</Tag>
                                                    ) : record.status.toLowerCase() === 'aprobado' ? (
                                                        <Tag color={'#1fb529'}>{record.status}</Tag>
                                                    ) : record.status.toLowerCase() === 'reprobado' ? (
                                                        <Tag color={'#de3415'}>{record.status}</Tag>
                                                    ) : (
                                                        record.status.toLowerCase() === 'retirado' && (
                                                            <Tag color={'#fd832b'}>{record.status}</Tag>
                                                        )
                                                    )}
                                                </td>
                                                <td className={'py-4 px-6'}>
                                                    <Space size="middle">
                                                        <TbCircleCheck
                                                            className="cursor-pointer transform hover:scale-150 duration-75"
                                                            title="Aprobar"
                                                            color={'green'}
                                                            onClick={async () => {
                                                                await handlePatch({ status: 'Aprobado' }, record.id);
                                                            }}
                                                        />

                                                        <RiCloseCircleLine
                                                            className="cursor-pointer transform hover:scale-150 duration-75"
                                                            title="Reprobar"
                                                            color={'red'}
                                                            onClick={async () => {
                                                                await handlePatch({ status: 'Reprobado' }, record.id);
                                                            }}
                                                        />

                                                        <RiErrorWarningLine
                                                            className="cursor-pointer transform hover:scale-150 duration-75"
                                                            title="Retirado"
                                                            color={'purple'}
                                                            onClick={async () => {
                                                                await handlePatch({ status: 'Retirado' }, record.id);
                                                            }}
                                                        />

                                                        <RiPlayCircleLine
                                                            className="cursor-pointer transform hover:scale-150 duration-75"
                                                            title="En Curso"
                                                            color={'#26BBD3'}
                                                            onClick={async () => {
                                                                await handlePatch({ status: 'En Curso' }, record.id);
                                                            }}
                                                        />

                                                        <Divider type="vertical" style={{ backgroundColor: 'black' }} />

                                                        <RiPrinterLine
                                                            className="cursor-pointer transform hover:scale-150 duration-75"
                                                            title="Inprimir Certificado"
                                                            onClick={() => {
                                                                setDrawerCertificate &&
                                                                    setDrawerCertificate(true, record.id);
                                                            }}
                                                        />
                                                        <MdOutlinePublishedWithChanges
                                                            className="cursor-pointer transform hover:scale-150 duration-75"
                                                            title="Cambiar Grupo"
                                                            color={'orange'}
                                                            onClick={() => {
                                                                setModalChangeCourse &&
                                                                    setModalChangeCourse(true, record.id);
                                                            }}
                                                        />
                                                    </Space>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })
                            ) : (
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td className="py-4 px-6" colSpan={4}>
                                        <div className={'flex justify-center'}>No existe registros</div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Spin>
            </div>
        );
    }
);
