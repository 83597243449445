import { makeObservable, observable, runInAction } from 'mobx';
import { get, patch, post, remove } from '../../../utils/api';
import { RegistrationProps } from '../types';

class RegistrationStore {
    registrations: RegistrationProps[] = [];
    registration: RegistrationProps | null = null;
    totals: { total: number; woman_total: number; man_total: number; percent_woman: number; percent_man: number } = {
        total: 0,
        woman_total: 0,
        man_total: 0,
        percent_woman: 0,
        percent_man: 0,
    };
    pagination: { defaultPageSize: number; total: number } = { defaultPageSize: 20, total: 0 };
    payload: any | null;

    constructor() {
        makeObservable(this, {
            registrations: observable,
            registration: observable,
            pagination: observable,
            payload: observable,
        });
    }

    async view(id: number) {
        const response = await get(`/staff/catechism/registration/${id}`);
        runInAction(() => {
            this.registration = response.data;
        });
    }

    async list(params = {}) {
        await this.reset_totals();
        const response = await get(`/staff/catechism/registration/`, params);
        if (response) {
            runInAction(() => {
                this.registrations = response.data.results;
                this.pagination = {
                    defaultPageSize: 20,
                    total: response.data.count,
                };
            });
        }
        return response.data;
    }

    async get_totals(params = {}) {
        const response_totals = await get(`/staff/catechism/registration/totals`, params);
        if (response_totals) {
            runInAction(() => {
                this.totals.total = response_totals.data.count;
                this.totals.percent_man = response_totals.data.percent_man.toFixed(2);
                this.totals.percent_woman = response_totals.data.percent_woman.toFixed(2);
                this.totals.man_total = response_totals.data.man_total;
                this.totals.woman_total = response_totals.data.woman_total;
            });
        }
    }

    async create(payload: RegistrationProps) {
        const response = await post(`/staff/catechism/registration/`, payload);
        runInAction(() => {
            this.registrations.push(response);
        });
        return response.data;
    }

    async patch(payload = {}, id: number) {
        const response = await patch(`/staff/catechism/registration/${id}/`, payload);
        runInAction(() => {
            this.registration = response.data;
        });
        return response.data;
    }

    async remove(id: number) {
        return await remove(`/staff/catechism/registration/${id}`);
    }

    async reset_totals() {
        this.totals.total = 0;
        this.totals.percent_man = 0;
        this.totals.percent_woman = 0;
        this.totals.man_total = 0;
        this.totals.woman_total = 0;
    }

    sync_payload(payload: any | null) {
        if (payload !== null) {
            this.payload = payload;
        } else {
            this.payload = null;
        }
    }
}

export default new RegistrationStore();
