import React from 'react';
import { FileImageOutlined } from '@ant-design/icons';
import { Badge, List, Avatar, Button, Skeleton, ConfigProvider, Dropdown, Menu } from 'antd';
import { Empty } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { INotificationProps } from '../../utils/types';
import { RiNotification3Line } from 'react-icons/ri';
dayjs.extend(relativeTime);

interface IProps {
    data: INotificationProps[];
    loading: boolean;
    makeRead: (id: number) => void;
}

const Notifications: React.FC<IProps> = ({ data, loading, makeRead }) => {
    const unReadNotifications = data.filter(item => {
        return !item.read;
    });

    const emptyTable = () => {
        return (
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span style={{ color: '#000' }}>No tienes notifaciones</span>}
            />
        );
    };

    const menu = (
        <Menu
            style={{
                width: 450,
            }}
            items={[
                {
                    key: '1',
                    label: (
                        <ConfigProvider renderEmpty={(data.length === 0 ? 'customize' : 'default') && emptyTable}>
                            <List
                                style={{
                                    padding: '15px 5px',
                                    height: '50vh',
                                    background: 'rgb(252, 252, 252)',
                                }}
                                className="overflow-y-auto"
                                header={
                                    <>
                                        <p className="text-xl font-bold pl-2 m-0">Notificaciones</p>
                                        <p className="text-sm pl-2 m-0 text-alpes_green-700">
                                            Tienes {unReadNotifications.length} notificaciones sin leer
                                        </p>
                                    </>
                                }
                                itemLayout="horizontal"
                                dataSource={data}
                                size="small"
                                rowKey={(record: any) => record.id}
                                renderItem={item => (
                                    <List.Item
                                        style={{
                                            backgroundColor: !item.read ? '#d6ebf775' : 'transparent',
                                            borderRadius: 5,
                                        }}
                                        className="mt-1"
                                    >
                                        <Skeleton avatar title={false} loading={loading} active>
                                            <List.Item.Meta
                                                avatar={
                                                    <Avatar
                                                        style={{ backgroundColor: '#026de728' }}
                                                        icon={<FileImageOutlined />}
                                                    />
                                                }
                                                title={
                                                    <div className="flex justify-between">
                                                        <a
                                                            style={{
                                                                color: item.read ? '#999' : '#000000c1',
                                                                width: '12.3vw',
                                                            }}
                                                            href={item.url ? item.url : '#'}
                                                            onClick={() => makeRead(item.id)}
                                                            className="font-bold"
                                                        >
                                                            {item.title}
                                                        </a>
                                                        <span style={{ color: '#999', width: '8vw' }}>
                                                            {dayjs(item.created_at).fromNow()}
                                                        </span>
                                                    </div>
                                                }
                                                description={
                                                    <div className="flex flex-col">
                                                        <a
                                                            style={{ color: item.read ? '#999' : '#000000c1' }}
                                                            href={item.url ? item.url : ''}
                                                            onClick={() => makeRead(item.id)}
                                                        >
                                                            {item.content}
                                                        </a>
                                                        {!item.read && (
                                                            <Button
                                                                key={item.id}
                                                                style={{
                                                                    border: 0,
                                                                    backgroundColor: 'transparent',
                                                                }}
                                                                onClick={() => makeRead(item.id)}
                                                            >
                                                                <span>Marcar como leida</span>
                                                            </Button>
                                                        )}
                                                    </div>
                                                }
                                            />
                                        </Skeleton>
                                    </List.Item>
                                )}
                            />
                        </ConfigProvider>
                    ),
                },
            ]}
        ></Menu>
    );

    return (
        <Dropdown overlay={menu}>
            <a onClick={e => e.preventDefault()}>
                <div className="flex flex-row items-center">
                    <Badge count={unReadNotifications.length}>
                        <RiNotification3Line className="text-slate-700 mr-1" size="20px" />
                    </Badge>
                </div>
            </a>
        </Dropdown>
    );
};

export default Notifications;
