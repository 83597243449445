import { makeObservable, observable, runInAction } from 'mobx';
import { get, patch, post, remove } from '../../../utils/api';
import { AreaProps, ArticleProps, SubareaProps } from '../types';

class AreaStore {
    areas: AreaProps[] = [];
    articles: ArticleProps[] = [];
    subareas: SubareaProps[] = [];
    area: AreaProps | null = null;
    pagination: { defaultPageSize: number; total: number } = { defaultPageSize: 20, total: 0 };
    pagination_articles: { defaultPageSize: number; total: number } = { defaultPageSize: 20, total: 0 };
    pagination_subareas: { defaultPageSize: number; total: number } = { defaultPageSize: 20, total: 0 };

    constructor() {
        makeObservable(this, {
            areas: observable,
            articles: observable,
            subareas: observable,
            area: observable,
            pagination: observable,
            pagination_articles: observable,
            pagination_subareas: observable,
        });
    }

    async view(id: number) {
        const response = await get(`/staff/inventory/area/${id}`);
        runInAction(() => {
            this.area = response.data;
        });
    }

    async list(params = {}) {
        const response = await get(`/staff/inventory/area/`, params);
        runInAction(() => {
            this.areas = response.data.results;
            this.pagination = {
                defaultPageSize: 20,
                total: response.data.count,
            };
        });
        return response.data.results;
    }

    async create(payload: AreaProps) {
        const response = await post(`/staff/inventory/area/`, payload);
        runInAction(() => {
            this.areas.push(response.data);
        });
        return response.data;
    }

    async patch(payload: any, id: number) {
        const response = await patch(`/staff/inventory/area/${id}/`, payload);
        runInAction(() => {
            this.area = response.data;
        });
        return response.data;
    }

    async remove(id: number) {
        return await remove(`/staff/inventory/area/${id}`).then(async () => await this.list());
    }

    /**articles by area***/
    async listArticles(id: number, params = {}) {
        const response = await get(`/staff/inventory/area/${id}/articles/`, params);
        runInAction(() => {
            this.articles = response.data.results;
            this.pagination_articles = {
                defaultPageSize: 20,
                total: response.data.count,
            };
        });
        return response.data.results;
    }

    /**subareas by area***/
    async listSubareas(id: number, params = {}) {
        const response = await get(`/staff/inventory/area/${id}/subareas/`, params);
        runInAction(() => {
            this.subareas = response.data.results;
            this.pagination_subareas = {
                defaultPageSize: 20,
                total: response.data.count,
            };
        });
        return response.data.results;
    }
}

export default new AreaStore();
