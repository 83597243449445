import React, { useState } from 'react';
import _ from 'lodash';
import { Select } from 'antd';
import { AreaProps, SubareaProps } from '../types';
import AreaStore from '../stores/AreaStore';

const { Option } = Select;

interface Props {
    style: any;
    onChange?: (id: number) => void;
    setSubareas?: (params: SubareaProps[]) => void;
}

export const AreaSelect = ({ onChange, style, setSubareas }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<Array<AreaProps>>([]);

    const handleSearch = _.debounce(async (value: string) => {
        setLoading(!loading);
        if (value) {
            AreaStore.list({ search: value }).then(() => {
                setData(AreaStore.areas);
            });
            setLoading(false);
        } else {
            setData([]);
        }
    }, 500);

    const change = (id: number) => {
        if (typeof onChange === 'function') {
            onChange(id);
        }
    };

    return (
        <Select
            key={'select-area'}
            showSearch
            allowClear
            className="form-control show-tick"
            loading={loading}
            placeholder="Buscar Area"
            style={style}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            notFoundContent={null}
            onChange={(id: string, row: any) => {
                if (row && setSubareas) {
                    change(row.key);
                    setSubareas(row.subareas);
                }
            }}
        >
            {data.map((area: AreaProps) => {
                return (
                    <Option key={area.id} subareas={area.subareas} value={area.name}>
                        {area.name}
                    </Option>
                );
            })}
        </Select>
    );
};
