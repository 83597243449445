import { makeObservable, observable, runInAction } from 'mobx';
import { get, patch, post, remove } from '../../../utils/api';
import { DeathProps } from '../types';

class DeathStore {
    deaths: DeathProps[] = [];
    death: DeathProps | null = null;
    pagination: { defaultPageSize: number; total: number } = { defaultPageSize: 20, total: 0 };

    constructor() {
        makeObservable(this, {
            deaths: observable,
            death: observable,
            pagination: observable,
        });
    }

    async get(id: number) {
        const response = await get(`/staff/books/death/${id}`);
        runInAction(() => {
            this.death = response.data;
        });
    }

    async list(params = {}) {
        const response = await get(`/staff/books/death/`, params);
        runInAction(() => {
            this.deaths = response.data.results;
            this.pagination = {
                defaultPageSize: 20,
                total: response.data.count,
            };
        });
        return response.data.results;
    }

    async create(payload: DeathProps) {
        const response = await post(`/staff/books/death/`, payload);
        runInAction(() => {
            this.deaths.push(response.data);
        });
        return response.data;
    }

    async patch(payload = {}, id?: number) {
        const response = await patch(`/staff/books/death/${id}/`, payload);
        runInAction(() => {
            this.death = response.data;
            this.list();
        });
        return response.data;
    }

    async remove(id: number) {
        return await remove(`/staff/books/death/${id}`).then(() => {
            this.list();
        });
    }
}

export default new DeathStore();
