import * as React from 'react';
import { Select } from 'antd';
import { LevelProps } from '../types';

const LevelSelect = (props: any) => {
    return (
        <Select
            showSearch
            allowClear
            labelInValue
            style={{ width: '100%' }}
            dropdownMatchSelectWidth={false}
            placeholder={'Seleccionar nivel'}
            filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            {...props}
        >
            {props.levels?.map((level: LevelProps) => {
                return (
                    <Select.Option key={level.id} value={level.id}>
                        {level.alias}
                    </Select.Option>
                );
            })}
        </Select>
    );
};

export default LevelSelect;
