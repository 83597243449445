import React from 'react';
import { notification, Popconfirm, Space, Typography } from 'antd';
import PeriodStore from '../stores/PeriodStore';
import { PeriodProps } from '../types';
import { Spin } from 'antd/es';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { TiLockClosed } from 'react-icons/ti';
import { IoIosUnlock } from 'react-icons/io';
import { toastSuccess } from '../../../utils/toast';
import ErrorRender from '../../../library/outlets/ErrorRender';

interface Props {
    loading: boolean;
    records: PeriodProps[];
    handleRemove: (id: number) => void;
}

export const PeriodsTable = ({ loading, handleRemove, records }: Props) => {
    return (
        <Spin spinning={loading}>
            <div className="overflow-x-auto relative">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="py-3 px-6">
                                #
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Inicio
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Fin
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Período
                            </th>
                            <th scope="col" className="py-3 px-6">
                                Estado
                            </th>
                            <th scope="col" className="py-3 px-6">
                                <span className="sr-only">Edit</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {records.map((record: PeriodProps, index: number) => {
                            return (
                                <tr
                                    key={index}
                                    className={
                                        index % 2 == 0
                                            ? 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'
                                            : 'bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700'
                                    }
                                >
                                    <th
                                        scope="row"
                                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        {++index}
                                    </th>
                                    <td className="py-4 px-6">{record.date_start}</td>
                                    <td className="py-4 px-6">{record.date_end}</td>
                                    <td className="py-4 px-6">{record.period}</td>
                                    <td className="py-4 px-6">
                                        {record.status ? (
                                            <span className="bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
                                                Activado
                                            </span>
                                        ) : (
                                            <span className={'text-gray-400'}>Bloqueado</span>
                                        )}
                                    </td>
                                    <td className="py-4 px-6">
                                        <Space size="middle">
                                            {record.status ? (
                                                <IoIosUnlock
                                                    className="cursor-pointer transform hover:scale-150 duration-75"
                                                    color={'green'}
                                                />
                                            ) : (
                                                <TiLockClosed
                                                    className="cursor-pointer transform hover:scale-150 duration-75 ml-1"
                                                    title="Activar"
                                                    onClick={() => {
                                                        if (record.id)
                                                            PeriodStore.enable_period(record.id)
                                                                .then(() => toastSuccess('Período activado'))
                                                                .catch((err: any) => {
                                                                    notification.error({
                                                                        message: <ErrorRender error={err} />,
                                                                        placement: 'topRight',
                                                                    });
                                                                });
                                                    }}
                                                />
                                            )}
                                        </Space>

                                        <Space size="middle" className="px-2">
                                            <Popconfirm
                                                title="Eliminar período?"
                                                onConfirm={() => record.id && handleRemove && handleRemove(record.id)}
                                                okText={'Eliminar'}
                                                cancelText={'Cancelar'}
                                            >
                                                <Typography.Link>
                                                    <RiDeleteBin2Fill
                                                        className="cursor-pointer transform hover:scale-150 duration-75 ml-1"
                                                        color="#DE3E1B"
                                                        title={'Eliminar'}
                                                    />
                                                </Typography.Link>
                                            </Popconfirm>
                                        </Space>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </Spin>
    );
};
