import React from 'react';
import { Button, Form, Input } from 'antd';
import { toastError } from '../../../utils/toast';
import { BsFileEarmarkRichtext } from 'react-icons/bs';
import { Strings } from '../../../utils/strings';
import { MdCleaningServices } from 'react-icons/md';

const { TextArea } = Input;

interface Props {
    getFileUrl: (payload: any) => void;
    type: string;
}

export const HonorabilityForm = (props: Props) => {
    const [form] = Form.useForm();
    const onFinish = (values: any) => {
        const payload = {
            name: values.name,
            idNumber: values.idNumber,
            additionalText: values.aditionalText,
            type: props.type,
        };
        if (props.type == 'A4') {
            props.getFileUrl(payload);
        } else {
            toastError('Este certificado no esta disponible en tamaño A5');
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log(errorInfo);
    };

    return (
        <Form className="p-6" form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <div className="grid grid-cols-2 gap-4">
                <Form.Item
                    label={'Nombre'}
                    name="name"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={'Cédula de Identidad'}
                    name="idNumber"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>
            </div>

            <div className="grid grid-cols-1 gap-4">
                <Form.Item label={'Texto adicional'} name="aditionalText">
                    <TextArea autoSize />
                </Form.Item>
            </div>

            <Form.Item>
                <div className={'flex flex-row justify-center space-x-4 pt-4'}>
                    <Button
                        className={'mx-2 second'}
                        type="primary"
                        htmlType="submit"
                        icon={<BsFileEarmarkRichtext className="inline-flex mr-1" size={'18px'} />}
                    >
                        {Strings.actions.generate_certificate}
                    </Button>
                    <Button
                        className={'mx-2'}
                        onClick={() => {
                            form.resetFields();
                        }}
                        icon={<MdCleaningServices className="inline-flex mr-1" size={'18px'} />}
                    >
                        {Strings.actions.clean_form}
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
};
