import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Divider, Form, Input, Radio, Checkbox, notification } from 'antd';
import { Strings } from '../../../utils/strings';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import { observer } from 'mobx-react';
import { MemberProps } from '../types';
import BaptismStore from '../../books/stores/BaptismStore';
import { capitalizeFirstLetter } from '../../../library';
import ErrorRender from '../../../library/outlets/ErrorRender';
import { BaptizedSelect } from '../selects';
import { VscSaveAll } from 'react-icons/vsc';
import { FcCancel } from 'react-icons/fc';
import { MdOutlineCleaningServices } from 'react-icons/md';
import MemberStore from '../stores/MemberStore';

interface Props {
    type: 'create' | 'edit';
    member?: any;
    handleCreate?: (payload: any) => void;
    handleUpdate?: (payload: any, id: number) => void;
    record?: any | null;
    setIsModalVisible: (value: boolean) => void;
}

export const MemberForm = observer(({ type, setIsModalVisible, handleCreate, handleUpdate }: Props) => {
    const [form] = Form.useForm();
    const { getFieldValue } = form;
    const [gender, setGender] = useState(true);
    const [foreignBaptism, setForeignBaptism] = useState(MemberStore.member ? MemberStore.member.bau_for : false);

    const has_foreign_christening = getFieldValue('bau_for');

    const onChangeGender = (e: any) => {
        setGender(e.target.value);
    };

    const onChangeForeignBaptism = () => {
        setForeignBaptism(!foreignBaptism);
    };

    const onFinish = (values: any) => {
        const payload = {
            card_id: values.card_id,
            nationality: values.nationality,
            name: values.name,
            last_name: values.last_name,
            father: values.father,
            mother: values.mother,
            address: values.address,
            cellphone: values.cellphone,
            phone: values.phone,
            gender: gender,
            bau_for: foreignBaptism,
            bau_fec: values.birth_date ? moment(values.bau_fec).format('YYYY-MM-DD') : null,
            bau_note: values.bau_note,
            bau_par: values.bau_par,
            bau_lug: values.bau_lug,
            birth_date: values.birth_date ? moment(values.birth_date).format('YYYY-MM-DD') : null,
            birth_place: values.birth_place,
        };

        if (type === 'create' && handleCreate) {
            handleCreate(payload);
        } else if (type === 'edit' && handleUpdate) {
            const data = {
                ...payload,
                bau_for: has_foreign_christening,
                id: MemberStore.member && MemberStore.member.id,
            };
            if (MemberStore.member && MemberStore.member.id) handleUpdate(data, MemberStore.member.id);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    async function handleGetMember(member_id: number) {
        try {
            await BaptismStore.get(member_id).then(() => {
                if (BaptismStore.baptism !== null) {
                    const member: MemberProps = {
                        card_id: BaptismStore.baptism.card_id,
                        name: capitalizeFirstLetter(BaptismStore.baptism.member),
                        father: BaptismStore.baptism.father && capitalizeFirstLetter(BaptismStore.baptism.father),
                        mother: BaptismStore.baptism.mother && capitalizeFirstLetter(BaptismStore.baptism.mother),
                        address:
                            BaptismStore.baptism.birth_place && capitalizeFirstLetter(BaptismStore.baptism.birth_place),
                        nationality: 'Ecuatoriana',
                        bau_for: false,
                        gender: false,
                        birth_date: BaptismStore.baptism?.birth_date
                            ? moment(BaptismStore.baptism.birth_date, 'YYYY-MM-DD')
                            : '',
                        birth_place:
                            BaptismStore.baptism?.birth_place &&
                            capitalizeFirstLetter(BaptismStore.baptism.birth_place),
                    };
                    MemberStore.syncMember(member);
                }
            });
            form.resetFields();
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
    }

    useEffect(() => {
        form.resetFields();
    }, []);

    useEffect(() => {
        form.resetFields();
    }, [MemberStore.member]);

    return (
        <>
            {type === 'create' && (
                <Form.Item label={'Buscar integrante en Bautizos'} help={'Posibles coincidencias'}>
                    <BaptizedSelect onChange={handleGetMember} />
                </Form.Item>
            )}
            <div className={'mt-4'}>
                <Form
                    form={form}
                    initialValues={{
                        card_id: MemberStore.member?.card_id,
                        name: MemberStore.member?.name && capitalizeFirstLetter(MemberStore.member.name),
                        last_name: MemberStore.member?.last_name && capitalizeFirstLetter(MemberStore.member.last_name),
                        father: MemberStore.member?.father && capitalizeFirstLetter(MemberStore.member.father),
                        mother: MemberStore.member?.mother && capitalizeFirstLetter(MemberStore.member.mother),
                        address: MemberStore.member?.address && capitalizeFirstLetter(MemberStore.member.address),
                        cellphone: MemberStore.member?.cellphone,
                        phone: MemberStore.member?.phone,
                        birth_date: MemberStore.member?.birth_date
                            ? moment(MemberStore.member?.birth_date, 'YYYY-MM-DD')
                            : '',
                        birth_place:
                            MemberStore.member?.birth_place && capitalizeFirstLetter(MemberStore.member.birth_place),
                        bau_fec: MemberStore.member?.bau_fec ? moment(MemberStore.member?.bau_fec, 'YYYY-MM-DD') : '',
                        bau_note: MemberStore.member?.bau_note && capitalizeFirstLetter(MemberStore.member?.bau_note),
                        bau_par: MemberStore.member?.bau_par && capitalizeFirstLetter(MemberStore.member?.bau_par),
                        bau_lug: MemberStore.member?.bau_lug && capitalizeFirstLetter(MemberStore.member?.bau_lug),
                    }}
                    name="add-edit-member"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                >
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-4">
                        <Form.Item label={'Cédula'} name="card_id">
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={'Nacionalidad'}
                            name="nationality"
                            rules={[{ required: true, message: Strings.inputs.errors.required }]}
                            initialValue={'Ecuatoriana'}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-4">
                        <Form.Item
                            label={'Nombres'}
                            name="name"
                            rules={[{ required: true, message: Strings.inputs.errors.required }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={'Apellidos'}
                            name="last_name"
                            rules={[{ required: true, message: Strings.inputs.errors.required }]}
                        >
                            <Input />
                        </Form.Item>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <Form.Item label={'Papá'} name="father">
                            <Input />
                        </Form.Item>

                        <Form.Item label={'Mamá'} name="mother">
                            <Input />
                        </Form.Item>
                    </div>

                    <div className="grid grid-cols-4 gap-4">
                        <Form.Item className="col-span-2" label={'Dirección'} name="address">
                            <Input />
                        </Form.Item>
                        <Form.Item label={'Celular'} name="cellphone">
                            <Input />
                        </Form.Item>
                        <Form.Item label={'Telefono'} name="phone">
                            <Input />
                        </Form.Item>
                    </div>

                    <div className="grid grid-cols-1 gap-4">
                        <Form.Item name="gender" initialValue={MemberStore.member ? MemberStore.member.gender : gender}>
                            <Radio.Group onChange={onChangeGender}>
                                <Radio value={true}>{'Hombre'}</Radio>
                                <Radio value={false}>{'Mujer'}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>

                    <div className="grid grid-cols-1 gap-4">
                        <Form.Item
                            name="bau_for"
                            initialValue={MemberStore.member ? MemberStore.member.bau_for : false}
                            valuePropName={'checked'}
                        >
                            <Checkbox onClick={onChangeForeignBaptism}>{'Bautizo Foráneo'}</Checkbox>
                        </Form.Item>
                    </div>

                    {has_foreign_christening && (
                        <>
                            <Divider>{'Datos del bautizo foráneo'}</Divider>
                            <div className="grid grid-cols-3 gap-4">
                                <Form.Item
                                    label={'Fecha de Bautizo'}
                                    name="bau_fec"
                                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                                >
                                    <DatePicker locale={locale} className="form-control" style={{ width: '100%' }} />
                                </Form.Item>
                                <Form.Item
                                    label={'Parroquia'}
                                    name="bau_par"
                                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={'Lugar'}
                                    name="bau_lug"
                                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                                >
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className="grid grid-cols-1 gap-4">
                                <Form.Item label={'Nota'} name="bau_note">
                                    <Input />
                                </Form.Item>
                            </div>
                        </>
                    )}

                    <Divider>{'Datos de Nacimiento'}</Divider>

                    <div className="grid grid-cols-5 gap-4">
                        <Form.Item
                            className="col-span-2"
                            label={'Fecha de Nacimiento'}
                            name="birth_date"
                            help={'F. aaaa-mm-dd'}
                        >
                            <DatePicker locale={locale} className="form-control" style={{ width: '100%' }} />
                        </Form.Item>

                        <Form.Item className="col-span-3" label={'Lugar'} name="birth_place">
                            <Input />
                        </Form.Item>
                    </div>

                    <Form.Item>
                        <div className={'flex flex-row-reverse'}>
                            <Button
                                className={'mx-2 second'}
                                type="primary"
                                htmlType="submit"
                                icon={<VscSaveAll className="inline-flex mr-1" size={'18px'} />}
                            >
                                {Strings.actions.save}
                            </Button>
                            <Button
                                className={'mx-2 remove'}
                                onClick={async () => {
                                    await MemberStore.syncMember(null);
                                    setIsModalVisible(false);
                                    form.resetFields();
                                }}
                                icon={<FcCancel className="inline-flex mr-1" size={'18px'} />}
                            >
                                {Strings.actions.cancel}
                            </Button>
                            {type === 'create' && (
                                <Button
                                    className={'mx-2'}
                                    onClick={async () => {
                                        await MemberStore.syncMember(null);
                                        form.resetFields();
                                    }}
                                    icon={<MdOutlineCleaningServices className="inline-flex mr-1" size={'18px'} />}
                                >
                                    {Strings.actions.clean_form}
                                </Button>
                            )}
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
});
