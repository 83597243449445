import React from 'react';
import { Button, Popconfirm, Typography } from 'antd';
import { RiDeleteBin2Fill, RiHome6Line } from 'react-icons/ri';
import { AreaProps } from '../types';

interface Props {
    areas: Array<AreaProps>;
    setArea: (record: AreaProps) => void;
    handleRemove: (id: number) => void;
    handleDownload: (id: number, name: string) => void;
}

export const AreaCard = (props: Props) => {
    return (
        <>
            {props.areas.map(area => (
                <article key={area.id} className="bg-white border-2 border-gray-100 rounded-xl">
                    <div className="flex items-start p-4">
                        <div>
                            <RiHome6Line style={{ fontSize: '40px' }} />
                        </div>

                        <div className="ml-4">
                            <div className="flex items-center gap-4">
                                <strong className="font-semibold sm:text-lg xl:text-sm ml-4">{area.name}</strong>
                                <Popconfirm
                                    title={'Eliminar área?'}
                                    onConfirm={() => area.id && props.handleRemove(area.id)}
                                    okText={'Eliminar'}
                                    cancelText={'Cancelar'}
                                >
                                    <Typography.Link>
                                        <RiDeleteBin2Fill
                                            className="cursor-pointer transform hover:scale-150 duration-75 ml-1"
                                            color="#DE3E1B"
                                            title={'Eliminar'}
                                        />
                                    </Typography.Link>
                                </Popconfirm>
                            </div>
                            <div className="mt-2 ml-4 text-gray-600">
                                <div className="flex items-center text-gray-400 my-2">
                                    <span className={'font-semibold'}>Subáreas</span>
                                    <span className="text-sm line-clamp-2 ml-4">{area.subareas?.length}</span>
                                </div>
                                <div className="flex items-center text-gray-400 my-2">
                                    <span className={'font-semibold'}>Artículos</span>
                                    <span className="text-sm line-clamp-2 ml-4">{area.articles}</span>
                                </div>
                                <div className="flex items-center text-gray-400 my-2">
                                    <Button
                                        type="primary"
                                        size={'small'}
                                        onClick={() => {
                                            area.id && props.handleDownload(area.id, area.name);
                                        }}
                                    >
                                        Exportar a PDF
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className="flex justify-end"
                        onClick={() => {
                            props.setArea(area);
                        }}
                    >
                        <span className="hover:cursor-pointer -mr-[2px] -mb-[2px] inline-flex items-center gap-1 rounded-tl-xl rounded-br-xl bg-gray-600 hover:bg-primary py-1.5 px-3 text-white hover:text-white">
                            <span className="text-[10px] font-medium sm:text-xs">Editar</span>
                        </span>
                    </div>
                </article>
            ))}
        </>
    );
};
