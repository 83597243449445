import React from 'react';
import { Popconfirm, Tag, Typography } from 'antd';
import { PhoneFilled, MailFilled, UserOutlined } from '@ant-design/icons';
import { ContactProps } from '../types';
import { RiDeleteBin2Fill } from 'react-icons/ri';

interface Props {
    contacts: Array<ContactProps>;
    setContact: (record: ContactProps) => void;
    handleRemove: (id: number) => void;
    setIsDetailContactModalVisible: (value: boolean) => void;
}

export const ContactCard = ({ contacts, setContact, setIsDetailContactModalVisible, handleRemove }: Props) => {
    return (
        <>
            {contacts.map(contact => (
                <article key={contact.id} className="bg-white border-2 border-gray-100 rounded-xl">
                    <div className="flex items-start p-4">
                        <div>
                            <UserOutlined style={{ fontSize: '40px' }} />
                        </div>

                        <div className="ml-4">
                            <div className="flex items-center gap-4">
                                <strong className="font-semibold sm:text-lg xl:text-sm ml-4">{contact.name}</strong>
                                <Popconfirm
                                    title={'Eliminar contacto?'}
                                    onConfirm={() => contact.id && handleRemove(contact.id)}
                                    okText={'Eliminar'}
                                    cancelText={'Cancelar'}
                                >
                                    <Typography.Link>
                                        <RiDeleteBin2Fill
                                            className="cursor-pointer transform hover:scale-150 duration-75 ml-1"
                                            color="#DE3E1B"
                                            title={'Eliminar'}
                                        />
                                    </Typography.Link>
                                </Popconfirm>
                            </div>
                            <p className="ml-4 mt-1 mb-2 text-sm" style={{ color: '#1FB529' }}>
                                {contact.categories.map((category: string, index: number) => {
                                    return (
                                        <Tag key={index} color={'purple'}>
                                            {category}
                                        </Tag>
                                    );
                                })}
                            </p>
                            <div className="mt-2 ml-4 text-gray-600">
                                <div className="flex items-center my-2">
                                    <MailFilled />
                                    <span className="text-sm line-clamp-2 ml-4">{contact.email}</span>
                                </div>

                                <div className="flex items-center my-2">
                                    <PhoneFilled />
                                    <span className="text-sm line-clamp-2 ml-4">{contact.mobile}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className="flex justify-end"
                        onClick={() => {
                            setContact(contact);
                            setIsDetailContactModalVisible(true);
                        }}
                    >
                        <a className="hover:cursor-pointer -mr-[2px] -mb-[2px] inline-flex items-center gap-1 rounded-tl-xl rounded-br-xl bg-gray-600 hover:bg-primary py-1.5 px-3 text-white hover:text-white">
                            <span className="text-[10px] font-medium sm:text-xs">Ver más</span>
                        </a>
                    </div>
                </article>
            ))}
        </>
    );
};
