import { get, post } from '../utils/api';

class NotificationStore {
    async getNotifications() {
        return await get('/staff/notification/');
    }

    async makeNotificationRead(id: number) {
        return await post(`/staff/notification/${id}/read/`);
    }
}
export default new NotificationStore();
