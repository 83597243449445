import React from 'react';
import { Link } from 'react-router-dom';
import { Popconfirm, Space, Typography } from 'antd';
import { observer } from 'mobx-react';
import { Spin } from 'antd/es';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { MdManageAccounts } from 'react-icons/md';
import { PaginationTable } from '../../../library';
import CourseStore from '../stores/CourseStore';
import { CourseProps } from '../types';

interface Props {
    loading: boolean;
    handleRemove?: (id: number) => void;
}

export const CoursesTable = observer(({ loading, handleRemove }: Props) => {
    return (
        <>
            <Spin spinning={loading}>
                <div className="overflow-x-auto relative">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="py-3 px-6">
                                    Grupo
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Nivel
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Comunidad
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Integrantes
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    <span className="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {CourseStore.courses.length > 0 ? (
                                CourseStore.courses.map((record: CourseProps, index: number) => {
                                    return (
                                        <tr
                                            key={index}
                                            className={
                                                index % 2 == 0
                                                    ? 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'
                                                    : 'bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700'
                                            }
                                        >
                                            <td className="py-4 px-6">{record.name}</td>
                                            <td className="py-4 px-6">
                                                <div className={'font-bold'}>
                                                    {typeof record.level === 'object' ? record.level.alias : ''}
                                                </div>
                                                <div className={'text-xs'}>
                                                    {typeof record.level === 'object' ? record.level.name : ''}
                                                </div>
                                            </td>
                                            <td className="py-4 px-6">
                                                {typeof record.community === 'object' ? record.community.name : ''}
                                            </td>
                                            <td className="py-4 px-6">
                                                {record.total_registrations + '/' + record.quota}
                                            </td>
                                            <td className="py-4 px-6">
                                                <Space size="middle" className="px-2">
                                                    <Link to={`/catechism/course/${record.id}`}>
                                                        <MdManageAccounts
                                                            color={'green'}
                                                            title={'Gestionar Grupo'}
                                                            className="cursor-pointer transform hover:scale-150 duration-75"
                                                        />
                                                    </Link>
                                                </Space>

                                                <Space size="middle" className="px-2">
                                                    <Popconfirm
                                                        title={'Eliminar grupo?'}
                                                        onConfirm={() =>
                                                            record.id && handleRemove && handleRemove(record.id)
                                                        }
                                                        okText={'Eliminar'}
                                                        cancelText={'Cancelar'}
                                                    >
                                                        <Typography.Link>
                                                            <RiDeleteBin2Fill
                                                                className="cursor-pointer transform hover:scale-150 duration-75 ml-1"
                                                                color="#DE3E1B"
                                                                title={'Eliminar'}
                                                            />
                                                        </Typography.Link>
                                                    </Popconfirm>
                                                </Space>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td className="py-4 px-6" colSpan={5}>
                                        <div className={'flex justify-center'}>No existe registros</div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className={'pt-2 pb-2'}>
                        <PaginationTable {...CourseStore.pagination} />
                    </div>
                </div>
            </Spin>
        </>
    );
});
