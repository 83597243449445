import React, { useEffect } from 'react';
import { Button, Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import { DateRangePicker, useQueryParameters } from '../../../../library';

interface Props {
    path: string;
}

export const ReportRegistrationFilter = (props: Props) => {
    const navigate = useNavigate();
    const query = useQueryParameters();

    const [form] = Form.useForm();

    const handleSubmit = (values: any) => {
        const params: any = [];

        if (values.created_time) {
            const created_time_min = moment(values.created_time[0]).format('YYYY-MM-DD');
            const created_time_max = moment(values.created_time[1]).format('YYYY-MM-DD');
            params['range_dates'] = [created_time_min, created_time_max];
        }

        const queryString = Object.keys(params)
            .map(key => key + '=' + params[key])
            .join('&');

        navigate(`${props.path}/?${queryString}`, { replace: true });
    };

    useEffect(() => {
        form.resetFields();
    }, []);

    return (
        <div className={'flex justify-start w-full py-6 px-8'}>
            <div className={'w-full'}>
                <Form onFinish={handleSubmit} layout="horizontal" form={form}>
                    <div className="grid grid-cols-2 lg:grid-cols-2 gap-4">
                        <Form.Item label="Fechas:" name="created_time" initialValue={query.get('created_time')}>
                            <DateRangePicker locale={locale} size={'small'} />
                        </Form.Item>
                    </div>

                    <Form.Item>
                        <div className="flex justify-center space-x-4">
                            <Button className={'second'} type="primary" htmlType={'submit'}>
                                Filtrar
                            </Button>
                            <Button
                                title={'Doble clic, para limpiar datos'}
                                onClick={() => {
                                    form.resetFields();
                                    navigate(`${props.path}/`, { replace: true });
                                }}
                            >
                                Limpiar Filtros
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};
