import { LoginPage, RecoverPasswordPage } from '../pages/authentication';
import { RouteProps } from '../utils/types';
import PublicLayout from '../layouts/PublicLayout';

const publicRoutes: RouteProps[] = [
    {
        path: 'login',
        Component: LoginPage,
        Layout: PublicLayout,
    },
    {
        path: 'recover_password',
        Component: RecoverPasswordPage,
        Layout: PublicLayout,
    },
];

export default publicRoutes;
