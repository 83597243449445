import { makeObservable, observable, runInAction } from 'mobx';
import { get, patch, post } from '../utils/api';
import { CompanyProps } from '../utils/types';

class CompanyStore {
    company: CompanyProps | null = null;
    priests: { id: number; name: string }[] = [];
    logos = false;
    signature = false;

    constructor() {
        makeObservable(this, {
            company: observable,
            logos: observable,
            signature: observable,
        });
    }

    async get(id: number) {
        const lg = localStorage.getItem('logos');
        const sg = localStorage.getItem('signature');

        if (lg && lg === 'true') {
            await runInAction(() => {
                this.logos = true;
            });
        } else if (lg && lg === 'false') {
            await runInAction(() => {
                this.logos = false;
            });
        } else {
            const response = await get(`/staff/company/${id}`);
            localStorage.setItem('logos', response.data.is_logo);
            runInAction(() => {
                this.company = response.data;
                this.logos = response.data.is_logo;
            });
        }

        if (sg && sg === 'true') {
            await runInAction(() => {
                this.signature = true;
            });
        } else if (sg && sg === 'false') {
            await runInAction(() => {
                this.signature = false;
            });
        } else {
            const response = await get(`/staff/company/${id}`);
            localStorage.setItem('signature', response.data.is_signature);
            runInAction(() => {
                this.company = response.data;
                this.signature = response.data.is_signature;
            });
        }
    }

    async getOnlyCompany(id: number) {
        return await get(`/staff/company/${id}`);
    }

    async patch(payload: any, id?: number) {
        const response = await patch(`/staff/company/${id}/`, payload);
        runInAction(() => {
            localStorage.setItem('logos', response.data.is_logo);
            localStorage.setItem('signature', response.data.is_signature);
            this.company = response.data;
            this.logos = response.data.is_logo;
            this.signature = response.data.is_signature;
        });
        return response.data;
    }

    async getPriests(params = {}) {
        const response = await get(`/staff/company/priests/`, params);
        runInAction(() => {
            this.priests = response.data;
        });
    }

    async updateSignatureFile(company_id: number, params = {}) {
        const response = await post(`/staff/company/${company_id}/update-signature-file/`, params);
        runInAction(() => {
            this.company = response.data;
        });
    }
}

export default new CompanyStore();
