import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Divider, Form, Input, Popconfirm } from 'antd';
import { VscFilePdf, VscSaveAll, VscSaveAs, VscTrash } from 'react-icons/vsc';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import { Strings } from '../../../utils/strings';
import { BaptismProps } from '../types';

interface Props {
    type: 'create' | 'update';
    baptism?: BaptismProps;
    handleUpdate?: (payload: BaptismProps, list: boolean) => void;
    handleCreate?: (payload: BaptismProps, list: boolean) => void;
    handleRemove?: (id: number) => void;
    handlePreviewCertificate?: (id: number, template: string) => void;
}

const { TextArea } = Input;

export const BaptismForm = ({
    type,
    baptism,
    handleCreate,
    handleRemove,
    handleUpdate,
    handlePreviewCertificate,
}: Props) => {
    const [form] = Form.useForm();
    const [list, setList] = useState(false);

    useEffect(() => {
        form.resetFields();
    }, [baptism]);

    const onFinish = (values: BaptismProps) => {
        const payload: BaptismProps = {
            member: values.member,
            father: values.father,
            mother: values.mother,
            place: values.place,
            date: moment(values.date).format('YYYY-MM-DD'),
            tome: values.tome,
            folio: values.folio,
            number: values.number,
            priest: values.priest,
            godfather_1: values.godfather_1,
            godfather_2: values.godfather_2,
            note: values.note,
            birth_date: values.birth_date ? moment(values.birth_date).format('YYYY-MM-DD') : null,
            birth_place: values.birth_place,
            birth_register: values.birth_register,
            birth_year: values.birth_year,
            birth_tome: values.birth_tome,
            birth_folio: values.birth_folio,
            birth_number: values.birth_number,
            birth_certificate: values.birth_certificate,
        };
        if (type === 'create' && handleCreate) {
            handleCreate(payload, list);
            form.resetFields();
        } else if (type === 'update' && handleUpdate) {
            const refactor_payload = {
                id: baptism?.id,
                ...payload,
            };
            handleUpdate(refactor_payload, list);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            form={form}
            initialValues={{
                member: baptism?.member,
                father: baptism?.father,
                mother: baptism?.mother,
                place: baptism?.place,
                date: baptism?.date ? moment(baptism?.date, 'YYYY-MM-DD') : '',
                tome: baptism?.tome,
                folio: baptism?.folio,
                number: baptism?.number,
                priest: baptism?.priest,
                godfather_1: baptism?.godfather_1,
                godfather_2: baptism?.godfather_2,
                note: baptism?.note,
                birth_date: baptism?.birth_date ? moment(baptism?.birth_date, 'YYYY-MM-DD') : '',
                birth_place: baptism?.birth_place,
                birth_register: baptism?.birth_register,
                birth_year: baptism?.birth_year,
                birth_tome: baptism?.birth_tome,
                birth_folio: baptism?.birth_folio,
                birth_number: baptism?.birth_number,
                birth_certificate: baptism?.birth_certificate,
            }}
            name="add-edit-baptism"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
        >
            <div className="grid grid-cols-2 gap-4">
                <Form.Item
                    label={'Nombre'}
                    name="member"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={'Fecha'}
                    name="date"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <DatePicker locale={locale} className="form-control" style={{ width: '100%' }} />
                </Form.Item>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <Form.Item label={'Papá'} name="father">
                    <Input />
                </Form.Item>

                <Form.Item label={'Mamá'} name="mother">
                    <Input />
                </Form.Item>
            </div>

            <div className="grid grid-cols-5 gap-4">
                <div className="col-span-2">
                    <Form.Item
                        label={'Lugar de Bautizo'}
                        name="place"
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <Input />
                    </Form.Item>
                </div>

                <Form.Item
                    label={'Tomo'}
                    name="tome"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={'Folio'}
                    name="folio"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={'Número'}
                    name="number"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <Form.Item
                    label={'Primer Padrino'}
                    name="godfather_1"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item label={'Segundo Padrino'} name="godfather_2">
                    <Input />
                </Form.Item>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <Form.Item
                    label={'Ministro'}
                    name="priest"
                    rules={[{ required: true, message: Strings.inputs.errors.required }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item label={'Nota Marginal'} name="note">
                    <TextArea autoSize />
                </Form.Item>
            </div>

            <Divider>{'Datos de Nacimiento'}</Divider>

            <div className="grid grid-cols-3 gap-4">
                <Form.Item label={'Fecha de Nacimiento'} name="birth_date">
                    <DatePicker locale={locale} className="form-control" style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item label={'Lugar de Nacimiento (Ciudad)'} name="birth_place">
                    <Input />
                </Form.Item>

                <Form.Item label={'Registro Civil (Ciudad)'} name="birth_register">
                    <Input />
                </Form.Item>
            </div>

            <div className="grid grid-cols-5 gap-4">
                <Form.Item label={'Año'} name="birth_year">
                    <Input />
                </Form.Item>

                <Form.Item label={'Tomo'} name="birth_tome">
                    <Input />
                </Form.Item>

                <Form.Item label={'Folio'} name="birth_folio">
                    <Input />
                </Form.Item>
                <Form.Item label={'Número'} name="birth_number">
                    <Input />
                </Form.Item>

                <Form.Item label={'ID Partida'} name="birth_certificate">
                    <Input />
                </Form.Item>
            </div>

            <Form.Item>
                <div className={'flex flex-row space-x-4'}>
                    <Button
                        className="second"
                        type="primary"
                        htmlType="submit"
                        icon={<VscSaveAs className="inline-flex mr-1" size={'18px'} />}
                        onClick={() => setList(false)}
                    >
                        Guardar
                    </Button>
                    <Button
                        className={'second'}
                        type="primary"
                        htmlType="submit"
                        icon={<VscSaveAll className="inline-flex mr-1" size={'18px'} />}
                        onClick={() => setList(true)}
                    >
                        Guardar y ver registros
                    </Button>
                    {type === 'update' && (
                        <>
                            <Button
                                type="default"
                                icon={<VscFilePdf className="inline-flex mr-1" size={'18px'} />}
                                onClick={() =>
                                    handlePreviewCertificate &&
                                    baptism?.id &&
                                    handlePreviewCertificate(baptism.id, 'A4')
                                }
                            >
                                Certificado en A4
                            </Button>

                            <Button
                                type="default"
                                icon={<VscFilePdf className="inline-flex mr-1" size={'18px'} />}
                                onClick={() =>
                                    handlePreviewCertificate &&
                                    baptism?.id &&
                                    handlePreviewCertificate(baptism.id, 'A5')
                                }
                            >
                                Certificado en A5
                            </Button>

                            <Popconfirm
                                title={'¿Eliminar Bautizo?'}
                                onConfirm={() => handleRemove && baptism?.id && handleRemove(baptism.id)}
                                okText={'Eliminar'}
                                cancelText={'Cancelar'}
                            >
                                <Button
                                    className={'remove'}
                                    type="default"
                                    icon={<VscTrash className="inline-flex mr-1" size={'18px'} />}
                                >
                                    Eliminar
                                </Button>
                            </Popconfirm>
                        </>
                    )}
                </div>
            </Form.Item>
        </Form>
    );
};
