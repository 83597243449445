import React from 'react';
import { Popconfirm, Space, Typography } from 'antd';
import RegistrationStore from '../stores/RegistrationStore';
import { Spin } from 'antd/es';
import moment from 'moment/moment';
import { capitalizeFirstLetter, PaginationTable } from '../../../library';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { BsFileEarmarkPdf } from 'react-icons/bs';

interface Props {
    loading: boolean;
    registrations: any[];
    get_file_registration: (registration_id: number) => void;
    handleRemove: (registration_id: number) => void;
}

export const RegistrationsTable = (props: Props) => {
    return (
        <>
            <div className="relative overflow-x-auto">
                <Spin spinning={props.loading}>
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-4 py-6">
                                    Integrante
                                </th>
                                <th scope="col" className="px-4 py-6">
                                    Fecha de Matrícula
                                </th>
                                <th scope="col" className="px-4 py-6">
                                    Comunidad / Nivel / Grupo
                                </th>
                                <th scope="col" className="px-4 py-6">
                                    Costo
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    <span className="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.registrations.length > 0 ? (
                                props.registrations.map((record: any, index: number) => {
                                    return (
                                        <tr
                                            key={index}
                                            className={
                                                index % 2 == 0
                                                    ? 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'
                                                    : 'bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700'
                                            }
                                        >
                                            <td className={'px-4 py-6'}>
                                                <div className={'font-bold'}>
                                                    {record.member.last_name !== null && (
                                                        <span>
                                                            {capitalizeFirstLetter(record.member.last_name)}
                                                            &nbsp;
                                                        </span>
                                                    )}
                                                    {capitalizeFirstLetter(record.member.name)}
                                                </div>
                                                <div className={'text-xs'}>
                                                    Rep: {capitalizeFirstLetter(record.representative)}
                                                </div>
                                            </td>
                                            <td className={'px-4 py-6'}>
                                                {moment(record.created_at).format('YYYY-MM-DD  HH:mm')}
                                            </td>
                                            <td className={'px-4 py-6'}>
                                                <div>{capitalizeFirstLetter(record.course.level.alias)}</div>
                                                <div className={'text-xs'}>
                                                    {capitalizeFirstLetter(record.course.community.name)} |{' '}
                                                    {capitalizeFirstLetter(record.course.name)}
                                                </div>
                                            </td>
                                            <td className="py-4 px-6">$ {record.price}</td>
                                            <td className="py-4 px-6">
                                                <Space size="middle" className={'px-2'}>
                                                    <BsFileEarmarkPdf
                                                        color={'#009688'}
                                                        className="cursor-pointer transform hover:scale-150 duration-75"
                                                        title="Comprobante"
                                                        onClick={() => {
                                                            props.get_file_registration(record.id);
                                                        }}
                                                    />
                                                </Space>

                                                <Space size="middle" className={'px-2'}>
                                                    <Popconfirm
                                                        title={'Eliminar matrícula?'}
                                                        onConfirm={() =>
                                                            record.id &&
                                                            props.handleRemove &&
                                                            props.handleRemove(record.id)
                                                        }
                                                        okText={'Eliminar'}
                                                        cancelText={'Cancelar'}
                                                    >
                                                        <Typography.Link>
                                                            <RiDeleteBin2Fill
                                                                className="cursor-pointer transform hover:scale-150 duration-75 ml-1"
                                                                color="#DE3E1B"
                                                                title={'Eliminar'}
                                                            />
                                                        </Typography.Link>
                                                    </Popconfirm>
                                                </Space>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td className="py-4 px-6" colSpan={5}>
                                        <div className={'flex justify-center'}>No existe registros</div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className={'pt-2 pb-2'}>
                        <PaginationTable {...RegistrationStore.pagination} />
                    </div>
                </Spin>
            </div>
        </>
    );
};
