export const Strings = {
    actions: {
        log_in: 'Iniciar sesión',
        login: 'Ingresar',
        recover_password: 'Recuperar',
        send: 'Enviar',
        save: 'Guardar',
        cancel: 'Cancelar',
        delete: 'Eliminar',
        clean_form: 'Limpiar formulario',
        startTutorial: 'Iniciar Tutorial',
        generate_certificate: 'Generar certificado',
        view_in_pdf: 'Ver en PDF',
        view_images: 'Ver imagenes',
    },
    inputs: {
        errors: {
            invalid_email: 'Correo electrónico no valido',
            required_email: 'Ingrese un correo electrónico',
            required_password: 'Ingrese una contraseña',
            required: 'Este campo es requerido!',
        },
        placeholders: {
            email: 'Correo electrónico',
            password: 'Contraseña',
        },
    },
    labels: {
        recover_password: '¿Olvidaste la contraseña?',
        login: '¿Tienes una cuenta?',
    },
    notifications: {
        error_login: 'Usuario o Contraseña incorrectas',
        error_email_recover: '¡Hubo un error!, intente de nuevo',
        success_welcome: 'Bienvenido',
        success_recover: 'Se ha enviado un email para restablecer su contraseña.',
    },
    texts: {
        login: 'Inicie sesión para ingresar al sistema',
        recover_password: 'Introduzca la dirección de correo electrónico que utiliza para iniciar sesión',
    },
    titles: {
        app: 'Sistema de Gestión Parroquial Alpes',
        login: 'Bienvenido',
        recover_password: 'Recuperar contraseña',
    },
    modals: {
        welcome: {
            title: 'Bienvenido a Sgp - Alpes!',
            description:
                'Si es la primera vez que utilizas Sgp-Alpes, te recomendamos que sigas un rápido tutorial paso a paso ' +
                'sobre el funcionamiento de la plataforma. Por favor, haga clic en el botón de abajo para iniciar el tutorial',
        },
    },
    inventory: {
        article: 'Artículo',
        area: 'Área',
        subarea: 'Subárea',
        code: 'Código',
        quantity: 'Cantidad',
        state: 'Estado',
        value: 'Valor',
        location: 'Ubicación',
        description: 'Descripción',
        uploadImages: 'Subir Imágenes',
        name: 'Nombre',
        newName: 'Nuevo Nombre',
    },
};
