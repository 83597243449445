import React from 'react';
import { Button, DatePicker, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import locale from 'antd/es/date-picker/locale/es_ES';
import CommunitySearchSelect from '../selects/CommunitySearchSelect';
import LevelSelect from '../selects/LevelSelect';
import { LevelProps } from '../types';
import { useQueryParameters } from '../../../library';

interface Props {
    levels: LevelProps[];
}

export const RegistrationsFilter = (props: Props) => {
    const query = useQueryParameters();
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        const params: any = {};

        if (values.full_name) params['full_name'] = values.full_name;
        if (values.parents) params['parents'] = values.parents;
        if (values.level !== 'undefined' && values.level !== null) params['level'] = values.level.value;
        if (values.community !== 'undefined' && values.community !== null) params['community'] = values.community.value;
        if (values.created_at) params['created_at'] = moment(values.created_at).format('YYYY-MM-DD');

        const queryString = Object.keys(params)
            .map(key => key + '=' + params[key])
            .join('&');

        navigate(`/catechism/registration/?${queryString}`, { replace: true });
    };

    return (
        <div className={'flex justify-start w-full py-6 px-8'}>
            <div className="w-full">
                <Form onFinish={onFinish} layout="horizontal" form={form}>
                    <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <Form.Item label="Integrante" name="full_name" initialValue={query.get('full_name')}>
                            <Input placeholder="Apellidos o nombres" allowClear size={'small'} />
                        </Form.Item>

                        <Form.Item label="Padres" name="parents" initialValue={query.get('parents')}>
                            <Input allowClear size={'small'} />
                        </Form.Item>
                    </div>
                    <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <Form.Item
                            label="Matrícula"
                            name={'created_at'}
                            initialValue={query.get('date') ? moment(query.get('created_at'), 'YYYY-MM-DD') : null}
                        >
                            <DatePicker locale={locale} size="small" style={{ width: '100%' }} />
                        </Form.Item>

                        <Form.Item label="Nivel" initialValue={query.get('level')} name={'level'}>
                            <LevelSelect levels={props.levels} size={'small'} />
                        </Form.Item>
                    </div>
                    <div className="grid grid-cols-1 gap-4">
                        <Form.Item label="Comunidad" initialValue={query.get('community')} name={'community'}>
                            <CommunitySearchSelect size={'small'} />
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <div className="flex justify-center space-x-4">
                            <Button className={'second'} type="primary" htmlType={'submit'}>
                                Filtrar Registros
                            </Button>
                            <Button
                                onClick={async () => {
                                    form.resetFields();
                                    navigate(`/catechism/registration/`, { replace: true });
                                }}
                            >
                                Limpiar Filtros
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};
