import React, { useEffect } from 'react';
import { Button, Input, Form } from 'antd';
import { ZoneProps } from '../types';
import { Strings } from '../../../utils/strings';
import { VscSaveAll } from 'react-icons/vsc';
import { FcCancel } from 'react-icons/fc';

interface Props {
    record?: ZoneProps | null;
    handleCreate?: (payload: ZoneProps) => void;
    handleUpdate?: (payload: ZoneProps, id: number) => void;
    setIsModalVisible: (value: boolean) => void;
}

export const ZoneForm: React.FC<Props> = ({ record, handleCreate, handleUpdate, setIsModalVisible }: Props) => {
    const [form] = Form.useForm();

    const onFinish = (values: { name: string; description: string }) => {
        const payload = {
            name: values.name,
            description: values.description,
        };

        if (handleUpdate && record && record.id) {
            handleUpdate(payload, record.id);
        } else if (handleCreate) {
            handleCreate(payload);
        }
        form.resetFields();
    };

    useEffect(() => {
        form.resetFields();
    }, [record]);

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Form
                form={form}
                initialValues={{ name: record?.name, description: record?.description }}
                name="period-form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
            >
                <div className="grid grid-cols-1 gap-4">
                    <Form.Item
                        label="Nombre"
                        name="name"
                        rules={[{ required: true, message: Strings.inputs.errors.required }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item label="Descripción" name="description">
                        <Input />
                    </Form.Item>

                    <Form.Item>
                        <div className={'flex flex-row-reverse'}>
                            <Button
                                className={'mx-2 second'}
                                type="primary"
                                htmlType="submit"
                                icon={<VscSaveAll className="inline-flex mr-1" size={'18px'} />}
                            >
                                {Strings.actions.save}
                            </Button>
                            <Button
                                className={'mx-2 remove'}
                                onClick={() => {
                                    form.resetFields();
                                    setIsModalVisible(false);
                                }}
                                icon={<FcCancel className="inline-flex mr-1" size={'18px'} />}
                            >
                                {Strings.actions.cancel}
                            </Button>
                        </div>
                    </Form.Item>
                </div>
            </Form>
        </>
    );
};
