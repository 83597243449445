import React, { useEffect, useState } from 'react';
import { PageHeaderReports, Price } from '../../../library';
import { notification, Spin } from 'antd';
import { ReportRegistrationFilter } from './filters';
import { useLocation } from 'react-router-dom';
import ErrorRender from '../../../library/outlets/ErrorRender';
import ReportStore from '../../../stores/ReportStore';
import { observer } from 'mobx-react';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const CatechismRegistrationsReportPage = observer(() => {
    const searchParams = useQuery();
    const [loading, setLoading] = useState<boolean>(false);

    const getReports = async (params = {}) => {
        setLoading(true);
        await ReportStore.getCatechismRegistrations(params)
            .catch((err: any) => {
                notification.error({
                    message: <ErrorRender error={err} />,
                    placement: 'topRight',
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getReports(searchParams);
    }, [location.search]);

    return (
        <>
            <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
                <Spin spinning={loading}>
                    <PageHeaderReports
                        title={'Catequeis: Matrículas'}
                        subtitle={'Ingresos económicos por matrículas'}
                    />
                    <ReportRegistrationFilter path={'/reports-catechism/registrations'} />
                </Spin>
            </div>

            <div>
                <Spin spinning={loading}>
                    {ReportStore.registrations_totals !== null && (
                        <section className="text-gray-600 body-font flex justify-center items-center">
                            <div className="container px-5 py-5 mx-auto">
                                <div className="flex flex-wrap -m-4 text-center">
                                    <div className="p-4 sm:w-1/2 lg:w-1/3 w-full hover:scale-105 duration-500">
                                        <div className=" flex items-center  justify-between p-4  rounded-lg bg-white shadow-indigo-50 shadow-md">
                                            <div>
                                                <span className="text-lg">Ingresos</span>
                                                <h3 className="mt-2 text-xl font-bold text-yellow-500 text-left">
                                                    $ <Price value={ReportStore.registrations_totals.grand_total} />
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-4 sm:w-1/2 lg:w-1/3 w-full hover:scale-105 duration-500">
                                        <div className=" flex items-center  justify-between p-4  rounded-lg bg-white shadow-indigo-50 shadow-md">
                                            <div>
                                                <span className="text-lg">Integrantes</span>
                                                <h3 className="mt-2 text-xl font-bold text-alpes_green-500 text-left">
                                                    {ReportStore.registrations_totals.count}
                                                </h3>
                                            </div>
                                            <div>
                                                <div>
                                                    <h1 className="text-gray-400 text-2xl">
                                                        {ReportStore.registrations_totals.percent_man.toFixed(2)}%
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-4 sm:w-1/2 lg:w-1/3 w-full hover:scale-105 duration-500">
                                        <div className=" flex items-center  justify-between p-4  rounded-lg bg-white shadow-indigo-50 shadow-md">
                                            <div>
                                                <span className="text-lg">Hombres</span>
                                                <h3 className="mt-2 text-xl font-bold text-cyan-500 text-left">
                                                    {ReportStore.registrations_totals.man_total}
                                                </h3>
                                            </div>
                                            <div>
                                                <div>
                                                    <h1 className="text-gray-400 text-2xl">
                                                        {ReportStore.baptisms.total_percent_woman} %
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-4 sm:w-1/2 lg:w-1/3 w-full hover:scale-105 duration-500">
                                        <div className=" flex items-center  justify-between p-4  rounded-lg bg-white shadow-indigo-50 shadow-md">
                                            <div>
                                                <span className="text-lg">Mujeres</span>
                                                <h3 className="mt-2 text-xl font-bold text-pink-500 text-left">
                                                    {ReportStore.registrations_totals.woman_total}
                                                </h3>
                                            </div>
                                            <div>
                                                <div>
                                                    <h1 className="text-red-600 text-2xl">
                                                        {ReportStore.registrations_totals.percent_woman.toFixed(2)}%
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </Spin>
            </div>
        </>
    );
});
