import React, { useEffect } from 'react';
import moment from 'moment';
import { Button, DatePicker, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import locale from 'antd/es/date-picker/locale/es_ES';
import { useQueryParameters } from '../../../library';
import PriestSearchSelect from '../../../library/selects/PriestSearchSelect';

const Search = Input.Search;

const FormItem = Form.Item;

interface Props {
    path: string;
}

export const BooksFilter = (props: Props) => {
    const navigate = useNavigate();

    const query = useQueryParameters();

    const [form] = Form.useForm();

    const handleSubmit = (values: any) => {
        const params: any = {};
        if (values.search) params['search'] = values.search;
        if (values.tome) params['tome'] = values.tome;
        if (values.folio) params['folio'] = values.folio;
        if (values.number) params['number'] = values.number;
        if (values.priest) params['priest'] = values.priest.label;
        if (values.date) params['date'] = moment(values.date).format('YYYY-MM-DD');

        if (props.path === 'books-baptisms' && values.parents_or_godparents)
            params['parents_or_godparents'] = values.parents_or_godparents;

        if (values.created_at) {
            params['created_at_min'] = moment(values.created_at[0]).format('YYYY-MM-DD');
            params['created_at_max'] = moment(values.created_at[1]).format('YYYY-MM-DD');
        }

        const queryString = Object.keys(params)
            .map(key => key + '=' + params[key])
            .join('&');

        navigate(`${props.path}/?${queryString}`, { replace: true });
    };

    useEffect(() => {
        form.resetFields();
    }, []);

    return (
        <div className={'flex justify-start w-full py-6 px-8'}>
            <div className="w-full">
                <Form onFinish={handleSubmit} layout="horizontal" form={form}>
                    <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <FormItem label="Buscar por" initialValue={query.get('search')} name={'search'}>
                            <Search placeholder="Nombres" style={{ width: '100%' }} allowClear size={'small'} />
                        </FormItem>
                        <FormItem label="Ministro" initialValue={query.get('priest')} name={'priest'}>
                            {/*<Select allowClear showSearch={true} size="small">*/}
                            {/*    {props.priests.length > 0 &&*/}
                            {/*        props.priests.map((priest: any, index: number) => {*/}
                            {/*            return (*/}
                            {/*                <Select.Option key={index} value={priest.name}>*/}
                            {/*                    {priest.name}*/}
                            {/*                </Select.Option>*/}
                            {/*            );*/}
                            {/*        })}*/}
                            {/*</Select>*/}
                            <PriestSearchSelect size={'small'} />
                        </FormItem>
                    </div>
                    <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <FormItem
                            label="Fecha de bautizo"
                            name={'date'}
                            initialValue={query.get('date') ? moment(query.get('date'), 'YYYY-MM-DD') : null}
                        >
                            <DatePicker locale={locale} size="small" style={{ width: '100%' }} />
                        </FormItem>
                        <FormItem
                            label="Fecha de registro (Rango)"
                            name={'created_at'}
                            initialValue={
                                query.get('created_at_min') && query.get('created_at_max')
                                    ? [
                                          moment(query.get('created_at_min'), 'YYYY-MM-DD'),
                                          moment(query.get('created_at_max'), 'YYYY-MM-DD'),
                                      ]
                                    : null
                            }
                        >
                            <DatePicker.RangePicker locale={locale} size="small" />
                        </FormItem>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <FormItem label="Tomo" initialValue={query.get('tome')} name={'tome'}>
                            <Input size={'small'} />
                        </FormItem>

                        <FormItem label="Folio" initialValue={query.get('folio')} name={'folio'}>
                            <Input size={'small'} />
                        </FormItem>

                        <FormItem label="Número" initialValue={query.get('number')} name={'number'}>
                            <Input size={'small'} />
                        </FormItem>
                    </div>

                    {props.path === 'books-baptisms' && (
                        <div className=" grid grid-cols-1 lg:grid-cols-1 gap-4">
                            <FormItem
                                label="Padres o Padrinos"
                                initialValue={query.get('parents_or_godparents')}
                                name={'parents_or_godparents'}
                            >
                                <Input size={'small'} />
                            </FormItem>
                        </div>
                    )}

                    <Form.Item>
                        <div className="flex justify-center space-x-4">
                            <Button className={'second'} type="primary" htmlType={'submit'}>
                                Filtrar Registros
                            </Button>
                            <Button
                                onClick={() => {
                                    form.resetFields();
                                    navigate(`${props.path}/`, { replace: true });
                                }}
                            >
                                Limpiar Filtros
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};
