import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import SidebarStore from '../../stores/SidebarStore';
import UserStore from '../../stores/UserStore';
import { AiOutlineMenu } from 'react-icons/ai';
import { getCurrentUser } from '../../hooks';
import { SettingsDrawer, UserMenu } from '../index';
import { useAuth } from '../../context/Auth';
import { useNavigate } from 'react-router-dom';
import Notifications from '../menus/Notifications';
import { INotificationProps } from '../../utils/types';
import NotificationStore from '../../stores/NotificationStore';
import { notification } from 'antd';
import ErrorRender from '../outlets/ErrorRender';

export const Navbar = observer(() => {
    const ref = useRef<HTMLButtonElement>(null);
    const companyName = getCurrentUser();
    const auth = useAuth();
    const navigate = useNavigate();
    const [openDrawerSettings, setDrawerSettings] = useState(false);
    const [data, setData] = useState<INotificationProps[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const onClickOpenSidebar = () => {
        SidebarStore.isVisible ? SidebarStore.handleVisibleSidebar(false) : SidebarStore.handleVisibleSidebar(true);
    };

    useEffect(() => {
        SidebarStore.getButtonElement(ref);
    }, []);

    const handleLogout = async () => {
        await UserStore.logout();
        // @ts-ignore
        await auth.logoutContext();
        navigate('/login', { replace: true });
    };

    const handleDrawerSettings = async (value: boolean) => {
        setDrawerSettings(value);
    };

    async function getNotifications() {
        setLoading(true);

        try {
            const response = await NotificationStore.getNotifications();
            setData(response.data.results);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    useEffect(() => {
        getNotifications();
    }, []);

    async function makeRead(id: number) {
        setLoading(true);
        try {
            await NotificationStore.makeNotificationRead(id);
            await getNotifications();
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    return (
        <>
            <header className="sticky top-0 bg-gray-200 border-b border-slate-200 z-30">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16 -mb-px">
                        <div className="flex">
                            <button
                                ref={ref}
                                className="text-slate-500 hover:text-slate-600 lg:hidden"
                                aria-controls="sidebar"
                                aria-expanded={SidebarStore.isVisible}
                                onClick={onClickOpenSidebar}
                            >
                                <AiOutlineMenu className="text-slate-700" size={'20px'} />
                            </button>
                            <span className="font-semibold text-base text-slate-700 hidden tracking-wide lg:inline-flex">
                                {companyName.data?.company.legal_name}
                            </span>
                        </div>

                        <div className={'flex flex-row space-x-4'}>
                            <Notifications data={data} loading={loading} makeRead={makeRead} />
                            <UserMenu
                                showSettings={handleDrawerSettings}
                                companyName={companyName.data}
                                logout={handleLogout}
                            />
                        </div>
                    </div>
                </div>
            </header>

            {openDrawerSettings && (
                <SettingsDrawer handleDrawerSettings={handleDrawerSettings} openDrawerSettings={openDrawerSettings} />
            )}
        </>
    );
});
