import React from 'react';
import { Drawer, Spin } from 'antd';
import { isMobile } from 'react-device-detect';

interface Props {
    loading: boolean;
    handleClose: (value: boolean) => void;
    fileUrl: string;
}

export const CertificatePreview = (props: Props) => {
    if (isMobile) {
        return null;
    }

    return (
        <Drawer
            title="Certificado"
            width={'80%'}
            closable
            visible
            placement={'left'}
            onClose={() => props.handleClose(true)}
        >
            <Spin spinning={props.loading} tip={'Loading...'}>
                <iframe
                    title="Cerificado"
                    src={props.fileUrl}
                    frameBorder="0"
                    height={window.innerHeight}
                    width="100%"
                />
            </Spin>
        </Drawer>
    );
};
