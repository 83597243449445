import React, { useEffect, useState } from 'react';
import { MenuTop as CatechismMenuTop } from '../MenuTop';
import PeriodStore from '../stores/PeriodStore';
import { CommunityProps } from '../types';
import CommunityStore from '../stores/CommunityStore';
import { Button, Card, Modal, notification } from 'antd';
import { SiAddthis } from 'react-icons/si';
import { observer } from 'mobx-react';
import ErrorRender from '../../../library/outlets/ErrorRender';
import { CommunitiesTable } from '../tables';
import { CreateForms } from '../CreateForms';
import { toastSuccess } from '../../../utils/toast';

export const CatechismCommunityPage = observer(() => {
    const [loading, setLoading] = useState(false);
    const [isModalCreate, setIsModalCreate] = useState(false);

    async function handleList() {
        setLoading(true);
        try {
            await CommunityStore.list();
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function handleCreateCommunity(values: CommunityProps) {
        const payload: CommunityProps = {
            zone: values.zone,
            name: values.name,
            description: values.description,
        };
        setLoading(true);
        try {
            await CommunityStore.create(payload);
            await handleList();
            toastSuccess('Comunidad registrada!');
            setLoading(false);
            setIsModalCreate(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    useEffect(() => {
        handleList();
        getPeriodActive();
    }, []);

    async function handleRemove(id: number) {
        setLoading(true);
        try {
            await CommunityStore.remove(id);
            toastSuccess('Comunidad eliminada!');
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function getPeriodActive() {
        try {
            await PeriodStore.get_active();
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
    }

    return (
        <>
            <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
                <CatechismMenuTop active={'community'} />

                <div className={'my-8'}>
                    <Card
                        bordered={false}
                        size="small"
                        title={
                            <>
                                <span className="font-bold">Comunidades</span> o lugares donde se dicta catequesis.
                            </>
                        }
                        extra={
                            <div className={'flex flex-row'}>
                                <div className={'mr-5 mt-1.5'}>
                                    {PeriodStore.period_active !== null && (
                                        <span>
                                            <b>Período activo: </b>
                                            {PeriodStore.period_active.year}
                                        </span>
                                    )}
                                </div>
                                <Button type={'link'} onClick={() => setIsModalCreate(true)}>
                                    <div className={'flex flex-row'}>
                                        <SiAddthis className={'mt-1 mr-1'} />
                                        <span>Nueva comunidad</span>
                                    </div>
                                </Button>
                            </div>
                        }
                    >
                        <CommunitiesTable
                            showActions={true}
                            loading={loading}
                            records={CommunityStore.communities}
                            handleRemove={handleRemove}
                        />
                    </Card>
                </div>

                {isModalCreate && (
                    <Modal
                        title={<b>{'Nueva Comunidad'}</b>}
                        visible={isModalCreate}
                        footer={false}
                        onCancel={() => setIsModalCreate(false)}
                    >
                        <CreateForms
                            isModalVisible={isModalCreate}
                            setIsModalVisible={setIsModalCreate}
                            modal={{ title: 'Nueva Comunidad', type: 'FormCommunity' }}
                            handleCreate={handleCreateCommunity}
                        />
                    </Modal>
                )}
            </div>
        </>
    );
});
